/* eslint-disable no-param-reassign */
import { IStepAction, IStepFormsState } from './types'
import { sliceName } from './constants'

export const setStepData = (
  state: IStepFormsState[typeof sliceName],
  { payload }: IStepAction
): void => {
  state.entity = payload
}
