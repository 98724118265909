import { INews, INewsState } from './types'
import { sliceName } from './constants'
import { getStateItem } from '../../utils/core'

export default {
  loading: getStateItem<INewsState, INewsState[typeof sliceName]['loading']>(
    sliceName,
    'loading'
  ),
  entity: getStateItem<INews, INews>(sliceName, 'entity'),
}
