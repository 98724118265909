import { FC } from 'react'
import { ChooseLocation as ChooseLocationDesktop } from './desktop'
import { ChooseLocation as ChooseLocationTouch } from './touch'
import { IChooseLocationIndexProps } from './types'

const ChooseLocation: FC<IChooseLocationIndexProps> = (props) => {
  const { isTouch } = props

  if (isTouch) {
    return <ChooseLocationTouch />
  }

  return <ChooseLocationDesktop />
}

export { ChooseLocation }
