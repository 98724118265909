import { INotice } from '@ucheba/ui/components/Notice/types'

export interface IUseVkPermissionNotice {
  (props: { allowedMessangers: boolean }): {
    notice: INotice
    title: string
    description: string
    buttonText: string
    removeStorage?: any
  }
}

export interface IVkPermissionNoticeIndex {
  isTouch: boolean
  blockedPaths?: string[]
}

export enum ENoticePermissionQueryType {
  n_m_block = 'n_m_block',
}
