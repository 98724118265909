/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import { ILocation, ILocations, ILocationState } from './types'
import { extraReducersResponse, responseInitialState } from '../utils/response'
import { sliceName } from './constants'
import thunks from './thunks'

export default createSlice({
  name: sliceName,

  initialState: {
    location: null as ILocation | null,
    locationByLocationIds: null as ILocation | null,
    allRussiaLocation: null as ILocation | null,
    locations: [] as ILocations['locations'],
    selectedLocations: [] as ILocations['locations'],
    popularLocations: [] as ILocation[],
    ...responseInitialState,
  },

  reducers: {
    setLocations: (state, { payload }) => {
      state.locations = payload
    },

    setSelectedLocations: (state, { payload }) => {
      state.selectedLocations = payload
    },

    setPopularLocations: (state, { payload }) => {
      state.popularLocations = payload
    },

    setAllRussiaLocation: (state, { payload }) => {
      state.allRussiaLocation = payload
    },
  },

  extraReducers: (builder) => {
    extraReducersResponse<ILocationState[typeof sliceName], ILocations['locations']>({
      builder,
      thunk: thunks.fetch,
      fulfilled: (state, { payload }) => {
        state.locations = payload
      },
    })

    extraReducersResponse<ILocationState[typeof sliceName], ILocation>({
      builder,
      thunk: thunks.getById,
      fulfilled: (state, { payload }) => {
        state.location = payload
      },
    })

    extraReducersResponse<ILocationState[typeof sliceName], ILocation>({
      builder,
      thunk: thunks.getByLocationIds,
      fulfilled: (state, { payload }) => {
        state.locationByLocationIds = payload
      },
    })
  },
})
