import styled, { keyframes, StyledProps } from 'styled-components'
import {
  EModColor,
  EModSize,
  IModSize,
  IModSpacing,
} from '@ucheba/utils/helpers/styles/mod.types'
import { sm } from '@ucheba/utils/helpers/styles/mixins'
import { sv } from '@ucheba/utils/helpers/styles/variables'
import { IModDuration } from '../types'

const startCountdown = keyframes`
  0% {
    width: 100%;
  }
  100% {
    width: 0%;
  }
`

const Block = styled.div<StyledProps<IModSize | IModSpacing | IModDuration>>`
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;

  ${sm.mod(EModSize.medium)`
    height: 2px;
  `};

  ${Object.keys(EModColor).map(
    (item) => sm.mod(EModColor[item])`
        background-color: ${sv.color[item]};
      `
  )};

  animation ${startCountdown} ${({ duration }): string => `${duration}ms`} linear;
`

export default { Block }
