import { sliceName } from './constsnts'
import { IProfMotivationTestResultsState } from './types'
import { getStateError, getStateItem } from '../../../utils/core'

export default {
  loading: getStateItem<
    IProfMotivationTestResultsState,
    IProfMotivationTestResultsState[typeof sliceName]['loading']
  >(sliceName, 'loading'),

  entity: getStateItem<
    IProfMotivationTestResultsState,
    IProfMotivationTestResultsState[typeof sliceName]['entity']
  >(sliceName, 'entity'),

  responses: getStateItem<
    IProfMotivationTestResultsState,
    IProfMotivationTestResultsState[typeof sliceName]['responses']
  >(sliceName, 'responses'),

  error: getStateError<IProfMotivationTestResultsState>(sliceName),
}
