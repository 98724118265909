import { Ref, useCallback } from 'react'
import { useOnClickOutside } from '@ucheba/utils/hooks/useOnClickOutside'
import { TUseToggleFunction } from '@ucheba/utils/hooks/useToggle'

export const useHandlerClickOutside = (
  ref: Ref<HTMLDivElement>,
  isOpen: boolean,
  toggleOpen: TUseToggleFunction,
  preventOutsideClose?: boolean
): void => {
  const handler = useCallback(
    () => !preventOutsideClose && isOpen && toggleOpen(),
    [isOpen, preventOutsideClose, toggleOpen]
  )

  useOnClickOutside(ref, handler)
}
