import { sliceName } from './constants'
import { IPageDataConsultState } from './types'
import { getStateItem } from '../../../utils/core'

export default {
  requestId: getStateItem<
    IPageDataConsultState,
    IPageDataConsultState[typeof sliceName]['requestId']
  >(sliceName, 'requestId'),

  isParent: getStateItem<
    IPageDataConsultState,
    IPageDataConsultState[typeof sliceName]['isParent']
  >(sliceName, 'isParent'),

  packageId: getStateItem<
    IPageDataConsultState,
    IPageDataConsultState[typeof sliceName]['packageId']
  >(sliceName, 'packageId'),

  successAddParent: getStateItem<
    IPageDataConsultState,
    IPageDataConsultState[typeof sliceName]['successAddParent']
  >(sliceName, 'successAddParent'),
}
