import { css } from 'styled-components'

export const fontProximaNova = css`
  @font-face {
    font-family: 'proxima-nova';
    src:
      url('https://use.typekit.net/af/949f99/00000000000000003b9b3068/27/l?primer=3a6076633bc77831ac9fb811601de898b4ca8cd1e0aff02d16b23a013090f4db&fvd=n7&v=3')
        format('woff2'),
      url('https://use.typekit.net/af/949f99/00000000000000003b9b3068/27/d?primer=3a6076633bc77831ac9fb811601de898b4ca8cd1e0aff02d16b23a013090f4db&fvd=n7&v=3')
        format('woff'),
      url('https://use.typekit.net/af/949f99/00000000000000003b9b3068/27/a?primer=3a6076633bc77831ac9fb811601de898b4ca8cd1e0aff02d16b23a013090f4db&fvd=n7&v=3')
        format('opentype');
    font-display: swap;
    font-style: normal;
    font-weight: 700;
  }

  @font-face {
    font-family: 'proxima-nova';
    src:
      url('https://use.typekit.net/af/d82519/00000000000000003b9b306a/27/l?primer=3a6076633bc77831ac9fb811601de898b4ca8cd1e0aff02d16b23a013090f4db&fvd=n8&v=3')
        format('woff2'),
      url('https://use.typekit.net/af/d82519/00000000000000003b9b306a/27/d?primer=3a6076633bc77831ac9fb811601de898b4ca8cd1e0aff02d16b23a013090f4db&fvd=n8&v=3')
        format('woff'),
      url('https://use.typekit.net/af/d82519/00000000000000003b9b306a/27/a?primer=3a6076633bc77831ac9fb811601de898b4ca8cd1e0aff02d16b23a013090f4db&fvd=n8&v=3')
        format('opentype');
    font-display: swap;
    font-style: normal;
    font-weight: 800;
  }

  @font-face {
    font-family: 'proxima-nova';
    src:
      url('https://use.typekit.net/af/576d53/00000000000000003b9b3066/27/l?primer=3a6076633bc77831ac9fb811601de898b4ca8cd1e0aff02d16b23a013090f4db&fvd=n6&v=3')
        format('woff2'),
      url('https://use.typekit.net/af/576d53/00000000000000003b9b3066/27/d?primer=3a6076633bc77831ac9fb811601de898b4ca8cd1e0aff02d16b23a013090f4db&fvd=n6&v=3')
        format('woff'),
      url('https://use.typekit.net/af/576d53/00000000000000003b9b3066/27/a?primer=3a6076633bc77831ac9fb811601de898b4ca8cd1e0aff02d16b23a013090f4db&fvd=n6&v=3')
        format('opentype');
    font-display: swap;
    font-style: normal;
    font-weight: 600;
  }

  @font-face {
    font-family: 'proxima-nova';
    src:
      url('https://use.typekit.net/af/705e94/00000000000000003b9b3062/27/l?primer=3a6076633bc77831ac9fb811601de898b4ca8cd1e0aff02d16b23a013090f4db&fvd=n4&v=3')
        format('woff2'),
      url('https://use.typekit.net/af/705e94/00000000000000003b9b3062/27/d?primer=3a6076633bc77831ac9fb811601de898b4ca8cd1e0aff02d16b23a013090f4db&fvd=n4&v=3')
        format('woff'),
      url('https://use.typekit.net/af/705e94/00000000000000003b9b3062/27/a?primer=3a6076633bc77831ac9fb811601de898b4ca8cd1e0aff02d16b23a013090f4db&fvd=n4&v=3')
        format('opentype');
    font-display: swap;
    font-style: normal;
    font-weight: 400;
  }

  @font-face {
    font-family: 'proxima-nova';
    src:
      url('https://use.typekit.net/af/6e816b/00000000000000003b9b3064/27/l?primer=3a6076633bc77831ac9fb811601de898b4ca8cd1e0aff02d16b23a013090f4db&fvd=n5&v=3')
        format('woff2'),
      url('https://use.typekit.net/af/6e816b/00000000000000003b9b3064/27/d?primer=3a6076633bc77831ac9fb811601de898b4ca8cd1e0aff02d16b23a013090f4db&fvd=n5&v=3')
        format('woff'),
      url('https://use.typekit.net/af/6e816b/00000000000000003b9b3064/27/a?primer=3a6076633bc77831ac9fb811601de898b4ca8cd1e0aff02d16b23a013090f4db&fvd=n5&v=3')
        format('opentype');
    font-display: swap;
    font-style: normal;
    font-weight: 500;
  }
`
