import { IProfileState } from './types'
import { sliceName } from './constants'
import { getStateError, getStateItem } from '../utils/core'

export default {
  loading: getStateItem<IProfileState, IProfileState[typeof sliceName]['loading']>(
    sliceName,
    'loading'
  ),

  entity: getStateItem<IProfileState, IProfileState[typeof sliceName]['entity']>(
    sliceName,
    'entity'
  ),

  error: getStateError<IProfileState>(sliceName),
}
