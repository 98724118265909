import { useCallback, useEffect, useRef } from 'react'
import { useRouter } from 'next/router'
import { useDispatch, useSelector, useStore } from 'react-redux'
import { userTagsSelectors, userTagsThunks } from '@ucheba/store/userTags'
import ym from 'react-yandex-metrika'
import { coreSelectors } from '@ucheba/store/core'
import {
  getParsedCookies,
  setCookie,
  isDev,
  isServer,
  getCookieDomain,
} from '../helpers/core'
import dayjs from '../helpers/date/dayjs'

export const userTagsKey = 'userTags'
export const utmTagsKey = 'utmTags'

/* Добавляет функционал пользовательских меток */
const useUserTags = (): void => {
  const router = useRouter()
  const dispatch = useDispatch()
  const store = useStore()
  const isInitRef = useRef(false)
  const isTouch = useSelector(coreSelectors.isTouch)

  const addTagsToCookie = (name: string, cookie: any): void => {
    setCookie(name, JSON.stringify(cookie), {
      expires: dayjs().add(365, 'days').toDate(),
      domain: getCookieDomain(),
      httpOnly: false,
    })
  }

  /* Метод отправки тегов в апи */
  const syncUserTags = useCallback(async (url: string) => {
    const cookies = getParsedCookies()
    const currentUserTags = JSON.parse(cookies[userTagsKey] || '{}')
    const unixUserTags = Object.keys(currentUserTags || {}).reduce((acc, el) => {
      acc[el] =
        typeof currentUserTags[el] === 'string'
          ? dayjs(currentUserTags[el]).unix()
          : currentUserTags[el]
      return acc
    }, {})
    const users = unixUserTags ? { ...unixUserTags } : {}
    const tags = {
      userTags: {
        ...users,
      },
    }
    const platform = isTouch ? 'web_mobile' : 'web_desktop'
    await dispatch(userTagsThunks.sync({ data: { url, platform, ...tags } }))
    const entity = userTagsSelectors.entity(store.getState())
    const userTagsEntity = entity?.userTags
    addTagsToCookie(userTagsKey, userTagsEntity || {})
    if (userTagsEntity && !isDev && !isServer) {
      const tagsForYm = Object.keys(userTagsEntity).reduce((acc, key) => {
        acc[key] =
          typeof userTagsEntity[key] === 'string'
            ? dayjs(userTagsEntity[key]).unix()
            : userTagsEntity[key]
        return acc
      }, {})

      ym('params', { tagsForYm })
    }
  }, [])

  /* Запрашиваем теги при заходе на страницу, без роутинга в SPA */
  useEffect(() => {
    if (!isInitRef.current) {
      syncUserTags(window.location.href)
      isInitRef.current = true
    }
    const handler = (_url: string, { shallow }): void => {
      if (shallow) {
        return
      }
      syncUserTags(window.location.href)
    }
    router.events.on('routeChangeComplete', handler)
    // eslint-disable-next-line consistent-return
    return () => {
      router.events.off('routeChangeComplete', handler)
    }
  }, [router, syncUserTags])
}

export default useUserTags
