import { sliceName } from './constants'
import { getStateError, getStateItem } from '../../utils/core'
import { ISpecialitiesIdState } from './types'

export default {
  entity: getStateItem<
    ISpecialitiesIdState,
    ISpecialitiesIdState[typeof sliceName]['entity']
  >(sliceName, 'entity'),

  min: getStateItem<ISpecialitiesIdState, ISpecialitiesIdState[typeof sliceName]['min']>(
    sliceName,
    'min'
  ),

  minItems: getStateItem<
    ISpecialitiesIdState,
    ISpecialitiesIdState[typeof sliceName]['minItems']
  >(sliceName, 'minItems'),

  loading: getStateItem<
    ISpecialitiesIdState,
    ISpecialitiesIdState[typeof sliceName]['loading']
  >(sliceName, 'loading'),

  error: getStateError<ISpecialitiesIdState>(sliceName),
}
