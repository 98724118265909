/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import { sliceName } from './constants'
import { IEntity } from './types'

export default createSlice({
  name: sliceName,

  initialState: {
    notificationsQueue: [] as IEntity[],
    activeNotifications: [] as IEntity[],
  },

  reducers: {
    addNotificationToQueueById: (state, { payload }): void => {
      if (!state.notificationsQueue.some((el) => el.id === payload.id)) {
        state.notificationsQueue = [
          ...state.notificationsQueue,
          { id: payload.id, priority: payload.priority || 1 },
        ]
      }
    },
    removeNotificationFromQueueById: (state, { payload }): void => {
      state.notificationsQueue = state.notificationsQueue.filter(
        (el) => el.id !== payload
      )
    },
    addNotificationToActiveById: (state, { payload }): void => {
      if (!state.activeNotifications.some((el) => el.id === payload.id)) {
        state.activeNotifications = [
          ...state.activeNotifications,
          { id: payload.id, priority: payload.priority || 1 },
        ]
      }
    },
    removeNotificationFromActiveById: (state, { payload }): void => {
      state.activeNotifications = state.activeNotifications.filter(
        (el) => el.id !== payload
      )
    },
  },
})
