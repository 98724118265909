import styled from 'styled-components'
import { sv } from '@ucheba/utils/helpers/styles/variables'

const Loader = styled.div`
  display: block;
  position: relative;
  margin: 8px auto;
  width: 80px;
  height: 8px;

  div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
    background: ${sv.color.gray25};
  }
  div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
    background: ${sv.color.gray25};
  }
  div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
    background: ${sv.color.gray25};
  }
  div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
    background: ${sv.color.gray25};
  }

  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
  }
`

const LoaderCircle = styled.div`
  position: absolute;
  top: 0;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
`

export default { Loader, LoaderCircle }
