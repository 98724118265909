import { NextPageContext, GetServerSidePropsContext } from 'next'
import { ParsedUrlQuery } from 'querystring'
import nookies from 'nookies'

/** Проверяет, действительно ли передан объект */
export const isObject = <T>(value: T): boolean => {
  return typeof value === 'object' && value !== null && !Array.isArray(value)
}

/** Всегда возвращает число, в том числе и из контекста строки
 * По дефолту вернет 0
 */
export const getNumber = <T extends string | number>(value: T): number => {
  // eslint-disable-next-line no-restricted-globals
  if (typeof value === 'number' && !isNaN(value)) {
    return value
  }

  const matchNumbers = value && value.toString().match(/(\d+)/)

  return (matchNumbers && Number(matchNumbers[0])) || 0
}

/** ??? */
export const omit = <T>(props: T, paths: string | string[]): Partial<T> => {
  let wrappedPaths: string[]

  if (Array.isArray(paths)) {
    wrappedPaths = paths
  } else {
    wrappedPaths = [paths]
  }

  return Object.keys(props).reduce((acc: Partial<T>, name) => {
    if (!wrappedPaths.find((path) => path === name)) {
      acc[name] = props[name]
    }

    return acc
  }, {})
}

/** Если dev окружение */
export const isDev = process.env.NODE_ENV === 'development'

/** Если prod окружение */
export const isProd = !isDev

/** Если выполняется в браузере, на клиенте */
export const isBrowser = typeof window !== 'undefined'

/** Если выполняется в браузере, на сервере */
export const isServer = !isBrowser

/** Если выполняется на localhost */
export const isLocal = isBrowser && window.location.hostname === 'localhost'
export const isUchebaLocal =
  isBrowser && window.location.hostname.includes('ucheba.local')
export const isLocalFunc = (host: string): boolean => /localhost:/g.test(host)
export const isUchebaLocalFunc = (host: string): boolean => /ucheba\.local/g.test(host)

/** Если выполняется на next.ucheba.ru */
export const isNextFunc = (host: string): boolean => /next/g.test(host)

/** Получение хоста на клиенте и сервере */
export const getHost = (
  ctx?: NextPageContext | GetServerSidePropsContext<ParsedUrlQuery>
): string => {
  return isBrowser ? window.location.host : ctx?.req?.headers.host || 'www.ucheba.ru'
}

/** Domain для куки */
export const getCookieDomain = (): string => {
  return process.env.NEXT_PUBLIC_COOKIE_DOMAIN
}

/** Парсит куки на клиенте и сервере */
export const getParsedCookies = (ctx?: NextPageContext): Record<string, any> => {
  return ctx ? nookies.get(ctx) : nookies.get()
}

export const setCookie = (
  name: string,
  value: string,
  options: Record<string, any>,
  ctx?: NextPageContext
): void => {
  if (options.expires && options.expires === -1) {
    nookies.destroy(ctx, name, { path: '/', ...options, expires: undefined })
  } else {
    nookies.set(ctx, name, value, { path: '/', ...options })
  }
}

declare global {
  interface Window {
    __REACT_DEVTOOLS_GLOBAL_HOOK__: any
  }
}

// функция блокирует react devtools (смотреть - https://www.npmjs.com/package/@fvilers/disable-react-devtools)
export const disableReactDevTools = (): void => {
  function isFunction(obj): boolean {
    return typeof obj === 'function'
  }

  function hasWindowObject(): boolean {
    return typeof window !== 'undefined' && !!window.document
  }

  if (hasWindowObject()) {
    // Ensure the React Developer Tools global hook exists
    // eslint-disable-next-line no-underscore-dangle
    if (!isObject(window.__REACT_DEVTOOLS_GLOBAL_HOOK__)) {
      return
    }

    // Replace all global hook properties with a no-op function or a null value
    // eslint-disable-next-line no-underscore-dangle,guard-for-in,no-restricted-syntax
    for (const prop in window.__REACT_DEVTOOLS_GLOBAL_HOOK__) {
      if (prop === 'renderers') {
        // prevents console error when dev tools try to iterate of renderers
        // eslint-disable-next-line no-underscore-dangle
        window.__REACT_DEVTOOLS_GLOBAL_HOOK__[prop] = new Map()
        // eslint-disable-next-line no-continue
        continue
      }
      // eslint-disable-next-line no-underscore-dangle
      window.__REACT_DEVTOOLS_GLOBAL_HOOK__[prop] = isFunction(
        // eslint-disable-next-line no-underscore-dangle
        window.__REACT_DEVTOOLS_GLOBAL_HOOK__[prop]
      )
        ? Function.prototype
        : null
    }
  }
}
