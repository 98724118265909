/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import { sliceName } from './constants'
import { IHeaderOffers } from './types'
import { extraReducersResponse, responseInitialState } from '../../../utils/response'
import thunks from './thunks'

export default createSlice({
  name: sliceName,
  initialState: {
    entity: null as IHeaderOffers | null,
    ...responseInitialState,
  },
  reducers: {},
  extraReducers: (builder) => {
    extraReducersResponse({
      builder,
      thunk: thunks.fetch,
      fulfilled: (state, action) => {
        state.entity = action.payload
      },
    })
  },
})
