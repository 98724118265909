import { sliceName } from './constants'
import { getStateError, getStateItem } from '../../../utils/core'

export default {
  requestId: getStateItem(sliceName, 'requestId'),
  isParent: getStateItem(sliceName, 'isParent'),
  packageId: getStateItem(sliceName, 'packageId'),
  isFormVisible: getStateItem(sliceName, 'isFormVisible'),
  successAddParent: getStateItem(sliceName, 'successAddParent'),
  error: getStateError(sliceName),
}
