interface ILevenshteinAlg {
  (s1: any, s2: any, lvl: number): number
}

const levenshteinAlg: ILevenshteinAlg = (s1, s2, lvl) => {
  // thanks for https://gist.github.com/graphnode/979790

  if (Math.abs(s1.length - s2.length) >= lvl) return lvl

  if (s1 === s2) return 0

  // eslint-disable-next-line camelcase
  const s1Len = s1.length
  const s2Len = s2.length

  if (s1Len === 0) return s2Len

  if (s2Len === 0) return s1Len

  let split = false

  try {
    split = !'0'[0]
  } catch (e) {
    split = true
  }

  if (split) {
    // eslint-disable-next-line no-param-reassign
    s1 = s1.split('')
    // eslint-disable-next-line no-param-reassign
    s2 = s2.split('')
  }

  let v0 = new Array(s1Len + 1)
  let v1 = new Array(s1Len + 1)

  let s1Idx = 0
  let s2Idx = 0

  for (s1Idx = 0; s1Idx < s1Len + 1; s1Idx++) {
    v0[s1Idx] = s1Idx
  }

  let charS1 = ''
  let charS2 = ''

  for (s2Idx = 1; s2Idx <= s2Len; s2Idx++) {
    v1[0] = s2Idx
    charS2 = s2[s2Idx - 1]

    for (s1Idx = 0; s1Idx < s1Len; s1Idx++) {
      charS1 = s1[s1Idx]

      const cost = charS1 === charS2 ? 0 : 1
      let mMin = v0[s1Idx + 1] + 1
      const b = v1[s1Idx] + 1
      const c = v0[s1Idx] + cost

      if (b < mMin) {
        mMin = b
      }

      if (c < mMin) {
        mMin = c
      }

      v1[s1Idx + 1] = mMin
    }

    const vTmp = v0

    v0 = v1
    v1 = vTmp
  }

  return v0[s1Len]
}

interface ILeftHandLevenshtein {
  (q, s, lvl): number
}

const leftHandLevenshtein: ILeftHandLevenshtein = (q, s, lvl) => {
  let base = levenshteinAlg(q, s, lvl)

  for (let i = 0, l = s.length; i < l; i++) {
    const d = levenshteinAlg(q, s.substr(0, i), lvl)

    if (d < base) base = d
  }

  return base
}

interface IGetDistance {
  (search: string, val: string, lvl): number
}

const getDistance: IGetDistance = (q, s, lvl) => {
  const wordsSeparator = /[^a-zа-яё]+/
  let fi = -1
  let qd = Infinity
  let sd = -Infinity
  const qw = q ? q.trim().split(wordsSeparator) : []
  const qwLength = qw.length
  const sw = s ? s.trim().split(wordsSeparator) : []
  let swLength = sw.length
  const wordMax = Math.max(qw.length, sw.length)

  if (qwLength > swLength) return Infinity

  const si2p: number[] = []

  for (let si = 0; si < swLength; si++) {
    si2p.push(si)
  }

  for (let qi = 0; qi < qwLength; qi++) {
    // eslint-disable-next-line no-param-reassign
    q = qw[qi]
    for (let si = 0; si < swLength; si++) {
      // eslint-disable-next-line no-param-reassign
      s = sw[si]

      // eslint-disable-next-line no-continue
      if (q.substr(0, 1) !== s.substr(0, 1)) continue

      let d = leftHandLevenshtein(q, s, lvl)

      d += Math.abs(qi - si2p[si]) / wordMax

      if (qd > d) {
        qd = d
        fi = si
      }
    }

    if (fi === -1) return Infinity

    sw.splice(fi, 1)
    swLength--
    si2p.splice(fi, 1)

    sd = Math.max(sd, qd)
  }
  return sd
}

interface IFiltering {
  (options: any[], search: string, label: string, lvl: number): any[]
}

export const filtering: IFiltering = (options, search, label, lvl) => {
  let result: {
    origin: any
    dist: number
    index: number
  }[] = []

  for (let index = 0, len = options.length; index < len; index++) {
    const origin = options[index]
    const val = label ? origin[label] : origin
    const dist = getDistance(search.toLowerCase(), val.toLowerCase(), lvl + 1)

    result.push({
      origin,
      dist,
      index,
    })
  }

  result = result.filter((resultItem) => (resultItem.dist <= lvl ? resultItem : null))

  result.sort((a, b) => a.dist - b.dist || a.index - b.index)

  result = result.slice(0, 9999)

  return result.map(({ origin }) => origin)
}
