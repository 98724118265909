import { IEgeSubjectsDetail, IEgeSubjectsDetailState, ISelectorBySlug } from './types'
import { egeSubjectsDetailAdapter } from './slice'
import { sliceName } from './constants'
import { getStateById, getStateError } from '../../../utils/core'

const adaptorSelectors = egeSubjectsDetailAdapter.getSelectors<IEgeSubjectsDetailState>(
  (state) => state[sliceName]
)

export default {
  ...adaptorSelectors,

  byId: getStateById<IEgeSubjectsDetailState, IEgeSubjectsDetail>(adaptorSelectors),

  bySlug:
    (slug: string): ISelectorBySlug =>
    (state): IEgeSubjectsDetail | undefined =>
      adaptorSelectors.selectAll(state).find((item) => item.slug === slug),

  error: getStateError<IEgeSubjectsDetailState>(sliceName),
}
