import apiThunk, { api } from '@ucheba/utils/helpers/api'
import { sliceName } from './constants'

const fetch = apiThunk(`${sliceName}/fetch`, async (params) => {
  const queries: string[] = []

  if (params?.data?.specialityId) {
    queries.push(`specialityId=${params?.data?.specialityId}`)
  }

  const response = await api.get(`/olympiads?${queries.join('&')}`, params)

  // нужно правильно поправить, а не касть в стор только items
  return response.data.items
})

export default { fetch }
