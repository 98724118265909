import selectors from './selectors'
import slice from './slice'
import thunks from './thunks'
import actions from './actions'

export {
  slice as authSlice,
  selectors as authSelectors,
  thunks as authThunks,
  actions as authActions,
}
