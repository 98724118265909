/* eslint-disable no-param-reassign */
import apiThunk, { api } from '@ucheba/utils/helpers/api'
import { sliceName } from './constants'

const fetchLearningRequestsIds = apiThunk(
  `${sliceName}/fetchLearningRequests`,
  async (params) => {
    const response = await api.get(`/learning-requests/ids`, params)

    return response.data
  }
)

const postLearningRequest = apiThunk(
  `${sliceName}/postLearningRequest`,
  async (params) => {
    const { referId, type } = params.data

    const response = await api.post(
      `/learning-request/${type}/${referId}`,
      {
        referId,
        type,
      },
      params
    )

    return response.data
  }
)

export default { fetchLearningRequestsIds, postLearningRequest }
