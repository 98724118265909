/* eslint-disable no-param-reassign */
import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { extraReducersResponse, responseInitialState } from '../../utils/response'
import { sliceName } from './constants'
import thunks from './thunks'
import {
  IItemOfSpecialitiesSearch,
  ISpecialitiesSearch,
  ISpecialitiesSearchByFriendlyURL,
  ISpecialitiesSearchState,
} from './types'
import { IResponseInitialState } from '../../utils/response/types'

export const specialitiesSearchAdaptor = createEntityAdapter<IItemOfSpecialitiesSearch>()

export default createSlice({
  name: sliceName,

  initialState: specialitiesSearchAdaptor.getInitialState<
    IResponseInitialState &
      Pick<ISpecialitiesSearch, 'total' | 'facets' | 'seo'> &
      Pick<ISpecialitiesSearchByFriendlyURL, 'canIndex' | 'criteria'>
  >({
    total: 0,
    facets: null,
    canIndex: null,
    criteria: null,
    seo: null,
    ...responseInitialState,
  }),

  reducers: {},

  extraReducers: (builder) => {
    extraReducersResponse<
      ISpecialitiesSearchState[typeof sliceName],
      ISpecialitiesSearch
    >({
      builder,
      thunk: thunks.fetch,
      fulfilled: (state, action) => {
        state.total = action.payload.total
        state.facets = action.payload.facets
        state.seo = action.payload.seo

        specialitiesSearchAdaptor.setAll(state, action.payload.items)
      },
    })

    extraReducersResponse<
      ISpecialitiesSearchState[typeof sliceName],
      ISpecialitiesSearchByFriendlyURL
    >({
      builder,
      thunk: thunks.fetchByFriendlyURL,
      fulfilled: (state, action) => {
        state.total = action.payload?.result?.total
        state.seo = action.payload?.result?.seo
        state.facets = action.payload?.result?.facets
        state.canIndex = action.payload?.canIndex
        state.criteria = action.payload?.criteria

        specialitiesSearchAdaptor.setAll(state, action.payload?.result?.items)
      },
    })
  },
})
