/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import { sliceName } from './constants'
import { extraReducersResponse, responseInitialState } from '../../utils/response'
import thunks from './thunks'

import { IPartnerEmployersState, IPartnerEmployers } from './types'

export default createSlice({
  name: sliceName,

  initialState: {
    entity: null as IPartnerEmployers | null,
    ...responseInitialState,
  },

  reducers: {},

  extraReducers: (builder) => {
    extraReducersResponse<IPartnerEmployersState[typeof sliceName], IPartnerEmployers>({
      builder,
      thunk: thunks.fetchPartnerEmployers,
      fulfilled: (state, { payload }) => {
        state.entity = payload
      },
    })
  },
})
