import styled, { keyframes, StyledProps } from 'styled-components'
import { sm } from '@ucheba/utils/helpers/styles/mixins'
import { sv } from '@ucheba/utils/helpers/styles/variables'
import {
  EModActive,
  EModClose,
  IModActive,
  IModClose,
  IModOverflow,
} from '@ucheba/utils/helpers/styles/mod.types'
import { IPropHeight } from '@ucheba/utils/helpers/components/prop.types'
import { IBlur } from '../types'

const openBackdrop = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

const closeBackdrop = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`

const openInner = keyframes`
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
`

const Backdrop = styled.div<StyledProps<IModClose & IBlur>>`
  cursor: pointer;
  ${sm.position.fixedFill};
  background-color: ${sv.color.gray60};

  ${(props) => {
    if (props.blur) {
      return `
        -webkit-backdrop-filter: blur(32px);
        backdrop-filter: blur(32px);
         background-color: rgba(0, 0, 0, 0);
        `
    }
    return ``
  }}

  ${sm.mod(EModClose.true)`
    animation: ${closeBackdrop} ${sv.duration.fast} forwards;
  `};

  ${sm.mod(EModClose.false)`
    animation: ${openBackdrop} ${sv.duration.fast} forwards;
  `};

  @-moz-document url-prefix() {
    background-color: ${sv.color.gray60};
  }
`

const Header = styled.div``

const Footer = styled.div``

const Body = styled.div<StyledProps<IModOverflow>>`
  overflow-y: ${(props) => props.overflow};
  -webkit-overflow-scrolling: auto;
  max-height: 100%;
  flex-grow: 1;
`

const Content = styled.div<StyledProps<IPropHeight & { isScrollable: boolean }>>`
  background-color: ${sv.color.white};
  border-top-left-radius: ${sv.radius.large};
  border-top-right-radius: ${sv.radius.large};
  max-height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: ${(props) => props.height};

  &:before {
    content: '';
    margin-bottom: ${sv.spacing.xxsmall};
    ${sm.position.horizontalCenter};
    bottom: 100%;
    opacity: ${({ isScrollable }) => (isScrollable ? 0 : 1)};
    transition: ${sv.duration.medium};
    background-color: ${sv.color.white};
    width: 80px;
    height: 4px;
    border-top: 2px solid ${sv.color.white};
    border-radius: ${sv.radius.medium};
    box-shadow: ${sv.shadow.medium};
    cursor: grab;
  }
`

const Inner = styled.div<{ isScrollable: boolean }>`
  position: ${({ isScrollable }) => (isScrollable ? 'relative' : 'unset')};
  max-height: calc(100% - ${sv.spacing.xlarge});
  width: 100%;
  max-width: ${sv.container.sm};
  margin-left: auto;
  margin-right: auto;
  display: flex;
  animation: ${openInner} ${sv.duration.medium} forwards;
  transition: ${sv.duration.medium};

  &:before {
    content: ${({ isScrollable }) => (isScrollable ? "''" : 'unset')};
    margin-bottom: ${sv.spacing.xxsmall};
    ${sm.position.horizontalCenter};
    bottom: 100%;
    opacity: 0;
    transition: ${sv.duration.medium};
    background-color: ${sv.color.white};
    width: 80px;
    height: 4px;
    border-top: 2px solid ${sv.color.white};
    border-radius: ${sv.radius.medium};
    box-shadow: ${sv.shadow.medium};
    cursor: grab;
  }
  &:after {
    content: '';
    display: none;
    bottom: 100%;
    background-color: ${sv.color.steel};
    width: calc(100% - ${sv.spacing.small});
    ${sm.position.horizontalCenter};
    height: 8px;
    border-top-left-radius: ${sv.radius.large};
    border-top-right-radius: ${sv.radius.large};
  }
`

const DraggableContainer = styled.div<StyledProps<IModActive & IModClose>>`
  height: 100%;
  display: flex;
  align-items: flex-end;

  ${sm.mod(EModActive.false)`
    transition: ${sv.duration.medium} ease-out;
  `};

  ${sm.mod(EModClose.true)`
    transform: translate(0px, 100%) !important;
    transition: ${sv.duration.fast} ease-in;
  `};
`

const DraggableArea = styled.div`
  ${sm.position.absoluteFill};
  cursor: grab;
`

const Block = styled.div`
  ${sm.position.fixedFill};
  z-index: 101;

  &:last-child {
    ${Inner} {
      max-height: calc(100% - ${sv.spacing.xxlarge});

      &:before {
        opacity: 1;
      }
    }

    ${Content} {
    }
  }

  &:only-child {
    ${Inner} {
      &:after {
        display: none;
      }
    }
  }
`

export default {
  Block,
  Backdrop,
  Inner,
  DraggableContainer,
  DraggableArea,
  Content,
  Body,
  Header,
  Footer,
}
