import { createAsyncThunk } from '@reduxjs/toolkit'
import { requestConfig, sliceName } from './constants'

const { url, config } = requestConfig.prod.fetch

const request = createAsyncThunk(`${sliceName}/request`, async (params) => {
  const requestData = {
    ...params,
    source: 'consultation',
    package: 6,
  }

  return fetch(url.request.send, {
    body: JSON.stringify(requestData),
    ...config,
  }).then((res) => {
    return res.json()
  })
})

const addParent = createAsyncThunk(`${sliceName}/addParent`, async (data) => {
  return fetch(url.request.addParent, {
    body: JSON.stringify(data),
    ...config,
  }).then((res) => {
    return res.json()
  })
})

export default { request, addParent }
