import apiThunk, { api } from '@ucheba/utils/helpers/api'
import { sliceName } from './constants'

const fetchNews = apiThunk(`${sliceName}/news`, async (params) => {
  const response = await api.get(`/institutions/${params.data.id}/news`, params)

  return response.data
})

export default { fetchNews }
