import { FC } from 'react'
import { useSelector } from 'react-redux'
import { DefineLocationNotice as DefineLocationNoticeDesktop } from './desktop'
import { DefineLocationNotice as DefineLocationNoticeTouch } from './touch'
import { IDefineLocationNoticeIndexProps } from './types'
import { locationNoticeSelectors } from './store'

const DefineLocationsNotice: FC<IDefineLocationNoticeIndexProps> = (props) => {
  const { isTouch } = props

  const isShowLocationNotice = useSelector(locationNoticeSelectors.isShowLocationNotice)

  if (!isShowLocationNotice) return null

  if (isTouch) {
    return <DefineLocationNoticeTouch />
  }

  return <DefineLocationNoticeDesktop />
}

export { DefineLocationsNotice }
