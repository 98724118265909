import { useNotice } from '@ucheba/ui/components/Notice/bll'
import { useCallback, useEffect, useState } from 'react'
import {
  getCookieDomain,
  getParsedCookies,
  isBrowser,
  setCookie,
} from '@ucheba/utils/helpers/core'
import { analyticEvent } from '@ucheba/utils/helpers/analytics/events'
import {
  EAnalyticEvents,
  EAnalyticEventTargets,
  EAnalyticSections,
} from '@ucheba/utils/helpers/analytics/events/types'
import { useSelector } from 'react-redux'
import { profileSelectors } from '@ucheba/store/profile'
import { useRouter } from 'next/router'
import dayjs from '@ucheba/utils/helpers/date/dayjs'
import { cookieBannerBlock, timer } from '../Banner/constants'
import {
  noticePermissionVkBlock,
  noticePermissionVkCounter,
  userReg,
  vkBannerFirstBlockTime,
  vkBannerOtherBlockTime,
  VkPermissionNoticeId,
} from './constants'
import { ENoticePermissionQueryType, IUseVkPermissionNotice } from './types'
import data from './data.json'

export const useVkPermissionNotice: IUseVkPermissionNotice = ({ allowedMessangers }) => {
  const vkPermissionNotice = useNotice(VkPermissionNoticeId)
  const cookies = getParsedCookies()
  const isBlock = isBrowser ? cookies[noticePermissionVkBlock] : false
  const profile = useSelector(profileSelectors.entity)
  const { query } = useRouter()

  const [dataNotice, setDataNotice] = useState({
    title: data.auth.title,
    description: data.auth.description,
    buttons: data.auth.buttons,
  })

  const removeStorage = useCallback(() => {
    localStorage.removeItem(userReg)
  }, [])

  // если есть данные о профиле, нет разрешение писать в вк и нет блокировщика то показываем нотис
  useEffect(() => {
    const userFirstReg = isBrowser ? localStorage.getItem(userReg) : false

    if (
      profile &&
      !allowedMessangers &&
      !isBlock &&
      !query[ENoticePermissionQueryType.n_m_block]
    ) {
      vkPermissionNotice.addNotice()

      // если показали ноти с разрешением писать в вк то добавляем блокировщик сплывающих баннеров на определенное время
      setCookie(cookieBannerBlock, 'block', {
        expires: dayjs().add(timer.break, 'second').toDate(),
        domain: getCookieDomain(),
        httpOnly: false,
      })

      let counter = parseInt(cookies[noticePermissionVkCounter], 10) || 0
      let blockBannerTime
      if (counter === 0) {
        blockBannerTime = vkBannerFirstBlockTime
      } else {
        blockBannerTime = vkBannerOtherBlockTime
      }
      // кладем куку для блокировки показа уведомления на определенное время
      setCookie(noticePermissionVkBlock, 'block', {
        expires: dayjs().add(blockBannerTime, 'second').toDate(),
        domain: getCookieDomain(),
        httpOnly: false,
      })
      // добавляем счётчик показов баннера ВК
      setCookie(noticePermissionVkCounter, (++counter).toString(), {
        expires: dayjs().add(vkBannerOtherBlockTime, 'second').toDate(),
        domain: getCookieDomain(),
        httpOnly: false,
      })

      // отправляем аналитику показа-нотиса в яндекс-метрику
      analyticEvent({
        targets: [EAnalyticEventTargets.ym, EAnalyticEventTargets.ur],
        eventName: EAnalyticEvents.vkNoticeDisplayLead,
        section: EAnalyticSections.vkNotice,
      })

      if (userFirstReg) {
        setDataNotice({
          title: data.firstReg.title,
          description: data.firstReg.description,
          buttons: data.firstReg.buttons,
        })
      }

      // если в локал сторадж есть флаг сигнализирующий о первой регитстрации пользователя, то удаляем его
      localStorage.removeItem(userReg)
    }
  }, [allowedMessangers, isBlock, profile])

  return {
    notice: vkPermissionNotice,
    description: dataNotice.description,
    title: dataNotice.title,
    buttons: dataNotice.buttons,
    removeStorage,
  }
}
