/* eslint-disable no-param-reassign */
import { createEntityAdapter, createSlice, EntityState } from '@reduxjs/toolkit'
import { IItemOfEgeSubjects } from './types'
import { IResponseInitialState } from '../../utils/response/types'
import { extraReducersResponse, responseInitialState } from '../../utils/response'
import thunks from './thunks'
import { sliceName } from './constants'

export const egeSubjectsAdapter = createEntityAdapter<IItemOfEgeSubjects>()

export default createSlice({
  name: sliceName,

  initialState:
    egeSubjectsAdapter.getInitialState<IResponseInitialState>(responseInitialState),

  reducers: {},

  extraReducers: (builder) => {
    extraReducersResponse<EntityState<IItemOfEgeSubjects>, IItemOfEgeSubjects[]>({
      builder,
      thunk: thunks.fetch,
      fulfilled: (state, action) => {
        egeSubjectsAdapter.setAll(state, action.payload)
      },
    })
  },
})
