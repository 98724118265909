import apiThunk, { api } from '@ucheba/utils/helpers/api'
import { addLimitOffset } from '@ucheba/utils/helpers/router'
import { eventsSearchLimit, sliceName } from './constants'

const fetch = apiThunk(`${sliceName}/fetch`, async (props) => {
  const response = await api.get('/events', addLimitOffset(props, eventsSearchLimit))

  return response.data
})

export default { fetch }
