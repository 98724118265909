import apiThunk, { api } from '@ucheba/utils/helpers/api'
import { sliceName } from './constants'

const sync = apiThunk(`${sliceName}/sync`, async (params) => {
  const response = await api.post(`user-tags/sync`, params?.data, params)

  return response.data
})

export default { sync }
