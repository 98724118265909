/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import { sliceName } from './constants'
import { coreSlice } from './index'
import { ILayoutTypes } from './types'

const initialState = {
  isLayout: true,
  isTouch: false,
  changedId: 0,
  layout: null as ILayoutTypes | null,
  programTypes: null as number[] | null,
}

export default createSlice({
  name: sliceName,

  initialState,

  reducers: {
    toggleLayout: (state, { payload }): void => {
      state.isLayout = payload
      coreSlice.caseReducers.changeId(state)
    },

    toggleTouch: (state, { payload }): void => {
      state.isTouch = payload
    },

    changeId: (state): void => {
      state.changedId = Date.now()
    },

    setLayout: (state, { payload }): void => {
      state.layout = payload
    },

    setProgramTypes: (state, { payload }): void => {
      state.programTypes = payload
    },
  },
})
