import { IOlympiadsState, IOlympiads } from './types'
import { sliceName } from './constants'
import { getStateItem } from '../../utils/core'

export default {
  loading: getStateItem<IOlympiadsState, IOlympiadsState[typeof sliceName]['loading']>(
    sliceName,
    'loading'
  ),

  entity: getStateItem<IOlympiads, IOlympiads>(sliceName, 'entity'),
}
