import selectors from './selectors'
import slice from './slice'
import thunks from './thunks'
import actions from './actions'

export {
  slice as institutionsProgramsSlice,
  selectors as institutionsProgramsSelectors,
  thunks as institutionsProgramsThunks,
  actions as InstitutionsProgramsActions,
}
