import TagManager from 'react-gtm-module'
import { isBrowser } from '../core'

export const init = (code: string): void => {
  if (isBrowser) {
    TagManager.initialize({
      gtmId: code,
    })
  }
}

declare global {
  interface Window {
    dataLayer: Record<string, any>
  }
}

export const event = (eventName: string): void => {
  if (isBrowser) {
    window.dataLayer.push({ event: eventName })
  }
}
