import { createSlice } from '@reduxjs/toolkit'
import { extraReducersResponse, responseInitialState } from '../utils/response'
import { sliceName } from './constants'
import thunks from './thunks'
import { IFeedbackEntity, IFeedbackOrder, IFeedbacksState } from './types'

export default createSlice({
  name: sliceName,

  initialState: {
    entity: null as IFeedbackEntity | null,
    order: null as IFeedbackOrder | null,
    ...responseInitialState,
  },

  reducers: {},

  extraReducers: (builder) => {
    extraReducersResponse<IFeedbacksState[typeof sliceName], IFeedbackEntity>({
      builder,
      thunk: thunks.fetch,
      fulfilled: (state, action) => {
        // eslint-disable-next-line no-param-reassign
        state.entity = action.payload
      },
    })

    extraReducersResponse<IFeedbacksState[typeof sliceName], IFeedbackOrder>({
      builder,
      thunk: thunks.fetchOrder,
      fulfilled: (state, action) => {
        // eslint-disable-next-line no-param-reassign
        state.order = action.payload
      },
    })
  },
})
