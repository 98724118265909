import { IAuthEmailState } from './types'
import { sliceName } from './constants'
import { getStateError, getStateItem } from '../../utils/core'

export default {
  timeout: getStateItem<IAuthEmailState, IAuthEmailState[typeof sliceName]['timeout']>(
    sliceName,
    'timeout'
  ),

  loading: getStateItem<IAuthEmailState, IAuthEmailState[typeof sliceName]['loading']>(
    sliceName,
    'loading'
  ),

  email: getStateItem<IAuthEmailState, IAuthEmailState[typeof sliceName]['email']>(
    sliceName,
    'email'
  ),

  error: getStateError<IAuthEmailState>(sliceName),
}
