import { NextPageContext } from 'next/dist/shared/lib/utils'
import { authQuery } from '@ucheba/store/auth/constants'
import { setAuthCookie, removeAuthCookie } from '@ucheba/store/auth/bll'
import { Store } from 'redux'
import { authActions, authSelectors, authThunks } from '@ucheba/store/auth'
import { getParsedCookies } from '../helpers/core'
import { authCookieToken } from '../constants/core'
import dayjs from '../helpers/date/dayjs'

const login = (ctx: NextPageContext, store: Store, tokenString: string): void => {
  const date = dayjs().add(30, 'days')
  const token = {
    token: tokenString,
    expiresAt: date.format('YYYY-MM-DD hh:mm:ss'),
  }

  store.dispatch(authActions.login(token))

  setAuthCookie({ ctx, token })
}

const logout = (ctx: NextPageContext, store: Store): void => {
  store.dispatch(authActions.logout())

  removeAuthCookie({ ctx })
}

const returnPromise = new Promise((resolve) => {
  setTimeout(() => {
    resolve(true)
  }, 10)
})

/** Проверка на наличие и "свежесть" токена */
const authMiddleware = async (ctx: NextPageContext, store: Store): Promise<any> => {
  const { query } = ctx
  const { dispatch, getState } = store

  const parsedCookie = getParsedCookies(ctx)
  const token = parsedCookie[authCookieToken]

  let isValidToken = authSelectors.isValidToken(getState())
  if (isValidToken) {
    return Promise.resolve()
  }

  /** Если есть токен и он валиден, то логиним, если не валиден, то логаутим */
  if (token) {
    // login(ctx, token)
    await dispatch<any>(authThunks.isValidToken({ ctx }))
    isValidToken = authSelectors.isValidToken(getState())

    if (isValidToken) {
      login(ctx, store, token)

      // Если здесь не сбросить состояние, то будут бесконечные запросы
      // FIXME: Это какой-то костыль для предотвращения бесконечных запросов в апи после логина по email
      // dispatch(profileShortActions.resetLoading())

      return returnPromise
    }

    /** Если есть квери параметры необходимые для автоматической авторизации, то авторизуем по ним. */
    if (query[authQuery.confirmationKey] && query[authQuery.userId]) {
      const data = {
        [authQuery.confirmationKey]: query[authQuery.confirmationKey],
        [authQuery.userId]: query[authQuery.userId],
      }

      await dispatch<any>(authThunks.authByKey({ ctx, data }))

      return returnPromise
    }

    logout(ctx, store)

    // FIXME: Это какой-то костыль для предотвращения бесконечных запросов в апи после логина по email
    // dispatch(profileShortActions.resetLoading())

    return returnPromise
  }

  /** Если есть квери параметры необходимые для автоматической авторизации, то авторизуем по ним. */
  if (query[authQuery.confirmationKey] && query[authQuery.userId] && !token) {
    const data = {
      [authQuery.confirmationKey]: query[authQuery.confirmationKey],
      [authQuery.userId]: query[authQuery.userId],
    }

    await dispatch<any>(authThunks.authByKey({ ctx, data }))

    return returnPromise
  }

  /** Если токена нет, то логаутим */
  logout(ctx, store)

  return returnPromise
}

export default authMiddleware
