import { IAuthPhoneState } from './types'
import { sliceName } from './constants'
import { getStateError, getStateItem } from '../../utils/core'

export default {
  loading: getStateItem<IAuthPhoneState, IAuthPhoneState[typeof sliceName]['loading']>(
    sliceName,
    'loading'
  ),

  code: getStateItem<IAuthPhoneState, IAuthPhoneState[typeof sliceName]['code']>(
    sliceName,
    'code'
  ),

  user: getStateItem<IAuthPhoneState, IAuthPhoneState[typeof sliceName]['user']>(
    sliceName,
    'user'
  ),
  userName: getStateItem<IAuthPhoneState, IAuthPhoneState[typeof sliceName]['userName']>(
    sliceName,
    'userName'
  ),

  error: getStateError<IAuthPhoneState>(sliceName),
}
