/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import { sliceName } from './constants'

const initialState = {
  isShowLocationNotice: true,
}

export default createSlice({
  name: sliceName,
  initialState,
  reducers: {
    toggleLocationNotice: (state, { payload }): void => {
      state.isShowLocationNotice = payload
    },
  },
})
