import { IEventsState } from './types'
import { eventsAdapter } from './slice'
import { sliceName } from './constants'
import { getStateError, getStateItem } from '../utils/core'

const adapterSelectors = eventsAdapter.getSelectors<IEventsState>(
  (state) => state[sliceName]
)

export default {
  ...adapterSelectors,

  total: getStateItem<IEventsState, IEventsState[typeof sliceName]['total']>(
    sliceName,
    'total'
  ),

  error: getStateError<IEventsState>(sliceName),
}
