import { getStateError, getStateItem } from '../../utils/core'
import { IPlannerProgramsState } from './types'
import { sliceName } from './constants'

export default {
  loading: getStateItem<
    IPlannerProgramsState,
    IPlannerProgramsState[typeof sliceName]['loading']
  >(sliceName, 'loading'),

  entitySuitablePrograms: getStateItem<
    IPlannerProgramsState,
    IPlannerProgramsState[typeof sliceName]['entitySuitablePrograms']
  >(sliceName, 'entitySuitablePrograms'),

  entitySuitableInstitutionsPrograms: getStateItem<
    IPlannerProgramsState,
    IPlannerProgramsState[typeof sliceName]['entitySuitableInstitutionsPrograms']
  >(sliceName, 'entitySuitableInstitutionsPrograms'),

  entityPrograms: getStateItem<
    IPlannerProgramsState,
    IPlannerProgramsState[typeof sliceName]['entityPrograms']
  >(sliceName, 'entityPrograms'),

  entityInstitutionsPrograms: getStateItem<
    IPlannerProgramsState,
    IPlannerProgramsState[typeof sliceName]['entityInstitutionsPrograms']
  >(sliceName, 'entityInstitutionsPrograms'),

  entityProgramsIds: getStateItem<
    IPlannerProgramsState,
    IPlannerProgramsState[typeof sliceName]['entityProgramsIds']
  >(sliceName, 'entityProgramsIds'),

  offset: getStateItem<
    IPlannerProgramsState,
    IPlannerProgramsState[typeof sliceName]['offset']
  >(sliceName, 'offset'),

  excludeIds: getStateItem<
    IPlannerProgramsState,
    IPlannerProgramsState[typeof sliceName]['excludeIds']
  >(sliceName, 'excludeIds'),

  excludeInstitutionsIds: getStateItem<
    IPlannerProgramsState,
    IPlannerProgramsState[typeof sliceName]['excludeInstitutionsIds']
  >(sliceName, 'excludeInstitutionsIds'),

  entityInstitutionsIds: getStateItem<
    IPlannerProgramsState,
    IPlannerProgramsState[typeof sliceName]['entityInstitutionsIds']
  >(sliceName, 'entityInstitutionsIds'),

  error: getStateError<IPlannerProgramsState>(sliceName),
}
