/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import { IEgeSchoolState, TExternalData, TPerson } from './types'
import { extraReducersResponse, responseInitialState } from '../../utils/response'
import thunks from './thunks'
import { sliceName } from './constants'

const initialState = {
  person: null as TPerson | null,
  externalData: null as TExternalData | null,
  ...responseInitialState,
}

export default createSlice({
  name: sliceName,

  initialState,

  reducers: {},

  extraReducers: (builder) => {
    extraReducersResponse<
      IEgeSchoolState[typeof sliceName],
      Pick<IEgeSchoolState[typeof sliceName], 'person' | 'externalData'>
    >({
      builder,
      thunk: thunks.sendLead,
      fulfilled: (state, { payload }) => {
        state.person = payload.person
        state.externalData = payload.externalData
      },
      rejected: () => {
        alert('Ошибка! Проверьте правильность заполнения данных.')
      },
    })
  },
})
