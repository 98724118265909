import dayjs from 'dayjs'
import locale from 'dayjs/locale/ru'

const updateLocale = require('dayjs/plugin/updateLocale')
const relativeTime = require('dayjs/plugin/relativeTime')

dayjs.extend(updateLocale)
dayjs.extend(relativeTime)

dayjs.locale('ru', locale)

export default dayjs
