import { IExamsState } from './types'
import { sliceName } from './constants'
import { getStateError } from '../utils/core'
import { examsAdapter } from './slice'

const adapterSelectors = examsAdapter.getSelectors<IExamsState>(
  (state) => state[sliceName]
)

export default {
  ...adapterSelectors,

  error: getStateError<IExamsState>(sliceName),
}
