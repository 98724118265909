/* eslint-disable no-param-reassign */
import { createEntityAdapter, createSlice, EntityState } from '@reduxjs/toolkit'
import { IItemOfOgeSubjects } from './types'
import { IResponseInitialState } from '../../utils/response/types'
import { extraReducersResponse, responseInitialState } from '../../utils/response'
import thunks from './thunks'
import { sliceName } from './constants'

export const ogeSubjectsAdapter = createEntityAdapter<IItemOfOgeSubjects>()

export default createSlice({
  name: sliceName,

  initialState:
    ogeSubjectsAdapter.getInitialState<IResponseInitialState>(responseInitialState),

  reducers: {},

  extraReducers: (builder) => {
    extraReducersResponse<EntityState<IItemOfOgeSubjects>, IItemOfOgeSubjects[]>({
      builder,
      thunk: thunks.fetch,
      fulfilled: (state, action) => {
        ogeSubjectsAdapter.setAll(state, action.payload)
      },
    })
  },
})
