import { IFooterState } from './types'
import { sliceName } from './constants'

const color = (state: IFooterState): IFooterState[typeof sliceName]['color'] =>
  state[sliceName].color

const changedId = (state: IFooterState): IFooterState[typeof sliceName]['changedId'] =>
  state[sliceName].changedId

const isShowFooter = (
  state: IFooterState
): IFooterState[typeof sliceName]['isShowFooter'] => state[sliceName].isShowFooter

const navLinks = (state: IFooterState): IFooterState[typeof sliceName]['navLinks'] =>
  state[sliceName].navLinks

const isShowMessengers = (
  state: IFooterState
): IFooterState[typeof sliceName]['isShowMessengers'] => state[sliceName].isShowMessengers

export default { color, changedId, isShowFooter, navLinks, isShowMessengers }
