import React, { FC, memo, useEffect, useState } from 'react'
import { Logo } from '@ucheba/ui/components/Logo/touch'
import MenuIcon from '@ucheba/ui/icons/icon__menu.svg'
import { Button } from '@ucheba/ui/components/Button/touch'

import FemaleProfileIcon from '@ucheba/ui/icons/icon__female-profile.svg'
import LoginIcon from '@ucheba/ui/icons/icon__login.svg'
import GraduationCapIcon from '@ucheba/ui/icons/icon__graduation-cap.svg'

import { useSelector } from 'react-redux'
import { useRouter } from 'next/router'
import { getLogoColor, useAuthContent } from '../bll'
import { IHeaderProps } from '../types'
import { headerSelectors } from '../store'
import SC from '../base/Header.styles'

const {
  Block,
  MainContainerTouchWrapper,
  Main,
  LogoLink,
  HeaderNavItem,
  TouchButtons,
  Circle,
} = SC

/** Шапка сайта */
const Header: FC<IHeaderProps> = (props) => {
  const { toggleSidebar, ...otherProps } = props
  const color = useSelector(headerSelectors.color)
  const { pathname, asPath, push } = useRouter()
  const { authLinkHref, isAuth } = useAuthContent(pathname)
  const isShowHeader = useSelector(headerSelectors.isShowHeader)

  const [isRenderCircle, setIsRenderCircle] = useState(false)

  useEffect(() => {
    setIsRenderCircle(!localStorage.getItem('wantToStudyButtonStepsShow'))
  }, [])

  const isPlanner = asPath.includes('/for-abiturients/planner')
  if (!isShowHeader) return null

  return (
    <Block {...otherProps}>
      <Main color={color}>
        <MainContainerTouchWrapper>
          <Button
            color={color === 'black' ? undefined : color}
            icon
            size='small'
            onClick={toggleSidebar}
          >
            <MenuIcon />
          </Button>

          <LogoLink href='/'>
            <Logo color={getLogoColor(color)} aria-label='Главная страница' />
          </LogoLink>

          <TouchButtons>
            <HeaderNavItem
              onClick={() => push('/for-abiturients/planner')}
              color={color}
              active={isPlanner}
            >
              <GraduationCapIcon />

              {isRenderCircle && <Circle id='admissionPlanButtonCircle' />}
            </HeaderNavItem>

            <HeaderNavItem color={color} onClick={() => push(authLinkHref)}>
              {isAuth ? <FemaleProfileIcon /> : <LoginIcon />}
            </HeaderNavItem>
          </TouchButtons>
        </MainContainerTouchWrapper>
      </Main>
    </Block>
  )
}

export { Header }
export default memo(Header)
