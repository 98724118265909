import { getStateError, getStateItem } from '../../utils/core'
import { sliceName } from '../constants'
import { IOlympsIdState } from './types'

export default {
  entity: getStateItem<IOlympsIdState, IOlympsIdState[typeof sliceName]['entity']>(
    sliceName,
    'entity'
  ),

  loading: getStateItem<IOlympsIdState, IOlympsIdState[typeof sliceName]['loading']>(
    sliceName,
    'loading'
  ),

  error: getStateError<IOlympsIdState>(sliceName),
}
