import { sliceName } from './constants'
import { getStateError, getStateItem } from '../utils/core'
import { IProfessionsState } from './types'
import { professionsAdaptor } from './slice'

const adapterSelectors = professionsAdaptor.getSelectors<IProfessionsState>(
  (state) => state[sliceName]
)

export default {
  ...adapterSelectors,

  loading: getStateItem<
    IProfessionsState,
    IProfessionsState[typeof sliceName]['loading']
  >(sliceName, 'loading'),

  error: getStateError<IProfessionsState>(sliceName),
}
