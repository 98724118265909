/** Проверяет, содержится ли каждое значение массивов друг у друга */
export const isArraysIncludesValuesBoth = (
  thisValue: string[],
  otherValue: string[]
): boolean => {
  if (thisValue.length !== otherValue.length) return false

  return thisValue.every((targetValue) => otherValue.includes(targetValue))
}

/* Возвращает массив из строк/чисел с уникальными значениями */
export const arrayUnique = (array: any[]): any[] => {
  const a = array.concat()

  for (let i = 0; i < a.length; ++i) {
    for (let j = i + 1; j < a.length; ++j) {
      if (a[i] === a[j]) a.splice(j--, 1)
    }
  }

  return a
}
