import apiThunk, { api } from '@ucheba/utils/helpers/api'
import { sliceName } from './constants'

const fetchFunction = async (props): Promise<any> => {
  const response = await api.get('tags/stats', props)

  return response.data
}

const fetchParents = apiThunk(`${sliceName}/fetchParents`, async (params) => {
  return fetchFunction(params)
})

const fetchChildren = apiThunk(`${sliceName}/fetchChildren`, async (params) => {
  return fetchFunction(params)
})

export default { fetchParents, fetchChildren }
