import styled, { StyledProps } from 'styled-components'
import { EColorBgText, sv } from '@ucheba/utils/helpers/styles/variables'
import { sm } from '@ucheba/utils/helpers/styles/mixins'
import {
  EModColorBgText,
  EModOverflow,
  EModRadius,
  EModShadowSize,
  EModSpacingH,
  EModSpacingV,
  IModColorBgText,
  IModOverflow,
  IModPosition,
  IModRadius,
  IModShadowSize,
  IModSpacingH,
  IModSpacingV,
  IModTextAlign,
} from '@ucheba/utils/helpers/styles/mod.types'
import { IPropHeight, IPropWidth } from '@ucheba/utils/helpers/components/prop.types'
import { IModResetLastSpacing } from '../types'

const Block = styled.div<
  StyledProps<
    IModSpacingV &
      IModSpacingH &
      IModColorBgText &
      IModResetLastSpacing &
      IModShadowSize &
      IModRadius &
      IModOverflow &
      IPropWidth &
      IPropHeight &
      IModPosition &
      IModTextAlign
  >
>`
  ${Object.keys(EModSpacingV).map(
    (item) => sm.mod(EModSpacingV[item])`
      padding-top: ${sv.spacing[item]};
      padding-bottom: ${sv.spacing[item]};
    `
  )};

  ${Object.keys(EModSpacingH).map(
    (item) => sm.mod(EModSpacingH[item])`
      padding-left: ${sv.spacing[item]};
      padding-right: ${sv.spacing[item]};
    `
  )};

  ${Object.keys(EModColorBgText).map(
    (item) => sm.mod(EModColorBgText[item])`
      background-color: ${sv.color[item]};
      color: ${sv.color[EColorBgText[item]]};
    `
  )};

  ${Object.keys(EModShadowSize).map(
    (item) => sm.mod(EModShadowSize[item])`
      box-shadow: ${sv.shadow[item]};
    `
  )};

  ${Object.keys(EModRadius).map(
    (item) => sm.mod(EModRadius[item])`
      border-radius: ${sv.radius[item]};
    `
  )};

  ${Object.keys(EModOverflow).map(
    (item) => sm.mod(EModOverflow[item])`
      overflow: ${item};
    `
  )};

  overflow: ${(props) => `${props.overflow}`};

  ${(props) => (props.width ? `width: ${props.width};` : ``)}

  ${(props) => (props.height ? `height: ${props.height};` : ``)}

  ${(props) => (props.position ? `position: ${props.position};` : ``)}

  ${(props) => (props.textAlign ? `text-align: ${props.textAlign};` : ``)}
`

export default { Block }
