import { sliceName } from './constants'
import { getStateError, getStateItem } from '../utils/core'
import { IClientRequestState } from './types'

export default {
  loading: getStateItem<
    IClientRequestState,
    IClientRequestState[typeof sliceName]['loading']
  >(sliceName, 'loading'),

  error: getStateError<IClientRequestState>(sliceName),
}
