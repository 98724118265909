import apiThunk, { api } from '@ucheba/utils/helpers/api'
import { getFullRouterPath } from '@ucheba/utils/helpers/router'
import { sliceName } from './constants'

const fetch = apiThunk(`${sliceName}/fetch`, async (params) => {
  const response = await api.get(getFullRouterPath('exams/ege/stats'), params)

  return response.data
})

export default { fetch }
