/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import { IAuthPhoneState, IUser } from './types'
import { extraReducersResponse, responseInitialState } from '../../utils/response'
import { sliceName } from './constants'
import thunks from './thunks'
import { ELoading } from '../../utils/response/types'

export default createSlice({
  name: sliceName,

  initialState: {
    user: null as IUser | null,
    userName: null,
    code: null,
    ...responseInitialState,
  },

  reducers: {
    resetLoading: (state): void => {
      state.loading = ELoading.idle
    },
    setCode: (state, { payload }): void => {
      state.code = payload
    },
    setUserName: (state, { payload }): void => {
      state.userName = payload
    },
  },

  extraReducers: (builder) => {
    extraReducersResponse<IAuthPhoneState[typeof sliceName], IUser>({
      builder,
      thunk: thunks.phone,
      fulfilled: (state, action) => {
        state.user = action.payload
      },
    })
  },
})
