import { sliceName } from './constants'
import { getStateError, getStateItem } from '../../utils/core'
import { ISpecialitiesSearchState } from './types'
import { specialitiesSearchAdaptor } from './slice'

const adapterSelectors = specialitiesSearchAdaptor.getSelectors<ISpecialitiesSearchState>(
  (state) => state[sliceName]
)

export default {
  ...adapterSelectors,

  total: getStateItem<
    ISpecialitiesSearchState,
    ISpecialitiesSearchState[typeof sliceName]['total']
  >(sliceName, 'total'),

  seo: getStateItem<
    ISpecialitiesSearchState,
    ISpecialitiesSearchState[typeof sliceName]['seo']
  >(sliceName, 'seo'),

  facets: getStateItem<
    ISpecialitiesSearchState,
    ISpecialitiesSearchState[typeof sliceName]['facets']
  >(sliceName, 'facets'),

  canIndex: getStateItem<
    ISpecialitiesSearchState,
    ISpecialitiesSearchState[typeof sliceName]['canIndex']
  >(sliceName, 'canIndex'),

  criteria: getStateItem<
    ISpecialitiesSearchState,
    ISpecialitiesSearchState[typeof sliceName]['criteria']
  >(sliceName, 'criteria'),

  loading: getStateItem<
    ISpecialitiesSearchState,
    ISpecialitiesSearchState[typeof sliceName]['loading']
  >(sliceName, 'loading'),

  error: getStateError<ISpecialitiesSearchState>(sliceName),
}
