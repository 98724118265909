import apiThunk, { api } from '@ucheba/utils/helpers/api'
import { sliceName } from './constants'
import { setAuthCookie } from '../bll'

const phone = apiThunk(`${sliceName}/phone`, async (params) => {
  const data = { ...params?.data, rememberMe: true }

  const response = await api.post(`/auth/phone`, data, params)

  if (response.data) {
    setAuthCookie({ ctx: params?.ctx, token: response.data.token })
  }

  return response.data
})

export default { phone }
