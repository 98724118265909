import apiThunk, { api } from '@ucheba/utils/helpers/api'
import { sliceName } from './constants'

/* В данном методе НИКОГДА нельзя передавать phone для патча данных, иначе багует
 * Позже, бэк заблокирует нам эту возможность */
const patch = apiThunk(`${sliceName}/patch`, async (params) => {
  const otherData = params?.data ? { ...params.data } : {}

  if (otherData.phone) {
    delete otherData.phone
  }

  const response = await api.patch(`/profile`, otherData, params)

  return response.data
})

const get = apiThunk(`${sliceName}/get`, async (params) => {
  const response = await api.get(`/profile`, params)

  return response.data
})

export default { patch, get }
