export enum EFooterColor {
  silver = 'black',
  navy = 'white70',
  blue = 'white70',
  gold = 'gray60',
  black = 'white',
}

export enum EFooterColorLogo {
  silver = 'blue',
  black = 'white',
  navy = 'white',
}

export enum EModFooterColor {
  silver = 'color=silver',
  navy = 'color=navy',
  blue = 'color=blue',
  gold = 'color=gold',
  black = 'color=black',
}

export interface IModFooterColor {
  color: keyof typeof EModFooterColor
}

export interface IFooterProps {
  notFoundPage: boolean
}

export interface IFooterNavLinksProps {
  list?:
    | {
        name: string
        href: string
      }[]
    | null
}
