import { NextPageContext } from 'next'
import { getCookieDomain, setCookie } from '@ucheba/utils/helpers/core'
import { locationIdsCookie } from '@ucheba/utils/constants/core'
import dayjs from '@ucheba/utils/helpers/date/dayjs'

export const setLocationIdCookie = ({
  ctx,
  locationIds,
}: {
  ctx?: NextPageContext
  locationIds?: number[]
}): void => {
  const expiresCalc = dayjs().add(1, 'year').toDate()
  setCookie(
    locationIdsCookie,
    JSON.stringify(locationIds),
    {
      expires: expiresCalc,
      domain: getCookieDomain(),
      httpOnly: false,
    },
    ctx
  )
}
