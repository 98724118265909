/* eslint-disable no-param-reassign */
import { createEntityAdapter, createSlice, EntityState } from '@reduxjs/toolkit'
import { IItemOfSubjects } from './types'
import { IResponseInitialState } from '../utils/response/types'
import { extraReducersResponse, responseInitialState } from '../utils/response'
import { sliceName } from './constants'
import thunks from './thunks'

export const subjectsAdapter = createEntityAdapter<IItemOfSubjects>()

export default createSlice({
  name: sliceName,

  initialState:
    subjectsAdapter.getInitialState<IResponseInitialState>(responseInitialState),

  reducers: {},

  extraReducers: (builder) => {
    extraReducersResponse<EntityState<IItemOfSubjects>, IItemOfSubjects[]>({
      builder,
      thunk: thunks.fetch,
      fulfilled: (state, action) => {
        subjectsAdapter.setAll(state, action.payload)
      },
    })
  },
})
