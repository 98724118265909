import styled, { StyledProps } from 'styled-components'
import { sv } from '@ucheba/utils/helpers/styles/variables'
import { sm } from '@ucheba/utils/helpers/styles/mixins'
import {
  EModActive,
  EModColor,
  EModDisabled,
  EModFocused,
  IModActive,
  IModColor,
  IModFocused,
} from '@ucheba/utils/helpers/styles/mod.types'
import {
  IPropCss,
  IPropHeight,
  IPropWidth,
} from '@ucheba/utils/helpers/components/prop.types'

interface IProps {
  height?: string
}

const TextArea = styled.textarea<StyledProps<IPropCss & IProps>>`
  padding: ${sv.spacing.xsmall};
  resize: none;
  color: currentColor;
  width: 100%;
  border: 0;
  margin: 0;
  display: block;
  min-width: 0;
  background: none;
  box-sizing: border-box;
  animation-name: mui-auto-fill-cancel;
  animation-duration: 10ms;
  -webkit-tap-highlight-color: transparent;
  transition: ${sv.duration.fast};
  ${sm.font.medium};

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type='number'] {
    -moz-appearance: textfield;
  }

  ${(props) => (props.height ? `height: ${props.height};` : ``)}
`

const Input = styled.input<StyledProps<IModColor>>`
  padding: ${sv.spacing.xsmall};
  color: currentColor;
  width: 100%;
  border: 0;
  height: 1.5em;
  margin: 0;
  display: block;
  min-width: 0;
  background: none;
  box-sizing: content-box;
  animation-name: mui-auto-fill-cancel;
  animation-duration: 10ms;
  -webkit-tap-highlight-color: transparent;
  transition: ${sv.duration.fast};
  ${sm.font.medium};

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    ${sm.mod(EModColor.white50)`
      -webkit-text-fill-color: ${sv.color.black};
      -webkit-box-shadow: 0 0 0px 1000px ${sv.color.white15} inset;
      border-radius: 4px;
      background-color: transparent !important;
    `}
  }

  &[type='number'] {
    -moz-appearance: textfield;
  }

  ${sm.mod(EModDisabled.true)`
    opacity: 0.5;
  `};

  ${sm.mod(EModColor.white50)`
    color: ${sv.color.white};
  `}
`

const Label = styled.label<StyledProps<IModActive & IModColor & { error?: boolean }>>`
  color: ${(props) => (props.error ? sv.color.red : sv.color.gray60)};
  display: block;
  transform-origin: top left;
  top: 0;
  left: 0;
  position: absolute;
  transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  z-index: 0;
  transform: translate(12px, ${sv.spacing.small}) scale(1);
  pointer-events: none;
  ${sm.font.medium};
  line-height: 1;

  ${sm.mod(EModColor.orange)`
    color: ${sv.color.gray60};
  `}

  ${sm.mod(EModColor.blue)`
    color: ${sv.color.gray60};
  `}

  ${sm.mod(EModColor.plum)`
    color: ${sv.color.gray60};
  `}

  ${sm.mod(EModColor.pink)`
    color: ${sv.color.gray60};
  `}

  ${sm.mod(EModColor.white50)`
    color: ${sv.color.white50};
  `}

  ${sm.mod(EModActive.true)`
    transform: translate(12px, -6px) scale(0.75);
  `};
`

const Fieldset = styled.fieldset.attrs(() => ({
  'aria-hidden': true,
}))<StyledProps<IModColor>>`
  top: -5px;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  padding: 0 7px;
  overflow: hidden;
  position: absolute;
  border-style: solid;
  border-width: 1px;
  border-radius: inherit;
  pointer-events: none;
  border-color: ${(props) => (props.error ? sv.color.red : sv.color.gray15)};
  transition: ${sv.duration.fast};

  ${sm.mod(EModColor.white50)`
    border-color: ${sv.color.white50};
  `}
`

const Legend = styled.legend<StyledProps<IModActive>>`
  width: auto;
  height: 11px;
  display: block;
  padding: 0;
  margin: 0;
  font-size: 0.78em;
  max-width: 0.01px;
  text-align: left;
  transition: max-width 50ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  visibility: hidden;

  ${sm.mod(EModActive.true)`
    max-width: 1000px;
    transition: max-width 100ms cubic-bezier(0, 0, 0.2, 1) 50ms;
    padding-left: ${sv.spacing.usmall};
    padding-right: ${sv.spacing.usmall};
  `};
`

const Inner = styled.div<StyledProps<IPropCss>>`
  position: relative;
  border-radius: ${sv.radius.medium};
  color: rgba(0, 0, 0, 0.87);
  cursor: text;
  display: inline-flex;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  ${sm.font.medium};
`

const Block = styled.div<
  StyledProps<
    IModFocused & IPropWidth & IModActive & IPropHeight & IModColor & { error?: boolean }
  >
>`
  border: 0;
  margin: 0;
  padding: 0;
  position: relative;
  min-width: 0;
  flex-direction: column;
  vertical-align: top;
  height: 48px;
  ${(props) => (props.width ? `width: ${props.width};` : ``)}
  ${(props) => (props.height ? `height: ${props.height};` : ``)}

  &:hover {
    ${Input},
    ${TextArea} {
      color: ${(props) => (props.error ? sv.color.black : sv.color.cobalt)};

      ${sm.mod(EModColor.white50)`
          color: ${sv.color.white};
      `}

      ${sm.mod(EModColor.orange)`
          color: ${sv.color.mandarin};
      `}

      ${sm.mod(EModColor.pink)`
          color: ${sv.color.raspberry};
      `}

      ${sm.mod(EModColor.plum)`
          color: ${sv.color.plum};
      `}

      ${sm.mod(EModColor.blue)`
          color: ${sv.color.cobalt};
      `}
    }

    ${Label} {
      color: ${(props) => (props.error ? sv.color.red : sv.color.cobalt)};

      ${sm.mod(EModColor.white50)`
          color: ${sv.color.white70};
      `}

      ${sm.mod(EModColor.orange)`
          color: ${sv.color.gray60};
      `}

      ${sm.mod(EModColor.pink)`
          color: ${sv.color.gray60};
      `}

      ${sm.mod(EModColor.plum)`
          color: ${sv.color.gray60};
      `}

      ${sm.mod(EModColor.blue)`
          color: ${sv.color.gray60};
      `}

      ${sm.mod(EModActive.true)`
          ${sm.mod(EModColor.orange)`
              color: ${sv.color.mandarin};
          `}

          ${sm.mod(EModColor.pink)`
              color: ${sv.color.raspberry};
          `}

          ${sm.mod(EModColor.plum)`
              color: ${sv.color.plum};
          `}

          ${sm.mod(EModColor.blue)`
              color: ${sv.color.cobalt};
          `}
      `}
    }

    ${Fieldset} {
      border-color: ${(props) => (props.error ? sv.color.red : sv.color.cobalt)};

      ${sm.mod(EModColor.white50)`
          border-color: ${sv.color.white70};
      `}

      ${sm.mod(EModColor.orange)`
          border-color: ${sv.color.mandarin};
      `}

      ${sm.mod(EModColor.pink)`
          border-color: ${sv.color.raspberry};
      `}

      ${sm.mod(EModColor.plum)`
          border-color: ${sv.color.plum};
      `}

      ${sm.mod(EModColor.blue)`
          border-color: ${sv.color.cobalt};
      `}
    }
  }

  ${sm.mod(EModFocused.true)`
    &,
    &:hover {
      ${Input}, ${TextArea} {
        color: ${sv.color.black};

        ${sm.mod(EModColor.white50)`
          color: ${sv.color.white};
        `}

        ${sm.mod(EModColor.orange)`
          color: ${sv.color.mandarin};
        `}

        ${sm.mod(EModColor.pink)`
            color: ${sv.color.raspberry};
        `}

        ${sm.mod(EModColor.plum)`
            color: ${sv.color.plum};
        `}

        ${sm.mod(EModColor.blue)`
            color: ${sv.color.cobalt};
        `}
      }

      ${Label} {
        color: ${sv.color.blue};

        ${sm.mod(EModColor.white50)`
          color: ${sv.color.white70};
        `}

        ${sm.mod(EModColor.orange)`
          color: ${sv.color.mandarin};
        `}

        ${sm.mod(EModColor.pink)`
            color: ${sv.color.raspberry};
        `}

        ${sm.mod(EModColor.plum)`
            color: ${sv.color.plum};
        `}

        ${sm.mod(EModColor.blue)`
            color: ${sv.color.cobalt};
        `}
      }

      ${Fieldset} {
        border-color: ${sv.color.blue};

        ${sm.mod(EModColor.white50)`
          border-color: ${sv.color.white70};
        `}

        ${sm.mod(EModColor.orange)`
          border-color: ${sv.color.mandarin};
        `}

        ${sm.mod(EModColor.pink)`
          border-color: ${sv.color.raspberry};
        `}

        ${sm.mod(EModColor.plum)`
          border-color: ${sv.color.plum};
        `}

        ${sm.mod(EModColor.blue)`
          border-color: ${sv.color.cobalt};
        `}
      }
    }
  `};
`

export default { Block, Inner, Legend, Fieldset, Label, Input, TextArea }
