import { createSlice } from '@reduxjs/toolkit'
import { sliceName } from './constsnts'
import { extraReducersResponse, responseInitialState } from '../../utils/response'
import {
  IPlannerStatisticsPrograms,
  IPlannerStatisticsSpecialities,
  IPlannerStatisticsState,
} from './types'
import thunks from './thunks'

export default createSlice({
  name: sliceName,

  initialState: {
    entityProgramsStatistics: null as IPlannerStatisticsPrograms | null,
    entitySpecialtiesStatistics: null as IPlannerStatisticsSpecialities | null,
    ...responseInitialState,
  },

  reducers: {},

  extraReducers: (builder) => {
    extraReducersResponse<
      IPlannerStatisticsState[typeof sliceName],
      IPlannerStatisticsPrograms
    >({
      builder,
      thunk: thunks.getProgramsStatistics,
      fulfilled: (state, action) => {
        // eslint-disable-next-line no-param-reassign
        state.entityProgramsStatistics = action.payload
      },
    })

    extraReducersResponse<
      IPlannerStatisticsState[typeof sliceName],
      IPlannerStatisticsSpecialities
    >({
      builder,
      thunk: thunks.getSpecialtiesStatistics,
      fulfilled: (state, action) => {
        // eslint-disable-next-line no-param-reassign
        state.entitySpecialtiesStatistics = action.payload
      },
    })
  },
})
