import {
  IPropHref,
  IPropName,
  IPropOnClick,
  IPropWidth,
} from '@ucheba/utils/helpers/components/prop.types'
import { EColor } from '@ucheba/utils/helpers/styles/variables'
import { MutableRefObject } from 'react'

export interface INavigationProps
  extends IModNavigationColor,
    IPropWidth,
    IPropOnClick<INavigationOnClickCallback> {
  /** Объект с данными для пукнтов навигации в формате */
  items: INavigationItem[]

  /** Активный индекс */
  activeIndex: number

  /** Затенение по краям (если пункты меню не влезают в контейнер) */
  fade?: keyof typeof EColor

  /** Перезагрузка при переходе */
  reload?: boolean

  /** Включить микроразметку */
  withRichSnippet?: boolean
}

interface INavigationItem extends IPropHref, Required<IPropName> {
  slug?: string | string[]
}

export type INavigationOnClickCallback = (event: any, slug: string) => void

export enum ENavigationColor {
  black = 'gray5',
  white = 'white15',
  pine = 'green5',
  maroon = 'pink5',
  coffee = 'orange5',
}

export enum EModNavigationColor {
  black = 'color=black',
  white = 'color=white',
  pine = 'color=pine',
  maroon = 'color=maroon',
  coffee = 'color=coffee',
}

export interface IModNavigationColor {
  /** Цвет текста навигации */
  color?: keyof typeof EModNavigationColor
}

export enum EFadePosition {
  right = 'right',
  left = 'left',
  both = 'both',
  null = 'null',
}

export type TFadePosition = keyof typeof EFadePosition

export enum EModIsFadeExist {
  true = 'isFadeExist=true',
  false = 'isFadeExist=false',
}

export interface IModIsFadeExist {
  isFadeExist?: keyof typeof EModIsFadeExist
}

export interface IUseNavigationCore {
  (): {
    wrapperRef: MutableRefObject<HTMLElement | null>
    menuRef: MutableRefObject<HTMLElement | null>
    isFadeLeft: boolean
    isFadeRight: boolean
    isFadeBoth: boolean
    isFadeExist: boolean
    leftButtonHandler: () => void
    rightButtonHandler: () => void
  }
}
