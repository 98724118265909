import apiThunk, { api } from '@ucheba/utils/helpers/api'
import { sliceName } from './constants'
import { setAuthCookie } from '../../auth/bll'

const fetch = apiThunk(`${sliceName}/registration`, async (params) => {
  const response = await api.post(`/profile/registration`, params?.data, params)

  setAuthCookie({ ctx: params?.ctx, token: response.data.token })

  return response.data
})

export default { fetch }
