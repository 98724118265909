export interface IAnalyticEvent {
  (props: IAnalyticEventProps): void
}

export interface IAnalyticEventProps {
  /** В какие системы аналитики отправлять. оставьте пустым, чтобы отправить во все */
  targets?: EAnalyticEventTargets[]

  /** Название ивента */
  eventName: string

  /** Значение, отправляемое в аналитику */
  value?: Record<string, any>

  section?: string
}

export enum EAnalyticEventTargets {
  vk = 'vk',
  ym = 'ym',
  ga = 'ga',
  fb = 'fb',
  ur = 'ur',
  gtm = 'gtm',
}

export enum EAnalyticEvents {
  userAuthSuccess = 'USER__AUTH__SUCCESS',
  consultSubmitLead = 'CONSULT__SUBMIT__LEAD',
  eventRegistrationSuccess = 'EVENT__REGISTRATION__SUCCESS',
  egeLeadLesson = 'EGE__LEAD__LESSON',
  egeLeadConsult = 'EGE__LEAD__CONSULT',
  egeLeadRequest = 'EGE__LEAD__REQUEST',
  egeLeadTest = 'EGE__LEAD__TEST',
  vkNoticeDisplayLead = 'VK-NOTICE__DISPLAY__LEAD',
  vkNoticeClickLead = 'VK-NOTICE__CLICK__LEAD',
  codeRequestBySms = 'USER__CODE_REQUEST__SMS',
  codeRequestByPhone = 'USER__CODE_REQUEST__PHONE',
  codeEnteredFromSms = 'USER__CODE_ENTERED__SMS',
  codeEnteredFromPhone = 'USER__CODE_ENTERED__PHONE',
  codeAcceptedFromSms = 'USER__CODE_ACCEPTED__SMS',
  codeAcceptedFromPhone = 'USER__CODE_ACCEPTED__PHONE',
  userAuthByCode = 'USER__AUTH__CODE',
  codeRetryFromSms = 'USER__CODE_RETRY__SMS',
  codeRetryFromPhone = 'USER__CODE_RETRY__PHONE',
  codeSwitchPhoneToSmsSwitch = 'USER__CODE_SWITCH__PHONE_TO_SMS',
  userRegistrationSuccess = 'USER__REGISTRATION__SUCCESS',
  plannerProgramAdded = 'PLANNER__PROGRAM__ADDED',
  egeStartRegistrationClick = 'EGE_START__REGISTRATION__CLICK',
  engineeringProjectsClick = 'ENGINEERING_PROJECTS__CLICK',
  engineeringProjectsSuccess = 'ENGINEERING_PROJECTS__SUCCESS',
  ogeLeadLesson = 'OGE__LEAD__LESSON',
  ogeLeadConsult = 'OGE__LEAD__CONSULT',
  ogeLeadRequest = 'OGE__LEAD__REQUEST',
  ogeLeadTest = 'OGE__LEAD__TEST',
  profMarathonRegistered = 'EVENT__PROF_MARATHON__REGISTERED',
  requestPopupOpened = 'REQUEST_POPUP_OPENED',
  requestMessengersFormOpened = 'REQUEST_MESSENGERS_FORM_OPENED',
  requestToVK = 'REQUEST_TO_VK',
  requestToTG = 'REQUEST_TO_TG',
  requestToWhatsApp = 'REQUEST_TO_WHATSAPP',
  egeClickBonuses = 'EGE__CLICK__BONUSES',
  profLeadRequest = 'PROF__LEAD__REQUEST',
  leadFormRequestVuz = 'LEADFORM__REQUEST__VUZ',
  leadFormRequestCollege = 'LEADFORM__REQUEST__COLLEGE',
  leadFormRequestChance = 'LEADFORM__REQUEST__CHANCE',
  leadFormRequestProgramVuz = 'LEADFORM__REQUEST__PROGRAM-VUZ',
  leadFormRequestProgramCollege = 'LEADFORM__REQUEST__PROGRAM-COLLEGE',
  leadFormRequestSpeciality = 'LEADFORM__REQUEST__SPECIALITY',
  leadFormRequestUzList = 'LEADFORM__REQUEST__UZ-LIST',
  leadFormRequestProf = 'LEADFORM__REQUEST__PROF',
  admissionGuideBannerButtonClick = 'ADMISSION_GUIDE_BANNER__BUTTON__CLICK',
  admissionGuideButtonClick = 'ADMISSION_GUIDE__BUTTON__CLICK',
  schoolSdamgiaClickButton = 'SCHOOL_SDAMGIA__CLICK__BUTTON',
  eventRegistrationClick = 'EVENT__REGISTRATION__CLICK',
  admissionSupportLeadRequest = 'ADMISSION_SUPPORT__LEAD__REQUEST',
  admissionGuideAdmissionSupportCardClick = 'ADMISSION_GUIDE__ADMISSION-SUPPORT-CARD__CLICK',
}

export type TGAEventName = {
  category: string
  action: string
  label: string
}

export enum EAnalyticSections {
  admissionSupportCard = 'admission_support_card',
  authForm = 'auth_form',
  consultLendingForm = 'consult_lending_form',
  eventRegistration = 'event_registration',
  registrationPage = 'registration_page',
  registrationButtonProfMarathon = 'registration_button_prof_marathon',
  requestForm = 'request_form',
  vkNotice = 'vk_notice',
  plannerProgram = 'planner_program',
  popupAssessmentOfChances = 'popup_assessment_of_chances',
  egeStartRegistrationButton = 'ege_start_registration_button',
  engineeringProjectsRegistrationButton = 'engineering_projects_registration_button',
  engineeringProjectsRegistration = 'engineering_projects_registration',
  profMarathonRegistered = 'prof_marathon_registration_button',
  eventNoticeNotAllowedMessages = 'event_notice_not_allowed_messages',
  egeDemo = 'ege_demo',
  egeStart = 'ege_start',
  bonusesBlock = 'bonuses_block',
  leadMagnetForm = 'lead_magnet_form',
  admissionGuideBanner = 'admissionGuideBanner',
  admissionGuideButton = 'admissionGuideButton',
  schoolSdamgiaButton = 'schoolSdamgiaButton',
  eventRegistrationClick = 'event_registration_button',
}
