import { IModColor, IModSize } from '@ucheba/utils/helpers/styles/mod.types'
import { IPropDuration } from '@ucheba/utils/helpers/components/prop.types'

export interface IProgressBarProps extends IModColor, IModSize, IPropDuration {}

export interface IModResetBorderRadius {
  /** Сброс округления бокса */
  resetBorderRadius: boolean
}

export enum EModResetBorderRadius {
  true = 'resetBorderRadius=true',
  false = 'resetBorderRadius=false',
}
