import { css, FlattenInterpolation } from 'styled-components'
import { sv } from '@ucheba/utils/helpers/styles/variables'
import { EModButtonColor, TButtonHoverColor, TButtonTextColor } from './types'

type TBgColor = keyof typeof EModButtonColor | 'gray5' | 'transparent'

type TBgHover =
  | TButtonHoverColor
  | 'acid'
  | 'gray5'
  | 'gray25'
  | 'gray50'
  | 'transparent'
  | 'white70'
  | 'white'
  | 'plum'
  | 'navy'
  | 'black'
  | 'flamingo'

type TTextColor = TButtonTextColor | 'gray60' | 'cobalt' | 'emerald' | 'maroon'

interface IGetButtonColor {
  (bgColor: TBgColor, bgHover: TBgHover, textColor: TTextColor): FlattenInterpolation<any>
}

export const getButtonColor: IGetButtonColor = (bgColor, bgHover, textColor) => {
  return css`
    background-color: ${sv.color[bgColor]};
    color: ${sv.color[textColor]};

    @media (hover: hover) {
      &:hover {
        background-color: ${sv.color[bgHover]};
        color: ${sv.color[textColor]};
      }
    }

    @media (hover: none) {
      &:active {
        background-color: ${sv.color[bgHover]};
        color: ${sv.color[textColor]};
      }
    }
  `
}

type TOutlineDefaultColor = keyof typeof EModButtonColor

type TOutlineHoverColor =
  | TButtonHoverColor
  | 'gray25'
  | 'gray50'
  | 'navy'
  | 'black'
  | 'flamingo'

type TOutlineTextColor = TButtonTextColor | 'navy' | 'rose'

interface IGetButtonColorOutline {
  (
    bgColor: TOutlineDefaultColor,
    bgHover: TOutlineHoverColor,
    textColor?: TOutlineTextColor
  ): FlattenInterpolation<any>
}

export const getButtonColorOutline: IGetButtonColorOutline = (
  defaultColor,
  hoverColor,
  textColor
): FlattenInterpolation<any> => {
  return css`
    background-color: transparent;
    box-shadow: inset 0 0 0 1px ${sv.color[defaultColor]};
    color: ${textColor || sv.color[defaultColor]};

    svg {
      fill: ${textColor || sv.color[defaultColor]};
    }

    @media (hover: hover) {
      &:hover {
        background-color: transparent;
        box-shadow: inset 0 0 0 1px ${sv.color[hoverColor]};
        color: ${textColor || sv.color[hoverColor]};

        svg {
          fill: ${textColor || sv.color[hoverColor]};
        }
      }
    }

    @media (hover: none) {
      &:active {
        background-color: transparent;
        box-shadow: inset 0 0 0 1px ${sv.color[hoverColor]};
        color: ${textColor || sv.color[hoverColor]};

        svg {
          fill: ${textColor || sv.color[hoverColor]};
        }
      }
    }
  `
}
