import React, { memo, forwardRef } from 'react'
import SC from '../base/GridCol.styles'
import { IGridColProps } from '../types'

const { Block } = SC

/** Колонка сетки */
const GridCol = forwardRef<HTMLElement, IGridColProps>((props, ref) => {
  const {
    as = 'div',
    sm,
    md,
    lg,
    uxs,
    xs,
    width = '',
    shrink,
    children,
    ...otherProps
  } = props

  return (
    <Block
      ref={ref}
      sm={sm}
      md={md}
      lg={lg}
      uxs={uxs}
      xs={xs}
      as={as}
      width={width}
      shrink={shrink}
      {...otherProps}
    >
      {children}
    </Block>
  )
})

export { GridCol }
export default memo(GridCol)
