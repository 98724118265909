import selectors from './selectors'
import slice from './slice'
import thunks from './thunks'
import actions from './actions'

export {
  slice as authPhoneSlice,
  selectors as authPhoneSelectors,
  thunks as authPhoneThunks,
  actions as authPhoneActions,
}
