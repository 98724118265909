import React, { FC, memo } from 'react'
import SC from '../base/Logo'
import { ILogoProps } from '../types'
import LogoImage from '../assets/icon__uchebaru.svg'
import LogoImageMini from '../assets/icon__uchebaru_minimized.svg'
import LogoSquare from '../assets/icon__uchebaru_square.svg'

const { Block } = SC

/** Логотип Учеба.ру */
const Logo: FC<ILogoProps> = (props) => {
  const {
    width = '100px',
    color = 'blue',
    height = '80px',
    minimized,
    isSquare,
    ...otherProps
  } = props

  const logo = isSquare ? LogoSquare : LogoImage

  return (
    <Block
      color={color}
      width={minimized ? '24px' : width}
      height={isSquare && height}
      as={minimized ? LogoImageMini : logo}
      {...otherProps}
    />
  )
}

export { Logo }
export default memo(Logo)
