import styled, { StyledProps } from 'styled-components'
import { EModColor, IModColor } from '@ucheba/utils/helpers/styles/mod.types'
import { sm } from '@ucheba/utils/helpers/styles/mixins'
import { sv } from '@ucheba/utils/helpers/styles/variables'
import { IPropHeight, IPropWidth } from '@ucheba/utils/helpers/components/prop.types'

const Block = styled.svg<StyledProps<IModColor & IPropWidth & IPropHeight>>`
  ${Object.keys(EModColor).map(
    (item) => sm.mod(EModColor[item])`
      color: ${sv.color[item]};
    `
  )};

  width: ${(props) => props.width};
  ${(props) => `height: ${props.height}`};
`

export default { Block }
