import React, { forwardRef, memo } from 'react'
import { useOnlyClientRendering } from '@ucheba/utils/hooks/useOnlyClientRendering'
import { Portal } from 'react-portal'
import Draggable from 'react-draggable'
import SC from '../base/BottomSheet.styles'
import { IBottomSheetBoxProps } from '../types'
import { useBottomSheetCore } from '../bll'

const {
  Block,
  Backdrop,
  Inner,
  DraggableContainer,
  DraggableArea,
  Content,
  Body,
  Header: HeaderSC,
  Footer: FooterSC,
} = SC

/** Контейнер, выезжающий снизу */
const BottomSheet = forwardRef<HTMLDivElement, IBottomSheetBoxProps>(
  function BottomSheet(props, ref) {
    const {
      open = false,
      toggleOpen,
      height,
      Header,
      Footer,
      children,
      blur = false,
      overflowForBody = 'auto',
      isScrollable = true,
    } = props

    const isClient = useOnlyClientRendering()

    const {
      innerBoxRef,
      innerContentRef,
      bottomSheetsElement,
      randomNumber,
      handleDrag,
      handleStop,
      dragging,
      closing,
      rendering,
    } = useBottomSheetCore({ open, toggleOpen, isScrollable })

    if (!rendering || !isClient || closing) return null

    const contentBlock = (
      <Content
        height={height}
        ref={ref}
        className={isScrollable ? '' : `handle-bottom-sheet-content${randomNumber}`}
        isScrollable={isScrollable}
      >
        {Header && <HeaderSC>{Header}</HeaderSC>}

        <Body ref={innerContentRef} overflow={overflowForBody}>
          {children}
        </Body>

        {Footer && <FooterSC>{Footer}</FooterSC>}
      </Content>
    )

    return (
      <Portal node={bottomSheetsElement}>
        <Block>
          <Backdrop close={closing} blur={blur} />
          <Draggable
            axis='y'
            handle={`.handle-bottom-sheet${randomNumber}`}
            position={{ x: 0, y: 0 }}
            bounds={{ top: 0 }}
            onDrag={handleDrag}
            onStop={handleStop}
          >
            <DraggableContainer active={dragging} close={closing}>
              <DraggableArea className={`handle-bottom-sheet${randomNumber}`} />

              <Inner ref={innerBoxRef} isScrollable={isScrollable}>
                {isScrollable ? (
                  <>{contentBlock}</>
                ) : (
                  <Draggable
                    axis='y'
                    handle={`.handle-bottom-sheet-content${randomNumber}`}
                    cancel='button, a, input'
                    position={{ x: 0, y: 0 }}
                    bounds={{ top: 0 }}
                    onDrag={handleDrag}
                    onStop={handleStop}
                  >
                    {contentBlock}
                  </Draggable>
                )}
              </Inner>
            </DraggableContainer>
          </Draggable>
        </Block>
      </Portal>
    )
  }
)

export { BottomSheet }
export default memo(BottomSheet)
