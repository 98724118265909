import { IProfileRegistrationState } from './types'
import { sliceName } from './constants'
import { getStateError, getStateItem } from '../../utils/core'

export default {
  loading: getStateItem<
    IProfileRegistrationState,
    IProfileRegistrationState[typeof sliceName]['loading']
  >(sliceName, 'loading'),

  userData: getStateItem<
    IProfileRegistrationState,
    IProfileRegistrationState[typeof sliceName]['userData']
  >(sliceName, 'userData'),

  user: getStateItem<
    IProfileRegistrationState,
    IProfileRegistrationState[typeof sliceName]['user']
  >(sliceName, 'user'),

  error: getStateError<IProfileRegistrationState>(sliceName),
}
