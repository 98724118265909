import { sliceName } from './constants'
import { getStateError, getStateItem } from '../utils/core'
import { IViewState } from './types'

export default {
  loading: getStateItem<IViewState, IViewState[typeof sliceName]['loading']>(
    sliceName,
    'loading'
  ),

  error: getStateError<IViewState>(sliceName),
}
