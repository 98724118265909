/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import { sliceName } from './constants'
import { extraReducersResponse, responseInitialState } from '../../utils/response'
import { IInstitutions } from '../../institutions/types'
import thunks from './thunks'
import { IParticipantsState } from './types'

export default createSlice({
  name: sliceName,

  initialState: {
    entity: null as IInstitutions | null,
    ...responseInitialState,
  },

  reducers: {},

  extraReducers: (builder) => {
    extraReducersResponse<IParticipantsState[typeof sliceName], IInstitutions>({
      builder,
      thunk: thunks.fetch,
      fulfilled: (state, action) => {
        state.entity = action.payload
      },
    })
  },
})
