/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import { extraReducersResponse, responseInitialState } from '../../../utils/response'
import { sliceName } from './constants'
import thunks from './thunks'

export default createSlice({
  name: sliceName,

  initialState: {
    requestId: null,
    isParent: null,
    packageId: null,
    isFormVisible: false,
    successAddParent: null,
    ...responseInitialState,
  },

  reducers: {
    setPackage: (state, { payload }): void => {
      state.packageId = payload
    },
    setIsFormVisible: (state): void => {
      state.isFormVisible = true
    },
    setIsParent: (state, { payload }): void => {
      state.isParent = payload
    },
  },

  extraReducers: (builder) => {
    extraReducersResponse({
      builder,
      thunk: thunks.request,
      fulfilled: (state, action) => {
        state.requestId = action.payload
      },
      rejected: (state, action) => {
        state.requestId = action.payload
      },
    })
    extraReducersResponse({
      builder,
      thunk: thunks.addParent,
      fulfilled: (state, action) => {
        state.successAddParent = action.payload
      },
    })
  },
})
