/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import { sliceName } from './constants'
import { extraReducersResponse, responseInitialState } from '../../utils/response'
import thunks from './thunks'

import { ILearningRequestState, ILearningRequestData } from './types'

export default createSlice({
  name: sliceName,

  initialState: {
    entity: null as ILearningRequestData | null,
    ...responseInitialState,
  },

  reducers: {},

  extraReducers: (builder) => {
    extraReducersResponse<ILearningRequestState[typeof sliceName], ILearningRequestData>({
      builder,
      thunk: thunks.fetchLearningRequestsIds,
      fulfilled: (state, { payload }) => {
        state.entity = payload
      },
    })
  },
})
