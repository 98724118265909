import {
  IInstitutionsRankingsParticipantsState,
  IItemOfInstitutionsRankingsParticipants,
} from './types'
import { institutionsRankingsParticipantsAdapter } from './slice'
import { sliceName } from './constants'
import { getStateById, getStateError } from '../../../../utils/core'

const adapterSelectors =
  institutionsRankingsParticipantsAdapter.getSelectors<IInstitutionsRankingsParticipantsState>(
    (state) => state[sliceName]
  )

export default {
  ...adapterSelectors,

  byId: getStateById<
    IInstitutionsRankingsParticipantsState,
    IItemOfInstitutionsRankingsParticipants
  >(adapterSelectors),

  error: getStateError<IInstitutionsRankingsParticipantsState>(sliceName),
}
