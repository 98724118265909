import { ISelectorBySlug, ISubjectsDetail, ISubjectsDetailState } from './types'
import { subjectsDetailAdaptor } from './slice'
import { sliceName } from './constants'
import { getStateById, getStateError } from '../../utils/core'

const adapterSelectors = subjectsDetailAdaptor.getSelectors<ISubjectsDetailState>(
  (state) => state[sliceName]
)

export default {
  ...adapterSelectors,

  byId: getStateById<ISubjectsDetailState, ISubjectsDetail>(adapterSelectors),

  bySlug:
    (slug: string): ISelectorBySlug =>
    (state): ISubjectsDetail | undefined =>
      adapterSelectors.selectAll(state).find((item) => item.slug === slug),

  error: getStateError<ISubjectsDetailState>(sliceName),
}
