import { FC } from 'react'
import { IProgressBarProps } from '../types'
import SC from '../base/ProgressBar.styles'

const { Block } = SC

const ProgressBar: FC<IProgressBarProps> = (props) => {
  const { color = 'orange', duration = 5000, size = 'medium' } = props

  return <Block color={color} duration={duration} size={size} />
}

export default ProgressBar
