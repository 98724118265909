import styled, { StyledProps } from 'styled-components'
import { EModColor, IModColor } from '@ucheba/utils/helpers/styles/mod.types'
import { sm } from '@ucheba/utils/helpers/styles/mixins'
import { sv } from '@ucheba/utils/helpers/styles/variables'
import {
  IPropCss,
  IPropHeight,
  IPropWidth,
} from '@ucheba/utils/helpers/components/prop.types'

interface IOpacity {
  opacity?: string
}

const Block = styled.svg<
  StyledProps<IModColor & IPropCss & IPropWidth & IPropHeight & IOpacity>
>`
  ${Object.keys(EModColor).map(
    (item) => sm.mod(EModColor[item])`
      color: ${sv.color[item]};
    `
  )};

  width: ${(props) => props.width};
  height: ${(props) => props.height};
  opacity: ${(props) => props.opacity || '1'};
`

export default { Block }
