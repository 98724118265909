import { NextPageContext } from 'next'
import { getCookieDomain, setCookie } from '@ucheba/utils/helpers/core'
import { advUserIdCookie } from '@ucheba/utils/constants/core'
import dayjs from '@ucheba/utils/helpers/date/dayjs'

export const setAdvUserIdToCookie = ({
  ctx,
  advUserId,
}: {
  ctx: NextPageContext | null
  advUserId: string
}): void => {
  const expiresCalc = dayjs().add(1, 'year').toDate()

  setCookie(
    advUserIdCookie,
    advUserId,
    {
      expires: expiresCalc,
      domain: getCookieDomain(),
      httpOnly: false,
    },
    ctx || undefined
  )
}
