import { IOgeSubjectsDetail, IOgeSubjectsDetailState, ISelectorBySlug } from './types'
import { ogeSubjectsDetailAdapter } from './slice'
import { sliceName } from './constants'
import { getStateById, getStateError } from '../../../utils/core'

const adaptorSelectors = ogeSubjectsDetailAdapter.getSelectors<IOgeSubjectsDetailState>(
  (state) => state[sliceName]
)

export default {
  ...adaptorSelectors,

  byId: getStateById<IOgeSubjectsDetailState, IOgeSubjectsDetail>(adaptorSelectors),

  bySlug:
    (slug: string): ISelectorBySlug =>
    (state): IOgeSubjectsDetail | undefined =>
      adaptorSelectors.selectAll(state).find((item) => item.slug === slug),

  error: getStateError<IOgeSubjectsDetailState>(sliceName),
}
