import { createSlice } from '@reduxjs/toolkit'
import { sliceName } from './constsnts'
import { extraReducersResponse, responseInitialState } from '../../../utils/response'
import {
  IProfMotivationTestResponse,
  IProfMotivationTestResults,
  IProfMotivationTestResultsState,
} from './types'
import thunks from './thunks'

export default createSlice({
  name: sliceName,

  initialState: {
    entity: null as IProfMotivationTestResults | null,
    responses: null as IProfMotivationTestResponse[] | null,
    ...responseInitialState,
  },

  reducers: {},

  extraReducers: (builder) => {
    extraReducersResponse<
      IProfMotivationTestResultsState[typeof sliceName],
      IProfMotivationTestResults
    >({
      builder,
      thunk: thunks.fetch,
      fulfilled: (state, action) => {
        // eslint-disable-next-line no-param-reassign
        state.entity = action.payload
      },
    })

    extraReducersResponse<
      IProfMotivationTestResultsState[typeof sliceName],
      IProfMotivationTestResponse[]
    >({
      builder,
      thunk: thunks.getResponses,
      fulfilled: (state, action) => {
        // eslint-disable-next-line no-param-reassign
        state.responses = action.payload
      },
    })
  },
})
