import React, { FC, memo } from 'react'
import { Portal } from 'react-portal'
import { useDivInBody } from '@ucheba/utils/hooks/useDivInBody'
import CloseIcon from '@ucheba/ui/icons/icon__close.svg'
import { EColorBgText, sv } from '@ucheba/utils/helpers/styles/variables'
import styled from 'styled-components'
import { INoticeProps } from '../types'
import { Box } from '../../Box/desktop'
import { notificationsRootDiv } from '../constants'
import SC from '../base/Notice.styles'
import { useNoticeCore } from '../bll'
import { Button } from '../../Button/desktop'
import { useBottomSheetHeight } from '../../Select/bll'
import { BottomSheet } from '../../BottomSheet/desktop'
import { ProgressBar } from '../../ProgressBar/desktop'
import '../base/styles.desktop.css'

const { Block, ButtonClose, BlockAnimated } = SC

const GrayBtn = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: ${sv.color.gray60};
  width: 24px;
  height: 24px;
  color: white;
  border-radius: 50%;
`

const Notice: FC<INoticeProps> = (props) => {
  const {
    id,
    preventAutoClose = true,
    showClose = true,
    bottomSheet,
    color = 'white',
    hideProgress,
    width = '100%',
    size,
    children,
    onClose,
    onOpen,
    overflow = 'hidden',
    open,
    duration = 5000,
    spacing,
  } = props

  const { isOpen, rendering, close } = useNoticeCore({
    preventAutoClose,
    duration,
    onOpen,
    onClose,
    open,
    id,
  })

  const bottomSheetNotice = useBottomSheetHeight({
    isOpen,
  })

  const notifications = useDivInBody(notificationsRootDiv)

  if (notifications) {
    notifications.style.left = '0'
    notifications.style.right = 'auto'
  }

  if (!rendering) return null

  return (
    <Portal node={notifications}>
      {bottomSheet ? (
        <BottomSheet
          open={isOpen}
          toggleOpen={close}
          ref={bottomSheetNotice.bottomSheetRef}
          height={bottomSheetNotice.bottomSheetHeight}
        >
          <Block
            width={width}
            size={size}
            resetBorderRadius
            as={Box as any}
            color={color}
            shadowSize='medium'
            spacing={spacing}
            overflow={overflow}
          >
            {!hideProgress && <ProgressBar duration={duration} />}

            {children}
          </Block>
        </BottomSheet>
      ) : (
        <BlockAnimated
          width={width}
          size={size}
          close={!isOpen}
          as={Box as any}
          color={color}
          shadowSize='medium'
          spacing={spacing}
          overflow={overflow}
        >
          {children}

          {!hideProgress && <ProgressBar duration={duration} />}

          {showClose && (
            <>
              {color === 'gray60' ? (
                <GrayBtn>
                  <Button
                    onClick={close}
                    shape='circle'
                    color={EColorBgText[color]}
                    icon
                    size='xsmall'
                  >
                    <CloseIcon width='16px' height='16px' />
                  </Button>
                </GrayBtn>
              ) : (
                <ButtonClose
                  as={Button}
                  onClick={close}
                  shape='circle'
                  color={EColorBgText[color]}
                  icon
                  size='xsmall'
                >
                  <CloseIcon width='16px' height='16px' />
                </ButtonClose>
              )}
            </>
          )}
        </BlockAnimated>
      )}
    </Portal>
  )
}

export default memo(Notice)
