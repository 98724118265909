import { createSlice } from '@reduxjs/toolkit'
import { sliceName } from './constants'
import { extraReducersResponse, responseInitialState } from '../utils/response'
import { IArticles, IArticlesState } from './types'
import thunks from './thunks'

export default createSlice({
  name: sliceName,

  initialState: {
    entity: {} as IArticles,
    ...responseInitialState,
  },

  reducers: {},

  extraReducers: (builder) => {
    extraReducersResponse<IArticlesState[typeof sliceName], IArticles>({
      builder,
      thunk: thunks.fetch,
      fulfilled: (state, action) => {
        // eslint-disable-next-line no-param-reassign
        state.entity = action.payload
      },
    })
  },
})
