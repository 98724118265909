import { IHeaderState } from './types'
import { sliceName } from './constants'

const color = (state: IHeaderState): IHeaderState[typeof sliceName]['color'] =>
  state[sliceName].color

const fade = (state: IHeaderState): IHeaderState[typeof sliceName]['fade'] =>
  state[sliceName].fade

const width = (state: IHeaderState): IHeaderState[typeof sliceName]['width'] =>
  state[sliceName].width

const changedId = (state: IHeaderState): IHeaderState[typeof sliceName]['changedId'] =>
  state[sliceName].changedId

const isShowNavigation = (
  state: IHeaderState
): IHeaderState[typeof sliceName]['isShowNavigation'] => state[sliceName].isShowNavigation

const isShowHeader = (
  state: IHeaderState
): IHeaderState[typeof sliceName]['isShowHeader'] => state[sliceName].isShowHeader

const isShowPhone = (
  state: IHeaderState
): IHeaderState[typeof sliceName]['isShowPhone'] => state[sliceName].isShowPhone

export default {
  color,
  changedId,
  isShowNavigation,
  fade,
  width,
  isShowHeader,
  isShowPhone,
}
