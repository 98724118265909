import { createSlice } from '@reduxjs/toolkit'
import { extraReducersResponse, responseInitialState } from '../utils/response'
import { sliceName } from './constants'
import thunks from './thunks'
import { IQuestionOrder, IQuestionsEntity, IQuestionsState } from './types'

export default createSlice({
  name: sliceName,

  initialState: {
    entity: null as IQuestionsEntity | null,
    order: null as IQuestionOrder | null,
    ...responseInitialState,
  },

  reducers: {},

  extraReducers: (builder) => {
    extraReducersResponse<IQuestionsState[typeof sliceName], IQuestionsEntity>({
      builder,
      thunk: thunks.fetch,
      fulfilled: (state, action) => {
        // eslint-disable-next-line no-param-reassign
        state.entity = action.payload
      },
    })

    extraReducersResponse<IQuestionsState[typeof sliceName], IQuestionOrder>({
      builder,
      thunk: thunks.fetchOrder,
      fulfilled: (state, action) => {
        // eslint-disable-next-line no-param-reassign
        state.order = action.payload
      },
    })
  },
})
