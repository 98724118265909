import { ITeachersState, ITeachers } from './types'
import { sliceName } from './constants'
import { getStateItem } from '../../utils/core'

export default {
  entity: getStateItem<ITeachers, ITeachers>(sliceName, 'entity'),

  loading: getStateItem<ITeachersState, ITeachersState[typeof sliceName]['loading']>(
    sliceName,
    'loading'
  ),
}
