/* eslint-disable no-param-reassign */
import { createSlice, createEntityAdapter } from '@reduxjs/toolkit'
import { IItemOfTags, ITagsStats, ITagsStatsState } from './types'
import { extraReducersResponse, responseInitialState } from '../../utils/response'
import { sliceName } from './constants'
import thunks from './thunks'
import { IResponseInitialState } from '../../utils/response/types'

export const tagsStatsAdapter = createEntityAdapter<IItemOfTags>()

export default createSlice({
  name: sliceName,

  initialState: {
    parents: tagsStatsAdapter.getInitialState<
      IResponseInitialState &
        Pick<ITagsStats, 'totalSpecialitiesCount' | 'totalInstitutionsCount'>
    >({
      totalSpecialitiesCount: 0,
      totalInstitutionsCount: 0,
      ...responseInitialState,
    }),

    children: tagsStatsAdapter.getInitialState<
      IResponseInitialState &
        Pick<ITagsStats, 'totalSpecialitiesCount' | 'totalInstitutionsCount'>
    >({
      totalSpecialitiesCount: 0,
      totalInstitutionsCount: 0,
      ...responseInitialState,
    }),
  },

  reducers: {},

  extraReducers: (builder) => {
    extraReducersResponse<ITagsStatsState[typeof sliceName]['parents'], ITagsStats>({
      builder,
      thunk: thunks.fetchParents,
      fulfilled: (state, { payload }) => {
        state.totalSpecialitiesCount = payload.totalSpecialitiesCount
        state.totalInstitutionsCount = payload.totalInstitutionsCount

        tagsStatsAdapter.setAll(state, payload.tags)
      },
      options: {
        statePath: 'parents',
      },
    })

    extraReducersResponse<ITagsStatsState[typeof sliceName]['children'], ITagsStats>({
      builder,
      thunk: thunks.fetchChildren,
      // eslint-disable-next-line sonarjs/no-identical-functions
      fulfilled: (state, { payload }) => {
        state.totalSpecialitiesCount = payload.totalSpecialitiesCount
        state.totalInstitutionsCount = payload.totalInstitutionsCount

        tagsStatsAdapter.setAll(state, payload.tags)
      },
      options: {
        statePath: 'children',
      },
    })
  },
})
