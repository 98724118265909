import apiThunk, { api } from '@ucheba/utils/helpers/api'
import { sliceName } from './constants'

const pay = apiThunk(`${sliceName}/fetch`, async (params) => {
  const { testId } = params.data

  const response = await api.post(
    `/client-products/prof-tests/${testId}/payment`,
    params.data,
    params
  )

  return response.data
})

export default { pay }
