/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import { extraReducersResponse, responseInitialState } from '../../utils/response'
import { sliceName } from './constants'
import thunks from './thunks'
import {
  IProfessionsSearchState,
  IProfessionsSearch,
  IProfessionsSearchByFriendlyURL,
} from './types'

export default createSlice({
  name: sliceName,

  initialState: {
    entity: null as IProfessionsSearch | null,
    entityByFriendlyUrl: null as IProfessionsSearchByFriendlyURL | null,
    ...responseInitialState,
  },

  reducers: {},

  extraReducers: (builder) => {
    extraReducersResponse<IProfessionsSearchState[typeof sliceName], IProfessionsSearch>({
      builder,
      thunk: thunks.fetch,
      fulfilled: (state, action) => {
        state.entity = action.payload
      },
    })

    extraReducersResponse<
      IProfessionsSearchState[typeof sliceName],
      IProfessionsSearchByFriendlyURL
    >({
      builder,
      thunk: thunks.fetchByFriendlyURL,
      fulfilled: (state, action) => {
        state.entityByFriendlyUrl = action.payload
      },
    })
  },
})
