import React, { FC } from 'react'
import { Heading } from '@ucheba/ui/components/Heading/touch'
import { Spacing } from '@ucheba/ui/components/Spacing/touch'
import { Button } from '@ucheba/ui/components/Button/touch'
import { Text } from '@ucheba/ui/components/Text/touch'
import { useAllowedMessangers } from '@ucheba/utils/hooks/useAllowedMessangers'
import { BottomSheet } from '@ucheba/ui/components/BottomSheet/touch'
import styled from 'styled-components'
import { Box } from '@ucheba/ui/components/Box/touch'
import { analyticEvent } from '@ucheba/utils/helpers/analytics/events'
import {
  EAnalyticEvents,
  EAnalyticEventTargets,
  EAnalyticSections,
} from '@ucheba/utils/helpers/analytics/events/types'
import VkIcon from '@ucheba/ui/icons/icon__vk_square.svg'
import TgIcon from '@ucheba/ui/icons/icon__telegram-filled.svg'
import { EMessengerType } from '@ucheba/store/profile/types'
import { useVkPermissionNotice } from '../bll'
import { linkToTgSubscribe, linkToVkSubscribe } from '../constants'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import ImgTop from '../assets/img__notice_touch.png'

const Image = styled.img`
  width: 100%;
`

const BoxSC = styled(Box)`
  border-radius: 8px 8px 0 0;
`

const VkPermissionNoticeTouch: FC = () => {
  const allowedMessangers = useAllowedMessangers([
    EMessengerType.vk,
    EMessengerType.telegram,
  ])

  const { notice, title, description, buttons, removeStorage } = useVkPermissionNotice({
    allowedMessangers,
  })

  return (
    <BottomSheet
      open={notice.open}
      toggleOpen={() => {
        removeStorage()
        notice.removeNotice()
      }}
    >
      <BoxSC color='navy'>
        <Image src={ImgTop} />

        <Spacing spacing='small' />

        <Heading h3={title} />

        <Spacing spacing='xxsmall' />

        <Text>{description}</Text>

        <Spacing spacing='small' />

        {buttons.map((button) => {
          return (
            <React.Fragment key={button.id}>
              <Button
                color='gold'
                target='_blank'
                href={button.id === 'vk' ? linkToVkSubscribe : linkToTgSubscribe}
                EndIcon={
                  button.id === 'vk' ? <VkIcon width='20px' /> : <TgIcon width='20px' />
                }
                block
                justifyContent='space-between'
                onClick={() => {
                  notice.removeNotice()
                  // отправляем аналитику клика-перехода в яндекс-метрику
                  analyticEvent({
                    targets: [EAnalyticEventTargets.ym, EAnalyticEventTargets.ur],
                    eventName: EAnalyticEvents.vkNoticeClickLead,
                    section: EAnalyticSections.vkNotice,
                  })
                }}
              >
                {button.text}
              </Button>
              <Spacing spacing='small' />
            </React.Fragment>
          )
        })}
      </BoxSC>
    </BottomSheet>
  )
}

export default VkPermissionNoticeTouch
