import apiThunk, { api } from '@ucheba/utils/helpers/api'
import { sliceName } from './constants'
import { setAuthCookie } from './bll'

const fetch = apiThunk(`${sliceName}/fetch`, async (params) => {
  const data = { ...params?.data, rememberMe: true }

  const response = await api.post(`/auth`, data, params)

  setAuthCookie({ ctx: params?.ctx, token: response.data })

  return response.data
})

const authByKey = apiThunk(`${sliceName}/authByKey`, async (params) => {
  const response = await api.post(`/auth/confirmationKey`, params?.data, params)

  setAuthCookie({ ctx: params?.ctx, token: response.data.token })

  return response.data
})

const isValidToken = apiThunk(`${sliceName}/isValidToken`, async (params) => {
  const response = await api.get(`/profile/short`, params)

  return response.data
})

export default { fetch, isValidToken, authByKey }
