import React, {
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { arePropsEqual } from '@ucheba/utils/helpers/components'
import { useCombinedRefs } from '@ucheba/utils/hooks/useCombinedRefs'
import { Text } from '../../Text/desktop'
import SC from '../base/TextField.styles'
import { ITextFieldProps } from '../types'
import { Spacing } from '../../Spacing/desktop'

const { Block, Inner, Legend, Fieldset, Label, Input, TextArea } = SC

/** Текстовое поле */

const TextField = forwardRef<HTMLInputElement, ITextFieldProps>(
  function TextField(props, ref) {
    const {
      textarea,
      height,
      maxLength,
      focused = false,
      active = false,
      label,
      width = '100%',
      onBlur,
      onFocus,
      onChangeWithoutValid,
      value,
      name,
      pattern,
      onChange,
      min,
      max,
      error,
      autofocus,
      autocomplete,
      disabledWithStyles,
      disabled,
      color = 'blue',
      errorColor,
      ...otherProps
    } = props
    // Что-то как-то это все костыльно выглядит

    const textareaRef = useRef<HTMLTextAreaElement>(null)
    const inputRef = useCombinedRefs(ref, useRef<HTMLInputElement>(null))
    const [textFieldValue, setTextFieldValue] = useState(value)
    const [isFocused, setFocused] = useState(focused)
    const [isActive, setActive] = useState(active)
    const isShowLabel =
      (label && isActive && textFieldValue) || (label && !textFieldValue)

    const onTextFieldBlur = useCallback(
      (event) => {
        setFocused(false)

        if (onBlur) onBlur(event)
      },
      [onBlur, setFocused]
    )

    const onTextFieldFocus = useCallback(
      (event) => {
        setFocused(true)

        if (onFocus) onFocus(event)
      },
      [onFocus, setFocused]
    )

    const checkIsPatternValid = useCallback(
      (str) => {
        const re = new RegExp(`^${pattern}$`, 'g')

        return !pattern || re.test(`${str}`)
      },
      [pattern]
    )

    const checkIsMinValid = useCallback(
      (str) => {
        return !min || str === '' || Number(str) >= min
      },
      [min]
    )

    const checkIsMaxValid = useCallback(
      (str) => {
        return !max || str === '' || Number(str) <= max
      },
      [max]
    )

    const validateValue = useCallback(
      (str) => {
        return (
          str === '' ||
          (checkIsPatternValid(str) && checkIsMinValid(str) && checkIsMaxValid(str))
        )
      },
      [checkIsMaxValid, checkIsMinValid, checkIsPatternValid]
    )

    const handleChange = useCallback(
      (event) => {
        if (inputRef?.current) {
          const currentValue = inputRef?.current.value

          if (onChangeWithoutValid) onChangeWithoutValid(event, currentValue)

          if (currentValue === '') {
            setTextFieldValue('')
          }

          if (validateValue(currentValue)) {
            setTextFieldValue(currentValue)

            if (onChange) onChange(event, currentValue)
          }
        }

        if (textareaRef?.current) {
          const currentValue = textareaRef?.current.value

          if (onChangeWithoutValid) onChangeWithoutValid(event, currentValue)

          if (currentValue === '') {
            setTextFieldValue('')
          }

          if (validateValue(currentValue)) {
            setTextFieldValue(currentValue)

            if (onChange) onChange(event, currentValue)
          }
        }
      },
      [textareaRef, inputRef, onChange, onChangeWithoutValid, validateValue]
    )

    useMemo(() => {
      if (validateValue(value)) {
        setTextFieldValue(value)
      }
    }, [validateValue, value])

    useMemo(() => {
      setActive(active || isFocused || !!textFieldValue)
    }, [textFieldValue, isFocused, setActive, active])

    useEffect(() => {
      if (autofocus && inputRef) {
        inputRef.current?.focus()
      }

      if (autofocus && textareaRef) {
        textareaRef.current?.focus()
      }
    }, [autofocus, inputRef])

    return (
      <Block
        focused={isFocused}
        width={width}
        height={textarea ? 'auto' : undefined}
        error={!!errorColor}
        color={color}
        active={isActive}
      >
        {isShowLabel && (
          <Label active={isActive} error={!!errorColor} color={color}>
            {label}
          </Label>
        )}

        <Inner>
          {textarea ? (
            <TextArea
              color={color}
              height={height}
              maxLength={maxLength}
              ref={textareaRef}
              disabled={disabled || disabledWithStyles}
              onBlur={onTextFieldBlur}
              onFocus={onTextFieldFocus}
              onChange={handleChange}
              name={name}
              value={textFieldValue}
              {...otherProps}
            />
          ) : (
            <Input
              color={color}
              maxLength={maxLength}
              ref={inputRef}
              value={textFieldValue}
              onBlur={onTextFieldBlur}
              onFocus={onTextFieldFocus}
              onInput={handleChange}
              name={name}
              pattern={pattern}
              disabled={disabled || disabledWithStyles}
              autoComplete={autocomplete}
              {...otherProps}
            />
          )}

          <Fieldset error={errorColor} color={color}>
            <Legend active={!!label && isActive}>{label}</Legend>
          </Fieldset>
        </Inner>

        {error && (
          <>
            <Spacing spacing='uxsmall' />
            <Text textAlign='left' color='red' fontSize='small1'>
              {error}
            </Text>
          </>
        )}
      </Block>
    )
  }
)

export { TextField }
export default memo(TextField, arePropsEqual)
