/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import { ISpecialitiesIdState, ISpeciality, ISpecialityMin } from './types'
import { extraReducersResponse, responseInitialState } from '../../utils/response'
import { sliceName } from './constants'
import thunks from './thunks'

export default createSlice({
  name: sliceName,

  initialState: {
    entity: null as ISpeciality | null,
    min: null as ISpecialityMin | null,
    minItems: null as ISpecialityMin[] | null,
    ...responseInitialState,
  },

  reducers: {},

  extraReducers: (builder) => {
    extraReducersResponse<ISpecialitiesIdState[typeof sliceName], ISpeciality>({
      builder,
      thunk: thunks.fetch,
      fulfilled: (state, action) => {
        state.entity = action.payload
      },
    })

    extraReducersResponse<ISpecialitiesIdState[typeof sliceName], ISpeciality>({
      builder,
      thunk: thunks.fetchMin,
      fulfilled: (state, action) => {
        state.min = action.payload
      },
    })

    extraReducersResponse<ISpecialitiesIdState[typeof sliceName], ISpeciality[]>({
      builder,
      thunk: thunks.fetchMinItems,
      fulfilled: (state, action) => {
        state.minItems = action.payload
      },
    })
  },
})
