import { ISerializedError } from '@ucheba/utils/helpers/core/types'
/* eslint-disable no-param-reassign */
import { ELoading, IResponseExtraReducers } from './types'

export const responseInitialState = {
  loading: ELoading.idle,
  currentRequestId: null,
  error: null as ISerializedError | null,
}

export const extraReducersResponse: IResponseExtraReducers = ({
  builder,
  thunk,
  pending,
  rejected,
  fulfilled,
  options = {
    withoutCondition: false,
    statePath: '',
  },
}) => {
  const getState = <T = any>(state): T =>
    options.statePath ? state[options.statePath] : state

  builder.addCase(thunk[ELoading.pending], (stateOrigin, action) => {
    const state = getState<typeof stateOrigin>(stateOrigin)

    state.loading = ELoading.pending
    state.currentRequestId = action.meta.requestId
    state.error = null

    if (pending) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      pending(state, action)
    }
  })

  builder.addCase(thunk[ELoading.rejected], (stateOrigin, action) => {
    const state = getState<typeof stateOrigin>(stateOrigin)

    state.loading = ELoading.rejected
    state.error = action.payload as ISerializedError

    if (rejected) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      rejected(state, action)
    }
  })

  builder.addCase(thunk[ELoading.fulfilled], (stateOrigin, action) => {
    const state = getState<typeof stateOrigin>(stateOrigin)
    state.loading = ELoading.fulfilled
    state.error = null
    state.currentRequestId = null

    if (fulfilled) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      fulfilled(state, action)
    }
  })
}
