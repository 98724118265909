import apiThunk, { api } from '@ucheba/utils/helpers/api'
import { analyticEvent } from '@ucheba/utils/helpers/analytics/events'
import {
  EAnalyticEvents,
  EAnalyticEventTargets,
  EAnalyticSections,
} from '@ucheba/utils/helpers/analytics/events/types'
import { sliceName } from './constants'

const fetch = apiThunk(`${sliceName}/fetch`, async (params) => {
  const response = await api.get(`/events/${params?.data.id}`, params)

  return response.data
})

const register = apiThunk(`${sliceName}/register`, async (params) => {
  const response = await api.post(
    `/events/${params?.data.id}/register`,
    params.data,
    params
  )

  analyticEvent({
    targets: [
      EAnalyticEventTargets.ym,
      EAnalyticEventTargets.vk,
      EAnalyticEventTargets.ga,
      EAnalyticEventTargets.ur,
    ],
    eventName: EAnalyticEvents.eventRegistrationSuccess,
    value: {
      eventID: params?.data.id,
    },
    section: EAnalyticSections.eventRegistration,
  })

  return response.data
})

export default { register, fetch }
