import apiThunk, { api } from '@ucheba/utils/helpers/api'
import { sliceName } from './constants'

/** Помечаем шаг завершенным */
const fetch = apiThunk(`${sliceName}/setStepComplete`, async (params) => {
  const { userId, ...otherData } = params?.data || {}

  const response = await api.post(
    `/forms/${params.data.type || 'profile'}/${userId}/step/complete`,
    otherData,
    params
  )

  return response.data
})

export default { fetch }
