import { Component, createElement } from 'react'
import { NextPageContext } from 'next'
import * as analytics from '../helpers/analytics/gtm'
import { isDev, isLocal } from '../helpers/core'

/** Подключает к приложения/странице счетчик Google analytics. */
const withGTM =
  (code) =>
  (Page: any): any => {
    class WithGTM extends Component {
      // eslint-disable-next-line @typescript-eslint/ban-types
      static getInitialProps: (context: NextPageContext) => {} | Promise<{}>

      componentDidMount(): void {
        const shouldntTrack = isLocal || isDev

        if (shouldntTrack) return

        analytics.init(code)
      }

      render(): JSX.Element {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return createElement(Page, this.props)
      }
    }

    if (Page.getInitialProps) {
      WithGTM.getInitialProps = Page.getInitialProps
    }

    return WithGTM
  }

export default withGTM
