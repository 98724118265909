import { sliceName } from './constants'
import { getStateError, getStateItem } from '../../utils/core'
import { IClientProductsTariffsState } from './types'

export default {
  entity: getStateItem<
    IClientProductsTariffsState,
    IClientProductsTariffsState[typeof sliceName]['entity']
  >(sliceName, 'entity'),

  loading: getStateItem<
    IClientProductsTariffsState,
    IClientProductsTariffsState[typeof sliceName]['loading']
  >(sliceName, 'loading'),

  error: getStateError<IClientProductsTariffsState>(sliceName),
}
