/**
 * Генератор кода для методов АПИ, для защиты от запросов не с клиента, по сути просто XOR
 * Обфусцирован с помощью https://obfuscator.io/
 * function (input) {
 *   let key = '984292629'
 *   let result = ''
 *   while (key.length < input.length) {
 *     key += key
 *   }
 *   for (let i=0; i<input.length; i++) {
 *     const value1 = input[i].charCodeAt(0)
 *     const value2 = key[i].charCodeAt(0)
 *     const xorValue = value1 ^ value2
 *     let xorValueAsHexString = xorValue.toString('16')
 *     xorValueAsHexString = xorValueAsHexString.padStart(2, '0')
 *     result += xorValueAsHexString
 *   }
 *   return result
 * }
 */
//
/* eslint-disable */
;(function (_0x2de516, _0x185dee) {
  const _0x32c3b5 = _0x472a,
    _0x578380 = _0x2de516()
  while (!![]) {
    try {
      const _0x2dce6a =
        (-parseInt(_0x32c3b5(0x8c)) / 0x1) * (parseInt(_0x32c3b5(0x87)) / 0x2) +
        (-parseInt(_0x32c3b5(0x88)) / 0x3) * (-parseInt(_0x32c3b5(0x7f)) / 0x4) +
        (-parseInt(_0x32c3b5(0x8b)) / 0x5) * (parseInt(_0x32c3b5(0x83)) / 0x6) +
        (parseInt(_0x32c3b5(0x86)) / 0x7) * (-parseInt(_0x32c3b5(0x85)) / 0x8) +
        (parseInt(_0x32c3b5(0x7c)) / 0x9) * (parseInt(_0x32c3b5(0x82)) / 0xa) +
        (-parseInt(_0x32c3b5(0x89)) / 0xb) * (-parseInt(_0x32c3b5(0x81)) / 0xc) +
        (-parseInt(_0x32c3b5(0x8a)) / 0xd) * (-parseInt(_0x32c3b5(0x84)) / 0xe)
      if (_0x2dce6a === _0x185dee) break
      else _0x578380['push'](_0x578380['shift']())
    } catch (_0x4763e9) {
      _0x578380['push'](_0x578380['shift']())
    }
  }
})(_0x12c5, 0x3e9c6)
function _0x12c5() {
  const _0x299708 = [
    '578900ZAjLow',
    '112erHiqF',
    '225155efiIlJ',
    '638566kjAGJw',
    '2247UdpVPb',
    '11DCOFyF',
    '156pfDWbN',
    '70psAgWi',
    '1tdBBwX',
    '9sgCzws',
    'charCodeAt',
    'length',
    '1364nMqTPv',
    '984292629',
    '1939308kReZhK',
    '3009610VUTEdE',
    '80628rRuoMm',
  ]
  _0x12c5 = function () {
    return _0x299708
  }
  return _0x12c5()
}
function _0x472a(_0x430781, _0x4d8059) {
  const _0x12c5da = _0x12c5()
  return (
    (_0x472a = function (_0x472ae2, _0x4b5d91) {
      _0x472ae2 = _0x472ae2 - 0x7c
      let _0x198311 = _0x12c5da[_0x472ae2]
      return _0x198311
    }),
    _0x472a(_0x430781, _0x4d8059)
  )
}
function keyGenerator(_0x2ea4fe) {
  const _0x2e62e9 = _0x472a
  let _0x2ee9cf = _0x2e62e9(0x80),
    _0x36df93 = ''
  while (_0x2ee9cf['length'] < _0x2ea4fe['length']) {
    _0x2ee9cf += _0x2ee9cf
  }
  for (let _0x503a87 = 0x0; _0x503a87 < _0x2ea4fe[_0x2e62e9(0x7e)]; _0x503a87++) {
    const _0x51a07d = _0x2ea4fe[_0x503a87][_0x2e62e9(0x7d)](0x0),
      _0x5e5555 = _0x2ee9cf[_0x503a87][_0x2e62e9(0x7d)](0x0),
      _0x589280 = _0x51a07d ^ _0x5e5555
    let _0x425cf2 = _0x589280['toString']('16')
    ;(_0x425cf2 = _0x425cf2['padStart'](0x2, '0')), (_0x36df93 += _0x425cf2)
  }
  return _0x36df93
}
/* eslint-enable */

export default keyGenerator
