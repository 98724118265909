import { getStateError, getStateItem } from '../utils/core'
import { sliceName } from './constants'
import { IProbabilitiesState } from './types'

export default {
  loading: getStateItem<
    IProbabilitiesState,
    IProbabilitiesState[typeof sliceName]['loading']
  >(sliceName, 'loading'),

  currentProbability: getStateItem<
    IProbabilitiesState,
    IProbabilitiesState[typeof sliceName]['currentProbability']
  >(sliceName, 'currentProbability'),

  countPrograms: getStateItem<
    IProbabilitiesState,
    IProbabilitiesState[typeof sliceName]['countPrograms']
  >(sliceName, 'countPrograms'),

  institution: getStateItem<
    IProbabilitiesState,
    IProbabilitiesState[typeof sliceName]['institution']
  >(sliceName, 'institution'),

  programs: getStateItem<
    IProbabilitiesState,
    IProbabilitiesState[typeof sliceName]['programs']
  >(sliceName, 'programs'),

  anotherPrograms: getStateItem<
    IProbabilitiesState,
    IProbabilitiesState[typeof sliceName]['anotherPrograms']
  >(sliceName, 'anotherPrograms'),

  error: getStateError<IProbabilitiesState>(sliceName),
}
