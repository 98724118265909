import { ParseOptions } from 'query-string'

export const validHostsForRedirect = [
  'ucheba.ru',
  'ucheba.space',
  'dev.ucheba.space',
  'dev2.ucheba.space',
  'vk.com',
  'vk.me',
  'timacad.ru',
]

export const queryStringBaseOptions = {
  arrayFormat: 'bracket' as ParseOptions['arrayFormat'],
  parseNumbers: false,
  parseBooleans: false,
  parseFragmentIdentifier: true,
  skipEmptyString: true,
  skipNull: true,
}
