import { IChancesOfAdmissionState } from './types'
import { sliceName } from './constants'
import { getStateItem } from '../../utils/core'

export default {
  loading: getStateItem<
    IChancesOfAdmissionState,
    IChancesOfAdmissionState[typeof sliceName]['loading']
  >(sliceName, 'loading'),

  chancesOfAdmission: getStateItem<
    IChancesOfAdmissionState,
    IChancesOfAdmissionState[typeof sliceName]['chancesOfAdmission']
  >(sliceName, 'chancesOfAdmission'),
}
