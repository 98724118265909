import { createSlice } from '@reduxjs/toolkit'
import { sliceName } from './constants'
import { extraReducersResponse, responseInitialState } from '../../utils/response'
import thunks from './thunks'
import { IEntityIds, IPlannerSpecialties, IPlannerSpecialtiesState } from './types'

export default createSlice({
  name: sliceName,

  initialState: {
    entitySuitableSpecialities: null as IPlannerSpecialties | null,
    entitySpecialities: null as IPlannerSpecialties['items'] | null,
    entitySpecialitiesIds: {
      entityIds: [],
    } as IEntityIds,
    offset: 0,
    excludeIds: [] as number[],
    ...responseInitialState,
  },

  reducers: {
    reset: (state) => {
      // eslint-disable-next-line no-param-reassign
      state.entitySpecialities = [] as IPlannerSpecialties['items']

      // eslint-disable-next-line no-param-reassign
      state.entitySuitableSpecialities = null

      // eslint-disable-next-line no-param-reassign
      state.entitySpecialitiesIds = { entityIds: [] }

      // eslint-disable-next-line no-param-reassign
      state.offset = 0

      // eslint-disable-next-line no-param-reassign
      state.excludeIds = []
    },
  },

  extraReducers: (builder) => {
    extraReducersResponse<
      IPlannerSpecialtiesState[typeof sliceName],
      IPlannerSpecialties
    >({
      builder,
      thunk: thunks.getSpecialitiesSuitable,
      fulfilled: (state, action) => {
        const specialites = state?.entitySuitableSpecialities?.items
          ? [...state.entitySuitableSpecialities.items, ...action.payload.items]
          : action.payload.items

        // eslint-disable-next-line no-param-reassign
        state.offset += action.payload.items.length

        // eslint-disable-next-line no-param-reassign
        state.entitySuitableSpecialities = {
          ...action.payload,
          items: specialites,
        }
      },
    })

    extraReducersResponse<
      IPlannerSpecialtiesState[typeof sliceName],
      IPlannerSpecialties['items']
    >({
      builder,
      thunk: thunks.getSpecialities,
      fulfilled: (state, action) => {
        // eslint-disable-next-line no-param-reassign
        state.entitySpecialities = action.payload

        // eslint-disable-next-line no-param-reassign
        state.entitySpecialitiesIds.entityIds = action.payload.map((item) =>
          Number(item.id)
        )
      },
    })

    extraReducersResponse<IPlannerSpecialtiesState[typeof sliceName], IEntityIds>({
      builder,
      thunk: thunks.postSpecialities,
      fulfilled: (state, action) => {
        // eslint-disable-next-line no-param-reassign
        state.entitySpecialitiesIds = {
          entityIds: [
            ...state.entitySpecialitiesIds.entityIds,
            ...action.payload.entityIds,
          ],
        }

        if (
          state.entitySpecialities?.some(
            (item) => item.id === action.payload.entityIds[0]
          )
        ) {
          // eslint-disable-next-line no-param-reassign
          state.excludeIds = state.excludeIds.filter(
            (id) => id !== action.payload.entityIds[0]
          )
        } else {
          // eslint-disable-next-line no-param-reassign
          state.offset -= 1
        }
      },
    })

    extraReducersResponse<IPlannerSpecialtiesState[typeof sliceName], IEntityIds>({
      builder,
      thunk: thunks.deleteSpecialities,
      fulfilled: (state, action) => {
        // eslint-disable-next-line no-param-reassign
        state.entitySpecialitiesIds.entityIds =
          state.entitySpecialitiesIds.entityIds.filter(
            (id) => Number(id) !== Number(action.payload.entityIds[0])
          )

        if (
          state?.entitySpecialities?.some(
            (item) => item?.id === action?.payload?.entityIds[0]
          )
        ) {
          // eslint-disable-next-line no-param-reassign
          state.excludeIds = [...state.excludeIds, ...action.payload.entityIds]
        } else {
          // eslint-disable-next-line no-param-reassign
          state.offset += 1
        }
      },
    })

    extraReducersResponse<IPlannerSpecialtiesState[typeof sliceName], number[]>({
      builder,
      thunk: thunks.getSpecialitiesIds,
      fulfilled: (state, action) => {
        // eslint-disable-next-line no-param-reassign
        state.entitySpecialitiesIds.entityIds = action.payload
      },
    })
  },
})
