import { ILogoProps } from '@ucheba/ui/components/Logo/types'
import { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { authSelectors } from '@ucheba/store/auth'
import { useAuthHref } from '@ucheba/utils/hooks/useAuthHref'
import { profileShortSelectors, profileShortThunks } from '@ucheba/store/profile/short'
import { profileSelectors } from '@ucheba/store/profile'
import { forClients } from '../../routs'
import { IModHeaderColor, IUseAuthContent } from './types'

export const getLogoColor = (color: IModHeaderColor['color']): ILogoProps['color'] => {
  return color === 'black' ? 'blue' : color
}

export const useAuthContent: IUseAuthContent = (href) => {
  const dispatch = useDispatch()
  const isAuth = useSelector(authSelectors.isAuth)
  const profileShort = useSelector(profileShortSelectors.entity)
  const profile = useSelector(profileSelectors.entity)
  const authHref = useAuthHref()

  const authLinkHref = useMemo(() => {
    if (href === forClients.path) return '/my/uz'

    return isAuth ? '/my' : authHref
  }, [authHref, href, isAuth])

  const userName = (profileShort || profile)?.name?.split(' ')[0] || ''

  useMemo(() => {
    if (!isAuth) return

    dispatch(profileShortThunks.fetch({}))
  }, [dispatch, isAuth])

  return {
    isAuth,
    authLinkHref,
    userName,
  }
}
