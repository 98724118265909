/* Общие названия ключей для отправки в апи, отображения в квери параметрах и использовании в формах */
export enum EDataKeys {
  name = 'name',
  phone = 'phone',
  email = 'email',
  classForm = 'classForm',
  code = 'code',
  specialOfferCode = 'specialOfferCode',
  isParent = 'isParent',
  parentName = 'secondName',
  parentPhone = 'secondPhone',
  parentEmail = 'secondEmail',
  query = 'query',
  offset = 'offset',
  limit = 'limit',
  onlyPaid = 'onlyPaid',
  isMain = 'isMain',
  programTypes = 'programTypes',
  locationIds = 'locationIds',
  trainingFormTypes = 'trainingFormTypes',
  topProgramTypes = 'topProgramTypes',
  tagIds = 'tagIds',
  examSubjectIds = 'examSubjectIds',
  priceFrom = 'priceFrom',
  priceTo = 'priceTo',
  academicHoursFrom = 'academicHoursFrom',
  academicHoursTo = 'academicHoursTo',
  sectionType = 'sectionType',
  orderBy = 'orderBy',
  orderDirection = 'orderDirection',
}
