import apiThunk, { api } from '@ucheba/utils/helpers/api'
import { sliceName } from '../constants'

const fetch = apiThunk(`${sliceName}/fetch`, async (params) => {
  const { id, subjectId } = params.data

  const response = await api.get(`/olympiads/${id}`, {
    params: {
      subjectId,
    },
  })

  return response.data
})

export default { fetch }
