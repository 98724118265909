// import MobileDetect from 'mobile-detect'

/** Определяет по юзер-агенту, мобила сейчас или десктоп */
export const isTouch = async (ctx: any): Promise<boolean> => {
  const MobileDetect = (await import('mobile-detect')).default

  const headers = ctx && ctx.req && ctx.req.headers

  const detected = headers
    ? new MobileDetect(headers['user-agent'] as string)
    : new MobileDetect(navigator.userAgent)

  return Boolean(detected.mobile())
}
