import React, { FC } from 'react'
import { Formik } from 'formik'
import { Spacing } from '@ucheba/ui/components/Spacing/touch'
import { Heading } from '@ucheba/ui/components/Heading/touch'
import { Description } from '@ucheba/ui/components/Description/touch'
import { Box } from '@ucheba/ui/components/Box/touch'
import { BottomSheet } from '@ucheba/ui/components/BottomSheet/touch'
import Loader from '@ucheba/ui/components/Loader/desktop/Loader'
import { useChooseLocationBottomSheetCore, useChooseLocationCore } from '../bll'
import { IChooseLocationProps } from '../types'
import ChooseLocationForm from './ChooseLocationForm'
import data from '../data.json'

const ChooseLocation: FC<IChooseLocationProps> = () => {
  const {
    chooseLocationBottomSheet,
    suggestingLocations,
    isOpenChooseLocation,
    toggleOpenChooseLocation,
  } = useChooseLocationBottomSheetCore()

  const { onSubmitForm, initialValues, locations } = useChooseLocationCore({
    closeDialog: () => toggleOpenChooseLocation(false),
    open: isOpenChooseLocation,
  })

  return (
    <BottomSheet
      open={isOpenChooseLocation}
      toggleOpen={toggleOpenChooseLocation}
      ref={chooseLocationBottomSheet.bottomSheetRef}
      height={chooseLocationBottomSheet.bottomSheetHeight}
    >
      <Box spacing='small'>
        <Heading h3={data.title} spacing='xxsmall' />

        <Description description={data.description} />

        <Spacing spacing='large' />
        {locations && locations.length > 0 ? (
          <Formik
            enableReinitialize
            initialValues={initialValues}
            onSubmit={onSubmitForm}
          >
            <ChooseLocationForm locations={locations} suggester={suggestingLocations} />
          </Formik>
        ) : (
          <Loader />
        )}
      </Box>
    </BottomSheet>
  )
}

export default ChooseLocation
