/* eslint-disable no-param-reassign */
import { createEntityAdapter, createSlice, EntityState } from '@reduxjs/toolkit'
import { extraReducersResponse, responseInitialState } from '../../utils/response'
import { sliceName } from './constants'
import thunks from './thunks'
import { IItemOfProfileFavorites } from './types'
import { IResponseInitialState } from '../../utils/response/types'

export const profileFavoritesAdaptor = createEntityAdapter<IItemOfProfileFavorites>({
  selectId: (book) => book.objectId,
})

export default createSlice({
  name: sliceName,

  initialState:
    profileFavoritesAdaptor.getInitialState<IResponseInitialState>(responseInitialState),

  reducers: {},

  extraReducers: (builder) => {
    extraReducersResponse<
      EntityState<IItemOfProfileFavorites>,
      IItemOfProfileFavorites[]
    >({
      builder,
      thunk: thunks.fetch,
      fulfilled: (state, action) => {
        profileFavoritesAdaptor.setAll(state, action.payload)
      },
    })

    extraReducersResponse<
      EntityState<IItemOfProfileFavorites>,
      IItemOfProfileFavorites[]
    >({
      builder,
      thunk: thunks.add,
      fulfilled: () => null,
    })

    extraReducersResponse<
      EntityState<IItemOfProfileFavorites>,
      IItemOfProfileFavorites[]
    >({
      builder,
      thunk: thunks.remove,
      fulfilled: () => null,
    })
  },
})
