import { IEgeSchoolState, TExternalData } from './types'
import { sliceName } from './constants'
import { getStateError, getStateItem } from '../../utils/core'

export default {
  redirectTo: (state: IEgeSchoolState): TExternalData['redirectTo'] => {
    const { externalData } = state[sliceName]

    return externalData ? externalData.redirectTo : ''
  },

  loading: getStateItem<IEgeSchoolState, IEgeSchoolState[typeof sliceName]['loading']>(
    sliceName,
    'loading'
  ),

  error: getStateError<IEgeSchoolState>(sliceName),
}
