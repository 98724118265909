import { useEffect, useMemo, useRef } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { useStore } from 'react-redux'
import defineAdvUserId from '../middlewares/defineAdvUserId'
import { SCHOOL_SITE_NAME, UCHEBA_SITE_NAME } from '../constants/core'

const VISIT_TIMEOUT = 30 * 60
const FIRST_VISIT_TIMEOUT = 180 * 24 * 60 * 60

type TObserver = Array<() => void>

const getJsonFromLocalStorage = (key: string): any => {
  try {
    const value = localStorage.getItem(key)
    if (!value) {
      return null
    }
    return JSON.parse(value)
  } catch (e) {
    return null
  }
}

const setJsonToLocalStorage = (key: string, value: any): void => {
  try {
    localStorage.setItem(key, JSON.stringify(value))
  } catch (e) {
    console.error(e)
  }
}

const createNewVisit = (advUserId: string, platform: string, domain: string): any => {
  const now = Math.floor(Date.now() / 1000)
  const url = window.location.href
  const queryParams = new URLSearchParams(window.location.search)
  const newVisit = {
    visitId: uuidv4(),
    userId: advUserId,
    visitAt: now,
    lastActivityAt: now,
    utmSource: queryParams.get('utm_source'),
    utmMedium: queryParams.get('utm_medium'),
    utmCampaign: queryParams.get('utm_campaign'),
    utmTerm: queryParams.get('utm_term'),
    utmContent: queryParams.get('utm_content'),
    from: queryParams.get('from'),
    referer: document.referrer,
    isImportantSource: false,
    url,
    platform,
  }
  newVisit.isImportantSource = !!(
    (newVisit.referer && !newVisit.referer.includes(domain)) ||
    newVisit.utmSource ||
    newVisit.utmMedium ||
    newVisit.utmCampaign ||
    newVisit.utmTerm ||
    newVisit.utmContent ||
    newVisit.from
  )
  return newVisit
}

const track = (
  siteName: string,
  advUserId: string,
  platform: string,
  domain: string
): void => {
  const currentVisitKey = `${siteName}_current_visit`
  const firstVisitKey = `${siteName}_first_visit`
  const lastImportantVisitKey = `${siteName}_last_important_visit`
  const now = Math.floor(Date.now() / 1000)
  const newVisit = createNewVisit(advUserId, platform, domain)
  const currentVisit = getJsonFromLocalStorage(currentVisitKey)
  const firstVisit = getJsonFromLocalStorage(firstVisitKey)
  // Продолжается текущий визит?
  if (
    currentVisit?.lastActivityAt &&
    currentVisit.lastActivityAt + VISIT_TIMEOUT > now &&
    // Если приходят с метками, то сразу новый визит
    !(
      newVisit.utmSource ||
      newVisit.utmMedium ||
      newVisit.utmCampaign ||
      newVisit.utmTerm ||
      newVisit.utmContent
    )
  ) {
    currentVisit.lastActivityAt = now
    setJsonToLocalStorage(currentVisitKey, currentVisit)
    return
  }

  // Повторный визит пользователя
  if (currentVisit?.visitId && currentVisit.visitAt + FIRST_VISIT_TIMEOUT > now) {
    // надо ли сохранить первый визит?
    if (!firstVisit?.visitId || firstVisit.visitAt + FIRST_VISIT_TIMEOUT < now) {
      setJsonToLocalStorage(firstVisitKey, currentVisit)
    }
    // надо ли сохранить последний значимый визит?
    if (!newVisit.isImportantSource && currentVisit.isImportantSource) {
      setJsonToLocalStorage(lastImportantVisitKey, currentVisit)
    } else {
      setJsonToLocalStorage(lastImportantVisitKey, newVisit)
    }
  }
  setJsonToLocalStorage(currentVisitKey, newVisit)
}

const useVisitsTracker = (
  siteName: string,
  domain: string,
  platform: string,
  observer?: TObserver,
  isBlocked?: boolean
): void => {
  const store = useStore()
  const isInitRef = useRef(false)
  const advUserId = useMemo(() => defineAdvUserId(null, store), [store])

  useEffect(() => {
    if (!isInitRef.current && !isBlocked) {
      track(siteName, advUserId, platform, domain)
      isInitRef.current = true
    }

    if (observer?.length === 0 && !isBlocked) {
      observer.push(() => {
        track(siteName, advUserId, platform, domain)
      })
    }
  }, [siteName, platform, domain, advUserId, observer, isBlocked])
}

export default useVisitsTracker

export const getVisitInfo = (siteName: string): any => {
  const getFromLocalStorage = (key: string): any => {
    try {
      const value = localStorage.getItem(key)
      if (!value) {
        return null
      }
      return JSON.parse(value)
    } catch (e) {
      return null
    }
  }
  const lastSource = getFromLocalStorage(`${siteName}_current_visit`)
  const firstSource = getFromLocalStorage(`${siteName}_first_visit`)
  const lastImportantSource = getFromLocalStorage(`${siteName}_last_important_visit`)
  const currentUrl = window.location.href
  return {
    currentUrl,
    lastSource,
    firstSource: firstSource || lastSource,
    lastImportantSource: lastImportantSource || lastSource,
  }
}

export const getSiteNameByDomain = (): string => {
  const domain = window.location.hostname
  if (domain.includes('school.')) {
    return SCHOOL_SITE_NAME
  }
  return UCHEBA_SITE_NAME
}
