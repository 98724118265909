import { sliceName } from './constants'
import { getStateError, getStateItem } from '../../utils/core'
import { IClientProductsRequestsState } from './types'

export default {
  entity: getStateItem<
    IClientProductsRequestsState,
    IClientProductsRequestsState[typeof sliceName]['entity']
  >(sliceName, 'entity'),

  loading: getStateItem<
    IClientProductsRequestsState,
    IClientProductsRequestsState[typeof sliceName]['loading']
  >(sliceName, 'loading'),

  error: getStateError<IClientProductsRequestsState>(sliceName),
}
