import { sliceName } from './constants'
import { getStateError, getStateItem } from '../../utils/core'
import { IStepFormsStartState } from './types'

export default {
  started: getStateItem<
    IStepFormsStartState,
    IStepFormsStartState[typeof sliceName]['started']
  >(sliceName, 'started'),

  loading: getStateItem<
    IStepFormsStartState,
    IStepFormsStartState[typeof sliceName]['loading']
  >(sliceName, 'loading'),

  error: getStateError<IStepFormsStartState>(sliceName),
}
