import styled, { keyframes, StyledProps } from 'styled-components'
import {
  EModClose,
  IModClose,
  IModOverflow,
} from '@ucheba/utils/helpers/styles/mod.types'
import { sm } from '@ucheba/utils/helpers/styles/mixins'
import { sv } from '@ucheba/utils/helpers/styles/variables'
import { animationDuration } from '../constants'
import { EModResetBorderRadius, IModResetBorderRadius } from '../../ProgressBar/types'
import { IBoxProps } from '../../Box/types'

const openNotice = keyframes`
  0% {
    opacity: 0;
    transform: translateY(100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`

const closeNotice = keyframes`
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(100%);
  }
`

const Block = styled.div<
  StyledProps<(IModClose | IModResetBorderRadius | IBoxProps) & IModOverflow>
>`
  position: relative;
  overflow: ${(props) => props.overflow};

  ${sm.mod(EModResetBorderRadius.true)`
    &&& {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  `}
`

const BlockAnimated = styled(Block)`
  margin-top: ${sv.spacing.xsmall};
  max-width: 518px;
  box-shadow:
    0 25px 30px 0 rgba(3, 15, 35, 0.1),
    0 0 20px 0 rgba(3, 15, 35, 0.15),
    0 0 1px 0 rgba(3, 15, 35, 0.15);

  ${sm.mod(EModClose.true)`
    animation ${closeNotice} ${animationDuration.css} forwards;
  `}

  ${sm.mod(EModClose.false)`
    animation ${openNotice} ${animationDuration.css} forwards;
  `}
`

const ButtonClose = styled.div`
  position: absolute;
  right: ${sv.spacing.medium};
  top: ${sv.spacing.medium};
`

export default { Block, ButtonClose, BlockAnimated }
