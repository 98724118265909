/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import { sliceName } from '../constants'
import { IOlympsIdState, IOlympsIdItem } from './types'
import { extraReducersResponse, responseInitialState } from '../../utils/response'
import thunks from './thunks'

export default createSlice({
  name: sliceName,
  initialState: {
    entity: null as IOlympsIdItem | null,
    ...responseInitialState,
  },

  reducers: {},

  extraReducers: (builder) => {
    extraReducersResponse<IOlympsIdState[typeof sliceName], IOlympsIdItem>({
      builder,
      thunk: thunks.fetch,
      fulfilled: (state, { payload }) => {
        state.entity = payload
      },
    })
  },
})
