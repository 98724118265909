import React, { FC } from 'react'
import { Field } from 'formik'
import { Select } from '@ucheba/ui/components/Select/touch'
import { EInstitutionsSearchDataKeys } from '@ucheba/store/institutions/search/types'
import { Form } from '@ucheba/ui/components/Form/touch'
import { Spacing } from '@ucheba/ui/components/Spacing/desktop'
import { Button } from '@ucheba/ui/components/Button/desktop'
import { useChooseLocationFormCore } from '../bll'
// import SC from '../base/ChooseLocationForm.styles'
import { IChooseLocationFormProps } from '../types'
import data from '../data.json'

// const { ChipsList } = SC

const ChooseLocationForm: FC<IChooseLocationFormProps> = (props) => {
  const { locations } = props

  const {
    // selectedLocations,
    // addLocation,
    // removeLocation,
    // onSuggesting,
    // popularLocationsList,
    onChangeHandler,
  } = useChooseLocationFormCore(props)

  return (
    <Form>
      <Field
        autocomplete
        noItems={data.select.noItems}
        items={locations}
        name={EInstitutionsSearchDataKeys.locationIds}
        label={data.select.label}
        as={Select}
        // onOpen={onSuggesting}
        onChange={onChangeHandler}
      />

      <Spacing spacing='large' />

      <Button type='submit' block color='blue'>
        {data.submitButtonText}
      </Button>
    </Form>
  )
}

export default ChooseLocationForm
