import { sliceName } from './constants'
import { IEventsOpenLessonsState } from './types'
import { getStateError, getStateItem } from '../../utils/core'

export default {
  entities: getStateItem<
    IEventsOpenLessonsState,
    IEventsOpenLessonsState[typeof sliceName]['entities']
  >(sliceName, 'entities'),

  ogeEntities: getStateItem<
    IEventsOpenLessonsState,
    IEventsOpenLessonsState[typeof sliceName]['ogeEntities']
  >(sliceName, 'ogeEntities'),

  loading: getStateItem<
    IEventsOpenLessonsState,
    IEventsOpenLessonsState[typeof sliceName]['loading']
  >(sliceName, 'loading'),

  error: getStateError<IEventsOpenLessonsState>(sliceName),
}
