import { IModSpacing } from '@ucheba/utils/helpers/styles/mod.types'

export interface IDividerProps extends IModSpacing, IModShiftRightDivider {}

export enum EModShiftRightDivider {
  small = 'shiftRight=small',
}

export interface IModShiftRightDivider {
  shiftRight?: EModShiftRightDivider
}
