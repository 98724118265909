import { IOgeSubjectsState } from './types'
import { ogeSubjectsAdapter } from './slice'
import { sliceName } from './constants'
import { getStateError } from '../../utils/core'

const adapterSelectors = ogeSubjectsAdapter.getSelectors<IOgeSubjectsState>(
  (state) => state[sliceName]
)

export default {
  ...adapterSelectors,

  error: getStateError<IOgeSubjectsState>(sliceName),
}
