import { sliceName } from './constants'
import { getStateError, getStateItem } from '../utils/core'
import { IChatBotState } from './types'

export default {
  redirectUrl: getStateItem<
    IChatBotState,
    IChatBotState[typeof sliceName]['redirectUrl']
  >(sliceName, 'redirectUrl'),

  loading: getStateItem<IChatBotState, IChatBotState[typeof sliceName]['loading']>(
    sliceName,
    'loading'
  ),

  isAllowed: getStateItem<IChatBotState, IChatBotState[typeof sliceName]['isAllowed']>(
    sliceName,
    'isAllowed'
  ),

  error: getStateError<IChatBotState>(sliceName),
}
