import { IEgeSubjectsState } from './types'
import { egeSubjectsAdapter } from './slice'
import { sliceName } from './constants'
import { getStateError } from '../../utils/core'

const adapterSelectors = egeSubjectsAdapter.getSelectors<IEgeSubjectsState>(
  (state) => state[sliceName]
)

export default {
  ...adapterSelectors,

  error: getStateError<IEgeSubjectsState>(sliceName),
}
