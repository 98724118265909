/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import {
  IClientProductsRequestsPaymentEntity,
  IClientProductsRequestsPaymentState,
} from './types'
import { extraReducersResponse, responseInitialState } from '../../../utils/response'
import { sliceName } from './constants'
import thunks from './thunks'
import { ELoading } from '../../../utils/response/types'

export default createSlice({
  name: sliceName,

  initialState: {
    confirmationUrl: '' as IClientProductsRequestsPaymentEntity['confirmationUrl'],
    hasPhone: null as IClientProductsRequestsPaymentEntity['hasPhone'] | null,
    ...responseInitialState,
  },

  reducers: {
    resetLoading: (state): void => {
      state.loading = ELoading.idle
    },
  },

  extraReducers: (builder) => {
    extraReducersResponse<
      IClientProductsRequestsPaymentState[typeof sliceName],
      IClientProductsRequestsPaymentEntity
    >({
      builder,
      thunk: thunks.pay,
      fulfilled: (state, { payload }) => {
        state.confirmationUrl = payload.confirmationUrl

        state.hasPhone = payload.hasPhone
      },
    })
  },
})
