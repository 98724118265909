import { useRouter } from 'next/router'
import { useSelector } from 'react-redux'
import { institutionItemSelectors } from '@ucheba/store/institutions/item'
import { institutionsProgramIdSelectors } from '@ucheba/store/institutions/programs/programId'
import { useMemo } from 'react'
import { EInstitutionType } from '@ucheba/store/institutions/item/types'
import { IProgramId } from '@ucheba/store/institutions/programs/programId/types'
import { EProgramTypes } from '@ucheba/utils/types/program'

interface IUseInitMenuByUzType {
  (): {
    href: string | null
  }
}

export const useInitMenuByUzType: IUseInitMenuByUzType = () => {
  const { pathname } = useRouter()
  const program = useSelector(institutionsProgramIdSelectors.entity) as IProgramId | null
  const currentInstitution = useSelector(institutionItemSelectors.currentInstitution)

  const href = useMemo(() => {
    if (pathname.includes('uz/[id]')) {
      if (currentInstitution) {
        const type = currentInstitution.type.value

        if (
          type === EInstitutionType.School ||
          type === EInstitutionType.Kindergarten ||
          type === EInstitutionType.ChildrenCourses
        ) {
          return '/for-kids'
        }

        if (
          type === EInstitutionType.MBA ||
          type === EInstitutionType.TrainingCenter ||
          type === EInstitutionType.TrainingCourses
        ) {
          return '/for-specialists'
        }

        return '/for-abiturients'
      }

      return null
    }
    if (pathname.includes('program/[id]')) {
      if (program) {
        const type = program.programType.value

        if (
          type === EProgramTypes.middle ||
          type === EProgramTypes.middleHS ||
          type === EProgramTypes.middleNS ||
          type === EProgramTypes.middleMS ||
          type === EProgramTypes.middleKind ||
          type === EProgramTypes.prepareSchool
        ) {
          return '/for-kids'
        }

        if (
          type === EProgramTypes.master ||
          type === EProgramTypes.aspirant ||
          type === EProgramTypes.prepareMaster ||
          type === EProgramTypes.course ||
          type === EProgramTypes.course1 ||
          type === EProgramTypes.course2 ||
          type === EProgramTypes.course3 ||
          type === EProgramTypes.event ||
          type === EProgramTypes.event1 ||
          type === EProgramTypes.event2 ||
          type === EProgramTypes.event3 ||
          type === EProgramTypes.event4 ||
          type === EProgramTypes.event5 ||
          type === EProgramTypes.mba ||
          type === EProgramTypes.mbaGeneral ||
          type === EProgramTypes.mbaExecutive ||
          type === EProgramTypes.mbaSpec ||
          type === EProgramTypes.mbaMini ||
          type === EProgramTypes.dba ||
          type === EProgramTypes.mpa ||
          type === EProgramTypes.bba
        ) {
          return '/for-specialists'
        }
      }

      return null
    }
    return null
  }, [currentInstitution, program, pathname])

  return {
    href,
  }
}
