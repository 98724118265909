import { getStateError, getStateItem } from '../../utils/core'
import { sliceName } from './constants'
import { IParticipantsState } from './types'

export default {
  entity: getStateItem<
    IParticipantsState,
    IParticipantsState[typeof sliceName]['entity']
  >(sliceName, 'entity'),

  loading: getStateItem<
    IParticipantsState,
    IParticipantsState[typeof sliceName]['loading']
  >(sliceName, 'loading'),

  error: getStateError<IParticipantsState>(sliceName),
}
