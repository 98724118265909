import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useEffect, useMemo } from 'react'
import { useNotice } from '@ucheba/ui/components/Notice/bll'
import { userDataActions, userDataSelectors } from '@ucheba/store/userData'
import { getParsedCookies } from '@ucheba/utils/helpers/core'
import { noticeCookie } from '@ucheba/utils/constants/core'
import { defineLocationNotice } from '@ucheba/utils/middlewares/defineLocationMiddleware'
import { IUseDefineLocationNotice } from './types'

export const useDefineLocationNotice: IUseDefineLocationNotice = () => {
  const userData = useSelector(userDataSelectors.entity)
  const locationNotice = useNotice(defineLocationNotice.id)
  const notificationsCookie = getParsedCookies()
  const dispatch = useDispatch()
  const notices =
    notificationsCookie[noticeCookie] && JSON.parse(notificationsCookie[noticeCookie])

  const isActiveLocationNotice = useMemo(() => {
    return notices?.length && notices.includes(defineLocationNotice.id)
  }, [notices])

  /** Добавляем нотис, если куки с айди локации нет */
  useEffect(() => {
    if (isActiveLocationNotice) {
      locationNotice.addNotice()
    } else {
      // locationNotice.removeNotice()
    }
  }, [isActiveLocationNotice]) // locationNotice не ставить

  const title = useMemo(() => {
    return userData?.selectedLocations?.length > 0
      ? `Вы ищете образование в ${userData?.selectedLocations[0].namePrepositional}?`
      : ''
  }, [userData?.selectedLocations])

  const onClickAcceptHandler = useCallback(() => {
    locationNotice.removeNotice()
  }, []) // locationNotice не ставить

  const onClickDeclineHandler = useCallback(() => {
    dispatch(userDataActions.setIsShowSelectLocation(true))

    dispatch(userDataActions.toggleIsRedirectOnSelectLocation(true))

    locationNotice.removeNotice()
  }, [dispatch]) // locationNotice не ставить

  return {
    title,
    onClickAcceptHandler,
    onClickDeclineHandler,
    notice: locationNotice,
  }
}
