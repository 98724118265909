import apiThunk, { api } from '@ucheba/utils/helpers/api'
import { sliceName } from './constants'

// Список специальностей в плане
const getSpecialities = apiThunk(`${sliceName}/getSpecialities`, async (params) => {
  const { programTypeValue } = params.data

  const response = await api.get(
    `/entrance-planners/${programTypeValue}/specialities`,
    params
  )

  return response.data
})

// Список подходящих специальностей
const getSpecialitiesSuitable = apiThunk(
  `${sliceName}/getSpecialitiesSuitable`,
  async (params) => {
    const { programTypeValue } = params.data

    const response = await api.get(
      `/entrance-planners/${programTypeValue}/suitable-specialities`,
      params
    )

    return response.data
  }
)

// Добавление специальностей в план
const postSpecialities = apiThunk(`${sliceName}/postSpecialities`, async (params) => {
  const response = await api.post(`/entrance-planners/specialities`, params?.data, params)

  return { ...response.data, entityIds: params.data.entityIds }
})

const getSpecialitiesIds = apiThunk(`${sliceName}/getSpecialtiesIds`, async (params) => {
  const response = await api.get(`/entrance-planners/specialities`, params)
  return response.data
})

// Удаление специальностей из плана
const deleteSpecialities = apiThunk(`${sliceName}/deleteSpecialities`, async (params) => {
  const response = await api.delete(`/entrance-planners/specialities`, params)

  return { ...response.data, entityIds: params.data.entityIds }
})

export default {
  getSpecialities,
  getSpecialitiesSuitable,
  postSpecialities,
  deleteSpecialities,
  getSpecialitiesIds,
}
