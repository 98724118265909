import { getStateError, getStateItem } from '../../utils/core'
import { sliceName } from './constants'
import { IPlannerSpecialtiesState } from './types'

export default {
  loading: getStateItem<
    IPlannerSpecialtiesState,
    IPlannerSpecialtiesState[typeof sliceName]['loading']
  >(sliceName, 'loading'),

  entitySpecialities: getStateItem<
    IPlannerSpecialtiesState,
    IPlannerSpecialtiesState[typeof sliceName]['entitySpecialities']
  >(sliceName, 'entitySpecialities'),

  entitySuitableSpecialities: getStateItem<
    IPlannerSpecialtiesState,
    IPlannerSpecialtiesState[typeof sliceName]['entitySuitableSpecialities']
  >(sliceName, 'entitySuitableSpecialities'),

  entitySpecialitiesIds: getStateItem<
    IPlannerSpecialtiesState,
    IPlannerSpecialtiesState[typeof sliceName]['entitySpecialitiesIds']
  >(sliceName, 'entitySpecialitiesIds'),

  offset: getStateItem<
    IPlannerSpecialtiesState,
    IPlannerSpecialtiesState[typeof sliceName]['offset']
  >(sliceName, 'offset'),

  excludeIds: getStateItem<
    IPlannerSpecialtiesState,
    IPlannerSpecialtiesState[typeof sliceName]['excludeIds']
  >(sliceName, 'excludeIds'),

  error: getStateError<IPlannerSpecialtiesState>(sliceName),
}
