/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import { sliceName } from './constants'
import { IProfile, IProfileState } from './types'
import { extraReducersResponse, responseInitialState } from '../utils/response'
import thunks from './thunks'
import { ELoading } from '../utils/response/types'

export default createSlice({
  name: sliceName,

  initialState: {
    entity: null as IProfile | null,
    ...responseInitialState,
  },

  reducers: {
    resetEntity: (state): void => {
      state.entity = null
    },
    resetLoading: (state): void => {
      state.loading = ELoading.idle
    },
    setLoadingFulfilled: (state): void => {
      state.loading = ELoading.fulfilled
    },
    setIsParent: (state, { payload }): void => {
      if (state.entity) {
        state.entity.isParent = payload
      }
    },
  },

  extraReducers: (builder) => {
    extraReducersResponse<IProfileState[typeof sliceName], IProfileState>({
      builder,
      thunk: thunks.patch,
      fulfilled: () => null,
    })
    extraReducersResponse<IProfileState[typeof sliceName], IProfile>({
      builder,
      thunk: thunks.get,
      fulfilled: (state, { payload }) => {
        state.entity = payload
      },
    })
  },
})
