/* eslint-disable no-param-reassign */
import { createSlice, createEntityAdapter, EntityState } from '@reduxjs/toolkit'
import { IItemOfTags } from './types'
import { extraReducersResponse, responseInitialState } from '../../utils/response'
import { sliceName } from './constants'
import thunks from './thunks'
import { IResponseInitialState } from '../../utils/response/types'

export const tagsTreeAdapter = createEntityAdapter<IItemOfTags>()

export default createSlice({
  name: sliceName,

  initialState:
    tagsTreeAdapter.getInitialState<IResponseInitialState>(responseInitialState),

  reducers: {},

  extraReducers: (builder) => {
    extraReducersResponse<EntityState<IItemOfTags>, IItemOfTags[]>({
      builder,
      thunk: thunks.fetch,
      fulfilled: (state, { payload }) => {
        tagsTreeAdapter.setAll(state, payload)
      },
    })
  },
})
