import { NextPageContext } from 'next'
import { v4 as uuidv4 } from 'uuid'
import { Store } from 'redux'
import { setAdvUserIdToCookie } from '@ucheba/store/adv/bll'
import { advActions, advSelectors } from '@ucheba/store/adv'
import { getParsedCookies } from '../helpers/core'
import { advUserIdCookie } from '../constants/core'

export const setAdvUserId = ({
  ctx,
  store,
  advUserId,
}: {
  ctx: NextPageContext | null
  store: Store
  advUserId: string
}): void => {
  setAdvUserIdToCookie({ ctx, advUserId })

  store.dispatch(advActions.setAdvUserId(advUserId))
}

const defineAdvUserId = (ctx: NextPageContext | null, store: Store): string => {
  const { getState } = store
  let advUserId = advSelectors.advUserId(getState())
  if (advUserId) {
    return advUserId
  }

  const parsedCookie = getParsedCookies(ctx || undefined)
  advUserId = parsedCookie[advUserIdCookie]

  if (!advUserId) {
    advUserId = uuidv4()
  }
  setAdvUserId({ ctx, store, advUserId })
  return advUserId
}

export default defineAdvUserId
