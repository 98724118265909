import { getStateError, getStateItem } from '../utils/core'
import { sliceName } from './constants'
import { IArticlesState, IArticlesItem } from './types'

export default {
  entity: getStateItem<IArticlesItem, IArticlesItem[typeof sliceName]['entity']>(
    sliceName,
    'entity'
  ),

  loading: getStateItem<IArticlesState, IArticlesState[typeof sliceName]['loading']>(
    sliceName,
    'loading'
  ),

  error: getStateError<IArticlesState>(sliceName),
}
