import { isBrowser } from '../core'
import { IGtagEvent } from './types'
import { analyticsIds } from './constants'

declare global {
  interface Window {
    gtag_params: any
  }
}

export const init = (code: string): void => {
  if (isBrowser && window.gtag) {
    window.gtag('js', new Date())

    window.gtag('config', code, {
      ...(window.gtag_params || {}),
      page_path: window.location.pathname,
    })
  }
}

export const pageview = (url: string): void => {
  if (isBrowser && window.gtag) {
    window.gtag('config', analyticsIds.ga, {
      ...(window.gtag_params || {}),
      page_path: url,
    })
  }
}

export const setParams = (params: Record<string, any>): void => {
  if (isBrowser && params) {
    window.gtag_params = params
  }
}

export const event = ({ action, category, label, value }: IGtagEvent): void => {
  if (isBrowser && window.gtag) {
    window.gtag('event', action, {
      event_category: category,
      event_label: label,
      value,
    })
  }
}
