import apiThunk, { api } from '@ucheba/utils/helpers/api'
import { sliceName } from './constants'

const fetchChancesAdmission = apiThunk(
  `${sliceName}/chancesOfAdmission`,
  async (params) => {
    const { readyToExam, scores, achievementIds } = params.data

    const response = await api.post(
      `/probabilities/${params.data.type}/${params.data.referId}`,
      {
        readyToExam,
        scores,
        achievementIds,
      },
      params
    )

    return response.data
  }
)

export default {
  fetchChancesAdmission,
}
