import React, { FC } from 'react'
import { Spacing } from '@ucheba/ui/components/Spacing/desktop'
import { Button } from '@ucheba/ui/components/Button/desktop'
import { Field } from 'formik'
import { Select } from '@ucheba/ui/components/Select/desktop'
import { EInstitutionsSearchDataKeys } from '@ucheba/store/institutions/search/types'
import { Form } from '@ucheba/ui/components/Form/desktop'
import { useChooseLocationFormCore } from '../bll'
// import SC from '../base/ChooseLocationForm.styles'
import { IChooseLocationFormProps } from '../types'
import data from '../data.json'

// const { ChipsList } = SC

const ChooseLocationForm: FC<IChooseLocationFormProps> = (props) => {
  const { locations } = props

  const {
    // selectedLocations,
    // addLocation,
    // removeLocation,
    // onSuggesting,
    // popularLocationsList,
    onChangeHandler,
  } = useChooseLocationFormCore(props)

  // const locationAllRussia = useSelector(locationSelectors.location)

  return (
    <Form>
      {/* {selectedLocations.length > 0 && ( */}
      {/*  <ChipsList> */}
      {/*    {selectedLocations.map((selectedLocation) => ( */}
      {/*      <Chips */}
      {/*        onClick={removeLocation(selectedLocation)} */}
      {/*        key={selectedLocation.id} */}
      {/*        color={selectedLocation.id === locationAllRussia?.id ? 'gray5' : 'blue'} */}
      {/*        clear={selectedLocation.id !== locationAllRussia?.id} */}
      {/*      > */}
      {/*        {selectedLocation.name} */}
      {/*      </Chips> */}
      {/*    ))} */}
      {/*  </ChipsList> */}
      {/* )} */}

      <Spacing spacing='small' />

      <Field
        autocomplete
        noItems={data.select.noItems}
        items={locations}
        name={EInstitutionsSearchDataKeys.locationIds}
        label={data.select.label}
        as={Select}
        onChange={onChangeHandler}
      />

      <Spacing spacing='small' />

      {/* <Grid spacing='xxsmall'> */}
      {/*  <GridCol width='auto'> */}
      {/*    <Text as='span' fontSize='small1' lineHeight='small1'> */}
      {/*      {data.popularLocationsTitle} */}
      {/*    </Text> */}
      {/*  </GridCol> */}

      {/*  {popularLocationsList.map((location) => ( */}
      {/*    <GridCol width='auto' key={location.id}> */}
      {/*      <Button */}
      {/*        onClick={addLocation(location)} */}
      {/*        view='link' */}
      {/*        color='blue' */}
      {/*        size='small' */}
      {/*        resetHeight */}
      {/*      > */}
      {/*        <Text as='span' fontSize='small1' lineHeight='small1' fontWeight='regular'> */}
      {/*          {location.name} */}
      {/*        </Text> */}
      {/*      </Button> */}
      {/*    </GridCol> */}
      {/*  ))} */}
      {/* </Grid> */}

      <Spacing spacing='small' />

      <Button type='submit' block color='blue'>
        {data.submitButtonText}
      </Button>
    </Form>
  )
}

export default ChooseLocationForm
