/* eslint-disable no-param-reassign */
import { createEntityAdapter, createSlice, EntityState } from '@reduxjs/toolkit'
import { extraReducersResponse, responseInitialState } from '../utils/response'
import { sliceName } from './constants'
import thunks from './thunks'
import { IItemOfOlympiads } from './types'
import { IResponseInitialState } from '../utils/response/types'

export const olympiadsAdaptor = createEntityAdapter<IItemOfOlympiads>()

export default createSlice({
  name: sliceName,

  initialState:
    olympiadsAdaptor.getInitialState<IResponseInitialState>(responseInitialState),

  reducers: {},

  extraReducers: (builder) => {
    extraReducersResponse<EntityState<IItemOfOlympiads>, IItemOfOlympiads[]>({
      builder,
      thunk: thunks.fetch,
      fulfilled: (state, action) => {
        olympiadsAdaptor.setAll(state, action.payload)
      },
    })
  },
})
