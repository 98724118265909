import { IPartnerEmployers, IPartnerEmployersState } from './types'
import { sliceName } from './constants'
import { getStateItem } from '../../utils/core'

export default {
  entity: getStateItem<IPartnerEmployers, IPartnerEmployers>(sliceName, 'entity'),

  loading: getStateItem<
    IPartnerEmployersState,
    IPartnerEmployersState[typeof sliceName]['loading']
  >(sliceName, 'loading'),
}
