import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useRouter } from 'next/router'
import smoothScroll from 'smoothscroll-polyfill'
import {
  EFadePosition,
  INavigationOnClickCallback,
  INavigationProps,
  IUseNavigationCore,
  TFadePosition,
} from './types'

export const useOnClickItem = (
  onClick: INavigationProps['onClick']
): INavigationOnClickCallback =>
  useCallback(
    (event, slug: string) => {
      if (onClick) onClick(event, slug)
    },
    [onClick]
  )

export const useNavigationCore: IUseNavigationCore = () => {
  const wrapperRef = useRef<null | HTMLElement>(null)
  const menuRef = useRef<null | HTMLElement>(null)
  const [childrenElementsWidth, setChildrenElementsWidth] = useState(0)
  const [wrapperWidth, setWrapperWidth] = useState(0)
  const [fadePosition, setFadePosition] = useState<TFadePosition>(EFadePosition.null)
  const { asPath } = useRouter()

  /** Выставление полифила для плавного скролла на сафари */
  useEffect(() => {
    smoothScroll.polyfill()
  }, [])

  /** Ширина скролла */
  const scrollWidth = useMemo(
    () => childrenElementsWidth - wrapperWidth,
    [childrenElementsWidth, wrapperWidth]
  )

  const scrollHandler = useCallback(
    (event) => {
      const target = event.target as HTMLElement
      /** Если скролл находится в крайнем левом положении */
      if (target.scrollLeft === 0) {
        setFadePosition(EFadePosition.right)
      }

      /** Если скролл находится в крайнем правом положении */
      if (Math.ceil(target.scrollLeft) >= target.scrollWidth - wrapperWidth) {
        setFadePosition(EFadePosition.left)
      }

      /** Если скролл находится где-то между крайними положениями */
      if (
        target.scrollLeft > 0 &&
        Math.ceil(target.scrollLeft) < target.scrollWidth - wrapperWidth
      ) {
        setFadePosition(EFadePosition.both)
      }
    },
    [wrapperWidth]
  )

  const resizeWindowHandler = useCallback(() => {
    if (menuRef && menuRef?.current) {
      setChildrenElementsWidth(menuRef?.current?.scrollWidth)
    }

    if (wrapperRef && wrapperRef?.current) {
      setWrapperWidth(wrapperRef?.current?.offsetWidth)
    }
  }, [menuRef, wrapperRef])

  /** Пересчитывание ширины блока и положение кнопок с затемнением по бокам при ресайзе окна */
  useEffect(() => {
    window.addEventListener('resize', resizeWindowHandler)

    return (): void => {
      window.removeEventListener('resize', resizeWindowHandler)
    }
  }, [resizeWindowHandler])

  /** Высчитываем ширину элементов меню */
  useEffect(() => {
    if (menuRef && menuRef?.current) {
      setChildrenElementsWidth(menuRef?.current?.scrollWidth)
    }
  }, [asPath, menuRef])

  /** Высчитаем ширину обертки для пунктов меню */
  useEffect(() => {
    if (wrapperRef && wrapperRef?.current) {
      setWrapperWidth(wrapperRef?.current?.offsetWidth)
    }
  }, [wrapperRef, asPath])

  /** Выставлем первоначальное состояние fade */
  useEffect(() => {
    const scrollPosition = menuRef?.current?.scrollLeft

    if (wrapperWidth < childrenElementsWidth && scrollPosition === 0) {
      setFadePosition(EFadePosition.right)
    }

    if (wrapperWidth < childrenElementsWidth && scrollPosition === scrollWidth) {
      setFadePosition(EFadePosition.left)
    }

    if (
      wrapperWidth < childrenElementsWidth &&
      scrollPosition &&
      scrollPosition > 0 &&
      scrollPosition < scrollWidth
    ) {
      setFadePosition(EFadePosition.both)
    }

    if (wrapperWidth >= childrenElementsWidth) {
      setFadePosition(EFadePosition.null)
    }
  }, [childrenElementsWidth, wrapperWidth, asPath, scrollWidth])

  /** Высчитываем положение скролла и выставляем позицию fade (если необходимо) */
  useEffect(() => {
    menuRef?.current?.addEventListener('scroll', scrollHandler)

    return (): void => {
      menuRef?.current?.removeEventListener('scroll', scrollHandler)
    }
  }, [childrenElementsWidth, menuRef, scrollHandler, scrollWidth, wrapperWidth])

  const isFadeLeft = useMemo(() => fadePosition === EFadePosition.left, [fadePosition])
  const isFadeRight = useMemo(() => fadePosition === EFadePosition.right, [fadePosition])
  const isFadeBoth = useMemo(() => fadePosition === EFadePosition.both, [fadePosition])
  const isFadeExist = useMemo(
    () => isFadeLeft || isFadeRight || isFadeBoth,
    [fadePosition]
  )

  const leftButtonHandler = useCallback(() => {
    if (menuRef && menuRef.current) {
      const scrollPosition = menuRef?.current?.scrollLeft

      menuRef.current.scrollTo({
        behavior: 'smooth',
        left: scrollPosition - Math.round((wrapperWidth / 100) * 80),
      })
    }
  }, [wrapperWidth])

  const rightButtonHandler = useCallback(() => {
    if (menuRef && menuRef.current) {
      const scrollPosition = menuRef?.current?.scrollLeft

      menuRef.current.scrollTo({
        behavior: 'smooth',
        left: scrollPosition + Math.round((wrapperWidth / 100) * 80),
      })
    }
  }, [wrapperWidth])

  return {
    wrapperRef,
    menuRef,
    isFadeLeft,
    isFadeRight,
    isFadeBoth,
    isFadeExist,
    leftButtonHandler,
    rightButtonHandler,
  }
}
