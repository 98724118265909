/* eslint-disable no-param-reassign */
import { createEntityAdapter, createSlice, EntityState } from '@reduxjs/toolkit'
import { extraReducersResponse, responseInitialState } from '../../utils/response'
import { sliceName } from './constants'
import thunks from './thunks'
import { IItemOfEgeStats } from './types'
import { IResponseInitialState } from '../../utils/response/types'

export const egeStatsAdaptor = createEntityAdapter<IItemOfEgeStats>({
  selectId: (ege) =>
    ege.subjects.reduce((acc, item) => {
      acc += item.id

      return acc
    }, ''),
})

export default createSlice({
  name: sliceName,

  initialState:
    egeStatsAdaptor.getInitialState<IResponseInitialState>(responseInitialState),

  reducers: {},

  extraReducers: (builder) => {
    extraReducersResponse<EntityState<IItemOfEgeStats>, IItemOfEgeStats[]>({
      builder,
      thunk: thunks.fetch,
      fulfilled: (state, action) => {
        egeStatsAdaptor.setAll(state, action.payload)
      },
    })
  },
})
