import { IAuthPhoneCodeState } from './types'
import { sliceName } from './constants'
import { getStateError, getStateItem } from '../../../utils/core'

export default {
  timeout: getStateItem<
    IAuthPhoneCodeState,
    IAuthPhoneCodeState[typeof sliceName]['timeout']
  >(sliceName, 'timeout'),

  loading: getStateItem<
    IAuthPhoneCodeState,
    IAuthPhoneCodeState[typeof sliceName]['loading']
  >(sliceName, 'loading'),

  phone: getStateItem<
    IAuthPhoneCodeState,
    IAuthPhoneCodeState[typeof sliceName]['phone']
  >(sliceName, 'phone'),

  error: getStateError<IAuthPhoneCodeState>(sliceName),
}
