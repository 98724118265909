import { FormikErrors, useFormikContext } from 'formik'
import { useEffect } from 'react'
import { ISerializedError } from '../helpers/core/types'

export const defaultErrorText = 'Проверьте правильность ввода данных и повторите попытку.'

/* Добавляет в контекст формы ошибки из апи */
export const useFormErrors = (
  apiErrors?: ISerializedError
): FormikErrors<Record<string, string>> => {
  const { setFieldError, errors } = useFormikContext()

  useEffect(() => {
    if (!apiErrors) return

    if (apiErrors?.formErrors) {
      apiErrors.formErrors.forEach(({ field, message }) => {
        setFieldError(field, message)
      })
    } else {
      alert(apiErrors?.message || defaultErrorText)
    }
  }, [apiErrors, setFieldError])

  return errors
}
