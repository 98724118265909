import { getStateError, getStateItem } from '../../utils/core'
import { sliceName } from './constsnts'
import { IPlannerStatisticsState } from './types'

export default {
  loading: getStateItem<
    IPlannerStatisticsState,
    IPlannerStatisticsState[typeof sliceName]['loading']
  >(sliceName, 'loading'),

  entityProgramsStatistics: getStateItem<
    IPlannerStatisticsState,
    IPlannerStatisticsState[typeof sliceName]['entityProgramsStatistics']
  >(sliceName, 'entityProgramsStatistics'),

  entitySpecialtiesStatistics: getStateItem<
    IPlannerStatisticsState,
    IPlannerStatisticsState[typeof sliceName]['entitySpecialtiesStatistics']
  >(sliceName, 'entitySpecialtiesStatistics'),

  error: getStateError<IPlannerStatisticsState>(sliceName),
}
