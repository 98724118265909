import apiThunk, { api } from '@ucheba/utils/helpers/api'
import { sliceName } from './constants'

const fetch = apiThunk(`${sliceName}/fetch`, async (props) => {
  const response = await api.get('events/rubrics', props)

  return response.data
})

export default { fetch }
