/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import { extraReducersResponse, responseInitialState } from '../../utils/response'
import { sliceName } from './constants'
import {
  INewOlympSearch,
  IItemOfNewOlympBySearchFriendlyURL,
  INewOlympSearchState,
} from './types'
import thunks from './thunks'

export default createSlice({
  name: sliceName,
  initialState: {
    entity: null as INewOlympSearch | null,
    entityByFriendlyUrl: null as IItemOfNewOlympBySearchFriendlyURL | null,
    ...responseInitialState,
  },
  reducers: {},

  extraReducers: (builder) => {
    extraReducersResponse<INewOlympSearchState[typeof sliceName], INewOlympSearch>({
      builder,
      thunk: thunks.fetch,
      fulfilled: (state, action) => {
        state.entity = action.payload
      },
    })

    extraReducersResponse<
      INewOlympSearchState[typeof sliceName],
      IItemOfNewOlympBySearchFriendlyURL
    >({
      builder,
      thunk: thunks.fetchByFriendlyURL,
      fulfilled: (state, action) => {
        state.entityByFriendlyUrl = action.payload
      },
    })
  },
})
