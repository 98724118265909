import { IAuthState } from './types'
import { sliceName } from './constants'
import { getStateError, getStateItem } from '../utils/core'

export default {
  loading: getStateItem<IAuthState, IAuthState[typeof sliceName]['loading']>(
    sliceName,
    'loading'
  ),

  token: getStateItem<IAuthState, IAuthState[typeof sliceName]['token']>(
    sliceName,
    'token'
  ),

  isValidToken: getStateItem<IAuthState, IAuthState[typeof sliceName]['isValidToken']>(
    sliceName,
    'isValidToken'
  ),

  isAuth: (state: IAuthState[typeof sliceName]): boolean => {
    return !!state[sliceName].token
  },

  error: getStateError<IAuthState>(sliceName),
}
