import { ITagsStatsState } from './types'
import { sliceName } from './constants'
import { getStateError, getStateItem } from '../../utils/core'
import { tagsStatsAdapter } from './slice'

const parentsAdapterSelectors = tagsStatsAdapter.getSelectors<ITagsStatsState>(
  (state) => state[sliceName].parents
)

const childrenAdapterSelectors = tagsStatsAdapter.getSelectors<ITagsStatsState>(
  (state) => state[sliceName].children
)

export default {
  parents: {
    ...parentsAdapterSelectors,

    totalInstitutionsCount: getStateItem<
      ITagsStatsState,
      ITagsStatsState[typeof sliceName]['parents']['totalInstitutionsCount']
    >(sliceName, 'totalInstitutionsCount', 'parents'),

    totalSpecialitiesCount: getStateItem<
      ITagsStatsState,
      ITagsStatsState[typeof sliceName]['parents']['totalSpecialitiesCount']
    >(sliceName, 'totalSpecialitiesCount', 'parents'),
  },

  children: {
    ...childrenAdapterSelectors,

    totalInstitutionsCount: getStateItem<
      ITagsStatsState,
      ITagsStatsState[typeof sliceName]['children']['totalInstitutionsCount']
    >(sliceName, 'totalInstitutionsCount', 'children'),

    totalSpecialitiesCount: getStateItem<
      ITagsStatsState,
      ITagsStatsState[typeof sliceName]['children']['totalSpecialitiesCount']
    >(sliceName, 'totalSpecialitiesCount', 'children'),
  },

  loading: {
    parents: getStateItem<
      ITagsStatsState,
      ITagsStatsState[typeof sliceName]['parents']['loading']
    >(sliceName, 'loading', 'parents'),

    children: getStateItem<
      ITagsStatsState,
      ITagsStatsState[typeof sliceName]['children']['loading']
    >(sliceName, 'loading', 'children'),
  },

  error: getStateError<ITagsStatsState>(sliceName),
}
