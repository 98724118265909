/* Текстовые коды ошибок */
export enum EFormErrors {
  isNotUnique = 'IS_NOT_UNIQUE',
}

export enum EHTTPStatusCodes {
  unauthorized = 401,
}

export enum EHttpStatusCode {
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
}
