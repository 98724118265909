import React, { FC, Fragment, memo, useRef } from 'react'
import CloseIcon from '@ucheba/ui/icons/icon__close.svg'
import SC from '../base/Select.styles'
import { ISelectProps } from '../types'
import { TextField } from '../../TextField/touch'
import { Menu, MenuItem } from '../../Menu/touch'
import { Button } from '../../Button/touch'
import { Text } from '../../Text/touch'
import { Spacing } from '../../Spacing/touch'
import { useBottomSheetHeight, useSelectCore } from '../bll'
import { BottomSheet } from '../../BottomSheet/touch'
import { Box } from '../../Box/touch'
import { Divider } from '../../Divider/touch'

const { Block, Activator, NativeSelect, SelectedText, Icon, SelectMenuItem } = SC

/** Селект */

const Select: FC<ISelectProps> = (props) => {
  const {
    label,
    multiple,
    value,
    keyValue = 'id',
    keyText = 'name',
    name,
    items,
    onChange,
    onBlur,
    onOpen,
    onClose,
    onInput,
    open,
    resetItem,
    noItems = 'Ничего не найдено',
    suggested,
    hideMenu,
    submitOnClose,
    hiddenItems,
    autocomplete = true,
    ...otherProps
  } = props

  const selectRef = useRef<HTMLSelectElement>(null)
  const {
    selectItems,
    selectedValuesIds,
    selectedText,
    textFieldValue,
    isOpen,
    toggleOpen,
    resetSelected,
    toggleMenu,
    getMenuItemActiveStatus,
    getMenuItemCheckedStatus,
    onChangeNativeSelect,
    onChangeTextField,
    onFocusTextField,
    onBlurTextField,
    toggleItem,
    onClickReady,
  } = useSelectCore({
    selectRef,
    items,
    keyValue,
    keyText,
    value,
    suggested,
    multiple,
    autocomplete,
    open,
    onOpen,
    onClose,
    onChange,
    onInput,
    name,
    submitOnClose,
  })

  const { bottomSheetHeight, bottomSheetRef } = useBottomSheetHeight({
    autocomplete,
    isOpen,
  })

  const Header =
    label || autocomplete ? (
      <>
        <Box spacing='small'>
          {autocomplete ? (
            <TextField
              type='text'
              label={label}
              autofocus={false}
              onFocus={onFocusTextField}
              onBlur={onBlurTextField}
              onChange={onChangeTextField}
              value={textFieldValue}
              autocomplete='off'
            />
          ) : (
            <Text fontWeight='semibold'>{label}</Text>
          )}
        </Box>

        <Divider spacing='null' />
      </>
    ) : undefined

  const Footer = multiple ? (
    <Box spacing='small'>
      <Button color='blue' block onClick={onClickReady}>
        Готово
      </Button>
    </Box>
  ) : undefined

  return (
    <Block {...otherProps}>
      <NativeSelect
        ref={selectRef}
        name={name}
        multiple
        value={selectedValuesIds}
        onChange={onChangeNativeSelect}
        onBlur={onBlur}
        aria-hidden='true'
      >
        {selectItems.map((item) => (
          <option value={item[keyValue]} key={item[keyValue]}>
            {item[keyText]}
          </option>
        ))}
      </NativeSelect>

      <Activator autocomplete={autocomplete} onClick={toggleMenu}>
        <TextField
          label={label}
          active={Boolean(selectedValuesIds.length)}
          autocomplete='off'
          autofocus={false}
          onFocus={(e) => {
            e.currentTarget.blur()
          }}
        />

        <Icon open={isOpen} />

        <SelectedText active>
          <Text firstLetterUppercase as='span'>
            {selectedText}
          </Text>
        </SelectedText>
      </Activator>

      {!hideMenu && (
        <BottomSheet
          open={isOpen}
          toggleOpen={toggleOpen}
          Header={Header}
          Footer={Footer}
          ref={bottomSheetRef}
          height={bottomSheetHeight}
        >
          <Menu shadow={false} spacing={['xsmall', 'small']} maxHeight='100%'>
            {selectItems.length > 0 ? (
              <>
                {resetItem && !textFieldValue && (
                  <MenuItem EndIcon={<CloseIcon width='16px' height='16px' />}>
                    <Button size='small' onClick={resetSelected}>
                      {resetItem}
                    </Button>
                  </MenuItem>
                )}
              </>
            ) : (
              <MenuItem>
                <div>
                  <Spacing spacing='xxsmall' />
                  <Text color='gray60'>{noItems}</Text>
                  <Spacing spacing='xxsmall' />
                </div>
              </MenuItem>
            )}

            {selectItems.map((item) => {
              if (hiddenItems && hiddenItems?.some((id) => String(id) === item.id))
                return null

              return (
                <Fragment key={item[keyValue]}>
                  {item?.items ? (
                    <>
                      {item.items.length > 0 && (
                        <>
                          {resetItem ? (
                            <>
                              <Divider spacing='xsmall' shiftRight='small' />

                              <Spacing spacing='medium' />
                            </>
                          ) : (
                            <Spacing spacing='xsmall' />
                          )}

                          <Text fontWeight='semibold' firstLetterUppercase>
                            {item[keyText]}
                          </Text>

                          <Spacing spacing='xsmall' />

                          {item.items.map((childItem) => (
                            <MenuItem
                              active={getMenuItemActiveStatus(childItem[keyValue])}
                              checked={getMenuItemCheckedStatus(childItem[keyValue])}
                              key={childItem[keyValue]}
                            >
                              <SelectMenuItem
                                as={Button}
                                size='small'
                                onClick={(): void => toggleItem(childItem[keyValue])}
                              >
                                <Text firstLetterUppercase>{childItem[keyText]}</Text>
                              </SelectMenuItem>
                            </MenuItem>
                          ))}
                        </>
                      )}
                    </>
                  ) : (
                    <MenuItem
                      active={getMenuItemActiveStatus(item[keyValue])}
                      checked={getMenuItemCheckedStatus(item[keyValue])}
                    >
                      <SelectMenuItem
                        as={Button}
                        size='small'
                        onClick={(): void => toggleItem(item[keyValue])}
                      >
                        <Text firstLetterUppercase>{item[keyText]}</Text>
                      </SelectMenuItem>
                    </MenuItem>
                  )}
                </Fragment>
              )
            })}
          </Menu>
        </BottomSheet>
      )}
    </Block>
  )
}

export { Select }
export default memo(Select)
