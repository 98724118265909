/* eslint-disable no-param-reassign */
import apiThunk, { api } from '@ucheba/utils/helpers/api'
import { sliceName } from './constants'

const fetchDivisions = apiThunk(`${sliceName}/divisions`, async (params) => {
  const response = await api.get(
    `/institutions/${params.data.institutionId}/childrens`,
    params
  )

  return { data: response.data, facetName: params.data.facetName }
})

const fetchDivisionsFacest = apiThunk(`${sliceName}/facest`, async (params) => {
  params.data.limit = 0
  const response = await api.get(
    `/institutions/${params.data.institutionId}/childrens`,
    params
  )

  return response.data
})

export default { fetchDivisions, fetchDivisionsFacest }
