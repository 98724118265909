import apiThunk, { api } from '@ucheba/utils/helpers/api'
import { addLimitOffset } from '@ucheba/utils/helpers/router'
import { participantsLimit, sliceName } from './constants'

const fetch = apiThunk(`${sliceName}/fetch`, async (params) => {
  const response = await api.get(
    `/events/${params?.data.id}/participants`,
    params.data.limit ? params : addLimitOffset(params, participantsLimit)
  )

  return response.data
})

export default { fetch }
