/* eslint-disable no-param-reassign */
import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { extraReducersResponse, responseInitialState } from '../utils/response'
import { sliceName } from './constants'
import thunks from './thunks'
import { IInstitutions, IInstitutionsState, IItemOfInstitutions } from './types'
import { IResponseInitialState } from '../utils/response/types'

export const institutionsAdaptor = createEntityAdapter<IItemOfInstitutions>()

export default createSlice({
  name: sliceName,

  initialState: institutionsAdaptor.getInitialState<
    IResponseInitialState & { total: IInstitutions['total'] }
  >({
    total: 0,
    ...responseInitialState,
  }),

  reducers: {},

  extraReducers: (builder) => {
    extraReducersResponse<IInstitutionsState[typeof sliceName], IInstitutions>({
      builder,
      thunk: thunks.fetch,
      fulfilled: (state, action) => {
        state.total = action.payload.total

        institutionsAdaptor.setAll(state, action.payload.items)
      },
    })
  },
})
