import { IEgeSubjectsScheduleState } from './types'
import { egeSubjectsScheduleAdapter } from './slice'
import { sliceName } from './constants'
import { getStateError } from '../../../utils/core'

export const adapterSelectors =
  egeSubjectsScheduleAdapter.getSelectors<IEgeSubjectsScheduleState>(
    (state) => state[sliceName]
  )

export default {
  ...adapterSelectors,

  error: getStateError<IEgeSubjectsScheduleState>(sliceName),
}
