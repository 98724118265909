import React, { FC } from 'react'
import { useRouter } from 'next/router'
import { useSelector } from 'react-redux'

import { useOnlyClientRendering } from '@ucheba/utils/hooks/useOnlyClientRendering'
import { authSelectors } from '@ucheba/store/auth'
import { institutionItemSelectors } from '@ucheba/store/institutions/item'

import VkPermissionNoticeTouch from './touch/VkPermissionNoticeTouch'
import VkPermissionNoticeDesktop from './desktop/VkPermissionNoticeDesktop'
import { IVkPermissionNoticeIndex } from './types'

const VkPermissionNotice: FC<IVkPermissionNoticeIndex> = (props) => {
  const { isTouch, blockedPaths } = props
  const router = useRouter()
  const isClient = useOnlyClientRendering()
  const isAuth = useSelector(authSelectors.isAuth)

  const institutionData = useSelector(institutionItemSelectors.currentInstitution)

  const isInstitutionWithPay =
    institutionData?.paidPackage && router.asPath.includes('uz')

  const isBlocked = blockedPaths?.includes(router.pathname)

  if (isBlocked || isInstitutionWithPay || !isClient || !isAuth) return null

  if (isTouch) {
    return <VkPermissionNoticeTouch />
  }
  return <VkPermissionNoticeDesktop />
}

export default VkPermissionNotice
