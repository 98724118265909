import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NextPageContext } from 'next'
import { getCookieDomain, getParsedCookies, setCookie } from '@ucheba/utils/helpers/core'
import { noticeCookie } from '@ucheba/utils/constants/core'
import { coreSelectors } from '@ucheba/store/core'
import {
  animationDuration,
  notificationsActiveLimitDesktop,
  notificationsActiveLimitTouch,
} from './constants'
import { notificationsActions, notificationsSelectors } from './store'
import { ISetNoticeCookie, IUseNotice, IUseNoticeCore, IUseNoticeQueue } from './types'

export const setNoticeCookie: ISetNoticeCookie = (props) => {
  const { ctx, id } = props

  const parsedCookies = getParsedCookies(ctx)
  const noticeCookiesArray =
    parsedCookies[noticeCookie] && JSON.parse(parsedCookies[noticeCookie])
  let cookieToSet: string[] = []

  if (noticeCookiesArray?.length && !noticeCookiesArray.includes(id)) {
    cookieToSet = [...noticeCookiesArray, id]
  } else {
    cookieToSet.push(id)
  }

  setCookie(
    noticeCookie,
    JSON.stringify(cookieToSet),
    {
      domain: getCookieDomain(),
      httpOnly: false,
    },
    ctx
  )
}

export const removeNoticeCookie = (props: {
  ctx?: NextPageContext
  id: string
}): void => {
  const { ctx, id } = props || {}

  const parsedCookies = getParsedCookies(ctx)
  const noticeCookiesArray =
    parsedCookies[noticeCookie] && JSON.parse(parsedCookies[noticeCookie])
  let cookieToSet: string[] = []

  if (noticeCookiesArray?.length && noticeCookiesArray?.includes(id)) {
    cookieToSet = noticeCookiesArray.filter((el) => el !== id)
  }

  setCookie(
    noticeCookie,
    JSON.stringify(cookieToSet),
    {
      domain: getCookieDomain(),
      httpOnly: false,
    },
    ctx
  )
}

export const useNoticeQueue: IUseNoticeQueue = (props) => {
  const { id } = props
  const allActiveNotifications = useSelector(notificationsSelectors.activeNotifications)
  const allNotifications = useSelector(notificationsSelectors.notificationsQueue)
  const isTouch = useSelector(coreSelectors.isTouch)
  const dispatch = useDispatch()

  const activeNotificationsToAdd = useMemo(
    () =>
      allNotifications.slice(
        0,
        isTouch ? notificationsActiveLimitTouch : notificationsActiveLimitDesktop
      ),
    [allNotifications, isTouch]
  )

  const isCanAddToActive = useMemo(
    () =>
      allActiveNotifications.length <
      (isTouch ? notificationsActiveLimitTouch : notificationsActiveLimitDesktop),
    [allActiveNotifications.length, isTouch]
  )

  useEffect(() => {
    if (isCanAddToActive) {
      activeNotificationsToAdd.forEach((el) => {
        dispatch(notificationsActions.addNotificationToActiveById({ id: el.id }))
      })
    }
  }, [dispatch, activeNotificationsToAdd, isCanAddToActive])

  const open = useMemo(
    () => allActiveNotifications.some((el) => el.id === id),
    [allActiveNotifications, id]
  )

  return {
    open,
  }
}

/** Функции открытия/закрытия нотиса и статус (открыт/закрыт) */
export const useNotice: IUseNotice = (id, priority) => {
  const dispatch = useDispatch()

  const addNotice = useCallback(() => {
    dispatch(notificationsActions.addNotificationToQueueById({ id, priority }))

    setNoticeCookie({ id })
  }, [dispatch, id, priority])

  const removeNotice = useCallback(() => {
    dispatch(notificationsActions.removeNotificationFromQueueById(id))

    dispatch(notificationsActions.removeNotificationFromActiveById(id))

    removeNoticeCookie({ id })
  }, [dispatch, id])

  const { open } = useNoticeQueue({ id })

  return {
    addNotice,
    removeNotice,
    open,
  }
}

export const useNoticeCore: IUseNoticeCore = (props) => {
  const { preventAutoClose, duration, onOpen, onClose, open, id } = props

  const [rendering, setRendering] = useState(false)
  const [isOpen, toggleOpen] = useState(open || false)

  const dispatch = useDispatch()

  /** Эффект на вызов переданных в компонент коллбэков на открытие и закрытие */
  useEffect(() => {
    if (isOpen && onOpen) onOpen()
  }, [isOpen, onOpen])

  /** Добавление и удаление компонента из DOM на основе локального стейта */
  useEffect(() => {
    if (isOpen) {
      setRendering(true)
    } else if (rendering) {
      setTimeout(() => {
        setRendering(false)

        dispatch(notificationsActions.removeNotificationFromQueueById(id))
        dispatch(notificationsActions.removeNotificationFromActiveById(id))
      }, animationDuration.js)
    }
  }, [dispatch, id, isOpen, rendering])

  /** Эффект обновления локального стейта на статус открыт/закрыт */
  useEffect(() => {
    toggleOpen(open || false)
  }, [toggleOpen, open])

  /** Метод закрытия компонента.
   * Вызывает колбэк функцию переключения состояния компонента */
  const close = useCallback(() => {
    if (toggleOpen) {
      if (onClose) onClose()

      toggleOpen(false)
    }
  }, [onClose])

  /** Закрытие компонента по duration, если не передан preventAutoClose */
  useEffect(() => {
    if (!preventAutoClose && rendering) {
      setTimeout(() => {
        close()
      }, duration)
    }
  }, [rendering, close, duration, preventAutoClose])

  return {
    isOpen,
    rendering,
    close,
  }
}
