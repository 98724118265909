import apiThunk, { api } from '@ucheba/utils/helpers/api'
import { sliceName } from './constants'

const fetch = apiThunk(`${sliceName}/fetch`, async (params) => {
  const { id } = params.data

  const response = await api.get(`/professions/${id}`, params)

  return response.data
})

const fetchSimilar = apiThunk(`${sliceName}/fetchSimilar`, async (params) => {
  const { id } = params.data

  const response = await api.get(`/professions/${id}/similar`, params)

  return response.data
})

export default { fetch, fetchSimilar }
