import { useRouter } from 'next/router'

interface IUseFooterCore {
  (): {
    isSchool: boolean
  }
}

export const useFooterCore: IUseFooterCore = () => {
  const { pathname } = useRouter()
  const siteNames = [
    'for-abiturients/ege',
    'for-abiturients/oge',
    'for-abiturients/school',
    'for-abiturients/ege-10',
  ]
  const isSchool = siteNames.some((name) => pathname.includes(name))

  return {
    isSchool,
  }
}
