/* eslint-disable no-param-reassign */
import { createEntityAdapter, createSlice, EntityState } from '@reduxjs/toolkit'
import { IItemOfExams } from './types'
import { extraReducersResponse, responseInitialState } from '../utils/response'
import { sliceName } from './constants'
import thunks from './thunks'
import { IResponseInitialState } from '../utils/response/types'

export const examsAdapter = createEntityAdapter<IItemOfExams>()

export default createSlice({
  name: sliceName,

  initialState: examsAdapter.getInitialState<IResponseInitialState>(responseInitialState),

  reducers: {},

  extraReducers: (builder) => {
    extraReducersResponse<EntityState<IItemOfExams>, IItemOfExams[]>({
      builder,
      thunk: thunks.fetch,
      fulfilled: (state, action) => {
        examsAdapter.setAll(state, action.payload)
      },
    })
  },
})
