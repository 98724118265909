import React, { FC } from 'react'
import { Heading } from '@ucheba/ui/components/Heading/desktop'
import { Notice } from '@ucheba/ui/components/Notice/desktop'
import { useSelector } from 'react-redux'
import { coreSelectors } from '@ucheba/store/core'
import { useBannerNotice } from '../bll/useBannerNotice'

const BannersNotice: FC = () => {
  const { isActive, onClose } = useBannerNotice()
  const isTouch = useSelector(coreSelectors.isTouch)

  return (
    <Notice
      width={!isTouch ? '518px' : undefined}
      color='white'
      showClose={false}
      onClose={onClose}
      open={isActive}
      preventAutoClose={false}
    >
      <Heading h3='Заявка успешно отправлена' spacing='null' />
    </Notice>
  )
}

export default BannersNotice
