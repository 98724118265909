import { sliceName } from './constants'
import { getStateError, getStateItem } from '../../utils/core'
import { IStepFormsStepState } from './types'

export default {
  loading: getStateItem<
    IStepFormsStepState,
    IStepFormsStepState[typeof sliceName]['loading']
  >(sliceName, 'loading'),

  error: getStateError<IStepFormsStepState>(sliceName),
}
