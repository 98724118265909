import { ILocationState } from './types'
import { sliceName } from './constants'
import { getStateError, getStateItem } from '../utils/core'

export default {
  location: getStateItem<ILocationState, ILocationState[typeof sliceName]['location']>(
    sliceName,
    'location'
  ),

  locationByLocationIds: getStateItem<
    ILocationState,
    ILocationState[typeof sliceName]['location']
  >(sliceName, 'locationByLocationIds'),

  allRussiaLocation: getStateItem<
    ILocationState,
    ILocationState[typeof sliceName]['allRussiaLocation']
  >(sliceName, 'allRussiaLocation'),

  locations: getStateItem<ILocationState, ILocationState[typeof sliceName]['locations']>(
    sliceName,
    'locations'
  ),

  selectedLocations: getStateItem<
    ILocationState,
    ILocationState[typeof sliceName]['selectedLocations']
  >(sliceName, 'selectedLocations'),

  popularLocations: getStateItem<
    ILocationState,
    ILocationState[typeof sliceName]['popularLocations']
  >(sliceName, 'popularLocations'),

  loading: getStateItem<ILocationState, ILocationState[typeof sliceName]['loading']>(
    sliceName,
    'loading'
  ),

  error: getStateError<ILocationState>(sliceName),
}
