import { institutionItemSlice } from './index'
import { ITeacher } from '../teachers/types'
import { IPartnerEmployer } from '../partner-employers/types'

export interface IInstitutionState {
  [key: string]: ReturnType<typeof institutionItemSlice.reducer>
}

export enum EInstitutionType {
  University = 1,
  College = 2,
  School = 3,
  MBA = 4,
  TrainingCenter = 5,
  Kindergarten = 6,
  ForeignUniversity = 7,
  Agency = 8,
  ChildrenCourses = 9,
  TrainingCourses = 10,
}

export interface IQuestionById {
  order: null | number
  parentId: null | number
}

export interface IShortChancesOfAdmission {
  currentProbability: number | null
  countPrograms: number | null
}

export interface IInstitutionType {
  name: string
  value: EInstitutionType
}

export interface IInstitutionFeedbackStats {
  total: number
  score: number | null
}

export enum EInstitutionParentType {
  Faculty = 1,
  Branch = 2,
  Other = 3,
}

export interface IInstitutionParent {
  id: number
  name: string
  abbreviation: string | null
  type: {
    name: string
    value: EInstitutionType
  }
}

export interface IInstitutionSummary {
  averageDiplomaAvgScore: number | null
  averageBySubjectExamScore: number | null
  budgetPlacesCount: number | null
  averagePriceBachelor: number | null
  averagePriceMaster: number | null
  averagePrice: number | null
  countStudents: number | null
  teachersPerHundredStudents: number | null
  dormitoryCapacity: number | null
  foreignStudentsProportion: number | null
}

export interface IInstitutionRankingData {
  ranking: {
    id: number
    name: string
    description: string | null
    url: string | null
    logo: IImage | null
  }
  rankFrom: number | null
  rankTo: number | null
  worldRankFrom: number | null
  worldRankTo: number | null
  isOutOfRank: boolean
  isOutOfWorldRank: boolean
}

export interface IInstitutionRankingYear {
  id: number
  name: string
  description: string | null
  url: string | null
  logo: IImage | null
}

export interface IInstitutionRanking {
  id: number
  rankingYear: {
    id: number
    ranking: IInstitutionRankingYear
    year: number
  }
  rankingDataList: IInstitutionRankingData[]
}

export type PaidPackageLevel = 'base' | 'standart' | 'premium'

export interface IPaidPackage {
  id: number
  title: string
  packageLevel: {
    name: string
    value: PaidPackageLevel
  }
}

export interface IInstitutionAdvantage {
  id: number
  content: string | null
  title: string
  factType: {
    id: number
    title: string
    color: string
    iconUrl: string | null
  }
}

export interface IInstitutionCampusAddress {
  id: number
  address: string
  description: string
  addressPoint: {
    latitude: number
    longitude: number
  }
}

export interface IInstitutionCampus {
  id: number
  description: string | null
  type: {
    id: number
    name: string
    color: string
  }
  objects: IInstitutionCampusAddress[]
}

export interface IInstitutionEgeForAdmission {
  subjects: Array<{
    id: number
    name: string
    slug: string | null
    iconUrl: string | null
  }>
  programCount: number | null
}

export interface IInstitutionMembers {
  id: number
  person: {
    id: number
    fullName: string | null
    description: string | null
    avatarUrl: string | null
    avatarThumbnailUrl: string | null
  }
  position: string | null
  comment: string | null
}

export interface IInstitutionPartnerCompanie {
  id: number
  description: string | null
  company: {
    id: number
    name: string
    description: string | null
    logo: IImageLinks
    externalLink: string | null
  }
}

export interface IInstitutionGraduateJob {
  id: number
  name: string
  description: string | null
  logo: IImage
  externalLink: string | null
}

// Вынести в общие типы
export enum EInstitutionMediaType {
  photo = 1,
  youtubeVideo = 10,
  rutubeVideo = 11,
  vimeoVideo = 12,
  vkVideo = 13,
}

export interface IImage {
  fallbackUrl: string
  webp: string
}

export interface IInstitutionMediaListItem {
  id: number
  fileUrl: string | null
  thumbnail: string | null
  mediaType: {
    value: EInstitutionMediaType
    name: string
  }
  isOfficialPromo: boolean
  description: string | null
  thumbnailUrl: string
  image: {
    fullUrl: IImage
    thumbMin: IImage
    thumbMid: IImage
    thumbBig: IImage
  } | null
}

export interface IInstitutionOfficialRepresentative {
  id: number
  fullName: string | null
  description: string | null
  avatar: {
    avatarFull?: {
      fallbackUrl: string | null
      webp: string | null
    } | null
    thumb?: {
      fallbackUrl: string | null
      webp: string | null
    } | null
  } | null
}

// Вынести в общие типы
export enum EInstitutionSchoolType {
  SecondarySchool = 1,
  SpecialSchool = 2,
  Gymnasium = 3,
  Lyceum = 4,
  EducationCentre = 5,
  Progymnasium = 6,
  CadetSchool = 7,
  DisabledChildrenSchool = 8,
  ArtSchool = 9,
  MusicSchool = 10,
  DrawingSchool = 11,
  ChoreographicSchool = 12,
  SportsSchool = 13,
  EthnicSchool = 14,
}

export enum EInstitutionSchoolStatus {
  StateInstitution = 1,
  StateFreeInstitution = 2,
  NonStateInstitution = 3,
  NonStateFreeInstitution = 4,
  NonStateFreeAutonomousInstitution = 5,
  EducationCentre = 6,
  Lyceum = 7,
  AutonomousFreeInstitution = 8,
  PrivateInstitution = 9,
  NonProfitPartnership = 10,
}

export interface IInstitutionSchoolInfo {
  id: number
  schoolType: {
    name: string
    value: EInstitutionSchoolType
  }
  price: number | null
  isFree: boolean | null
  admissionConditions: string | null
  otherSubject: string | null
  electives: string | null
  otherSection: string | null
  openingTimeFrom: string | null
  openingTimeTo: string | null
  schoolStatus: EInstitutionSchoolStatus
  classesFrom: number | null
  classesTo: number | null
  egeResultsDescription: string | null
  profileElementary: string | null
  universityProfileClasses: string | null
  additionalInformation: string | null
  egeMore160: number | null
  egeMore220: number | null
}

export interface IInstitutionAbroadInfo {
  id: number
  priceFrom: number | null
  priceTo: number | null
  admissionConditions: string | null
  learningConditions: string | null
  abroadTypes?: ITag[]
}

export interface ITag {
  id: number
  name: string | null
  slug: string | null
}

export interface ILink {
  title: string
  url: string
}

interface INewAuthor {
  avatarThumbnailUrl: string
  avatarUrl: string
  email: string
  fullName: string
  id: number
}

export interface INew {
  author: INewAuthor
  content: string
  dateCreated: string
  title: string
  id: number
  institutionId: number
}

export interface INews {
  items: INew[]
  total: number
}

export interface IVoteStats {
  usefulCount: number
  notUsefulCount: number
}

export interface IAdmissionInstruction {
  title: string | null
  text: string | null
  documentLink: string | null
}

export interface IPhone {
  description: string
  phone: string
}

export interface IPoint {
  latitude: number
  longitude: number
}

export interface ILocation {
  id: number
  name: string
  slug: string
  namePrepositional: string | null
  nameGenitive: string | null
  locationTypeId: number
}

export interface IAddresses {
  id: number
  location: ILocation
  name: string
  phones: IPhone[]

  point: IPoint
}

export interface ISocialAccounts {
  type: {
    value: string
    name: string
  }
  url: string
}

export interface IImageLinks {
  fallbackUrl: string
  webp: string
}

export interface IInstitutionItem {
  additionalBrandingTags: string[]
  hasArticles: boolean
  hasNews: boolean
  hasOlympiads: boolean
  hasPrograms: boolean
  hasSubdivisions: boolean
  id: number
  name: string
  keywords: string
  addresses: IAddresses[]
  webSite: string
  email: string
  socialAccounts: ISocialAccounts[]
  type: {
    name: string
    value: EInstitutionType
  }
  admissionInstruction: IAdmissionInstruction
  isState: boolean
  abbreviation: string | null
  description: string | null
  logo: IImageLinks
  feedbackStats: IInstitutionFeedbackStats | null
  parentType: {
    value: EInstitutionParentType
    name: string
  } | null
  parent: IInstitutionParent | null
  currentAverageSummary: IInstitutionSummary | null
  averageSummary: IInstitutionSummary | null
  maximumSummary: IInstitutionSummary | null
  rankings: IInstitutionRanking[]
  paidPackage: IPaidPackage
  advantages: IInstitutionAdvantage[]
  campus: IInstitutionCampus[]
  descriptionCampus: string | null
  egeForAdmission: IInstitutionEgeForAdmission[]
  members: ITeacher[]
  partnerCompanies: IPartnerEmployer[]
  companiesGraduateJobList: IInstitutionGraduateJob[]
  mediaList: IInstitutionMediaListItem[]
  campusMediaList: IInstitutionMediaListItem[]
  location: {
    id: number
    name: string
  } | null
  officialRepresentative: IInstitutionOfficialRepresentative | null
  foundYear: number | null
  license: number | null
  dateLicense: Record<string, unknown> | null
  accreditation: string | null
  dateAccreditation: Record<string, unknown> | null
  schoolInfo: IInstitutionSchoolInfo | null
  abroadInfo: IInstitutionAbroadInfo | null
  isMilitaryChair: boolean | null
  hasHostel: boolean | null
  tags: ITag[] | null
  seoLinks: ILink[]
  domain: {
    id: number
    name: string
    slug: string
  }
}
