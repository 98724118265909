/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import { sliceName } from './constants'

export default createSlice({
  name: sliceName,

  initialState: {
    activeDialogs: [] as string[],
  },

  reducers: {
    addDialogById: (state, { payload }): void => {
      if (!state.activeDialogs.some((dialogId) => dialogId === payload)) {
        // state.activeDialogs = [...state.activeDialogs, payload]
        state.activeDialogs.push(payload)
      }
    },
    removeDialogById: (state, { payload }): void => {
      state.activeDialogs = state.activeDialogs.filter((el) => el !== payload)
    },
  },
})
