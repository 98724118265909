import { createSlice } from '@reduxjs/toolkit'
import { sliceName } from './constants'
import { extraReducersResponse, responseInitialState } from '../../../utils/response'
import { IProfMotivationTestPayment, IProfMotivationTestPaymentState } from './types'
import thunks from './thunks'

export default createSlice({
  name: sliceName,

  initialState: {
    confirmationUrl: null as IProfMotivationTestPayment['confirmationUrl'] | null,
    hasPhone: null as IProfMotivationTestPayment['hasPhone'] | null,
    ...responseInitialState,
  },

  reducers: {},

  extraReducers: (builder) => {
    extraReducersResponse<
      IProfMotivationTestPaymentState[typeof sliceName],
      IProfMotivationTestPayment
    >({
      builder,
      thunk: thunks.pay,
      fulfilled: (state, action) => {
        // eslint-disable-next-line no-param-reassign
        state.confirmationUrl = action.payload.confirmationUrl

        // eslint-disable-next-line no-param-reassign
        state.hasPhone = action.payload.hasPhone
      },
    })
  },
})
