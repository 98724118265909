import styled from 'styled-components'

import { sm } from '@ucheba/utils/helpers/styles/mixins'
import { sv } from '@ucheba/utils/helpers/styles/variables'

import SC from '../base/Header.styles'

import { headerHeight } from '../constants'

const { Block: BlockSC, MainContainer: MainContainerSC } = SC

const AuthLink = styled.a`
  ${sm.linkColor.inherit};
`

const MainLineLeft = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  column-gap: ${sv.spacing.xsmall};
`

const MainLineRight = styled(MainLineLeft)`
  column-gap: 20px;
`

const MainContainer = styled(MainContainerSC)`
  padding: ${sv.spacing.medium} 16px;
`

const Block = styled(BlockSC)`
  height: ${headerHeight.desktop}px;
`

export default {
  ...SC,
  Block,
  MainContainer,
  AuthLink,
  MainLineLeft,
  MainLineRight,
}
