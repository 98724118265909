import { IProfileFavoritesState } from './types'
import { sliceName } from './constants'
import { getStateError, getStateItem } from '../../utils/core'
import { profileFavoritesAdaptor } from './slice'

const adapterSelectors = profileFavoritesAdaptor.getSelectors<IProfileFavoritesState>(
  (state) => state[sliceName]
)

export default {
  ...adapterSelectors,

  loading: getStateItem<
    IProfileFavoritesState,
    IProfileFavoritesState[typeof sliceName]['loading']
  >(sliceName, 'loading'),

  error: getStateError<IProfileFavoritesState>(sliceName),
}
