/** Основные query-параметры */
export const coreQuery = {
  /** Урл откуда пришел пользователь и куда его вернем после определенных действий */
  redirect: 'redirect',

  /** Альтернативный урл откуда пришел пользователь? */
  referer: 'referer',

  /* Номер страницы */
  page: 'page',
}
