import { sliceName } from './constants'
import { getStateItem } from '../utils/core'
import { IUserDataState } from './types'

export default {
  entity: getStateItem<IUserDataState, IUserDataState[typeof sliceName]['entity']>(
    sliceName,
    'entity'
  ),
}
