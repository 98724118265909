import { sliceName } from './constants'
import { IPageDataHeaderOffersState } from './types'
import { getStateItem } from '../../../utils/core'

export default {
  entity: getStateItem<
    IPageDataHeaderOffersState,
    IPageDataHeaderOffersState[typeof sliceName]['entity']
  >(sliceName, 'entity'),
}
