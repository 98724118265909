import { getStateItem } from '@ucheba/store/utils/core'
import { IDialogState } from './types'
import { sliceName } from './constants'

export default {
  activeDialogs: getStateItem<
    IDialogState,
    IDialogState[typeof sliceName]['activeDialogs']
  >(sliceName, 'activeDialogs'),
}
