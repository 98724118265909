import { sliceName } from './constants'
import { getStateError, getStateItem } from '../../../utils/core'
import { IClientProductsRequestsPaymentState } from './types'

export default {
  confirmationUrl: getStateItem<
    IClientProductsRequestsPaymentState,
    IClientProductsRequestsPaymentState[typeof sliceName]['confirmationUrl']
  >(sliceName, 'confirmationUrl'),

  hasPhone: getStateItem<
    IClientProductsRequestsPaymentState,
    IClientProductsRequestsPaymentState[typeof sliceName]['hasPhone']
  >(sliceName, 'hasPhone'),

  loading: getStateItem<
    IClientProductsRequestsPaymentState,
    IClientProductsRequestsPaymentState[typeof sliceName]['loading']
  >(sliceName, 'loading'),

  error: getStateError<IClientProductsRequestsPaymentState>(sliceName),
}
