import {
  IInstitutionItem,
  IInstitutionState,
  IQuestionById,
  IShortChancesOfAdmission,
} from './types'
import { sliceName } from './constants'
import { getStateItem } from '../../utils/core'

export default {
  currentInstitution: getStateItem<IInstitutionItem, IInstitutionItem>(
    sliceName,
    'currentInstitution'
  ),

  questionById: getStateItem<IQuestionById, IQuestionById>(sliceName, 'questionById'),

  shortChancesOfAdmission: getStateItem<
    IShortChancesOfAdmission,
    IShortChancesOfAdmission
  >(sliceName, 'shortChancesOfAdmission'),

  loading: getStateItem<
    IInstitutionState,
    IInstitutionState[typeof sliceName]['loading']
  >(sliceName, 'loading'),
}
