import { sliceName } from './constants'
import { getStateError, getStateItem } from '../../utils/core'
import { IEventsIdState } from './types'

export default {
  entity: getStateItem<IEventsIdState, IEventsIdState[typeof sliceName]['entity']>(
    sliceName,
    'entity'
  ),

  loading: getStateItem<IEventsIdState, IEventsIdState[typeof sliceName]['loading']>(
    sliceName,
    'loading'
  ),

  registered: getStateItem<
    IEventsIdState,
    IEventsIdState[typeof sliceName]['registered']
  >(sliceName, 'registered'),

  error: getStateError<IEventsIdState>(sliceName),
}
