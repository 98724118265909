import apiThunk, { api } from '@ucheba/utils/helpers/api'
import { sliceName } from './constsnts'

const fetch = apiThunk(`${sliceName}/fetch`, async (params) => {
  const { id } = params.data

  const response = await api.get(`/motivation-test/results/${id}`, params)

  return response.data
})

const getResponses = apiThunk(`${sliceName}/getResponses`, async (params) => {
  const { id } = params.data

  const response = await api.get(`/motivation-test/results/${id}/responses`, params)

  return response.data
})

export default { fetch, getResponses }
