import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'
import { useKeyPress } from '@ucheba/utils/hooks/useKeyPress'
import { useDivInBody } from '@ucheba/utils/hooks/useDivInBody'
import { IUseDialog, IUseDialogCore } from './types'
import { dialogActions, dialogSelectors } from './store'

/** Функции открытия/закрытия диалогового окна и статус (открыт/закрыт) */
export const useDialog: IUseDialog = (id) => {
  const dispatch = useDispatch()
  const allActiveDialogs = useSelector(dialogSelectors.activeDialogs)

  const openDialog = useCallback(() => {
    dispatch(dialogActions.addDialogById(id))
  }, [dispatch, id])

  const isActive = useMemo(() => {
    return allActiveDialogs.includes(id)
  }, [allActiveDialogs, id])

  const closeDialog = useCallback(() => {
    dispatch(dialogActions.removeDialogById(id))
  }, [dispatch, id])

  return {
    openDialog,
    isActive,
    closeDialog,
  }
}

const timeout = 150

export const useDialogCore: IUseDialogCore = (props) => {
  const { onOpen, onClose, open } = props
  const isPressed = useKeyPress('Escape')

  const dialogElement = useDivInBody('dialog-element')
  const ref = useRef(null)
  const [isOpen, setIsOpen] = useState(open || false)
  const [rendering, setRendering] = useState(false)

  /** Эффект на вызов переданных в компонент коллбэков на открытие и закрытие */
  useEffect(() => {
    if (isOpen) {
      if (onOpen) onOpen()
    } else if (onClose) onClose()
  }, [isOpen, onClose, onOpen])

  /** Эффект обновления локального стейта на статус открыт/закрыт */
  useEffect(() => {
    setIsOpen(open || false)
  }, [setIsOpen, open])

  /** Эффект закрытия диалогового окна при нажатии на кнопку */
  useEffect(() => {
    if (isPressed) setIsOpen(false)
  }, [setIsOpen, isPressed])

  /** Когда компонент открывается, останавливаем скролл.
   * Когда он уничтожается, удаляем блокировку скролла */
  useEffect(() => {
    if (open) {
      // setTimeout(() => {
      //   document.documentElement.style.overflowY = 'hidden'
      //
      //   disableBodyScroll(ref.current)
      // }, 0)
      setTimeout(() => disableBodyScroll(ref.current), 0)
    }

    return (): void => {
      const openedDialogs = dialogElement?.childNodes && dialogElement?.childNodes.length

      if (!openedDialogs) {
        // document.documentElement.style.overflowY = 'auto'

        clearAllBodyScrollLocks()
      }
    }
    // здесь у тебя в зависимостях нет rendering, но у меня без этого не срабатывает clearAllBodyScrollLocks()
  }, [open, dialogElement, rendering])

  /** Сбрасываем статус процесса закрытия
   * при каждом открытии/закрытии компонента */
  useEffect(() => {
    if (open) {
      setIsOpen(true)
      setRendering(true)
    } else {
      setIsOpen(false)

      setTimeout(() => {
        setIsOpen(true)
        setRendering(false)
      }, timeout)
    }
  }, [open, setIsOpen])

  /** Метод закрытия компонента.
   * Вызывает колбэк функцию переключения состояния компонента */
  const close = useCallback(() => {
    if (setIsOpen) setIsOpen(false)
  }, [setIsOpen])

  /** Когда происходит процесс закрытия, закрываем компонент
   * после задержки, чтобы успелы пройти анимация */
  useEffect(() => {
    if (!isOpen) setTimeout(() => close(), timeout)
  }, [close, isOpen])

  return {
    rendering,
    close,
    isOpen,
    ref,
    dialogElement,
  }
}
