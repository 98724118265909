import { createAsyncThunk } from '@reduxjs/toolkit'
import apiThunk, { api } from '@ucheba/utils/helpers/api'
import { sliceName } from './constants'

const getPageData = apiThunk(`${sliceName}/fetch`, async (params) => {
  const response = await api.get(`/page_data/prof`, params)

  return response.data
})

const request = createAsyncThunk(`${sliceName}/request`, async (params) => {
  const requestData = {
    ...params,
    source: 'landing',
    package: params.package || undefined,
  }

  return fetch(`/proforientation/request/send`, {
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      accept: 'application/json, text/javascript, */*; q=0.01',
      'accept-language': 'ru-RU,ru;q=0.9,en-US;q=0.8,en;q=0.7',
      'cache-control': 'no-cache',
      'sec-fetch-dest': 'empty',
      'sec-fetch-mode': 'cors',
      'sec-fetch-site': 'same-origin',
      'x-requested-with': 'XMLHttpRequest',
    },
    referrer: 'https://www.ucheba.ru/project/prof',
    referrerPolicy: 'strict-origin-when-cross-origin',
    body: JSON.stringify(requestData),
    method: 'POST',
    mode: 'cors',
  }).then((res) => {
    return res.json()
  })
})

const addParent = createAsyncThunk(`${sliceName}/addParent`, async (data) => {
  return fetch(`/proforientation/request/add_parent`, {
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      accept: 'application/json, text/javascript, */*; q=0.01',
      'accept-language': 'ru-RU,ru;q=0.9,en-US;q=0.8,en;q=0.7',
      'cache-control': 'no-cache',
      'sec-fetch-dest': 'empty',
      'sec-fetch-mode': 'cors',
      'sec-fetch-site': 'same-origin',
      'x-requested-with': 'XMLHttpRequest',
    },
    referrer: 'https://www.ucheba.ru/project/prof',
    referrerPolicy: 'strict-origin-when-cross-origin',
    body: JSON.stringify(data),
    method: 'POST',
    mode: 'cors',
  }).then((res) => {
    return res.json()
  })
})

export default { request, addParent, getPageData }
