import { createSlice } from '@reduxjs/toolkit'
import { sliceName } from './constants'
import { extraReducersResponse, responseInitialState } from '../utils/response'
import { IProbabilities, IProbabilitiesCheck, IProbabilitiesState } from './types'
import thunks from './thunks'

export default createSlice({
  name: sliceName,

  initialState: {
    currentProbability: null as IProbabilities['currentProbability'] | null,
    institution: null as IProbabilities['institution'] | null,
    programs: null as IProbabilities['programs'] | null,
    anotherPrograms: null as IProbabilities['anotherPrograms'] | null,
    countPrograms: null as IProbabilitiesCheck['countPrograms'] | null,
    ...responseInitialState,
  },

  reducers: {},

  extraReducers: (builder) => {
    extraReducersResponse<IProbabilitiesState[typeof sliceName], IProbabilities>({
      builder,
      thunk: thunks.post,
      fulfilled: (state, action) => {
        // eslint-disable-next-line no-param-reassign
        state.currentProbability = action.payload?.currentProbability
        // eslint-disable-next-line no-param-reassign
        state.institution = action.payload?.institution
        // eslint-disable-next-line no-param-reassign
        state.programs = action.payload?.programs || null
        // eslint-disable-next-line no-param-reassign
        state.anotherPrograms = action.payload?.anotherPrograms || null
      },
    })

    extraReducersResponse<IProbabilitiesState[typeof sliceName], IProbabilitiesCheck>({
      builder,
      thunk: thunks.check,
      fulfilled: (state, action) => {
        // eslint-disable-next-line no-param-reassign
        state.currentProbability = action.payload?.currentProbability
        // eslint-disable-next-line no-param-reassign
        state.countPrograms = action.payload?.countPrograms
      },
    })
  },
})
