import React from 'react'
import { api } from '../helpers/api'

export default (error: Error | string, errorInfo: React.ErrorInfo | null): void => {
  api.post('/internal/client/error/log', {
    page: window.location.href,
    text: error.toString(),
    data: errorInfo,
  })
}
