import { createSlice } from '@reduxjs/toolkit'
import { sliceName } from './constants'
import { IEventsSimilar, IEventsSimilarState } from './types'
import { extraReducersResponse, responseInitialState } from '../../utils/response'
import thunks from './thunks'

export default createSlice({
  name: sliceName,

  initialState: {
    entity: null as IEventsSimilar | null,
    ...responseInitialState,
  },

  reducers: {},

  extraReducers: (builder) => {
    extraReducersResponse<IEventsSimilarState[typeof sliceName], IEventsSimilar>({
      builder,
      thunk: thunks.fetch,
      fulfilled: (state, action) => {
        // eslint-disable-next-line no-param-reassign
        state.entity = action.payload
      },
    })
  },
})
