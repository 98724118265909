import React, { FC } from 'react'
import { Notice } from '@ucheba/ui/components/Notice/touch'
import { Text } from '@ucheba/ui/components/Text/touch'
import { Spacing } from '@ucheba/ui/components/Spacing/touch'
import { Button } from '@ucheba/ui/components/Button/touch'
import { ButtonGroup } from '@ucheba/ui/components/ButtonGroup/touch'
import styled from 'styled-components'
import { defineLocationNotice } from '@ucheba/utils/middlewares/defineLocationMiddleware'
import { IDefineLocationNoticeProps } from '../types'
import { useDefineLocationNotice } from '../bll'

const StyledButton = styled.div`
  width: max-content;
`

/** Нотис с определением геолокации */
const DefineLocationNotice: FC<IDefineLocationNoticeProps> = () => {
  const { title, onClickAcceptHandler, onClickDeclineHandler, notice } =
    useDefineLocationNotice()

  return (
    <Notice hideProgress open={notice.open} showClose={false}>
      <Text fontWeight='bold'>{title}</Text>

      <Spacing spacing='xxsmall' />

      <ButtonGroup orientation='vertical' spacing='usmall'>
        <StyledButton
          size='small'
          as={Button}
          onClick={onClickAcceptHandler}
          color='blue'
        >
          {defineLocationNotice.buttons.accept.buttonText}
        </StyledButton>

        <StyledButton
          as={Button}
          onClick={onClickDeclineHandler}
          color='blue'
          view='link'
          size='small'
        >
          {defineLocationNotice.buttons.decline.buttonText}
        </StyledButton>
      </ButtonGroup>
    </Notice>
  )
}

export default DefineLocationNotice
