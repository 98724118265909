import apiThunk, { api } from '@ucheba/utils/helpers/api'
import { getFullRouterPath, objectToQueryString } from '@ucheba/utils/helpers/router'
import { sliceName } from './constants'

const fetchInstitution = apiThunk(`${sliceName}/fetch`, async (params) => {
  const response = await api.get(`/institutions/${params?.data?.id}`, params)
  return response.data
})

const fetchQuestionById = apiThunk(`${sliceName}/fetchQuestionById`, async (params) => {
  const { data = {} } = params

  const { questionId, type, id } = data

  delete data.type

  delete data.feedbackId

  delete data.id
  const response = await api.get(
    getFullRouterPath(
      `/questions/${questionId}/order/${type}/${id}`,
      objectToQueryString(data)
    ),
    { ...params, data }
  )
  return response.data
})

const getAllInstitutions = apiThunk(`${sliceName}/getAllInstitutions`, async (params) => {
  const response = await api.get(`/institutions`, params)
  return response.data
})

const fetchShortChancesOfAdmission = apiThunk(
  `${sliceName}/shortChancesOfAdmission`,
  async (params) => {
    const response = await api.get(
      `/probabilities/${params.data.type}/${params.data.referId}/check`,
      params
    )

    return response.data
  }
)

export default {
  fetchInstitution,
  getAllInstitutions,
  fetchShortChancesOfAdmission,
  fetchQuestionById,
}
