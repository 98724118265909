import React, { FC } from 'react'
// import FbCircleIcon from '@ucheba/ui/icons/icon__fb_circle.svg'
import VkCircleIcon from '@ucheba/ui/icons/icon__vk_circle.svg'
import YouTubeIcon from '@ucheba/ui/icons/icon__youtube-filled.svg'
import TgIcon from '@ucheba/ui/icons/icon__telegram-filled.svg'

export const FooterSocialLinks: FC = () => (
  <>
    <a
      target='_blank'
      rel='nofollow noreferrer'
      href='https://vk.com/ucheba_rus'
      aria-label='Вконтакте'
    >
      <VkCircleIcon />
    </a>

    {/* Временно отключаю иконку facebook */}
    {/* <a */}
    {/*  target='_blank' */}
    {/*  rel='nofollow noreferrer' */}
    {/*  href='https://www.facebook.com/ucheba.ru' */}
    {/*  aria-label='Facebook' */}
    {/* > */}
    {/*  <FbCircleIcon /> */}
    {/* </a> */}

    <a
      target='_blank'
      rel='nofollow noreferrer'
      href='https://t.me/ucheba_bot'
      aria-label='Telegram'
    >
      <TgIcon />
    </a>

    <a
      target='_blank'
      rel='nofollow noreferrer'
      href='https://www.youtube.com/@UchebaRu'
      aria-label='Youtube'
    >
      <YouTubeIcon />
    </a>
  </>
)
