import React, { FC, memo } from 'react'
import { IPropOnClick } from '@ucheba/utils/helpers/components/prop.types'
import styled, { StyledProps } from 'styled-components'
import { EModColor } from '@ucheba/utils/helpers/styles/mod.types'
import { sm } from '@ucheba/utils/helpers/styles/mixins'
import { ETransparentColor, sv } from '@ucheba/utils/helpers/styles/variables'
import Link from 'next/link'
import ArrowRight from '../../../icons/icon__arrow-right_circle-outline.svg'
import ArrowLeft from '../../../icons/icon__arrow-left_circle-outline.svg'
import SC from '../base/Navigation.styles'
import { INavigationProps } from '../types'
import { useNavigationCore, useOnClickItem } from '../bll'
import { Button } from '../../Button/desktop'
import { IButtonProps } from '../../Button/types'

const { Block, List, Item, A } = SC

const NavigationButton = styled.div<StyledProps<IButtonProps>>`
  position: absolute;
  z-index: 1;
  width: 64px;
  height: 100%;
  display: flex;
`

const LeftButton = styled(NavigationButton)`
  left: 0;
  justify-content: flex-start;

  ${Object.keys(EModColor).map(
    (item) => sm.mod(EModColor[item])`
      background: linear-gradient(90deg,
      ${sv.color[item]} 0%,
      ${sv.color[item]} 50%,
      ${ETransparentColor[item]} 100%);
    `
  )};

  ${Object.keys(EModColor).map(
    (item) => sm.mod(EModColor[item])`
      &:hover {
        background: linear-gradient(90deg,
        ${sv.color[item]} 0%,
        ${sv.color[item]} 50%,
        ${ETransparentColor[item]} 100%);
      }
    `
  )};

}
`

const RightButton = styled(NavigationButton)`
  right: 0;
  justify-content: flex-end;

  ${Object.keys(EModColor).map(
    (item) => sm.mod(EModColor[item])`
      background: linear-gradient(270deg,
      ${sv.color[item]} 0%,
      ${sv.color[item]} 50%,
      ${ETransparentColor[item]} 100%);
    `
  )};

  ${Object.keys(EModColor).map(
    (item) => sm.mod(EModColor[item])`
      &:hover {
        background: linear-gradient(270deg,
        ${sv.color[item]} 0%,
        ${sv.color[item]} 50%,
        ${ETransparentColor[item]} 100%);
      }
    `
  )};
`

/** Навигация */
const Navigation: FC<INavigationProps> = (props) => {
  const {
    reload = false,
    activeIndex,
    fade = 'white',
    width = 'auto',
    color = 'black',
    items,
    onClick,
    withRichSnippet = false,
    ...otherProps
  } = props

  const onClickItemHook = useOnClickItem(onClick)

  const onClickItem =
    (slug): IPropOnClick['onClick'] =>
    (event) =>
      onClickItemHook(event, slug)

  const {
    wrapperRef,
    menuRef,
    isFadeLeft,
    isFadeRight,
    isFadeBoth,
    isFadeExist,
    leftButtonHandler,
    rightButtonHandler,
  } = useNavigationCore()

  return (
    <Block width={width} isFadeExist={isFadeExist} ref={wrapperRef} {...otherProps}>
      {(isFadeLeft || isFadeBoth) && (
        <LeftButton
          onClick={leftButtonHandler}
          color={fade}
          as={Button}
          icon
          size='xxsmall'
        >
          <ArrowLeft width='24px' height='24px' color={color} />
        </LeftButton>
      )}
      <List
        ref={menuRef as any}
        itemType={
          withRichSnippet ? 'https://schema.org/SiteNavigationElement' : undefined
        }
      >
        {items.map(({ name, href, slug }, index) => (
          <Item key={index} active={activeIndex === index} color={color}>
            {href &&
              (reload ? (
                <A
                  itemProp={withRichSnippet ? 'url' : undefined}
                  href={href}
                  active={activeIndex === index}
                  color={color}
                >
                  <Button size='xsmall'>{name}</Button>
                </A>
              ) : (
                <Button
                  size='xsmall'
                  conditionalWrapper={(child) => (
                    <A
                      as={Link}
                      href={href}
                      itemProp={withRichSnippet ? 'url' : undefined}
                      active={activeIndex === index}
                      color={color}
                    >
                      {child}
                    </A>
                  )}
                >
                  {name}
                </Button>
              ))}

            {onClick && slug && (
              <A
                as='div'
                itemProp={withRichSnippet ? 'url' : undefined}
                active={activeIndex === index}
                color={color}
              >
                <Button size='xsmall' onClick={onClickItem(slug)}>
                  {name}
                </Button>
              </A>
            )}
          </Item>
        ))}
      </List>

      {(isFadeRight || isFadeBoth) && (
        <RightButton
          onClick={rightButtonHandler}
          color={fade}
          as={Button}
          icon
          size='xxsmall'
        >
          <ArrowRight width='24px' height='24px' color={color} />
        </RightButton>
      )}
    </Block>
  )
}

export { Navigation }
export default memo(Navigation)
