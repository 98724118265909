import { createSlice } from '@reduxjs/toolkit'
import { sliceName } from './constants'
import { extraReducersResponse, responseInitialState } from '../../utils/response'
import {
  IInstitutionsPreparatory,
  IInstitutionsPrograms,
  IInstitutionsProgramsCourse,
  IInstitutionsProgramsHeight,
  IInstitutionsProgramsMaster,
  IInstitutionsProgramsMBA,
  IInstitutionsProgramsPostgraduate,
  IInstitutionsProgramsSchool,
  IInstitutionsProgramsState,
  IInstitutionsProgramsVocational,
} from './types'
import thunks from './thunks'

export default createSlice({
  name: sliceName,

  initialState: {
    entity: null as IInstitutionsPrograms | null,
    programsHigher: null as IInstitutionsProgramsHeight | null,
    programsVocational: null as IInstitutionsProgramsVocational | null,
    programsMaster: null as IInstitutionsProgramsMaster | null,
    programsPostgraduate: null as IInstitutionsProgramsPostgraduate | null,
    programsCourse: null as IInstitutionsProgramsMBA | null,
    programsMBA: null as IInstitutionsProgramsCourse | null,
    programsSchool: null as IInstitutionsProgramsSchool | null,
    preparatory: null as IInstitutionsPreparatory | null,
    queryString: '',
    priceFrom: null as number | null,
    priceTo: null as number | null,
    ...responseInitialState,
  },

  reducers: {
    setQueryString: (state, action): void => {
      // eslint-disable-next-line no-param-reassign
      state.queryString = action.payload
    },

    setPrice: (state, action): void => {
      // eslint-disable-next-line no-param-reassign
      state.priceTo = action.payload.priceTo
      // eslint-disable-next-line no-param-reassign
      state.priceFrom = action.payload.priceFrom
    },
  },

  extraReducers: (builder) => {
    extraReducersResponse<
      IInstitutionsProgramsState[typeof sliceName],
      IInstitutionsPrograms
    >({
      builder,
      thunk: thunks.fetch,
      fulfilled: (state, { payload }) => {
        // eslint-disable-next-line no-param-reassign
        state.entity = payload
      },
    })

    extraReducersResponse<
      IInstitutionsProgramsState[typeof sliceName],
      IInstitutionsProgramsHeight
    >({
      builder,
      thunk: thunks.fetchProgramsHigher,
      fulfilled: (state, { payload }) => {
        // eslint-disable-next-line no-param-reassign
        state.programsHigher = payload
      },
    })

    extraReducersResponse<
      IInstitutionsProgramsState[typeof sliceName],
      IInstitutionsProgramsVocational
    >({
      builder,
      thunk: thunks.fetchProgramsVocational,
      fulfilled: (state, { payload }) => {
        // eslint-disable-next-line no-param-reassign
        state.programsVocational = payload
      },
    })

    extraReducersResponse<
      IInstitutionsProgramsState[typeof sliceName],
      IInstitutionsProgramsMaster
    >({
      builder,
      thunk: thunks.fetchProgramsMaster,
      fulfilled: (state, { payload }) => {
        // eslint-disable-next-line no-param-reassign
        state.programsMaster = payload
      },
    })

    extraReducersResponse<
      IInstitutionsProgramsState[typeof sliceName],
      IInstitutionsProgramsPostgraduate
    >({
      builder,
      thunk: thunks.fetchProgramsPostgraduate,
      fulfilled: (state, { payload }) => {
        // eslint-disable-next-line no-param-reassign
        state.programsPostgraduate = payload
      },
    })

    extraReducersResponse<
      IInstitutionsProgramsState[typeof sliceName],
      IInstitutionsProgramsCourse
    >({
      builder,
      thunk: thunks.fetchProgramsCourse,
      fulfilled: (state, { payload }) => {
        // eslint-disable-next-line no-param-reassign
        state.programsCourse = payload
      },
    })

    extraReducersResponse<
      IInstitutionsProgramsState[typeof sliceName],
      IInstitutionsProgramsMBA
    >({
      builder,
      thunk: thunks.fetchProgramsMBA,
      fulfilled: (state, { payload }) => {
        // eslint-disable-next-line no-param-reassign
        state.programsMBA = payload
      },
    })

    extraReducersResponse<
      IInstitutionsProgramsState[typeof sliceName],
      IInstitutionsProgramsSchool
    >({
      builder,
      thunk: thunks.fetchProgramsSchool,
      fulfilled: (state, { payload }) => {
        // eslint-disable-next-line no-param-reassign
        state.programsSchool = payload
      },
    })

    extraReducersResponse<
      IInstitutionsProgramsState[typeof sliceName],
      IInstitutionsPreparatory
    >({
      builder,
      thunk: thunks.fetchPreparatory,
      fulfilled: (state, { payload }) => {
        // eslint-disable-next-line no-param-reassign
        state.preparatory = payload
      },
    })
  },
})
