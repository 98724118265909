import { sliceName } from './constants'
import { getStateError, getStateItem } from '../utils/core'
import { IAdvState } from './types'
import { advAdapter } from './slice'

const adapterSelectors = advAdapter.getSelectors<IAdvState>((state) => state[sliceName])

export default {
  ...adapterSelectors,

  advUserId: getStateItem<IAdvState, IAdvState[typeof sliceName]['advUserId']>(
    sliceName,
    'advUserId'
  ),

  loading: getStateItem<IAdvState, IAdvState[typeof sliceName]['loading']>(
    sliceName,
    'loading'
  ),

  error: getStateError<IAdvState>(sliceName),
}
