import apiThunk, { api } from '@ucheba/utils/helpers/api'
import { sliceName } from './constants'

/** Установка текущего шага */
const fetch = apiThunk(`${sliceName}/setStep`, async (params) => {
  const { userId, ...otherData } = params?.data || {}

  const response = await api.post(
    `/forms/${params.data.type || 'profile'}/${userId}/step`,
    otherData,
    params
  )

  return response.data
})

export default { fetch }
