import { ILocation } from '@ucheba/store/location/types'
import { Dispatch, FormEvent, SetStateAction } from 'react'
import { IDialog } from '@ucheba/ui/components/Dialog/types'
import { IUseBottomSheetHeight } from '@ucheba/ui/components/Select/types'
import { IPropIsTouch } from '@ucheba/utils/helpers/components/prop.types'

export interface IChooseLocationIndexProps extends IPropIsTouch {}

export interface IChooseLocationProps {
  /* Список всех регионов */
  locations: ILocation[]

  /* Популярные локации */
  popularLocations?: ILocation[]

  /* Выбранные локации */
  selectedLocations?: ILocation[]

  suggester(name: string, values?: string[]): void

  /* Колбэк вызываемый при сабмите формы */
  onSubmit(props: string[]): void
}

export interface IChooseLocationFormProps
  extends Pick<
    IChooseLocationProps,
    'locations' | 'popularLocations' | 'selectedLocations' | 'suggester'
  > {}

export interface IInitialValues {
  locationIds: TSelectedLocations
}

export type TSelectedLocations = string[]

export enum EChooseLocationDataKeys {
  locationIds = 'locationIds',
}

export interface IUseChooseLocationCore {
  (props: {
    closeDialog: () => void | Dispatch<SetStateAction<boolean>>
    open: boolean
  }): {
    onSubmitForm(values: IInitialValues): void
    initialValues: {
      [EChooseLocationDataKeys.locationIds]: string[]
    }
    locations: ILocation[]
  }
}

export interface IUseChooseLocationFormCore {
  (
    props: Pick<
      IChooseLocationFormProps,
      'locations' | 'popularLocations' | 'selectedLocations' | 'suggester'
    >
  ): {
    selectedLocations?: ILocation[]
    popularLocationsList?: TPopularLocation[]
    addLocation?(location: TPopularLocation): () => void
    removeLocation?(location: ILocation): () => void
    onChangeHandler: (_: FormEvent<HTMLInputElement>, locationIds: string[]) => void
  }
}

export type TPopularLocation = ILocation | Pick<ILocation, 'id' | 'name'>

export interface IUseChooseLocationDialogCore {
  (): {
    chooseLocationDialog: IDialog
    suggestingLocations: (name: string) => void
    extendedHeaderOpenDialog: () => void
    extendedHeroOpenDialog: () => void
  }
}

export interface IUseChooseLocationBottomSheetCore {
  (): {
    chooseLocationBottomSheet: ReturnType<IUseBottomSheetHeight>
    suggestingLocations: (name: string) => void
    isOpenChooseLocation: boolean
    toggleOpenChooseLocation: (value: boolean) => void
  }
}

export interface IUseLocationText {
  (): {
    baseText: string
    headerText: string
  }
}
