import apiThunk, { api } from '@ucheba/utils/helpers/api'
import { sliceName } from './constants'

const post = apiThunk(`${sliceName}/post`, async (params) => {
  const { type, referId } = params.data

  const response = await api.post(
    `/probabilities/${type}/${referId}`,
    params.data,
    params
  )

  return response.data
})

const check = apiThunk(`${sliceName}/check`, async (params) => {
  const { type, referId } = params.data

  const response = await api.get(`/probabilities/${type}/${referId}/check`, {
    ...params,
    params: {},
  })

  return response.data
})

export default { post, check }
