import React, { FC, Fragment, memo, useCallback } from 'react'
import { Spacing } from '@ucheba/ui/components/Spacing/desktop'
import { Menu, MenuItem } from '@ucheba/ui/components/Menu/desktop'
import { useRouter } from 'next/router'
import Link from 'next/link'
import { Text } from '@ucheba/ui/components/Text/desktop'
import { ISidebarContentProps } from '../types'
import SC from '../base/SidebarContent.styles'
import { headerNavigation } from '../../Header/constants'
import { forAbiturients } from '../../../routs'

const { Block } = SC

/** Контент сайдбара */
const SidebarContent: FC<ISidebarContentProps> = (props) => {
  const { pathname } = useRouter()

  const withRichSnippet = useCallback(
    (href) => {
      if (pathname === '/') {
        return href === forAbiturients.path
      }

      return pathname.includes(href)
    },
    [pathname]
  )

  return (
    <Block {...props}>
      {headerNavigation.map(({ name, href, childNavigation }, index) => (
        <Fragment key={index}>
          <Menu
            spacing='null'
            shadow={false}
            maxHeight='auto'
            itemType={
              withRichSnippet(href)
                ? 'https://schema.org/SiteNavigationElement'
                : undefined
            }
          >
            <MenuItem>
              <Link href={href} passHref>
                <Text fontSize='h4' fontWeight='bold'>
                  {name}
                </Text>
              </Link>
            </MenuItem>

            {childNavigation?.map(
              ({ name: childName, href: hrefChild, paths }, indexChild) => (
                <MenuItem
                  active={paths ? paths.includes(pathname) : pathname === hrefChild}
                  key={indexChild}
                >
                  <a
                    href={hrefChild}
                    itemProp={withRichSnippet(href) ? 'url' : undefined}
                  >
                    {childName}
                  </a>
                </MenuItem>
              )
            )}
          </Menu>

          <Spacing spacing='small' />
        </Fragment>
      ))}
    </Block>
  )
}

export { SidebarContent }
export default memo(SidebarContent)
