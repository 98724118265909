import apiThunk, { api } from '@ucheba/utils/helpers/api'
import { sliceName } from './constants'

const fetch = apiThunk(`${sliceName}/fetch`, async (params) => {
  const response = await api.get('/events/open-lessons/1', params)

  return response.data
})

const fetchOge = apiThunk(`${sliceName}/fetch`, async (params) => {
  const response = await api.get('/events/open-lessons/2', params)

  return response.data
})

export default { fetch, fetchOge }
