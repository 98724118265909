/* eslint-disable no-param-reassign */
import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { IAdvBlock, IAdvState } from './types'
import { extraReducersResponse, responseInitialState } from '../utils/response'
import { sliceName } from './constants'
import thunks from './thunks'

export const advAdapter = createEntityAdapter<IAdvBlock>()

export default createSlice({
  name: sliceName,

  initialState: {
    advUserId: null,
    ...advAdapter.getInitialState({ ...responseInitialState }),
  },

  reducers: {
    setAdvUserId: (state, { payload }) => {
      state.advUserId = payload
    },
  },

  extraReducers: (builder) => {
    extraReducersResponse<IAdvState[typeof sliceName], IAdvBlock>({
      builder,
      thunk: thunks.fetch,
      fulfilled: (state, action) => {
        advAdapter.addOne(state, action.payload)
      },
    })
  },
})
