import { ICoreState } from './types'
import { sliceName } from './constants'
import { getStateItem } from '../utils/core'

export default {
  isLayout: getStateItem<ICoreState, ICoreState[typeof sliceName]['isLayout']>(
    sliceName,
    'isLayout'
  ),

  isTouch: getStateItem<ICoreState, ICoreState[typeof sliceName]['isTouch']>(
    sliceName,
    'isTouch'
  ),

  changedId: getStateItem<ICoreState, ICoreState[typeof sliceName]['changedId']>(
    sliceName,
    'changedId'
  ),

  layout: getStateItem<ICoreState, ICoreState[typeof sliceName]['layout']>(
    sliceName,
    'layout'
  ),

  programTypes: getStateItem<ICoreState, ICoreState[typeof sliceName]['programTypes']>(
    sliceName,
    'programTypes'
  ),
}
