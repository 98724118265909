export const VkPermissionNoticeId = 'VkPermissionNoticeId'

export const userReg = 'userReg'

export const vkBannerOtherBlockTime = 864000 // 10 суток в секундах
export const vkBannerFirstBlockTime = 86400 // 10 суток в секундах

export const noticePermissionVkBlock = 'noticePermissionVkBlock'
export const noticePermissionVkCounter = 'noticePermissionVkCounter'

export const linkToVkSubscribe = '/events/vk-subscribe/registration'
export const linkToTgSubscribe = '/events/tg-subscribe/registration'
