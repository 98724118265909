import { ILocationNoticeState } from './types'
import { sliceName } from './constants'

const isShowLocationNotice = (
  state: ILocationNoticeState
): ILocationNoticeState[typeof sliceName]['isShowLocationNotice'] =>
  state[sliceName].isShowLocationNotice

export default {
  isShowLocationNotice,
}
