export default {
  title: 'Учёба.ру: всё о высшем и профессиональном образовании в Москве и России',
  description:
    'Учёба.ру: всё о высшем и профессиональном образовании в Москве и России: как поступить в вуз, выбор профессии, подготовка к ЕГЭ, второе образование, курсы.',
  openGraph: {
    type: 'website',
    locale: 'ru_RU',
    site_name: 'Учёба.ру',
    images: [
      {
        url: 'https://www.ucheba.ru/pix/upload/articles/img__og_ucheba.png',
        width: 958,
        height: 503,
        alt: 'Учёба.ру',
      },
    ],
  },
  additionalMetaTags: [
    {
      name: 'viewport',
      content: 'width=device-width, user-scalable=no',
    },
    {
      name: 'theme-color',
      content: '#ffffff',
    },
    {
      name: 'yandex-verification',
      content: '1d57e9083ac15d2a',
    },
    {
      name: 'verify-admitad',
      content: '0858ab3f58',
    },
    {
      charSet: 'utf-8',
    },
    {
      httpEquiv: 'x-ua-compatible',
      content: 'ie=edge',
    },
  ],
  additionalLinkTags: [
    {
      rel: 'shortcut icon',
      href: '/favicon.ico',
    },
    {
      rel: 'icon',
      type: 'image/svg+xml',
      href: '/favicon.svg',
    },
    {
      rel: 'apple-touch-icon',
      href: '/apple-touch-icon.png',
    },
  ],
}
