import React, { FC, memo } from 'react'
import { Form as FormikForm } from 'formik'
import { useFormErrors } from '@ucheba/utils/hooks/useFormErrors'
import { IFormProps } from '../types'
import { useFillForm } from '../bll'

/** Форма */
const Form: FC<IFormProps> = (props) => {
  const { apiErrors, fill, fillSrc, fillByQuery, fillByProfile, children } = props

  useFormErrors(apiErrors)
  useFillForm({ fill, fillSrc, fillByQuery, fillByProfile })

  return <FormikForm>{children}</FormikForm>
}

export { Form }
export default memo(Form)
