/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import { sliceName } from './constants'
import { extraReducersResponse, responseInitialState } from '../../utils/response'
import thunks from './thunks'

import { INews, INewsState } from './types'

export default createSlice({
  name: sliceName,

  initialState: {
    entity: {} as INews,
    ...responseInitialState,
  },

  reducers: {},

  extraReducers: (builder) => {
    extraReducersResponse<INewsState[typeof sliceName], INews>({
      builder,
      thunk: thunks.fetchNews,
      fulfilled: (state, { payload }) => {
        state.entity = payload
      },
    })
  },
})
