import apiThunk, { api } from '@ucheba/utils/helpers/api'
import { sliceName } from './constants'
import { setAuthCookie } from '../../auth/bll'

const send = apiThunk(`${sliceName}/send`, async (params) => {
  const { sendEvent } = params.data

  const data = {
    ...params.data,
  }

  if (typeof sendEvent !== 'undefined') {
    delete data.sendEvent
  }

  const response = await api.post('client-products/requests', data, { ...params, data })

  if (response.data?.token) {
    setAuthCookie({ ctx: params?.ctx, token: response.data.token, sendEvent })
  }

  return response.data
})

const patch = apiThunk(`${sliceName}/patch`, async (params) => {
  const response = await api.patch(
    `client-products/requests/${params?.data.requestId}`,
    params?.data,
    params
  )

  return response.data
})

export default { send, patch }
