import apiThunk, { api } from '@ucheba/utils/helpers/api'
import { sliceName } from './constants'

const start = apiThunk(`${sliceName}/start`, async (params) => {
  const response = await api.post(`/chat-bot/start`, params.data, params)

  return response.data
})
const messageAllow = apiThunk(`${sliceName}/message/allow`, async (params) => {
  const response = await api.post(
    `${params?.data.slug}/message/allow`,
    params?.data,
    params
  )

  return response.data
})
const isAllowed = apiThunk(
  `${sliceName}/message/messages/users/is-allowed`,
  async (params) => {
    const response = await api.get(
      `${params?.data.slug}/messages/users/${params?.data.vkId}/is-allowed`,
      params
    )
    return response.data
  }
)

export default { start, messageAllow, isAllowed }
