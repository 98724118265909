import { IProfileShortState } from './types'
import { sliceName } from './constants'
import { getStateError, getStateItem } from '../../utils/core'

export default {
  entity: getStateItem<
    IProfileShortState,
    IProfileShortState[typeof sliceName]['entity']
  >(sliceName, 'entity'),

  loading: getStateItem<
    IProfileShortState,
    IProfileShortState[typeof sliceName]['loading']
  >(sliceName, 'loading'),

  error: getStateError<IProfileShortState>(sliceName),
}
