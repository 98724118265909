import { ILearningRequestState, ILearningRequestData } from './types'
import { sliceName } from './constants'
import { getStateItem } from '../../utils/core'

export default {
  loading: getStateItem<
    ILearningRequestState,
    ILearningRequestState[typeof sliceName]['loading']
  >(sliceName, 'loading'),

  entity: getStateItem<ILearningRequestData, ILearningRequestData>(sliceName, 'entity'),
}
