import apiThunk, { api } from '@ucheba/utils/helpers/api'
import { sliceName } from './constants'

const fetch = apiThunk(`${sliceName}/fetch`, async (params) => {
  const response = await api.get(`/programs/${params?.data?.id}`, params)

  return response.data
})

const fetchSimilar = apiThunk(`${sliceName}/fetchSimilar`, async (params) => {
  const { data } = params
  const { programId } = data

  const response = await api.get(`/programs/${programId}/similar`, params)

  return response.data
})

export default { fetch, fetchSimilar }
