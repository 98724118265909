import apiThunk, { api } from '@ucheba/utils/helpers/api'
import { objectToQueryString } from '@ucheba/utils/helpers/router'
import { sliceName } from './constants'

const fetch = apiThunk(`${sliceName}/fetch`, async (params) => {
  const response = await api.get(
    `/tags/tree?${objectToQueryString(params?.data)}`,
    params
  )

  return response.data
})

export default { fetch }
