import { INewsByIdState, INew } from './types'
import { sliceName } from './constants'
import { getStateItem } from '../../utils/core'

export default {
  entity: getStateItem<INew, INew>(sliceName, 'entity'),

  loading: getStateItem<INewsByIdState, INewsByIdState[typeof sliceName]['loading']>(
    sliceName,
    'loading'
  ),
}
