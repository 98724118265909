/* eslint-disable no-param-reassign */
import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { IEvents, IEventsState, IItemOfEvents } from './types'
import { IResponseInitialState } from '../utils/response/types'
import { extraReducersResponse, responseInitialState } from '../utils/response'
import thunks from './thunks'
import { sliceName } from './constants'

export const eventsAdapter = createEntityAdapter<IItemOfEvents>()

export default createSlice({
  name: sliceName,

  initialState: eventsAdapter.getInitialState<
    IResponseInitialState & Pick<IEvents, 'total'>
  >({
    total: 0,
    ...responseInitialState,
  }),

  reducers: {},

  extraReducers: (builder) => {
    extraReducersResponse<IEventsState[typeof sliceName], IEvents>({
      builder,
      thunk: thunks.fetch,
      fulfilled: (state, action) => {
        state.total = action.payload.total

        eventsAdapter.setAll(state, action.payload.items)
      },
    })
  },
})
