export const sliceName = 'pageDataConsult'

export const requestConfig = {
  prod: {
    fetch: {
      url: {
        request: {
          send: '/proforientation/request/send',
          addParent: '/proforientation/request/add_parent',
        },
      },
      config: {
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          accept: 'application/json, text/javascript, */*; q=0.01',
          'accept-language': 'ru-RU,ru;q=0.9,en-US;q=0.8,en;q=0.7',
          'cache-control': 'no-cache',
          'sec-fetch-dest': 'empty',
          'sec-fetch-mode': 'cors',
          'sec-fetch-site': 'same-origin',
          'x-requested-with': 'XMLHttpRequest',
        },
        referrer: 'http://www.next.ucheba.ru/for-abiturients/consult',
        referrerPolicy: 'strict-origin-when-cross-origin',
        method: 'POST',
      } as unknown,
    },
    paymentBaseLink: 'https://www.ucheba.ru/project/prof/main/payment/',
  },
  next: {
    fetch: {
      url: {
        request: {
          send: '/proforientation/request/send',
          addParent: '/proforientation/request/add_parent',
        },
      },
      config: {
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          accept: 'application/json, text/javascript, */*; q=0.01',
          'accept-language': 'ru-RU,ru;q=0.9,en-US;q=0.8,en;q=0.7',
          'cache-control': 'no-cache',
          'sec-fetch-dest': 'empty',
          'sec-fetch-mode': 'cors',
          'sec-fetch-site': 'same-origin',
          'x-requested-with': 'XMLHttpRequest',
        },
        referrer: 'http://www.next.ucheba.ru/for-abiturients/consult',
        referrerPolicy: 'strict-origin-when-cross-origin',
        method: 'POST',
      } as { credentials: 'include' },
    },
    paymentBaseLink: 'http://www.next.ucheba.ru/project/prof/main/payment/',
  },
}
