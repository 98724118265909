/* eslint-disable no-nested-ternary */
import { sm } from '@ucheba/utils/helpers/styles/mixins'
import styled from 'styled-components'
import { sv } from '@ucheba/utils/helpers/styles/variables'

import { Button } from '@ucheba/ui/components/Button/touch'

import {
  EHeaderColor,
  EHeaderItemActiveColor,
  EModHeaderColor,
  EModLocationIconColor,
} from '../types'

const LogoLink = styled.a`
  display: flex;
  cursor: pointer;
`

const MainContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: 64px;
  align-items: center;
  padding-left: ${sv.spacing.small};
  padding-right: ${sv.spacing.small};
`

const MainContainerTouchWrapper = styled(MainContainer)``

const Main = styled.div`
  backdrop-filter: blur(20px);
  position: relative;
  z-index: 1;

  a,
  button {
    &,
    &:hover {
      color: inherit;
    }
  }

  ${Object.keys(EModHeaderColor).map(
    (item) => sm.mod(EModHeaderColor[item])`
      background-color: ${sv.color[EHeaderColor[item]]};
      color: ${sv.color[item]};
    `
  )};

  ${sm.mod(EModHeaderColor.black)`
    background-color: transparent;
    color: ${sv.color[EHeaderColor.black]};

    ${MainContainer} {
      position: relative;

      &:after {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        left: ${sv.spacing.small};
        right: ${sv.spacing.small};
        height: 1px;
        background-color: ${sv.color.gray9};
      }
    }
  `};
`

const LocationIcon = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;

  ${Object.keys(EModHeaderColor).map(
    (item) => sm.mod(EModHeaderColor[item])`
        color: ${sv.color[EModLocationIconColor[item]]};
      `
  )};
`

interface IHeaderNavItem {
  active?: boolean
  color: string
}

const HeaderNavItem = styled.div<IHeaderNavItem>`
  height: 64px;
  position: relative;
  display: flex;
  align-items: center;
  column-gap: 8px;

  cursor: pointer;
  overflow: hidden;
  color: ${({ active, color }) =>
    active ? sv.color[EHeaderItemActiveColor[color]] : sv.color[color] || color};

  svg {
    width: 20px;
    height: 20px;
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 1px;

    transition: all 0.2s;
    background: ${({ active, color }) =>
      active ? sv.color[EHeaderItemActiveColor[color]] : sv.color[color] || color};

    opacity: ${({ active }) => (active ? 1 : 0)};
  }

  &:hover {
    &::after {
      opacity: 1;
    }
  }
`

const Block = styled.header`
  position: relative;
  z-index: 10;
`

const TouchButtons = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  column-gap: 12px;

  svg {
    width: 24px;
    height: 24px;
  }
`

const ButtonIcon = styled(Button)`
  padding: 0;

  svg {
    color: inherit;
  }
`

const Circle = styled.div`
  position: absolute;
  width: 24px;
  height: 24px;
  left: -6px;
  left: 50%;
  transform: translate(-50%, 0);

  border-radius: 50%;
  opacity: 1;
`

export default {
  Block,
  MainContainer,
  Main,
  LogoLink,
  LocationIcon,
  HeaderNavItem,
  TouchButtons,
  ButtonIcon,
  MainContainerTouchWrapper,
  Circle,
}
