import { IInstitutionsProgramTypesState } from './types'
import { sliceName } from './constants'
import { getStateItem } from '../../utils/core'

export default {
  programTypes: getStateItem<
    IInstitutionsProgramTypesState,
    IInstitutionsProgramTypesState[typeof sliceName]['programTypes']
  >(sliceName, 'programTypes'),
}
