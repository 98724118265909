import { useRouter } from 'next/router'
import { useCallback, useEffect, useMemo, useRef } from 'react'
import { useDispatch, useSelector, useStore } from 'react-redux'
import { userTagsSelectors } from '@ucheba/store/userTags'
import { authSelectors } from '@ucheba/store/auth'
import { ELoading } from '@ucheba/store/utils/response/types'
import { viewsTrackerThunks } from '@ucheba/store/viewsTracker'
import defineAdvUserId from '../middlewares/defineAdvUserId'
import { getVisitInfo } from './useVisitsTracker'

const useViewsTracker = (
  siteName: string,
  platform: string,
  data: Record<string, any> = {}
): void => {
  const router = useRouter()
  const dispatch = useDispatch()
  const userTagsEntity = useSelector(userTagsSelectors.entity)
  const userTagsLoading = useSelector(userTagsSelectors.loading)
  const authLoading = useSelector(authSelectors.loading)
  const isInitRef = useRef(false)
  const store = useStore()
  const advUserId = useMemo(() => defineAdvUserId(null, store), [store])
  const sendStats = useCallback(() => {
    const visit = getVisitInfo(siteName)
    const userTags =
      localStorage.getItem('userTags') &&
      JSON.parse(localStorage.getItem('userTags') || '{}')
    const userTagList = Object.entries(userTags || {}).map(([key, value]) => {
      return {
        slug: key,
        assignedAt: value,
      }
    })
    dispatch(
      viewsTrackerThunks.view({
        data: {
          url: window.location.href,
          platform,
          visitorId: advUserId,
          referer: document.referrer,
          userTags: userTagList,
          visit,
          ...data,
        },
      })
    )
  }, [userTagsEntity, platform, advUserId])
  useEffect(() => {
    if (
      !isInitRef.current &&
      (userTagsLoading === ELoading.idle ||
        userTagsLoading === ELoading.pending ||
        authLoading === ELoading.pending)
    ) {
      return
    }
    if (!isInitRef.current) {
      sendStats()
      isInitRef.current = true
    }
    const handler = (url: string, { shallow }): void => {
      if (shallow) {
        return
      }
      sendStats()
    }
    router.events.on('routeChangeComplete', handler)
    // eslint-disable-next-line consistent-return
    return () => {
      router.events.off('routeChangeComplete', handler)
    }
  }, [userTagsLoading, authLoading, sendStats, router])
}

export default useViewsTracker
