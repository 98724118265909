import { sliceName } from './constants'
import { getStateError, getStateItem } from '../utils/core'
import { IOlympiadsState } from './types'
import { olympiadsAdaptor } from './slice'

const adapterSelectors = olympiadsAdaptor.getSelectors<IOlympiadsState>(
  (state) => state[sliceName]
)

export default {
  ...adapterSelectors,

  loading: getStateItem<IOlympiadsState, IOlympiadsState[typeof sliceName]['loading']>(
    sliceName,
    'loading'
  ),

  error: getStateError<IOlympiadsState>(sliceName),
}
