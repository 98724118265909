import apiThunk, { api } from '@ucheba/utils/helpers/api'
import { sliceName } from './constants'

const fetch = apiThunk(`${sliceName}/fetch`, async (params) => {
  const response = await api.get(`geoip`, params)

  return response.data
})

export default { fetch }
