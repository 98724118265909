import React, { FC, memo } from 'react'
import { IDescriptionProps } from '../types'
import { Text } from '../../Text/desktop'
import SC from '../base/Description'

const { Block } = SC

/** Блок с описанием чего-либо */
const Description: FC<IDescriptionProps> = (props) => {
  const {
    description = [],
    fontSize,
    lineHeight,
    textAlign,
    color,
    fontWeight,
    spacing,
    uppercase,
    as: element,
    ...otherProps
  } = props

  return (
    <Block spacing={spacing} {...otherProps}>
      {description.map((text, index) => (
        <Text
          color={color}
          key={index}
          as={element || 'p'}
          fontSize={fontSize}
          lineHeight={lineHeight}
          textAlign={textAlign}
          fontWeight={fontWeight}
          uppercase={uppercase}
        >
          {text}
        </Text>
      ))}
    </Block>
  )
}

export { Description }
export default memo(Description)
