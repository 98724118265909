import { IOgeSubjectsScheduleState } from './types'
import { ogeSubjectsScheduleAdapter } from './slice'
import { sliceName } from './constants'
import { getStateError } from '../../../utils/core'

export const adapterSelectors =
  ogeSubjectsScheduleAdapter.getSelectors<IOgeSubjectsScheduleState>(
    (state) => state[sliceName]
  )

export default {
  ...adapterSelectors,

  error: getStateError<IOgeSubjectsScheduleState>(sliceName),
}
