/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import { IChatBot, IChatBotState, IIsAllowedResponse } from './types'
import { extraReducersResponse, responseInitialState } from '../utils/response'
import { sliceName } from './constants'
import thunks from './thunks'

export default createSlice({
  name: sliceName,

  initialState: {
    redirectUrl: null as string | null,
    isAllowed: null as boolean | null,
    ...responseInitialState,
  },

  reducers: {},

  extraReducers: (builder) => {
    extraReducersResponse<IChatBotState[typeof sliceName], IChatBot>({
      builder,
      thunk: thunks.start,
      fulfilled: (state, action) => {
        state.redirectUrl = action.payload.redirectUrl
      },
    })

    extraReducersResponse<IChatBotState[typeof sliceName], IChatBotState>({
      builder,
      thunk: thunks.messageAllow,
      fulfilled: () => null,
    })

    extraReducersResponse<IChatBotState[typeof sliceName], IIsAllowedResponse>({
      builder,
      thunk: thunks.isAllowed,
      fulfilled: (state, action) => {
        state.isAllowed = action.payload.isAllowed
      },
    })
  },
})
