/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import { IAuthState, IToken } from './types'
import { extraReducersResponse, responseInitialState } from '../utils/response'
import { sliceName } from './constants'
import thunks from './thunks'
import { profileRegistrationThunks } from '../profile/registration'
import { IUser } from './phone/types'
import { createLoginAction, login } from './bll'
import { authPhoneThunks } from './phone'
import { authEmailThunks } from './email'
import { clientProductsRequestsThunks } from '../client-products/requests'
import { IClientProductsRequestsEntity } from '../client-products/requests/types'

export default createSlice({
  name: sliceName,

  initialState: {
    token: '',
    expiresAt: '',
    isValidToken: false,
    ...responseInitialState,
  },

  reducers: {
    login,
    logout: (state): void => {
      state.token = ''
      state.expiresAt = ''
    },
  },

  extraReducers: (builder) => {
    extraReducersResponse<IAuthState[typeof sliceName], IToken>({
      builder,
      thunk: thunks.fetch,
      fulfilled: login,
    })

    extraReducersResponse<IAuthState[typeof sliceName], IUser>({
      builder,
      thunk: thunks.authByKey,
      fulfilled: (state, { payload }): void => {
        login(state, createLoginAction(payload.token))
      },
    })

    extraReducersResponse<IAuthState[typeof sliceName], IToken>({
      builder,
      thunk: thunks.isValidToken,
      fulfilled: (state): void => {
        state.isValidToken = true
      },
      rejected: (state): void => {
        state.isValidToken = false
      },
    })

    extraReducersResponse<IAuthState[typeof sliceName], IUser>({
      builder,
      thunk: profileRegistrationThunks.fetch,
      fulfilled: (state, { payload }): void => {
        login(state, createLoginAction(payload.token))
      },
    })

    extraReducersResponse<IAuthState[typeof sliceName], IUser>({
      builder,
      thunk: authPhoneThunks.phone,
      fulfilled: (state, { payload }): void => {
        login(state, createLoginAction(payload.token))
      },
    })

    extraReducersResponse<IAuthState[typeof sliceName], IUser>({
      builder,
      thunk: authEmailThunks.fetch,
      fulfilled: (state, { payload }): void => {
        login(state, createLoginAction(payload.token))
      },
    })

    extraReducersResponse<IAuthState[typeof sliceName], IClientProductsRequestsEntity>({
      builder,
      thunk: clientProductsRequestsThunks.send,
      fulfilled: (state, { payload }): void => {
        if (payload?.token) {
          login(state, createLoginAction(payload.token))
        }
      },
    })
  },
})
