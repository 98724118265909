import React, { FC, memo, useCallback, useState } from 'react'
import { useSelector } from 'react-redux'

import { Container } from '@ucheba/ui/components/Container/touch'
import { Logo } from '@ucheba/ui/components/Logo/touch'
import { Text } from '@ucheba/ui/components/Text/touch'
import { Button } from '@ucheba/ui/components/Button/touch'

import styled from 'styled-components'
import { sm } from '@ucheba/utils/helpers/styles/mixins'
import { sv } from '@ucheba/utils/helpers/styles/variables'

import Link from 'next/link'
import { ETypeRequest } from '../../../pages/for-abiturients/ege/index/types'

import { EFooterColorLogo, IFooterProps } from '../types'

import { ApplicationForm } from '../../AppliactionForm/touch'
import {
  EApplicationFormProductTypes,
  EApplicationFunnels,
} from '../../AppliactionForm/types'

import { FooterSocialLinks } from '../base/FooterSocialLinks'
import { FooterNavLinks } from '../base/FooterNavLinks'
import { footerSelectors } from '../store'
import { LINKS } from '../constants'

import {
  Block,
  FooterLinks as FooterLinksSC,
  Inner as InnerSC,
  Links,
  LogoLink as LogoLinkSC,
  ShowAllLinks,
  SocialLinks,
  Title,
  TopBlock as TopBlockSC,
} from '../base/Footer'
import { useFooterCore } from '../bll'
import InformerEddu from '../desktop/InformerEddu'

const ButtonStyled = styled(Button)`
  height: auto;
  padding: 8px 12px;
  font-size: 16px;

  @media screen and (min-width: 720px) {
    margin-right: 9px;
  }
`

const Column = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
  &:last-child {
    margin-bottom: 0;
  }
  ${sm.breakpoint.smAndUp`
   &:nth-child(1) {
    grid-area: a;
  }

   &:nth-child(2) {
    grid-area: b;
  }

   &:nth-child(3) {
    grid-area: c;
  }
 `};
`

const ColumnTop = styled.div<{ color: string }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
  padding-bottom: 32px;
  border-bottom: 1px solid
    ${({ color }) =>
      color === 'black' || color === 'navy' ? sv.color.white25 : 'rgba(3, 15, 35, 0.15)'};

  ${sm.breakpoint.smAndUp`
   &:nth-child(1) {
    grid-area: a;
  }

   &:nth-child(2) {
    grid-area: b;
  }

   &:nth-child(3) {
    grid-area: c;
  }
 `};
`

const LinksStyled = styled(Links)`
  margin: 16px 0;
  justify-content: center;
  column-gap: 8px;
  row-gap: 16px;

  &:last-of-type {
    margin-bottom: 0;
  }

  ${sm.breakpoint.smAndUp`
    row-gap: 8px;
  `};

  a {
    ${sm.breakpoint.smAndUp`
    display: inline-flex;
  `};
  }
`

const Inner = styled(InnerSC)`
  ${sm.breakpoint.smAndDown`
    flex-direction: column;
    padding-top: ${sv.spacing.xxlarge};
    padding-bottom: ${sv.spacing.xxlarge};
  `};
`

const TitleStyled = styled(Title)`
  text-align: center;
`

const TopBlock = styled(TopBlockSC)`
  ${sm.breakpoint.smAndUp`
   display: grid;
   grid-template-areas:
        "a a"
        "b c";
  `};

  ${sm.breakpoint.smAndDown`
    flex-direction: column;
    padding-top: ${sv.spacing.xxlarge};
    padding-bottom: ${sv.spacing.xxlarge};
  `};
`

const FooterLinks = styled(FooterLinksSC)`
  ${sm.breakpoint.smAndDown`

    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-bottom: ${sv.spacing.large};
    column-gap: 8px;
    row-gap: 16px;
    ${sm.resetLastMb};

    a {
      flex-shrink: 0;
      text-align: center;
      margin-right: 0;
      display: block;
    }
  `};
`

const LogoLink = styled(LogoLinkSC)`
  margin-bottom: ${sv.spacing.large};

  ${sm.breakpoint.smAndUp`
    && {
      display: none;
    }
  `};
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 12px;
  @media screen and (min-width: 720px) {
    flex-direction: row;
    justify-content: center;
  }
`

const InformerWrapper = styled.div`
  display: inline-block;
  width: 113px;
  height: 42px;
  background-color: #ffffff;
  padding: 1px;
  border-radius: 10px;
  margin: 20px auto 0;

  @media screen and (min-width: 720px) {
    margin: 0;
  }
`

/** Футер сайта */
const Footer: FC<IFooterProps> = ({ notFoundPage, ...props }) => {
  const color = useSelector(footerSelectors.color)
  const navLinks = useSelector(footerSelectors.navLinks)
  const isShowFooter = useSelector(footerSelectors.isShowFooter)
  const isShowMessengers = useSelector(footerSelectors.isShowMessengers)
  const { isSchool } = useFooterCore()

  const [showAllTrainingEGE, setShowAllTrainingEGE] = useState(false)
  const [showAllTrainingOGE, setShowAllTrainingOGE] = useState(false)
  const [showAllEGE, setShowAllEGE] = useState(false)
  const [showAllOGE, setShowAllOGE] = useState(false)

  const [isOpenFreeLesson, setIsOpenFreeLesson] = useState(false)

  const handleCloseFreeLesson = useCallback((): void => setIsOpenFreeLesson(false), [])
  const handleOpenFreeLesson = useCallback((): void => setIsOpenFreeLesson(true), [])

  if (!isShowFooter) return null

  return (
    <Block color={color} {...props}>
      {!notFoundPage && (
        <Container>
          <TopBlock>
            <ColumnTop color={color}>
              <TitleStyled>Готовим к поступлению в лучшие вузы России</TitleStyled>

              <Wrapper>
                <ButtonStyled onClick={handleOpenFreeLesson} width='225px' color='blue'>
                  Бесплатный пробный урок
                </ButtonStyled>

                {isSchool && (
                  <InformerWrapper>
                    <InformerEddu />
                  </InformerWrapper>
                )}
              </Wrapper>
            </ColumnTop>

            <Column>
              <Link href='https://school.ucheba.ru/training/ege'>
                <Text textAlign='center' fontWeight='bold' fontSize='h4'>
                  Бесплатный тренажер по подготовке к ЕГЭ
                </Text>
              </Link>

              <LinksStyled isShow={showAllTrainingEGE}>
                {LINKS.map(({ subject, text }) => (
                  <a
                    key={subject}
                    href={`https://school.ucheba.ru/training/ege/${subject}`}
                  >
                    Тренажер {`ЕГЭ ${text}`}
                  </a>
                ))}

                <br />
                {!showAllTrainingEGE && (
                  <ShowAllLinks
                    textAlign
                    color={color}
                    onClick={() => setShowAllTrainingEGE((prevValue) => !prevValue)}
                  >
                    Все предметы
                  </ShowAllLinks>
                )}
              </LinksStyled>
            </Column>

            <Column>
              <Link href='https://school.ucheba.ru/training/oge'>
                <Text textAlign='center' fontWeight='bold' fontSize='h4'>
                  Бесплатный тренажер по подготовке к ОГЭ
                </Text>
              </Link>

              <LinksStyled isShow={showAllTrainingOGE}>
                {LINKS.map(({ subject, text }) => (
                  <a
                    key={subject}
                    href={`https://school.ucheba.ru/training/oge/${subject}`}
                  >
                    Тренажер {`ОГЭ ${text}`}
                  </a>
                ))}

                <br />
                {!showAllTrainingOGE && (
                  <ShowAllLinks
                    textAlign
                    color={color}
                    onClick={() => setShowAllTrainingOGE((prevValue) => !prevValue)}
                  >
                    Все предметы
                  </ShowAllLinks>
                )}
              </LinksStyled>
            </Column>

            <Column>
              <Link href='/for-abiturients/ege'>
                <Text textAlign='center' fontWeight='bold' fontSize='h4'>
                  Подготовка к ЕГЭ
                </Text>
              </Link>

              <LinksStyled isShow={showAllEGE}>
                {LINKS.map(({ subject, text }) => (
                  <a key={subject} href={`/for-abiturients/ege/${subject}`}>
                    {`ЕГЭ ${text}`}
                  </a>
                ))}

                <br />
                {!showAllEGE && (
                  <ShowAllLinks
                    textAlign
                    color={color}
                    onClick={() => setShowAllEGE((prevValue) => !prevValue)}
                  >
                    Все предметы
                  </ShowAllLinks>
                )}
              </LinksStyled>
            </Column>

            <Column>
              <Link href='/for-abiturients/oge'>
                <Text textAlign='center' fontWeight='bold' fontSize='h4'>
                  Подготовка к ОГЭ
                </Text>
              </Link>

              <LinksStyled isShow={showAllOGE}>
                {LINKS.map(({ subject, text }) => (
                  <a key={subject} href={`/for-abiturients/oge/${subject}`}>
                    {`ОГЭ ${text}`}
                  </a>
                ))}

                <br />
                {!showAllOGE && (
                  <ShowAllLinks
                    textAlign
                    color={color}
                    onClick={() => setShowAllOGE((prevValue) => !prevValue)}
                  >
                    Все предметы
                  </ShowAllLinks>
                )}
              </LinksStyled>
            </Column>
          </TopBlock>
        </Container>
      )}

      <Container>
        <Inner color={color}>
          <LogoLink href='/' aria-label='На главную'>
            <Logo color={EFooterColorLogo[color]} />
          </LogoLink>

          <FooterLinks>
            <FooterNavLinks list={navLinks} />
          </FooterLinks>

          {isShowMessengers && (
            <SocialLinks>
              <FooterSocialLinks />
            </SocialLinks>
          )}
        </Inner>
      </Container>

      <ApplicationForm
        funnel={EApplicationFunnels.free_lesson}
        productType={EApplicationFormProductTypes.ege}
        requestTarget={ETypeRequest.ege_lead_free_lesson}
        open={isOpenFreeLesson}
        close={handleCloseFreeLesson}
      />
    </Block>
  )
}

export { Footer }
export default memo(Footer)
