import { IDivisions, IDivisionsState } from './types'
import { sliceName } from './constants'
import { getStateItem } from '../../utils/core'

export default {
  loading: getStateItem<IDivisionsState, IDivisionsState[typeof sliceName]['loading']>(
    sliceName,
    'loading'
  ),

  divisions: getStateItem<IDivisions, IDivisionsState[typeof sliceName]['divisions']>(
    sliceName,
    'divisions'
  ),

  facets: getStateItem<IDivisions, IDivisionsState[typeof sliceName]['facets']>(
    sliceName,
    'facets'
  ),
}
