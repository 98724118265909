import { IFacet } from '@ucheba/utils/types/search'
import { specialitiesSearchSlice } from './index'
import { ISpeciality } from '../id/types'

export interface ISpecialitiesSearchState {
  [key: string]: ReturnType<typeof specialitiesSearchSlice.reducer>
}

export interface ISpecialitiesSearch {
  total: number
  items: IItemOfSpecialitiesSearch[]
  facets: ISpecialitiesFacets | null
  seo: {
    h1?: string | null
  } | null
}

export interface ISpecialitiesSearchByFriendlyURL {
  result: ISpecialitiesSearch
  criteria: {
    limit: number | null
    offset: number | null
    query: string | null
    examSubjectIds: number[] | null
    locationIds: number[] | null
    programExamSubjectIds: number[] | null
    programTypes: number[] | null
    tagIds: number[] | null
    topProgramTypes:
      | {
          value: number
          name: string
        }[]
      | null
    trainingFormTypes: number[] | null
  } | null
  canIndex: boolean | null
}

export interface IItemOfSpecialitiesSearch extends ISpeciality {}

export enum ESpecialitiesFacets {
  programTypes = 'programTypes',
  examSubjects = 'examSubjects',
  trainingFormTypes = 'trainingFormTypes',
  locations = 'locations',
  tags = 'tags',
}

export enum ESpecialitiesDataKeys {
  query = 'query',
  programTypes = 'programTypes',
  locationIds = 'locationIds',
  trainingFormTypes = 'trainingFormTypes',
  topProgramTypes = 'topProgramTypes',
  tagIds = 'tagIds',
  examSubjectIds = 'examSubjectIds',
}

export interface ISpecialitiesFacets {
  [ESpecialitiesFacets.examSubjects]: IFacet[]
  [ESpecialitiesFacets.programTypes]: IFacet[]
  [ESpecialitiesFacets.trainingFormTypes]: IFacet[]
  [ESpecialitiesFacets.locations]: IFacet[]
  [ESpecialitiesFacets.tags]: IFacet[]
}
