import { NextPageContext } from 'next/dist/shared/lib/utils'
import { Store } from 'redux'
import { setLocationIdCookie } from '@ucheba/store/userData/bll'
import { IDomain } from '@ucheba/store/domain/types'
import { geoipSelectors, geoipThunks } from '@ucheba/store/geoip'
import { userDataActions } from '@ucheba/store/userData'
import { ILocation } from '@ucheba/store/location/types'
import { setNoticeCookie } from '@ucheba/ui/components/Notice/bll'
import { IncomingMessage } from 'http'
import { getParsedCookies, isBrowser, isLocalFunc } from '../helpers/core'
import { allRussiaLocationId, locationIdsCookie } from '../constants/core'
import { getHostByRegionDomain, redirect } from '../helpers/router'
import { IPropButtonText, IPropId } from '../helpers/components/prop.types'
import { sendRequest } from '../helpers/api'

export interface IDefineLocationNoticeContent extends Required<IPropId> {
  buttons: {
    accept: IDefineLocationNoticeContentButton
    decline: IDefineLocationNoticeContentButton
  }
}

interface IDefineLocationNoticeContentButton extends IPropButtonText {}

export const locationAllRussiaId = 9653

export const defineLocationNotice: IDefineLocationNoticeContent = {
  id: 'defineLocationNoticeId',
  buttons: {
    accept: {
      buttonText: 'Да, все верно',
    },
    decline: {
      buttonText: 'Нет, выбрать другой город',
    },
  },
}

export const selectLocation = ({
  ctx,
  locations,
  store,
}: {
  ctx?: NextPageContext
  store: Store
  locations?: ILocation[]
}): void => {
  setLocationIdCookie({ ctx, locationIds: locations?.map((location) => location?.id) })

  store.dispatch(userDataActions.setSelectedLocation(locations))
}

/** Есть поддомен региона. Возвращает поддомен (slug из Location) если есть */
export const getLocationBySubdomain = async (host: string): IDomain | null => {
  const hostSplit = host.split('.')
  let slug = hostSplit[0]

  if (host.includes('localhost')) {
    return null
  }
  if (slug === 'dev') {
    slug = 'www'
  }

  const response = await sendRequest({ url: `/domain/${slug}` })

  if (response?.data?.slug) {
    return response.data as IDomain
  }

  return null
}

/** Проверка текущего поддомена  */
export const isSubdomainMatches = (
  request: IncomingMessage | undefined,
  store: Store,
  domain: string
): boolean => {
  const host = request?.headers.host || ''
  const hostSplit = host.split('.')
  const subDomain = hostSplit[0]

  return domain === subDomain
}

const returnPromise = new Promise((resolve) => {
  setTimeout(() => {
    resolve(true)
  }, 10)
})

const defineLocationMiddleware = async (
  ctx: NextPageContext,
  store: Store,
  basePath = ''
  // eslint-disable-next-line consistent-return,sonarjs/cognitive-complexity
): Promise<any> => {
  if (isBrowser) return returnPromise

  const { req } = ctx
  const { dispatch, getState } = store
  const host = req?.headers.host || ''

  const parsedCookie = getParsedCookies(ctx)

  let locationByIP = geoipSelectors.entity(getState())
  // const allRussiaLocation = locationSelectors.allRussiaLocation(getState())
  // let locations = locationSelectors.locations(getState())

  const locationBySubdomain = await getLocationBySubdomain(host)
  const isDomainWWW = locationBySubdomain?.slug === 'www'
  let locationIdsByCookie = []
  if (parsedCookie[locationIdsCookie]) {
    try {
      locationIdsByCookie = JSON.parse(parsedCookie[locationIdsCookie])
      // eslint-disable-next-line no-empty
    } catch (err) {
      console.error(err)
    }
  }

  if (!locationByIP && isDomainWWW && !locationIdsByCookie?.length) {
    await dispatch<any>(
      geoipThunks.fetch({
        ctx,
        data: {
          ip: JSON.stringify(
            !isLocalFunc(req?.headers?.host || '')
              ? req?.connection?.remoteAddress || '109.252.99.96'
              : '109.252.99.96'
          ),
        },
      })
    )

    locationByIP = geoipSelectors.entity(getState())
  }

  if (locationBySubdomain && !isDomainWWW) {
    selectLocation({ ctx, locations: [locationBySubdomain.location], store })
  } else if (locationIdsByCookie?.length) {
    if (
      locationBySubdomain &&
      locationBySubdomain?.location.id !== locationIdsByCookie[0]
    ) {
      if (locationIdsByCookie[0] === allRussiaLocationId) {
        selectLocation({ ctx, locations: [locationBySubdomain.location], store })

        const newHostWithRegionDomain = await getHostByRegionDomain({
          locationId: 3,
          ctx,
          basePath,
        })

        redirect({ ctx, location: newHostWithRegionDomain.url })
      } else {
        const newHostWithRegionDomain = await getHostByRegionDomain({
          locationId: locationIdsByCookie[0],
          ctx,
          basePath,
        })

        redirect({ ctx, location: newHostWithRegionDomain.url })
      }
    }
  } else if (locationByIP) {
    setNoticeCookie({ ctx, id: defineLocationNotice.id })

    selectLocation({ ctx, locations: [locationByIP.location], store })

    const newHostWithRegionDomain = await getHostByRegionDomain({
      locationId: locationByIP.location.id,
      ctx,
      basePath,
    })

    if (host.split('.')[0] !== newHostWithRegionDomain.domainSlug) {
      redirect({ ctx, location: newHostWithRegionDomain.url })
    }
  }

  return returnPromise
}

export default defineLocationMiddleware
