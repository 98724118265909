/* Возвращает данные профиля и запрашивает их при авторизации */
import { useDispatch, useSelector } from 'react-redux'
import { authSelectors } from '@ucheba/store/auth'
import { profileSelectors, profileThunks } from '@ucheba/store/profile'
import { useEffect, useMemo, useState } from 'react'
import { useFormikContext } from 'formik'
import { queryStringToObject } from '@ucheba/utils/helpers/router'
import { IFormProps } from './types'

/* Заполняет форму данными из профиля */
export const useFillForm = ({
  fill,
  fillSrc,
  fillByProfile,
  fillByQuery,
}: IFormProps): void => {
  const { setFieldValue, dirty, values, touched, setFieldTouched } = useFormikContext()
  const isAuth = useSelector(authSelectors.isAuth)
  const profile = useSelector(profileSelectors.entity)
  const dispatch = useDispatch()
  const [query, setQuery] = useState({})

  useEffect(() => {
    if (
      window &&
      JSON.stringify(query) !==
        JSON.stringify(queryStringToObject(window.location.search))
    ) {
      setQuery(queryStringToObject(window.location.search) || {})
    }
  })

  /* Если авторизован и данные нужны из профиля, то запрашиваем профиль */
  useEffect(() => {
    if (isAuth && fillByProfile && !profile) {
      setTimeout(() => {
        dispatch(profileThunks.get({}))
      }, 0)
    }
  }, [dispatch, fillByProfile, isAuth, profile])

  /* Получаем нужный источник данных */
  const src = useMemo(() => {
    if (fillSrc) return fillSrc

    if (fillByProfile) return profile

    if (fillByQuery) return query

    return {}
  }, [fillByProfile, fillByQuery, fillSrc, profile, query])

  /* Получаем данные формы */
  const value = useMemo(() => values || {}, [values]) as Record<string, any>

  // TODO: сделать глубокое заполнение данных, а не только верхний уровень
  /* Проставляем данные из источника в форму */
  useEffect(() => {
    if (fillByQuery) return

    const fields = Array.isArray(fill)
      ? fill
      : Object.keys(value).map((valueItem) => valueItem)

    if (fields && src && !dirty) {
      fields.forEach((field) => {
        if (touched[field]) return

        const srcValue = src[field]

        const fieldValue = typeof srcValue === 'boolean' ? String(srcValue) : srcValue

        if (fieldValue) {
          setFieldTouched(field)
          setFieldValue(field, String(fieldValue))
        }
      })
    }
  }, [dirty, fill, fillByQuery, setFieldTouched, setFieldValue, src, touched]) // не добавлять value

  /* Проставляем данные из квери параметров в форму */
  useEffect(() => {
    if (!fillByQuery) return

    const fields = Array.isArray(fill)
      ? fill
      : Object.keys(value).map((valueItem) => valueItem)

    if (fields && src) {
      fields.forEach((field) => {
        const srcValue = src[field]

        const fieldValue = typeof srcValue === 'boolean' ? String(srcValue) : srcValue
        const preparedValue = Array.isArray(fieldValue)
          ? fieldValue.map((targetFieldValue) => String(targetFieldValue))
          : fieldValue

        setFieldTouched(field)
        setFieldValue(field, preparedValue || '')
      })
    }
  }, [fill, fillByQuery, setFieldTouched, setFieldValue, src]) // не добавлять value
}
