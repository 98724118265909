import { configureStore, Slice, ThunkDispatch } from '@reduxjs/toolkit'
import { createWrapper, HYDRATE, MakeStore } from 'next-redux-wrapper'
import { Action, AnyAction, combineReducers, Reducer, Store } from 'redux'
import { ELoading } from '@ucheba/store/utils/response/types'
import { isDev } from '../core'

// обработка сериализации
const handleSerializeState = (state: Record<string, any>): string => {
  const newState = {} as Record<string, any> // собираю новый стейт для сериализации

  // цикл по общему стейту
  Object.keys(state).forEach((key) => {
    const stateSlice = state[key] as Record<string, any>

    if (stateSlice) {
      // если у стейта есть loading и он равен изначальному состоянию, то сотояние не включаем в стейт для сериализации
      if (stateSlice.loading && stateSlice.loading === ELoading.idle) {
        return
      }

      // нужно ли включать ключ в стейт для сериализации
      const isSerializeState = Object.keys(stateSlice).some((stateSliceKey) => {
        // если это пустой массив. то считаем, что данных нет
        if (Array.isArray(stateSlice[stateSliceKey])) {
          return stateSlice[stateSliceKey].length > 0
        }

        // если это пустой объект, то считаем что нет данных
        if (stateSlice[stateSliceKey] instanceof Object) {
          return Object.keys(stateSlice[stateSliceKey]).length > 0
        }

        // иначе проверям ключ на null или undefined значение
        return (
          stateSlice[stateSliceKey] !== null && stateSlice[stateSliceKey] !== undefined
        )
      })

      // если слайс общего стора нужно включать в сериализацию, то вкючаем
      if (isSerializeState) {
        newState[key] = state[key]
      }
    }
  })

  return newState
}

// обработчик вывода в консоль размера слайса
const handleSizeActionPayload = (state: Record<string, any>): void => {
  if (isDev) {
    console.log('__NEXT_REDUX_WRAPPER_HYDRATE__')

    // eslint-disable-next-line sonarjs/no-unused-collection
    const result = []

    Object.keys(state).forEach((key) => {
      const blob = new Blob([JSON.stringify(state[key])])

      const size = blob.size / 1024

      result.push({
        name: key,
        value: `${size.toFixed(2)}кб`,
      })

      if (size >= 30) {
        console.warn(`| ${key} | ${size.toFixed(2)}кб |`)
      }
    })

    // console.table(result)
  }
}

/** Создает wrapper для библиотеки next-redux-wrapper */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/explicit-function-return-type
export const createReduxWrapper = <S extends Slice[]>(slices: S) => {
  const reducers = slices.reduce((acc, slice) => {
    acc[slice.name] = slice.reducer

    return acc
  }, {})

  const rootReducer: Reducer<any, AnyAction> = combineReducers(reducers)

  const reducer = (state, action): ReturnType<typeof rootReducer> => {
    if (action.type === HYDRATE) {
      handleSizeActionPayload(action.payload)

      return {
        ...state,
        ...action.payload,
      }
    }

    return rootReducer(state, action)
  }
  type ThunkDispatchType = ThunkDispatch<RootState, any, Action<string>>
  type RootState = ReturnType<typeof rootReducer>
  type StoreType = Store<RootState, Action<string>> & {
    dispatch: ThunkDispatchType
  }

  const makeStore: MakeStore<StoreType> = () => {
    return configureStore({
      reducer,
      devTools: isDev,
    })
  }

  return createWrapper(makeStore, {
    serializeState: (state) => handleSerializeState(state),
  })
}
