import { getStateItem } from '@ucheba/store/utils/core'
import { sliceName } from './constants'
import { INotificationsState } from './types'

export default {
  notificationsQueue: getStateItem<
    INotificationsState,
    INotificationsState[typeof sliceName]['notificationsQueue']
  >(sliceName, 'notificationsQueue'),

  activeNotifications: getStateItem<
    INotificationsState,
    INotificationsState[typeof sliceName]['activeNotifications']
  >(sliceName, 'activeNotifications'),
}
