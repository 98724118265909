import { getStateError, getStateItem } from '../../utils/core'
import { sliceName } from './constants'
import { IArticleIdState } from './types'

export default {
  entity: getStateItem<IArticleIdState, IArticleIdState[typeof sliceName]['entity']>(
    sliceName,
    'entity'
  ),

  loading: getStateItem<IArticleIdState, IArticleIdState[typeof sliceName]['loading']>(
    sliceName,
    'loading'
  ),

  error: getStateError<IArticleIdState>(sliceName),
}
