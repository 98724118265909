import selectors from './selectors'
import slice from './slice'
import thunks from './thunks'
import actions from './actions'

export {
  slice as pageDataConsultSlice,
  selectors as pageDataConsultSelectors,
  actions as pageDataConsultActions,
  thunks as pageDataConsultThunks,
}
