import apiThunk, { api } from '@ucheba/utils/helpers/api'
import { sliceName } from './constants'

// Список подходящих программ
const getSuitablePrograms = apiThunk(
  `${sliceName}/getSuitablePrograms`,
  async (params) => {
    const { programTypeValue, institutionIds } = params.data

    const response = await api.get(
      `/entrance-planners/${programTypeValue}/suitable-programs`,
      params
    )

    return institutionIds ? { ...response.data, institutionIds } : response.data
  }
)

// Список программ в плане
const getPrograms = apiThunk(`${sliceName}/getPrograms`, async (params) => {
  const { programTypeValue } = params.data

  const response = await api.get(
    `/entrance-planners/${programTypeValue}/programs`,
    params
  )

  return response.data
})

// Список программ сгруппированных по учебным заведениям
const getSuitableInstitutionsPrograms = apiThunk(
  `${sliceName}/getSuitableInstitutionsPrograms`,
  async (params) => {
    const { programTypeValue } = params.data

    const response = await api.get(
      `/entrance-planners/${programTypeValue}/suitable-institutions`,
      params
    )

    return response.data
  }
)

// Список программ по вузам добавленных в план поступления
const getInstitutionsPrograms = apiThunk(
  `${sliceName}/getInstitutionsPrograms`,
  async (params) => {
    const { programTypeValue } = params.data

    const response = await api.get(
      `/entrance-planners/${programTypeValue}/institutions`,
      params
    )

    return response.data
  }
)

// Добавление программ в план
const postPrograms = apiThunk(`${sliceName}/postPrograms`, async (params) => {
  const response = await api.post(`/entrance-planners/programs`, params?.data, params)

  return { ...response.data, ...params.data }
})

// Удаление программ из плана
const deletePrograms = apiThunk(`${sliceName}/deletePrograms`, async (params) => {
  const response = await api.delete(`/entrance-planners/programs`, params)

  return { ...response.data, ...params.data }
})

// Список ID программ в плане поступления
const getProgramIds = apiThunk(`${sliceName}/getProgramIds`, async (params) => {
  const response = await api.get(`/entrance-planners/programs`, params)

  return response.data
})

export default {
  getSuitablePrograms,
  getPrograms,
  getSuitableInstitutionsPrograms,
  getInstitutionsPrograms,
  postPrograms,
  deletePrograms,
  getProgramIds,
}
