import apiThunk, { api } from '@ucheba/utils/helpers/api'
import { sliceName } from './constants'

const fetchNewsById = apiThunk(`${sliceName}/newsById`, async (params) => {
  const response = await api.get(
    `/institutions/${params.data.id}/news/${params.data.newsId}`,
    params
  )

  return response.data
})

export default {
  fetchNewsById,
}
