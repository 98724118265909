import { sliceName } from './constants'
import { IProfMotivationTestPaymentState } from './types'
import { getStateError, getStateItem } from '../../../utils/core'

export default {
  loading: getStateItem<
    IProfMotivationTestPaymentState,
    IProfMotivationTestPaymentState[typeof sliceName]['loading']
  >(sliceName, 'loading'),

  confirmationUrl: getStateItem<
    IProfMotivationTestPaymentState,
    IProfMotivationTestPaymentState[typeof sliceName]['confirmationUrl']
  >(sliceName, 'confirmationUrl'),

  hasPhone: getStateItem<
    IProfMotivationTestPaymentState,
    IProfMotivationTestPaymentState[typeof sliceName]['hasPhone']
  >(sliceName, 'hasPhone'),

  error: getStateError<IProfMotivationTestPaymentState>(sliceName),
}
