import { useCallback, useEffect, useRef, useState } from 'react'
import { useDivInBody } from '@ucheba/utils/hooks/useDivInBody'
import { getRandomNumber } from '@ucheba/utils/helpers/number'
import { IUseBottomSheetCore } from './types'
import { closingDuration } from './constants'

export const useBottomSheetCore: IUseBottomSheetCore = (props) => {
  const { open, toggleOpen, isScrollable } = props

  const innerBoxRef = useRef(null as HTMLDivElement | null)
  const innerContentRef = useRef(null as HTMLDivElement | null)
  const bottomSheetsElement = useDivInBody('bottom-sheets')
  const { current: randomNumber } = useRef(getRandomNumber(1000))

  const [dragging, setDragging] = useState(false)
  const [closing, setClosing] = useState(false)
  const [rendering, setRendering] = useState(false)

  /** Метод закрытия компонента.
   * Вызывает колбэк функцию переключения состояния компонента */
  const close = useCallback(() => {
    if (toggleOpen) toggleOpen(false)
  }, [toggleOpen])

  /** Записываем в стейт событие перетаскивания */
  const handleDrag = useCallback(() => setDragging(true), [])

  /** Когда перетаскивание заканчивается,
   * записивыем в стей остановку перетаскивания
   * и состояние закрытия */
  const handleStop = useCallback(
    (_, { lastY }) => {
      const heightInnerBox = innerBoxRef.current?.offsetHeight || 0
      const isMoved = lastY > heightInnerBox / 3
      const isClicked = lastY === 0
      const shouldClose = isScrollable ? isMoved || isClicked : isMoved

      setDragging(false)
      setClosing(shouldClose)
    },
    [isScrollable]
  )

  /** Когда компонент открывается, останавливаем скролл.
   * Когда он уничтожается, удаляем блокировку скролла */
  useEffect(() => {
    const { body } = document
    if (rendering) {
      // setTimeout(() => disableBodyScroll(innerContentRef.current), 0)

      // overflow_hidden
      body.classList.add('overflow_hidden')
    }

    if (!rendering && !open) {
      body.classList.remove('overflow_hidden')
    }

    return (): void => {
      if (!open) {
        body.classList.remove('overflow_hidden')
      }
      // setTimeout(() => {
      //   const openedBottomSheets =
      //     bottomSheetsElement?.childNodes && bottomSheetsElement.childNodes.length
      //
      //   if (!openedBottomSheets) {
      //     clearAllBodyScrollLocks()
      //   }
      // }, closingDuration + 1)
    }
  }, [bottomSheetsElement?.childNodes, rendering])

  useEffect(() => {
    if (closing && !open) {
      document.body.style.overflow = 'auto'
      document.body.classList.remove('overflow_hidden')
    }
  }, [closing])

  /** Сбрасываем статус процесса закрытия
   * при каждом открытии/закрытии компонента */
  // eslint-disable-next-line consistent-return,@typescript-eslint/ban-ts-comment
  // @ts-ignore
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (open) {
      setClosing(false)
      setRendering(true)
    } else if (rendering) {
      setClosing(true)

      const timeout = setTimeout(() => {
        setRendering(false)
        setClosing(false)
      }, closingDuration)

      return () => {
        clearTimeout(timeout)
      }
    }
  }, [open, rendering])

  /** Когда происходит процесс закрытия, закрываем компонент
   * после задержки, чтобы успела пройти анимация */
  // eslint-disable-next-line consistent-return,@typescript-eslint/ban-ts-comment
  // @ts-ignore
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (closing) {
      const timeout = setTimeout(() => close(), closingDuration)

      return () => {
        clearTimeout(timeout)
      }
    }
  }, [close, closing])

  return {
    innerBoxRef,
    innerContentRef,
    bottomSheetsElement,
    randomNumber,
    handleDrag,
    handleStop,
    dragging,
    closing,
    rendering,
  }
}
