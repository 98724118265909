/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import { IStepFormsStartState } from './types'
import { extraReducersResponse, responseInitialState } from '../../utils/response'
import { sliceName } from './constants'
import thunks from './thunks'
import { ELoading } from '../../utils/response/types'
import { IStepEntity } from '../types'

export default createSlice({
  name: sliceName,

  initialState: {
    started: false,
    ...responseInitialState,
  },

  reducers: {
    resetLoading: (state): void => {
      state.loading = ELoading.idle
    },
  },

  extraReducers: (builder) => {
    extraReducersResponse<IStepFormsStartState[typeof sliceName], IStepEntity>({
      builder,
      thunk: thunks.start,
      fulfilled: (state) => {
        state.started = true
      },
      rejected: (state) => {
        state.started = true
      },
    })
  },
})
