import { NextPageContext } from 'next/dist/shared/lib/utils'
import {
  notificationsActions,
  notificationsSelectors,
} from '@ucheba/ui/components/Notice/store'
import { removeNoticeCookie, setNoticeCookie } from '@ucheba/ui/components/Notice/bll'
import { Store } from 'redux'
import { getParsedCookies } from '../helpers/core'
import { noticeCookie } from '../constants/core'

export const addNotification = (props: {
  ctx: NextPageContext
  id: string
  addToCookie?: boolean
}): void => {
  const { ctx, id, addToCookie = false } = props

  ctx?.store?.dispatch(notificationsActions.addNotificationToQueueById({ id }))

  /** Если какие-то нотисы уже добавлены в куки, то не переписывать куки */
  if (addToCookie) {
    setNoticeCookie({ ctx, id })
  }
}

export const removeNotification = (ctx: NextPageContext, id: string): void => {
  ctx?.store?.dispatch(notificationsActions.removeNotificationFromQueueById(id))

  removeNoticeCookie({ ctx, id })
}

const enableNotifications = async (ctx: NextPageContext, store: Store): Promise<any> => {
  const { getState } = store

  const parsedCookies = getParsedCookies(ctx)
  const noticeCookiesArray =
    parsedCookies[noticeCookie] && JSON.parse(parsedCookies[noticeCookie])

  let notices: any[] = []

  if (notificationsSelectors.notificationsQueue(getState()).length) {
    notices = notificationsSelectors.notificationsQueue(getState()).map((el) => el.id)
  }

  if (noticeCookiesArray?.length) {
    notices = noticeCookiesArray
  }

  if (notices?.length) {
    notices.forEach((noticeId) => {
      addNotification({
        ctx,
        id: noticeId,
        addToCookie: !noticeCookiesArray?.length,
      })
    })
  }

  return true
}

export default enableNotifications
