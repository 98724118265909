import apiThunk, { api } from '@ucheba/utils/helpers/api'
import { sliceName } from './constants'

const fetch = apiThunk(`${sliceName}/fetch`, async (params) => {
  const response = await api.get('subject/oge/schedule', params)
  return response.data.map((item) => ({
    ...item,
    id: item.date,
  }))
})

export default { fetch }
