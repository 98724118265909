import ym from 'react-yandex-metrika'
import * as ga from '../ga'
import * as gtm from '../gtm'
import { getParsedCookies, isBrowser, isDev, isServer } from '../../core'
import {
  EAnalyticEventTargets,
  IAnalyticEvent,
  IAnalyticEventProps,
  TGAEventName,
} from './types'
import { api } from '../../api'
import { advUserIdCookie, authCookieToken } from '../../../constants/core'
import { isTouch } from '../../core/server'

// const getVKGoalName = (eventName: IAnalyticEventProps['eventName']): string => {
//   switch (eventName) {
//     case EAnalyticEvents.eventRegistrationSuccess:
//     case EAnalyticEvents.userAuthSuccess:
//       return 'conversion'
//     default:
//       return ''
//   }
// }
//
// const getFBEventName = (eventName: IAnalyticEventProps['eventName']): string => {
//   switch (eventName) {
//     case EAnalyticEvents.consultSubmitLead:
//       return 'Lead'
//     default:
//       return ''
//   }
// }

// const sendVKEvent = (props: Pick<IAnalyticEventProps, 'eventName'>): void => {
//   const goal = getVKGoalName(props.eventName)
//
//   if (goal) vk.goal(goal)
//
//   vk.event(props.eventName)
// }

// const sendFBEvent = (props: Pick<IAnalyticEventProps, 'eventName'>): void => {
//   const event = getFBEventName(props.eventName)
//
//   if (event) fb.event(getFBEventName(event))
// }

const getGAEventName = (eventName: IAnalyticEventProps['eventName']): TGAEventName => {
  return eventName.split('__').reduce((acc, item, index) => {
    switch (index) {
      case 0:
        acc.category = item
        break
      case 1:
        acc.action = item
        break
      case 2:
        acc.label = item
        break
      default:
    }

    return acc
  }, {} as TGAEventName)
}

const sendYMEvent = (props: Pick<IAnalyticEventProps, 'eventName' | 'value'>): void => {
  ym('reachGoal', props.eventName, props.value)
}

const sendGAEvent = (props: Pick<IAnalyticEventProps, 'eventName' | 'value'>): void => {
  ga.event({
    ...getGAEventName(props.eventName),
    value: 1,
  })
}

const sendGTMEvent = (props: Pick<IAnalyticEventProps, 'eventName'>): void => {
  gtm.event(props.eventName)
}

const fetchEvent = async ({ eventName, section }: IAnalyticEventProps): Promise<void> => {
  const parsedCookie = getParsedCookies()
  const token = parsedCookie?.[authCookieToken] || ''
  const visitorId = parsedCookie?.[advUserIdCookie] || ''

  const userTags = (() => {
    if (isBrowser) {
      const result: Record<string, unknown> = JSON.parse(
        localStorage.getItem('userTags') || '{}'
      )

      return Object.keys(result).reduce(
        (acc, el) => {
          acc.push({
            slug: el,
            assignedAt: String(result[el]),
          })

          return acc
        },
        [] as {
          slug: string
          assignedAt: string
        }[]
      )
    }
    return {}
  })()

  const location = (() => {
    if (parsedCookie?.locationIds) {
      return JSON.parse(parsedCookie?.locationIds)[0]
    }

    // если пользователь не выбрал локацию, то отправляем ид 9553 = Россия
    return 9553
  })()

  const isTouchClient = await isTouch(null)

  const userAgentPlatform = (() => {
    if (isTouchClient) {
      return 'web_mobile'
    }

    return 'web_desktop'
  })()

  const params = {
    data: [
      {
        route: window.location.pathname,
        section: section || '',
        event: eventName,
        platform: userAgentPlatform,
        locationId: location,
        userTags,
        visitorId,
      },
    ],
    params: {},
    props: {
      cookie: {
        ...parsedCookie,
        [authCookieToken]: token,
      },
    },
  }

  try {
    await api.post(`/event`, params.data, params)
  } catch (err) {
    console.error('catch error', err)
  }
}

export const analyticEvent: IAnalyticEvent = ({ targets, eventName, value, section }) => {
  if (isServer || isDev) return
  console.log(`отправка события в аналитику: ${eventName}`)

  if (!targets) {
    // sendVKEvent({ eventName })
    sendYMEvent({ eventName, value })
    sendGAEvent({ eventName, value })
    sendGTMEvent({ eventName })
    fetchEvent({ eventName, section })
    // sendFBEvent({ eventName })

    return
  }

  targets.forEach((targetName) => {
    switch (targetName) {
      case EAnalyticEventTargets.ur:
        fetchEvent({ eventName, section })
        break
      case EAnalyticEventTargets.gtm:
        sendGTMEvent({ eventName })
        break
      // case EAnalyticEventTargets.vk:
      //   sendVKEvent({ eventName })
      //   break
      case EAnalyticEventTargets.ym:
        sendYMEvent({ eventName, value })
        break
      case EAnalyticEventTargets.ga:
        sendGAEvent({ eventName, value })
        break
      // case EAnalyticEventTargets.fb:
      //   sendFBEvent({ eventName })
      //   break
      default:
        console.warn(
          'Передайте в targets названия сервисов аналитики (EAnalyticEventTargets), либо удалите targets и событие отправится во все сразу.'
        )
    }
  })
}
