/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import { IProfileShort, IProfileShortState } from './types'
import { sliceName } from './constants'
import { extraReducersResponse, responseInitialState } from '../../utils/response'
import thunks from './thunks'
import { ELoading } from '../../utils/response/types'

export default createSlice({
  name: sliceName,

  initialState: {
    entity: null as IProfileShort | null,
    ...responseInitialState,
  },

  reducers: {
    resetLoading: (state): void => {
      state.loading = ELoading.idle
    },
  },

  extraReducers: (builder) => {
    extraReducersResponse<IProfileShortState[typeof sliceName], IProfileShort>({
      builder,
      thunk: thunks.fetch,
      fulfilled: (state, action) => {
        state.entity = action.payload
      },
    })
  },
})
