import { useMemo } from 'react'
import { useRouter } from 'next/router'
import { coreQuery } from '../constants/queries'

export const useAuthHref = (): string => {
  const router = useRouter()
  return useMemo(
    () => `/auth?${coreQuery.redirect}=${router.asPath || '/'}`,
    [router.asPath]
  )
}
