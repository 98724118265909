/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import { sliceName } from './constants'
import { extraReducersResponse, responseInitialState } from '../../utils/response'
import thunks from './thunks'

import {
  IDivisions,
  IDivisionsFacet,
  IDivisionsState,
  IFacetDivision,
  IParentType,
} from './types'

export default createSlice({
  name: sliceName,

  initialState: {
    divisions: {} as IFacetDivision,
    facets: {} as IParentType[],
    ...responseInitialState,
  },

  reducers: {},

  extraReducers: (builder) => {
    extraReducersResponse<IDivisionsState[typeof sliceName], IDivisionsFacet>({
      builder,
      thunk: thunks.fetchDivisions,
      fulfilled: (state, { payload }) => {
        state.divisions = {
          items: payload.data.items,
          total: payload.data.total,
        }
      },
    })
    extraReducersResponse<IDivisionsState[typeof sliceName], IDivisions>({
      builder,
      thunk: thunks.fetchDivisionsFacest,
      fulfilled: (state, { payload }) => {
        state.facets = payload.facets.parentTypes
      },
    })
  },
})
