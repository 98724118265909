import styled, { StyledComponentProps, StyledProps } from 'styled-components'
import { IPropOnClick } from '@ucheba/utils/helpers/components/prop.types'
import { TUseToggleFunction } from '@ucheba/utils/hooks/useToggle'
import {
  EModActive,
  EModAutocomplete,
  EModOpen,
  IModActive,
  IModAutocomplete,
  IModOpen,
} from '@ucheba/utils/helpers/styles/mod.types'
import { sm } from '@ucheba/utils/helpers/styles/mixins'
import { sv } from '@ucheba/utils/helpers/styles/variables'
import { ISelectProps } from '../types'
import { IDropdownMenuProps } from '../../DropdownMenu/types'
import { IButtonProps } from '../../Button/types'

const HiddenTextField = styled.input<StyledProps<any>>``

const HiddenTextFieldActivator = styled.div`
  height: 0;
  width: 0;
  border: 0;
  overflow: hidden;
  opacity: 0;
`

const SelectMenuItem = styled.button<StyledProps<IButtonProps>>`
  && {
    height: auto;
    padding-right: ${sv.spacing.medium};
  }
`

const NativeSelect = styled.select<
  StyledComponentProps<'select', Pick<ISelectProps, 'value'>, any, any>
>`
  display: none;
`

const Activator = styled.div<
  StyledProps<IModAutocomplete & IPropOnClick<TUseToggleFunction>>
>`
  position: relative;

  input {
    padding-right: ${sv.spacing.large};
  }

  ${sm.mod(EModAutocomplete.false)`
    cursor: pointer;

    input {
      cursor: pointer;
    }
  `};
`

const Icon = styled.svg<StyledProps<IModOpen>>`
  transition: ${sv.duration.fast};
  ${sm.position.verticalCenter};
  right: ${sv.spacing.xsmall};
  ${sm.arrow.down(4, 'gray60')};

  ${sm.mod(EModOpen.true)`
    transform: rotate(180deg);
    margin-top: -2px;
  `};
`

const SelectedText = styled.div<StyledProps<IModActive>>`
  pointer-events: none;
  ${sm.position.absoluteFill};
  padding: ${sv.spacing.xsmall};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-right: ${sv.spacing.large};

  ${sm.mod(EModActive.false)`
    color: ${sv.color.gray60};
  `};

  > * {
    display: inline-block;
    overflow: hidden;
    width: 100%;
    text-overflow: ellipsis;
  }
`

const SelectMenu = styled.div<StyledProps<IDropdownMenuProps>>`
  && {
    top: 100%;
    left: 0;
    margin-top: ${sv.spacing.xxsmall};
  }
`

const Block = styled.div<StyledProps<any>>`
  position: relative;
`

export default {
  Block,
  Activator,
  NativeSelect,
  SelectMenu,
  SelectedText,
  Icon,
  SelectMenuItem,
  HiddenTextFieldActivator,
  HiddenTextField,
}
