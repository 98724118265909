import { ITagsTreeState } from './types'
import { sliceName } from './constants'
import { getStateError, getStateItem } from '../../utils/core'
import { tagsTreeAdapter } from './slice'

const adapterSelectors = tagsTreeAdapter.getSelectors<ITagsTreeState>(
  (state) => state[sliceName]
)

export default {
  ...adapterSelectors,

  loading: getStateItem<ITagsTreeState, ITagsTreeState[typeof sliceName]['loading']>(
    sliceName,
    'loading'
  ),

  error: getStateError<ITagsTreeState>(sliceName),
}
