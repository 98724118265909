export enum EProgramTypes {
  middle = 1000, // среднее (школа)
  middleKind = 21, // детский сад
  middleNS = 22, // начальное (4 класса)
  middleMS = 23, // среднее (9 классов)
  middleHS = 24, // полное (11 классов)

  vocational = 1001, // среднее профессиональное
  vocationalSpo = 11, // подготовка специалистов
  vocationalNpo = 12, // подготовка квалифицированных рабочих

  higher = 1002, // высшее
  higherBachelor = 1, // бакалавриат
  higherSpeciality = 2, // специалитет
  master = 3, // магистратура
  aspirant = 4, // аспирантура

  mba = 1005, // программа MBA
  mbaGeneral = 51, // General MBA
  mbaExecutive = 52, // Executive MBA
  mbaSpec = 53, // MBA-специализация
  mbaMini = 54, // Mini-MBA
  dba = 55, // DBA
  mpa = 56, // MPA
  bba = 57, // BBA

  prepare = 1003, // подготовка к поступлению
  prepareSchool = 31, // подготовка к школе
  prepareOGE = 32, // подготовка к ОГЭ
  prepareEGE = 33, // подготовка к ЕГЭ
  prepareCollage = 34, // подготовка в колледж
  prepareVUZ = 35, // подготовка в вуз
  prepareMaster = 36, // подготовка в магистратуру
  prepareMBA = 37, // подготовка к MBA
  prepareOlimp = 38, // подготовка к олимпиадам

  course = 1004, // курс
  course1 = 41, // базовый курс
  course2 = 42, // повышение квалификации
  course3 = 43, // профессиональная переподготовка

  event = 1006, // мероприятие
  event1 = 61, // тренинг
  event2 = 62, // семинар
  event3 = 63, // мастер-класс
  event4 = 64, // лекция
  event5 = 65, // хакатон
}
