/* eslint-disable no-param-reassign */
import { createEntityAdapter, createSlice, EntityState } from '@reduxjs/toolkit'
import { IResponseInitialState } from '../../../utils/response/types'
import { extraReducersResponse, responseInitialState } from '../../../utils/response'
import { IEgeSubjectsSchedule } from './types'
import { sliceName } from './constants'
import thunks from './thunks'

export const egeSubjectsScheduleAdapter = createEntityAdapter<IEgeSubjectsSchedule>()

export default createSlice({
  name: sliceName,

  initialState:
    egeSubjectsScheduleAdapter.getInitialState<IResponseInitialState>(
      responseInitialState
    ),

  reducers: {},

  extraReducers: (builder) => {
    extraReducersResponse<EntityState<IEgeSubjectsSchedule>, IEgeSubjectsSchedule[]>({
      builder,
      thunk: thunks.fetch,
      fulfilled: (state, action) => {
        egeSubjectsScheduleAdapter.setAll(state, action.payload)
      },
    })
  },
})
