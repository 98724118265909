import apiThunk, { api } from '@ucheba/utils/helpers/api'
import { sliceName } from './constants'

const fetch = apiThunk(`${sliceName}/fetch`, async (params) => {
  const { subId } = params.data
  const query = subId ? `?subId=${subId}` : ''
  const response = await api.get(
    `/institutions/rankings/${params.data.id}/participants${query}`,
    params
  )

  return {
    id: subId || params.data.id,
    participants: response.data,
  }
})

export default { fetch }
