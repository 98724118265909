/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import { sliceName } from './constants'
import { extraReducersResponse, responseInitialState } from '../../utils/response'
import { IEntityIds, IEntityUzIds, IPlannerPlan, IPlannerPlanState } from './types'
import thunks from './thunks'

export default createSlice({
  name: sliceName,

  initialState: {
    entity: null as IPlannerPlan | null,
    entityProgramIds: [] as number[],
    entityUzIds: null as IEntityUzIds['ids'],
    ...responseInitialState,
  },

  reducers: {
    incrementProgramTotal: (state, action): void => {
      if (state.entity?.institutions) {
        state.entity.institutions = state.entity.institutions.map((uz) => {
          if (uz.id === action.payload.id) {
            return {
              ...uz,
              programsTotal: uz.programsTotal + 1,
            }
          }

          return uz
        })
      }
    },

    decrementProgramTotal: (state, action): void => {
      if (state.entity?.institutions) {
        state.entity.institutions = state.entity.institutions.map((uz) => {
          if (uz.id === action.payload.id) {
            return {
              ...uz,
              programsTotal: uz.programsTotal - 1,
            }
          }

          return uz
        })
      }
    },

    reset: (state) => {
      state.entityProgramIds = []
    },
  },

  extraReducers: (builder) => {
    extraReducersResponse<IPlannerPlanState[typeof sliceName], IPlannerPlan>({
      builder,
      thunk: thunks.getPlan,
      fulfilled: (state, { payload }) => {
        state.entity = payload
      },
    })

    extraReducersResponse<IPlannerPlanState[typeof sliceName], IEntityIds['ids']>({
      builder,
      thunk: thunks.getProgramIds,
      fulfilled: (state, { payload }) => {
        state.entityProgramIds = payload
      },
    })

    extraReducersResponse<IPlannerPlanState[typeof sliceName], IEntityUzIds['ids']>({
      builder,
      thunk: thunks.getUzIds,
      fulfilled: (state, { payload }) => {
        state.entityUzIds = payload
      },
    })
  },
})
