import { createSlice } from '@reduxjs/toolkit'
import { sliceName } from './constants'
import {
  IEntityIds,
  IPlannerProgramsState,
  IProgramsEntity,
  IProgramsIntoInstitutionEntity,
} from './types'
import { extraReducersResponse, responseInitialState } from '../../utils/response'
import thunks from './thunks'

export default createSlice({
  name: sliceName,

  initialState: {
    entitySuitablePrograms: null as IProgramsEntity | null,
    entitySuitableInstitutionsPrograms: null as IProgramsIntoInstitutionEntity | null,
    entityPrograms: null as IProgramsEntity | null,
    entityInstitutionsPrograms: null as IProgramsIntoInstitutionEntity | null,
    entityProgramsIds: {
      entityIds: [],
    } as IEntityIds,
    entityInstitutionsIds: {
      entityIds: [],
    } as IEntityIds,
    offset: 0,
    excludeIds: [] as number[],
    excludeInstitutionsIds: [] as number[],
    ...responseInitialState,
  },

  reducers: {
    setOffset: (state, action): void => {
      // eslint-disable-next-line no-param-reassign
      state.offset = action.payload
    },
    setExcludeIds: (state, action): void => {
      // eslint-disable-next-line no-param-reassign
      state.excludeIds = action.payload
    },
    setProgramsIds: (state, action): void => {
      // eslint-disable-next-line no-param-reassign
      state.entityProgramsIds.entityIds = action.payload
    },
    reset: (state) => {
      // eslint-disable-next-line no-param-reassign
      state.offset = 0

      // eslint-disable-next-line no-param-reassign
      state.entitySuitablePrograms = null

      // eslint-disable-next-line no-param-reassign
      state.entitySuitableInstitutionsPrograms = null

      // eslint-disable-next-line no-param-reassign
      state.entityInstitutionsPrograms = null

      // eslint-disable-next-line no-param-reassign
      state.entityPrograms = null

      // eslint-disable-next-line no-param-reassign
      state.entityProgramsIds = { entityIds: [] }

      // eslint-disable-next-line no-param-reassign
      state.entityInstitutionsIds = { entityIds: [] }

      // eslint-disable-next-line no-param-reassign
      state.excludeInstitutionsIds = []

      // eslint-disable-next-line no-param-reassign
      state.excludeIds = []
    },
  },

  extraReducers: (builder) => {
    extraReducersResponse<IPlannerProgramsState[typeof sliceName], IProgramsEntity>({
      builder,
      thunk: thunks.getSuitablePrograms,
      fulfilled: (state, action) => {
        const institutionIds = action.payload?.institutionIds

        if (institutionIds && state.entityInstitutionsPrograms) {
          // eslint-disable-next-line no-param-reassign
          state.entityInstitutionsPrograms.items =
            state.entityInstitutionsPrograms.items.map((institution) => {
              if (institutionIds.includes(institution.id)) {
                return {
                  ...institution,
                  programs: [...institution.programs, ...action.payload.items],
                }
              }

              return institution
            })
        }

        if (institutionIds && state.entitySuitableInstitutionsPrograms) {
          // eslint-disable-next-line no-param-reassign
          state.entitySuitableInstitutionsPrograms.items =
            // eslint-disable-next-line sonarjs/no-identical-functions
            state.entitySuitableInstitutionsPrograms.items.map((institution) => {
              if (institutionIds.includes(institution.id)) {
                return {
                  ...institution,
                  programs: [...institution.programs, ...action.payload.items],
                }
              }

              return institution
            })
        }

        const programs = state.entitySuitablePrograms?.items
          ? [...state.entitySuitablePrograms.items, ...action.payload.items]
          : action.payload.items

        // eslint-disable-next-line no-param-reassign
        state.offset += action.payload.items.length

        // eslint-disable-next-line no-param-reassign
        state.entitySuitablePrograms = {
          ...action.payload,
          items: programs,
        }
      },
    })

    extraReducersResponse<
      IPlannerProgramsState[typeof sliceName],
      IProgramsIntoInstitutionEntity
    >({
      builder,
      thunk: thunks.getSuitableInstitutionsPrograms,
      fulfilled: (state, action) => {
        const institutions = state.entitySuitableInstitutionsPrograms?.items
          ? [...state.entitySuitableInstitutionsPrograms.items, ...action.payload.items]
          : action.payload.items

        // eslint-disable-next-line no-param-reassign
        state.offset += action.payload.items.length

        // eslint-disable-next-line no-param-reassign
        state.entitySuitableInstitutionsPrograms = {
          ...action.payload,
          items: institutions,
        }
      },
    })

    extraReducersResponse<IPlannerProgramsState[typeof sliceName], IProgramsEntity>({
      builder,
      thunk: thunks.getPrograms,
      fulfilled: (state, action) => {
        // eslint-disable-next-line no-param-reassign
        state.entityPrograms = action.payload

        // eslint-disable-next-line no-param-reassign
        state.entityProgramsIds.entityIds = action.payload.items.map(
          (program) => program.id
        )
      },
    })

    extraReducersResponse<
      IPlannerProgramsState[typeof sliceName],
      IProgramsIntoInstitutionEntity
    >({
      builder,
      thunk: thunks.getInstitutionsPrograms,
      fulfilled: (state, action) => {
        // eslint-disable-next-line no-param-reassign
        state.entityInstitutionsPrograms = action.payload

        // eslint-disable-next-line no-param-reassign
        state.entityInstitutionsIds.entityIds = action.payload.items.map(
          (institution) => institution.id
        )
      },
    })

    extraReducersResponse<IPlannerProgramsState[typeof sliceName], IEntityIds>({
      builder,
      thunk: thunks.postPrograms,
      fulfilled: (state, action) => {
        // eslint-disable-next-line no-param-reassign
        state.entityProgramsIds.entityIds = [
          ...action.payload.entityIds,
          ...state.entityProgramsIds.entityIds,
        ]

        if (action.payload.institutionId) {
          if (
            !state.entityInstitutionsIds.entityIds.includes(action.payload.institutionId)
          ) {
            // eslint-disable-next-line no-param-reassign
            state.entityInstitutionsIds.entityIds = [
              action.payload.institutionId,
              ...state.entityInstitutionsIds.entityIds,
            ]
          }

          // eslint-disable-next-line no-param-reassign
          state.excludeInstitutionsIds = state.excludeInstitutionsIds.filter(
            (_id) => _id !== action.payload.institutionId
          )

          if (action.payload.excludeIds && action.payload.excludeIds.length > 0) {
            // eslint-disable-next-line no-param-reassign
            state.excludeInstitutionsIds = state.excludeInstitutionsIds.filter((id) =>
              action.payload.excludeIds?.includes(id)
            )
          }
        } else if (
          state.entityPrograms?.items?.some(
            (item) => item.id === action.payload.entityIds[0]
          )
        ) {
          // eslint-disable-next-line no-param-reassign
          state.excludeIds = state.excludeIds.filter(
            (id) => id !== action.payload.entityIds[0]
          )
        } else {
          // eslint-disable-next-line no-param-reassign
          state.offset -= 1
        }
      },
    })

    extraReducersResponse<IPlannerProgramsState[typeof sliceName], IEntityIds>({
      builder,
      thunk: thunks.deletePrograms,
      fulfilled: (state, action) => {
        // eslint-disable-next-line no-param-reassign
        state.entityProgramsIds.entityIds = state.entityProgramsIds.entityIds.filter(
          (id) => Number(id) !== Number(action.payload.entityIds[0])
        )

        // если есть ид вуза - то работаем с программами сгруппированные по вузам
        if (action.payload.institutionId) {
          if (action.payload.excludeIds && action.payload.excludeIds.length > 0) {
            // eslint-disable-next-line no-param-reassign
            state.excludeInstitutionsIds = [
              ...state.excludeInstitutionsIds,
              ...action.payload.excludeIds,
            ]

            // eslint-disable-next-line no-param-reassign
            state.entityInstitutionsIds.entityIds =
              state.entityInstitutionsIds.entityIds.filter(
                (id) => Number(id) !== Number(action.payload.institutionId)
              )
          }
        } else if (
          state.entityPrograms?.items?.some(
            (item) => item.id === action.payload.entityIds[0]
          )
        ) {
          // eslint-disable-next-line no-param-reassign
          state.excludeIds = [...state.excludeIds, ...action.payload.entityIds]
        } else {
          // eslint-disable-next-line no-param-reassign
          state.offset += 1
        }
      },
    })

    extraReducersResponse<
      IPlannerProgramsState[typeof sliceName],
      IEntityIds['entityIds']
    >({
      builder,
      thunk: thunks.getProgramIds,
      fulfilled: (state, action) => {
        // eslint-disable-next-line no-param-reassign
        state.entityProgramsIds.entityIds = action.payload
      },
    })
  },
})
