import styled, { StyledProps } from 'styled-components'

import { sv } from '@ucheba/utils/helpers/styles/variables'

import { Button } from '@ucheba/ui/components/Button/desktop'

import { EFooterColor, IModFooterColor } from '../types'

const LogoLink = styled.a``

const FooterLinks = styled.div`
  a {
    margin-right: ${sv.spacing.xsmall};

    &:last-child {
      margin-right: 0;
    }
  }
`

const SocialLinks = styled.div`
  display: flex;
  align-items: center;
  a {
    margin-right: ${sv.spacing.small};
    height: 24px;

    &:last-child {
      margin-right: 0;
    }
  }
`

const Inner = styled.div<{ color: string }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: ${sv.spacing.large};
  padding-bottom: ${sv.spacing.large};
  border-top: 1px solid
    ${({ color }) =>
      color === 'black' || color === 'navy' ? sv.color.white25 : 'rgba(3, 15, 35, 0.15)'};
`

const Block = styled.footer<StyledProps<IModFooterColor>>`
  background-color: ${({ color }) => sv.color[color]};

  div,
  a,
  a:hover {
    color: ${({ color }) => sv.color[EFooterColor[color]]};
  }

  ${SocialLinks} {
    svg {
      color: ${({ color }) => sv.color[EFooterColor[color]]};

      opacity: ${({ color }) => (color === 'black' ? 0.6 : 0.7)};
    }
  }
`

const TopBlock = styled.div`
  width: 100%;
  display: flex;
  column-gap: 50px;
  padding: 32px 0;
`

const ButtonStyled = styled(Button)<StyledProps<any>>`
  height: ${(props) => (props.marginTop ? 'unset' : 'auto')};
  padding: 8px 12px;

  font-size: 16px;
`

const Column = styled.div`
  width: 33.333%;
  display: flex;
  flex-direction: column;
`

const Title = styled.div`
  font-size: 24px;
  font-weight: bold;
  line-height: 32px;
`

const Links = styled.div<{ isShow: boolean }>`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin: 8px 0;

  a {
    // начиная с 7 ого применяем свойство
    &:nth-child(n + 7) {
      display: ${({ isShow }) => (isShow ? 'block' : 'none')};
    }
  }
`

const ShowAllLinks = styled.div<StyledProps<any>>`
  width: 100%;
  color: ${(props) =>
    props.color === 'black' ? 'rgba(255, 255, 255, 0.5)' : '#030f2380'} !important;
  font-size: 16px;
  line-height: 1.5;
  cursor: pointer;
  text-align: ${(props) => (props.textAlign ? 'center' : 'unset')};
`

export {
  Block,
  Inner,
  SocialLinks,
  FooterLinks,
  LogoLink,
  TopBlock,
  ButtonStyled,
  Column,
  Title,
  Links,
  ShowAllLinks,
}
