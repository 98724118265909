import apiThunk, { api } from '@ucheba/utils/helpers/api'
import { getFullRouterPath, objectToQueryString } from '@ucheba/utils/helpers/router'
import { sliceName } from './constants'

const fetch = apiThunk(`${sliceName}/fetch`, async (params) => {
  const { id, ...queryParams } = params?.data || {}

  const response = await api.get(
    getFullRouterPath(
      `specialities/${id}/similar`,
      objectToQueryString(queryParams || {})
    ),
    params
  )

  return response.data
})

export default { fetch }
