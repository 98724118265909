import styled from 'styled-components'
import { sm } from '@ucheba/utils/helpers/styles/mixins'
import { EModSpacing } from '@ucheba/utils/helpers/styles/mod.types'
import { sv } from '@ucheba/utils/helpers/styles/variables'

const Block = styled.div`
  ${sm.resetLastMb};

  ${Object.keys(EModSpacing).map(
    (item) => sm.mod(EModSpacing[item])`
        & p:not(:last-child) {
          margin-bottom: ${sv.spacing[item]}
        }
      `
  )};
`

export default { Block }
