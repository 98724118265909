import React, { FC, memo } from 'react'
import { Portal } from 'react-portal'
import SC from '../base/Dialog.styles'
import { IDialogProps } from '../types'
import { Box } from '../../Box/desktop'
import CloseIcon from '../../../icons/icon__close.svg'
import { Button } from '../../Button/desktop'
import { useDialogCore } from '../bll'

const { Content, Wrapper, Backdrop, ButtonClose, Box: BoxSC, BoxScroll } = SC

/** Диалоговое окно (попап) */
const Dialog: FC<IDialogProps> = (props) => {
  const {
    children,
    open,
    onOpen,
    onClose,
    size = 'medium',
    overflow,
    spacing = 'medium',
    radius = 'large',
    blur = false,
    scrollY,
  } = props

  const { close, rendering, isOpen, ref, dialogElement } = useDialogCore({
    onOpen,
    onClose,
    open,
  })

  if (!rendering) return null

  return (
    <Portal node={dialogElement}>
      <Backdrop close={!isOpen} blur={blur} />

      <Wrapper onClick={close}>
        <Content
          scrollY={scrollY}
          size={size}
          ref={ref}
          onClick={(e) => {
            e.stopPropagation()
          }}
        >
          <Box
            overflow={overflow}
            as={scrollY ? BoxScroll : BoxSC}
            color='white'
            shadowSize='medium'
            spacing={spacing}
            radius={radius}
          >
            {children}

            <ButtonClose
              shape='circle'
              icon
              size='xsmall'
              color='gray5'
              as={Button}
              onClick={close}
            >
              <CloseIcon color='gray60' width='16px' height='16px' />
            </ButtonClose>
          </Box>
        </Content>
      </Wrapper>
    </Portal>
  )
}

export default memo(Dialog)
