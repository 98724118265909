/* eslint-disable no-param-reassign */
import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { extraReducersResponse, responseInitialState } from '../../../utils/response'
import { sliceName } from './constants'
import thunks from './thunks'
import {
  IItemOfSpecialitiesIdSimilar,
  ISpecialitiesIdSimilar,
  ISpecialitiesIdSimilarState,
} from './types'
import { IResponseInitialState } from '../../../utils/response/types'

export const specialitiesIdSimilarAdaptor =
  createEntityAdapter<IItemOfSpecialitiesIdSimilar>()

export default createSlice({
  name: sliceName,

  initialState: specialitiesIdSimilarAdaptor.getInitialState<
    IResponseInitialState & { total: ISpecialitiesIdSimilar['total'] }
  >({
    total: 0,
    ...responseInitialState,
  }),

  reducers: {},

  extraReducers: (builder) => {
    extraReducersResponse<
      ISpecialitiesIdSimilarState[typeof sliceName],
      ISpecialitiesIdSimilar
    >({
      builder,
      thunk: thunks.fetch,
      fulfilled: (state, action) => {
        state.total = action.payload.total

        specialitiesIdSimilarAdaptor.setAll(state, action.payload.items)
      },
    })
  },
})
