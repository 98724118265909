import selectors from './selectors'
import slice from './slice'
import thunks from './thunks'
import actions from './actions'

export {
  slice as profileRegistrationSlice,
  selectors as profileRegistrationSelectors,
  thunks as profileRegistrationThunks,
  actions as profileRegistrationActions,
}
