import { ISubjectsState } from './types'
import { subjectsAdapter } from './slice'
import { sliceName } from './constants'
import { getStateError } from '../utils/core'

const adapterSelectors = subjectsAdapter.getSelectors<ISubjectsState>(
  (state) => state[sliceName]
)

export default {
  ...adapterSelectors,

  error: getStateError<ISubjectsState>(sliceName),
}
