import selectors from './selectors'
import slice from './slice'
import thunks from './thunks'
import actions from './actions'

export {
  slice as authPhoneCodeSlice,
  selectors as authPhoneCodeSelectors,
  thunks as authPhoneCodeThunks,
  actions as authPhoneCodeActions,
}
