/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import { IViewState } from './types'
import { extraReducersResponse, responseInitialState } from '../utils/response'
import { sliceName } from './constants'
import thunks from './thunks'

export default createSlice({
  name: sliceName,

  initialState: {
    ...responseInitialState,
  },

  reducers: {},

  extraReducers: (builder) => {
    extraReducersResponse<IViewState[typeof sliceName], IViewState>({
      builder,
      thunk: thunks.view,
      fulfilled: () => null,
    })
  },
})
