import { IEventsRubricsState } from './types'
import { eventsRubricsAdapter } from './slice'
import { sliceName } from './constants'
import { getStateError } from '../../utils/core'

const adapterSelectors = eventsRubricsAdapter.getSelectors<IEventsRubricsState>(
  (state) => state[sliceName]
)

export default {
  ...adapterSelectors,

  error: getStateError<IEventsRubricsState>(sliceName),
}
