import apiThunk, { api } from '@ucheba/utils/helpers/api'
import { sliceName } from './constants'
import { setAuthCookie } from '../bll'

const fetch = apiThunk(`${sliceName}/code`, async (params) => {
  const response = await api.post(`/auth/email`, params?.data, params)

  if (response.data) {
    setAuthCookie({ ctx: params?.ctx, token: response.data.token })
  }

  return response.data
})

export default { fetch }
