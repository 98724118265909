import { sliceName } from './constants'
import { getStateError, getStateItem } from '../../utils/core'
import { IEventsSearchState, IEventsSearch } from './types'

export default {
  entity: getStateItem<IEventsSearch, IEventsSearch>(sliceName, 'entity'),

  loading: getStateItem<
    IEventsSearchState,
    IEventsSearchState[typeof sliceName]['loading']
  >(sliceName, 'loading'),

  error: getStateError<IEventsSearchState>(sliceName),
}
