import { IModFontWeight } from '@ucheba/utils/helpers/styles/mod.types'

export enum EHeaderColor {
  black = 'black',
  white = 'white9',
  pine = 'green5',
  maroon = 'pink5',
  coffee = 'orange5',
}

export enum EModHeaderColor {
  black = 'color=black',
  white = 'color=white',
  pine = 'color=pine',
  maroon = 'color=maroon',
  coffee = 'color=coffee',
}

export enum EModLocationIconColor {
  black = 'gray50',
  white = 'white50',
  pine = 'pine60',
  maroon = 'maroon60',
  coffee = 'coffee60',
}

export interface IModHeaderColor {
  /** Цвет текста шапки */
  color?: keyof typeof EModHeaderColor
}

export interface IHeaderProps extends IModHeaderColor {
  toggleSidebar?: () => void
  activeMenuItemHref?: string
}

export interface IHeaderMenuProps extends IModFontWeight {
  sectionNavigation: {
    name: string
    href: string
  }[]
}

export interface IUseAuthContent {
  (href: string): {
    isAuth: boolean
    userName: string
    authLinkHref: string
  }
}

export enum EHeaderItemActiveColor {
  black = 'blue',
  white = 'white',
  pine = 'blue',
  maroon = 'blue',
  coffee = 'blue',
}
