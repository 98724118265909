/* eslint-disable no-param-reassign */
import { createSlice, createEntityAdapter } from '@reduxjs/toolkit'
import { IPersonalAchievementsState, IAchievement } from './types'
import { extraReducersResponse, responseInitialState } from '../utils/response'
import { sliceName } from './constants'
import thunks from './thunks'

export const achievementsAdapter = createEntityAdapter<IAchievement>()

export default createSlice({
  name: sliceName,

  initialState: achievementsAdapter.getInitialState({
    ...responseInitialState,
  }),

  reducers: {},

  extraReducers: (builder) => {
    extraReducersResponse<IPersonalAchievementsState[typeof sliceName], IAchievement[]>({
      builder,
      thunk: thunks.fetch,
      fulfilled: (state, { payload }) => {
        achievementsAdapter.setAll(state, payload)
      },
    })
  },
})
