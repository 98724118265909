import { EDuration } from '@ucheba/utils/helpers/styles/variables'

export const notificationsRootDiv = 'notifications'

export const animationDuration = {
  css: EDuration.slow,
  js: 400,
}

/** Ограничение количества показываемых уведомлений */
export const notificationsActiveLimitDesktop = 2
export const notificationsActiveLimitTouch = 1
