import styled, { StyledProps } from 'styled-components'
import { sm as styledMixins } from '@ucheba/utils/helpers/styles/mixins'
import { IPropShrink, IPropWidth } from '@ucheba/utils/helpers/components/prop.types'
import { modColSizes } from '../mixins'
import { IModColsCount } from '../types'

const Block = styled.div<StyledProps<IModColsCount & IPropWidth & IPropShrink>>`
  flex-grow: 0;
  flex-shrink: 0;
  width: 100%;

  ${modColSizes};

  && {
    ${(props) => styledMixins.width(props.width || '')};
    flex-shrink: ${(props) => props.shrink};
  }
`

export default { Block }
