import apiThunk, { api, getHttpStatusText } from '@ucheba/utils/helpers/api'
import { sliceName } from './constants'

const fetchFeedbacks = apiThunk(`${sliceName}/fetchFeedbacks`, async (params) => {
  const response = await api.get(`/feedbacks/institution/${params?.data?.id}`, params)
  return response.data
})

const sendFeedback = apiThunk(`${sliceName}/sendFeedback`, async (params) => {
  const { text, id, rate, parentId } = params.data

  try {
    await api.post(
      `/feedbacks/institution/${id}`,
      {
        text,
        rate,
        parentId,
      },
      params
    )

    return { status: 200 }
  } catch (err) {
    const { status } = err.response
    return {
      error: getHttpStatusText(status),
    }
  }
})

const rateFeedback = apiThunk(`${sliceName}/rateFeedback`, async (params) => {
  const { isUseful, id } = params.data

  try {
    await api.post(`/feedbacks/${id}/vote`, { isUseful }, params)

    return { status: 200 }
  } catch (err) {
    const { status } = err.response
    return {
      error: getHttpStatusText(status),
    }
  }
})

export default {
  sendFeedback,
  rateFeedback,
  fetchFeedbacks,
}
