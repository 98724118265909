import React, { FC, memo } from 'react'
import SC from '../base/Divider'
import { IDividerProps } from '../types'

const { Block } = SC

/** Разделительная линия */
const Divider: FC<IDividerProps> = (props) => {
  const { spacing = 'xlarge', shiftRight, children, ...otherProps } = props

  return (
    <Block spacing={spacing} shiftRight={shiftRight} {...otherProps}>
      {children}
    </Block>
  )
}

export { Divider }
export default memo(Divider)
