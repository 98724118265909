import apiThunk, { api } from '@ucheba/utils/helpers/api'
import { addLimitOffset } from '@ucheba/utils/helpers/router'
import { IFacet } from '@ucheba/utils/types/search'
import { institutionProgramsLimit, sliceName, allTagName } from './constants'

const decoratorFacets = (facets: Record<string, IFacet[]>): Record<string, IFacet[]> => {
  return Object.keys(facets).reduce(
    (acc, facetName) => {
      if (facetName === 'tags') {
        acc[facetName] = facets[facetName].map((facet) => {
          let items: IFacet[] = []

          if (facet.items) {
            items = [
              ...facet.items,
              {
                count: facet.count,
                name: allTagName,
                value: facet.value,
              },
            ]
          }

          const faceCopy = { ...facet }

          faceCopy.items = items

          return faceCopy
        })
      } else {
        acc[facetName] = facets[facetName]
      }

      return acc
    },
    {} as Record<string, IFacet[]>
  )
}

const fetch = apiThunk(`${sliceName}/fetch`, async (params) => {
  const response = await api.get(
    `/institutions/${params?.data?.id}/tags/programs`,
    params
  )

  return response.data
})

const fetchProgramsHigher = apiThunk(
  `${sliceName}/fetchProgramsHigher`,
  async (params) => {
    const response = await api.get(
      `/programs/higher/search`,
      addLimitOffset(params, institutionProgramsLimit)
    )

    const { data } = response

    return {
      ...data,
      facets: decoratorFacets(data.facets),
    }
  }
)

const fetchProgramsVocational = apiThunk(
  `${sliceName}/fetchProgramsVocational`,
  async (params) => {
    const response = await api.get(
      `/programs/vocational/search`,
      addLimitOffset(params, institutionProgramsLimit)
    )

    const { data } = response

    return {
      ...data,
      facets: decoratorFacets(data.facets),
    }
  }
)

const fetchProgramsMaster = apiThunk(
  `${sliceName}/fetchProgramsMaster`,
  async (params) => {
    const response = await api.get(
      `/programs/master/search`,
      addLimitOffset(params, institutionProgramsLimit)
    )

    const { data } = response

    return {
      ...data,
      facets: decoratorFacets(data.facets),
    }
  }
)

const fetchProgramsPostgraduate = apiThunk(
  `${sliceName}/fetchProgramsPostgraduate`,
  async (params) => {
    const response = await api.get(
      `/programs/postgraduate/search`,
      addLimitOffset(params, institutionProgramsLimit)
    )

    const { data } = response

    return {
      ...data,
      facets: decoratorFacets(data.facets),
    }
  }
)

const fetchProgramsCourse = apiThunk(
  `${sliceName}/fetchProgramsCourse`,
  async (params) => {
    const response = await api.get(
      `/programs/course/search`,
      addLimitOffset(params, institutionProgramsLimit)
    )

    const { data } = response

    return {
      ...data,
      facets: decoratorFacets(data.facets),
    }
  }
)

const fetchProgramsMBA = apiThunk(`${sliceName}/fetchProgramsMBA`, async (params) => {
  const response = await api.get(
    `/programs/mba/search`,
    addLimitOffset(params, institutionProgramsLimit)
  )

  const { data } = response

  return {
    ...data,
    facets: decoratorFacets(data.facets),
  }
})

const fetchProgramsSchool = apiThunk(
  `${sliceName}/fetchProgramsSchool`,
  async (params) => {
    const response = await api.get(
      `/programs/school/search`,
      addLimitOffset(params, institutionProgramsLimit)
    )

    const { data } = response

    return {
      ...data,
      facets: decoratorFacets(data.facets),
    }
  }
)

const fetchPreparatory = apiThunk(`${sliceName}/fetchPreparatory`, async (params) => {
  const response = await api.get(
    `/programs/preparatory/search`,
    addLimitOffset(params, institutionProgramsLimit)
  )

  const { data } = response

  return {
    ...data,
    facets: decoratorFacets(data.facets),
  }
})

export default {
  fetch,
  fetchProgramsHigher,
  fetchProgramsVocational,
  fetchProgramsMaster,
  fetchProgramsPostgraduate,
  fetchProgramsCourse,
  fetchProgramsMBA,
  fetchProgramsSchool,
  fetchPreparatory,
}
