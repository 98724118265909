/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import { IAuthEmailState } from './types'
import { extraReducersResponse, responseInitialState } from '../../utils/response'
import { sliceName } from './constants'
import thunks from './thunks'
import { IProfileShort } from '../../profile/short/types'

export default createSlice({
  name: sliceName,

  initialState: {
    entity: null as IProfileShort | null,
    ...responseInitialState,
  },

  reducers: {},

  extraReducers: (builder) => {
    extraReducersResponse<IAuthEmailState[typeof sliceName], IProfileShort>({
      builder,
      thunk: thunks.fetch,
      fulfilled: (state, action) => {
        state.entity = action.payload
      },
    })
  },
})
