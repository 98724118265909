import { getStateItem } from '../utils/core'
import { sliceName } from './constants'
import { ISiteNameState } from './types'

export default {
  siteName: getStateItem<ISiteNameState, ISiteNameState['siteName']>(
    sliceName,
    'siteName'
  ),
}
