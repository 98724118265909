import { sliceName } from './constants'
import { IPageDataOfferState } from './types'
import { getStateItem } from '../../utils/core'

export default {
  entity: getStateItem<
    IPageDataOfferState,
    IPageDataOfferState[typeof sliceName]['entity']
  >(sliceName, 'entity'),
}
