import styled, { keyframes, StyledProps } from 'styled-components'
import {
  EModClose,
  EModSize,
  IModClose,
  IModSize,
} from '@ucheba/utils/helpers/styles/mod.types'
import { sv } from '@ucheba/utils/helpers/styles/variables'
import { sm } from '@ucheba/utils/helpers/styles/mixins'
import { IBlur } from '../types'

const Wrapper = styled.div`
  display: flex;
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  overflow: auto;
  top: 0;
  z-index: 99999;
  cursor: pointer;
`

const closeBackdrop = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`

const openBackdrop = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

const Backdrop = styled.div<StyledProps<IModClose & IBlur>>`
  cursor: pointer;
  ${sm.position.fixedFill};
  background-color: ${sv.color.gray60};
  height: 100%;
  overflow: auto;
  z-index: 99999;

  ${(props) => {
    if (props.blur) {
      return `
        -webkit-backdrop-filter: blur(32px);
        backdrop-filter: blur(32px);
         background-color: rgba(0, 0, 0, 0);
        `
    }
    return ``
  }}

  ${sm.mod(EModClose.true)`
    animation: ${closeBackdrop} ${sv.duration.fast} forwards;
  `};

  ${sm.mod(EModClose.false)`
    animation: ${openBackdrop} ${sv.duration.fast} forwards;
  `};

  @-moz-document url-prefix() {
    background-color: ${sv.color.gray60};
  }
`

const Content = styled.div<StyledProps<IModSize & { scrollY?: boolean }>>`
  width: 100%;
  display: flex;
  margin: auto;
  align-items: center;
  justify-content: center;
  padding: ${(props) => (props.scrollY ? '64px 0' : '0')};
  cursor: default;

  box-sizing: border-box;

  ${sm.mod(EModSize.large)`
    max-width: 768px;
  `}

  ${sm.mod(EModSize.medium)`
    max-width: 688px;
  `}

  ${sm.mod(EModSize.small)`
    max-width: 568px;
  `}

  ${sm.mod(EModSize.xsmall)`
    max-width: 360px;
  `}
`

const ButtonClose = styled.div`
  position: absolute !important;
  top: ${sv.spacing.small};
  right: ${sv.spacing.small};
`

const Box = styled.div`
  position: relative;
  width: 100%;
  max-height: calc(100vh - ${sm.mathVars(sv.spacing.xlarge, '*', 2)});
  height: 100%;

  &::-webkit-scrollbar {
    display: none;
  }

  scrollbar-width: none;
`

const BoxScroll = styled.div`
  position: relative;
  width: 100%;
`

export default { Wrapper, Content, Backdrop, ButtonClose, Box, BoxScroll }
