/* eslint-disable no-param-reassign */
import { createEntityAdapter, createSlice, EntityState } from '@reduxjs/toolkit'
import { ISubjectsDetail } from './types'
import { IResponseInitialState } from '../../utils/response/types'
import { extraReducersResponse, responseInitialState } from '../../utils/response'
import { sliceName } from './constants'
import thunks from './thunks'

export const subjectsDetailAdaptor = createEntityAdapter<ISubjectsDetail>()

export default createSlice({
  name: sliceName,

  initialState:
    subjectsDetailAdaptor.getInitialState<IResponseInitialState>(responseInitialState),

  reducers: {},

  extraReducers: (builder) => {
    extraReducersResponse<EntityState<ISubjectsDetail>, ISubjectsDetail>({
      builder,
      thunk: thunks.fetch,
      fulfilled: (state, action) => {
        subjectsDetailAdaptor.addOne(state, action.payload)
      },
    })
  },
})
