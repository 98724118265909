/** Из чисел делает массив с числами: 1, 5 => [1,2,3,4,5] */
export const getArrayRange = (start: number, end: number): number[] => {
  const length = end - start + 1

  return Array.from({ length }, (_, i) => start + i)
}

/** Возвращает случайное число из переданного максимального */
export const getRandomNumber = (max = 1000): number => {
  return Math.floor(Math.random() * Math.floor(max))
}

/** Деление числа на разряды по тысячам */
export const spaceDigits = (number: string | number): string => {
  /** Преобразование числа в number, если оно передано строкой */
  const value = typeof number === 'string' ? Number(number) : number

  return value.toString().replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ')
}

/** Возвращает склонение числительных
 * getCountForm(10, ['рубль', 'рубля', 'рублей']) */
export const getCountForm = (number: number, titles: string[]): string => {
  const newNumber = Math.abs(number)

  if (Number.isInteger(newNumber)) {
    const cases = [2, 0, 1, 1, 1, 2]

    return titles[
      newNumber % 100 > 4 && newNumber % 100 < 20
        ? 2
        : cases[newNumber % 10 < 5 ? newNumber % 10 : 5]
    ]
  }

  return titles[1]
}
