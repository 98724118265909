import apiThunk, { api } from '@ucheba/utils/helpers/api'
import { sliceName } from './constants'

const fetch = apiThunk(`${sliceName}/fetch`, async (params) => {
  const response = await api.get(
    `exams?examTypeId=${params?.data.examTypeId || 1}`,
    params
  )

  return response.data
})

export default { fetch }
