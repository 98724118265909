/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import { IEventsId, IEventsIdState } from './types'
import { extraReducersResponse, responseInitialState } from '../../utils/response'
import { sliceName } from './constants'
import thunks from './thunks'

export default createSlice({
  name: sliceName,

  initialState: {
    entity: null as IEventsId | null,
    registered: false,
    testValue: null,
    param: null,
    ...responseInitialState,
  },

  reducers: {},

  extraReducers: (builder) => {
    extraReducersResponse<IEventsIdState[typeof sliceName], IEventsId>({
      builder,
      thunk: thunks.fetch,
      fulfilled: (state, action) => {
        state.entity = action.payload
      },
    })

    extraReducersResponse<IEventsIdState[typeof sliceName], IEventsIdState>({
      builder,
      thunk: thunks.register,
      fulfilled: (state, action) => {
        state.testValue = action.payload
        state.registered = true
      },
    })
  },
})
