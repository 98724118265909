import React, { FC } from 'react'
import { Heading } from '@ucheba/ui/components/Heading/desktop'
import { Description } from '@ucheba/ui/components/Description/desktop'
import { Spacing } from '@ucheba/ui/components/Spacing/desktop'
import { Formik } from 'formik'
import { Dialog } from '@ucheba/ui/components/Dialog/desktop'
import Loader from '@ucheba/ui/components/Loader/desktop/Loader'
import { useChooseLocationCore, useChooseLocationDialogCore } from '../bll'
import { IChooseLocationProps } from '../types'
import ChooseLocationForm from './ChooseLocationForm'
import data from '../data.json'
// import { initialValues } from '../constants'

const ChooseLocation: FC<IChooseLocationProps> = () => {
  const { chooseLocationDialog, suggestingLocations } = useChooseLocationDialogCore()
  const { onSubmitForm, locations, initialValues } = useChooseLocationCore({
    closeDialog: chooseLocationDialog.closeDialog,
    open: chooseLocationDialog.isActive,
  })

  return (
    <Dialog
      size='small'
      spacing='xlarge'
      open={chooseLocationDialog.isActive}
      onClose={chooseLocationDialog.closeDialog}
    >
      <Heading h2={data.title} spacing='xxsmall' />

      <Description description={data.description} />

      <Spacing spacing='large' />

      {locations && locations.length > 0 ? (
        <Formik enableReinitialize initialValues={initialValues} onSubmit={onSubmitForm}>
          <ChooseLocationForm locations={locations} suggester={suggestingLocations} />
        </Formik>
      ) : (
        <Loader />
      )}
    </Dialog>
  )
}

export default ChooseLocation
