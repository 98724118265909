import { coreQuery } from '@ucheba/utils/constants/queries'

export const sliceName = 'auth'

export const authQuery = {
  confirmationKey: 'confirmationKey',
  userId: 'userId',
  short: 'short',
  institution: 'institution',
  scenario: 'scenario',
  [coreQuery.redirect]: coreQuery.redirect,
}
