import apiThunk, { api } from '@ucheba/utils/helpers/api'
import { addLimitOffset } from '@ucheba/utils/helpers/router'
import { sliceName } from './constants'
import { eventsSearchLimit } from '../constants'

const fetch = apiThunk(`${sliceName}/fetch`, async (params) => {
  const response = await api.get(
    `/events/search`,
    params.data.limit ? params : addLimitOffset(params, eventsSearchLimit)
  )

  return response.data
})

export default { fetch }
