import React, { forwardRef, memo } from 'react'
import { ConditionalWrapper } from '@ucheba/utils/helpers/components'
import { Progress } from '../../Progress/desktop'
import SC from '../base/Button.styles'
import { IButtonProps } from '../types'
import { getColorProgress, getProps } from '../bll'

const { Block, Icon, ProgressWrapper, Inner } = SC

/** Кнопка */
const Button = forwardRef<HTMLButtonElement & HTMLLinkElement, IButtonProps>(
  function Button(props, ref) {
    const {
      children,
      StartIcon,
      EndIcon,
      color,
      view = '',
      icon,
      progress,
      size = 'medium',
      shape = 'rectangle',
      rel,
      target,
      href,
      justifyContent = 'center',
      resetHeight,
      disabled,
      width,
      conditionalWrapper,
      ...otherProps
    } = props

    const isDisabled = !!disabled || !!progress

    return (
      <ConditionalWrapper condition={conditionalWrapper} wrapper={conditionalWrapper}>
        <Block
          color={color}
          view={otherProps.disabled ? '' : view}
          icon={icon}
          size={size}
          shape={shape}
          ref={ref}
          rel={rel}
          target={target}
          justifyContent={justifyContent}
          resetHeight={resetHeight}
          disabled={isDisabled}
          progress={progress}
          width={width}
          {...getProps({ href, ...otherProps })}
        >
          {StartIcon && (
            <Icon StartIcon={Boolean(StartIcon)} progress={progress}>
              {StartIcon}
            </Icon>
          )}

          {!progress && <Inner>{children}</Inner>}

          {progress && (
            <ProgressWrapper
              as={Progress}
              color={getColorProgress(color, view)}
              progress={progress}
            />
          )}

          {EndIcon && (
            <Icon StartIcon={false} EndIcon={Boolean(EndIcon)} progress={progress}>
              {EndIcon}
            </Icon>
          )}
        </Block>
      </ConditionalWrapper>
    )
  }
)

export { Button }
export default memo(Button)
