import {
  IModBlock,
  IModEndIcon,
  IModFontSize,
  IModFontWeight,
  IModOpen,
  IModPosition,
  IModSpacing,
  IModStartIcon,
} from '@ucheba/utils/helpers/styles/mod.types'
import {
  IPropChildren,
  IPropCss,
  IPropPreventOutsideClose,
  IPropWidth,
} from '@ucheba/utils/helpers/components/prop.types'

export interface IDropdownProps
  extends IPropChildren,
    IPropWidth,
    IPropPreventOutsideClose,
    IPropCss,
    IModDropdownColor,
    IModOpen,
    IModFontSize,
    IModFontWeight,
    IModPosition,
    IModBlock,
    IModSpacing,
    IModStartIcon,
    IModEndIcon {
  /** Активатор дропдауна. Так же можно вместо свойства `title` передать активатор в виде содержимого компонента. */
  title?: string

  /** Передаем `<Menu><MenuItem>Ссылка</MenuItem></Menu>` */
  menu: JSX.Element | JSX.Element[]

  /** иконка стрелкф */
  arrowMenu?: boolean

  /** тайтл начинается с большой буквы? */
  firstLetterUppercase?: boolean

  /** скрыть элемент */
  hidden?: boolean

  onClose?: () => void
}

export enum EModDropdownColor {
  black = 'color=black',
  maroon = 'color=maroon',
  pine = 'color=pine',
  coffee = 'color=coffee',
  white = 'color=white',
  gold = 'color=gold',
  flamingo = 'color=flamingo',
  blue = 'color=blue',
  orange = 'color=orange',
}

export interface IModDropdownColor {
  /** Цвет активатора переданного через свойство `title` */
  color?: keyof typeof EModDropdownColor
}
