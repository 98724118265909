import React, { FC, Fragment, memo, useRef } from 'react'
import CloseIcon from '@ucheba/ui/icons/icon__close.svg'
import SC from '../base/Select.styles'
import { ISelectProps } from '../types'
import { TextField } from '../../TextField/desktop'
import { DropdownMenu } from '../../DropdownMenu/desktop'
import { Menu, MenuItem } from '../../Menu/desktop'
import { Button } from '../../Button/desktop'
import { useHandlerClickOutside } from '../../Dropdown/bll'
import { Text } from '../../Text/desktop'
import { Spacing } from '../../Spacing/desktop'
import { useSelectCore } from '../bll'
import { Divider } from '../../Divider/desktop'

const {
  Block,
  Activator,
  NativeSelect,
  SelectMenu,
  SelectedText,
  Icon,
  SelectMenuItem,
  HiddenTextFieldActivator,
  HiddenTextField,
} = SC

/** Селект */
const Select: FC<ISelectProps> = (props) => {
  const autocomplete = true
  const {
    label,
    multiple,
    value,
    keyValue = 'id',
    keyText = 'name',
    name,
    items,
    onChange,
    onBlur,
    onOpen,
    onClose,
    onInput,
    open,
    preventOutsideClose,
    resetItem,
    noItems = 'Ничего не найдено',
    suggested,
    hideMenu,
    tabIndex,
    submitOnClose,
    isNotShowSelected,
    onIdNotFounded,
    hiddenItems,
    errorColor,
    ...otherProps
  } = props

  const selectRef = useRef<HTMLSelectElement>(null)
  const {
    selectItems,
    selectedValuesIds,
    selectedText,
    textFieldValue,
    isTextFieldActive,
    isTextFieldFocused,
    isOpen,
    toggleOpen,
    resetSelected,
    toggleMenu,
    getMenuItemActiveStatus,
    getMenuItemCheckedStatus,
    onChangeNativeSelect,
    onChangeTextField,
    onFocusTextField,
    onBlurTextField,
    toggleItem,
  } = useSelectCore({
    selectRef,
    items,
    keyValue,
    keyText,
    value,
    suggested,
    multiple,
    autocomplete,
    open,
    onOpen,
    onClose,
    onChange,
    onInput,
    name,
    submitOnClose,
    onIdNotFounded,
    hiddenItems,
  })

  const blockRef = useRef<HTMLDivElement>(null)

  useHandlerClickOutside(blockRef, isOpen, toggleOpen, !!preventOutsideClose)

  return (
    <Block ref={blockRef} {...otherProps}>
      <NativeSelect
        ref={selectRef}
        tabIndex={-1}
        name={name}
        multiple
        value={selectedValuesIds}
        onChange={onChangeNativeSelect}
        onBlur={onBlur}
        aria-hidden='true'
      >
        {selectItems.map((item) => (
          <option value={item[keyValue]} key={item[keyValue]}>
            {item[keyText]}
          </option>
        ))}
      </NativeSelect>

      <Activator autocomplete={autocomplete} onClick={toggleMenu}>
        {!autocomplete && (
          <HiddenTextFieldActivator>
            <HiddenTextField type='text' onFocus={onFocusTextField} tabIndex={tabIndex} />
          </HiddenTextFieldActivator>
        )}

        <TextField
          label={label}
          disabledWithStyles={!autocomplete}
          active={isTextFieldActive && !isNotShowSelected}
          onFocus={onFocusTextField}
          onBlur={onBlurTextField}
          tabIndex={tabIndex}
          onChange={onChangeTextField}
          value={textFieldValue}
          autocomplete='nope'
          name={name}
          errorColor={errorColor}
        />

        <Icon open={isOpen} />

        {!textFieldValue && !isNotShowSelected && (
          <SelectedText active={!isTextFieldFocused}>
            <Text firstLetterUppercase as='span'>
              {selectedText}
            </Text>
          </SelectedText>
        )}
      </Activator>

      {isOpen && !hideMenu && (
        <SelectMenu as={DropdownMenu} minWidth='100%'>
          <Menu>
            {selectItems.length > 0 ? (
              <>
                {resetItem && !textFieldValue && (
                  <MenuItem EndIcon={<CloseIcon width='16px' height='16px' />}>
                    <Button size='small' onClick={resetSelected}>
                      {resetItem}
                    </Button>
                  </MenuItem>
                )}
              </>
            ) : (
              <MenuItem>
                <div>
                  <Spacing spacing='xxsmall' />
                  <Text color='gray60'>{noItems}</Text>
                  <Spacing spacing='xxsmall' />
                </div>
              </MenuItem>
            )}

            {selectItems.map((item) => {
              if (hiddenItems && hiddenItems?.some((id) => String(id) === item.id))
                return null

              return (
                <Fragment key={item[keyValue]}>
                  {item?.items ? (
                    <>
                      {item.items.length > 0 && (
                        <>
                          {resetItem ? (
                            <>
                              <Divider spacing='xsmall' shiftRight='small' />

                              <Spacing spacing='medium' />
                            </>
                          ) : (
                            <Spacing spacing='xsmall' />
                          )}

                          <Text fontWeight='semibold' firstLetterUppercase>
                            {item[keyText]}
                          </Text>

                          <Spacing spacing='xsmall' />

                          {item.items.map((childItem) => (
                            <MenuItem
                              active={getMenuItemActiveStatus(childItem[keyValue])}
                              checked={getMenuItemCheckedStatus(childItem[keyValue])}
                              key={childItem[keyValue]}
                            >
                              <SelectMenuItem
                                as={Button}
                                size='small'
                                onClick={(): void => toggleItem(childItem[keyValue])}
                              >
                                <Text firstLetterUppercase>{childItem[keyText]}</Text>
                              </SelectMenuItem>
                            </MenuItem>
                          ))}
                        </>
                      )}
                    </>
                  ) : (
                    <MenuItem
                      active={getMenuItemActiveStatus(item[keyValue])}
                      checked={getMenuItemCheckedStatus(item[keyValue])}
                    >
                      <SelectMenuItem
                        as={Button}
                        size='small'
                        onClick={(): void => toggleItem(item[keyValue])}
                      >
                        <Text firstLetterUppercase>{item[keyText]}</Text>
                      </SelectMenuItem>
                    </MenuItem>
                  )}
                </Fragment>
              )
            })}
          </Menu>
        </SelectMenu>
      )}
    </Block>
  )
}

export { Select }
export default memo(Select)
