import { IInstitutionsEventsState } from './types'
import { institutionsRankingsStatsAdapter } from './slice'
import { sliceName } from './constants'
import { getStateError } from '../../utils/core'

const adapterSelectors =
  institutionsRankingsStatsAdapter.getSelectors<IInstitutionsEventsState>(
    (state) => state[sliceName]
  )

export default {
  ...adapterSelectors,

  error: getStateError<IInstitutionsEventsState>(sliceName),
}
