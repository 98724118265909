/* eslint-disable no-param-reassign */
import { createEntityAdapter, createSlice, EntityState } from '@reduxjs/toolkit'
import { IItemOfInstitutionsRankings } from './types'
import { IResponseInitialState } from '../../utils/response/types'
import { extraReducersResponse, responseInitialState } from '../../utils/response'
import { sliceName } from './constants'
import thunks from './thunks'

export const institutionsRankingsAdapter =
  createEntityAdapter<IItemOfInstitutionsRankings>()

export default createSlice({
  name: sliceName,

  initialState:
    institutionsRankingsAdapter.getInitialState<IResponseInitialState>(
      responseInitialState
    ),

  reducers: {},

  extraReducers: (builder) => {
    extraReducersResponse<
      EntityState<IItemOfInstitutionsRankings>,
      IItemOfInstitutionsRankings[]
    >({
      builder,
      thunk: thunks.fetch,
      fulfilled: (state, action) => {
        institutionsRankingsAdapter.setAll(state, action.payload)
      },
    })
  },
})
