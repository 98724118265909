import ym from 'react-yandex-metrika'
import Router from 'next/router'

export const enableRouteChangeEvents = (): void => {
  Router.events.on('routeChangeComplete', (url: string, { shallow }) => {
    if (shallow) {
      return
    }
    if (process.env.NODE_ENV !== 'development') {
      ym('hit', url || window.location.href)
    }
  })
}
