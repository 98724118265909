import { useEffect, useMemo } from 'react'
import { useRouter } from 'next/router'
import { stringifyUrl } from '@ucheba/utils/helpers/router'
import { useNotice } from '@ucheba/ui/components/Notice/bll'
import { getParsedCookies } from '@ucheba/utils/helpers/core'

interface IUseBannerNotice {
  (): {
    isActive: boolean
    onClose: () => void
  }
}

export const useBannerNotice: IUseBannerNotice = () => {
  const router = useRouter()
  const { query } = router
  const notice = useNotice(`noticeBannerSuccessId`)
  const cookies = getParsedCookies()

  const noticesCookies = useMemo((): string[] => {
    const { notices } = cookies

    if (notices) {
      return JSON.parse(notices)
    }

    return []
  }, [cookies])

  const hasNotices = useMemo(() => {
    return noticesCookies.some((str) => str.includes('noticeBannerSuccessId'))
  }, [noticesCookies])

  useEffect(() => {
    const queryNotice = query.notice

    if (!hasNotices && queryNotice === 'successReq') {
      notice.addNotice()

      router.push(
        stringifyUrl(window.location.pathname, {
          exclude: ['notice'],
          include: { ...query },
        }),
        undefined,
        {
          shallow: true,
        }
      )
    }
  }, [query])

  const isActive = useMemo(() => {
    return !!notice.open
  }, [notice.open])

  return {
    isActive,
    onClose: notice.removeNotice,
  }
}
