import { IInstitutionsProgramIdState } from './types'
import { sliceName } from './constants'
import { getStateItem } from '../../../utils/core'

export default {
  entity: getStateItem<
    IInstitutionsProgramIdState,
    IInstitutionsProgramIdState[typeof sliceName]['entity']
  >(sliceName, 'entity'),

  similar: getStateItem<
    IInstitutionsProgramIdState,
    IInstitutionsProgramIdState[typeof sliceName]['similar']
  >(sliceName, 'similar'),

  loading: getStateItem<
    IInstitutionsProgramIdState,
    IInstitutionsProgramIdState[typeof sliceName]['loading']
  >(sliceName, 'loading'),
}
