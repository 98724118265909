import apiThunk, { api } from '@ucheba/utils/helpers/api'
import { sliceName } from './constants'

const sendLead = apiThunk(`${sliceName}/sendLead`, async (params) => {
  const response = await api.post('/lead/tetrika', params.data, {
    retryTimes: 0,
  })
  const { externalData } = response.data

  delete response.data.externalData

  return {
    externalData,
    person: response.data,
  }
})

export default { sendLead }
