import { IPageDataAboutPlatformState } from './types'
import { sliceName } from './constants'
import { getStateError, getStateItem } from '../../utils/core'

export default {
  entity: getStateItem<
    IPageDataAboutPlatformState,
    IPageDataAboutPlatformState[typeof sliceName]['entity']
  >(sliceName, 'entity'),

  error: getStateError<IPageDataAboutPlatformState>(sliceName),
}
