import { getStateError, getStateItem } from '../../utils/core'
import { sliceName } from './constants'
import { IProfessionsIdState } from './types'

export default {
  entity: getStateItem<
    IProfessionsIdState,
    IProfessionsIdState[typeof sliceName]['entity']
  >(sliceName, 'entity'),

  similar: getStateItem<
    IProfessionsIdState,
    IProfessionsIdState[typeof sliceName]['similar']
  >(sliceName, 'similar'),

  loading: getStateItem<
    IProfessionsIdState,
    IProfessionsIdState[typeof sliceName]['loading']
  >(sliceName, 'loading'),

  error: getStateError<IProfessionsIdState>(sliceName),
}
