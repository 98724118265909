import { sliceName } from './constants'
import { getStateError, getStateItem } from '../../utils/core'
import { IEgeStatsState } from './types'
import { egeStatsAdaptor } from './slice'

const adapterSelectors = egeStatsAdaptor.getSelectors<IEgeStatsState>(
  (state) => state[sliceName]
)

export default {
  ...adapterSelectors,

  loading: getStateItem<IEgeStatsState, IEgeStatsState[typeof sliceName]['loading']>(
    sliceName,
    'loading'
  ),

  error: getStateError<IEgeStatsState>(sliceName),
}
