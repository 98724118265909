export const analyticsIds = {
  vk: 'VK-RTRG-1847360-A4il',
  ga: 'UA-3126744-1',
  ya: 2330005,
  tt: 'BU326CAHT2A1QT375EE0',
  fb: 351783202752081,
  mt: '3235211',
  strangeFB: 247297997546013,
  strangeVK: 'VK-RTRG-1178444-49Kqu',
  strangeTT: 'C7FET2Q0VTGLO2V4EI2G',
  gtm: 'GTM-TBXJDLQ',
}
