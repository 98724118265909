/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import { extraReducersResponse, responseInitialState } from '../../utils/response'
import { sliceName } from './constants'
import thunks from './thunks'
import { IProfessionsIdState, IItemOfProfessions, ISimilarProfessions } from './types'

export default createSlice({
  name: sliceName,

  initialState: {
    entity: null as IItemOfProfessions | null,
    similar: null as ISimilarProfessions | null,
    ...responseInitialState,
  },

  reducers: {},

  extraReducers: (builder) => {
    extraReducersResponse<IProfessionsIdState[typeof sliceName], IItemOfProfessions>({
      builder,
      thunk: thunks.fetch,
      fulfilled: (state, action) => {
        state.entity = action.payload
      },
    })

    extraReducersResponse<IProfessionsIdState[typeof sliceName], ISimilarProfessions>({
      builder,
      thunk: thunks.fetchSimilar,
      fulfilled: (state, action) => {
        state.similar = action.payload
      },
    })
  },
})
