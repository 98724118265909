import apiThunk, { api } from '@ucheba/utils/helpers/api'
import { addLimitOffset } from '@ucheba/utils/helpers/router'
import { sliceName } from './constants'
import { changeLocationIfAllRussiaLocation } from './bll'

const fetch = apiThunk(`${sliceName}/fetch`, async (props) => {
  const response = await api.get(
    `location`,
    addLimitOffset(props, props.params?.limit || 20, props.params?.isMain || 0)
  )

  return changeLocationIfAllRussiaLocation(response.data)
})

const getById = apiThunk(`${sliceName}/getById`, async (props) => {
  const response = await api.get(`/location/${props.params?.id}`, props)

  return changeLocationIfAllRussiaLocation(response.data, props.params?.id)
})

const getByLocationIds = apiThunk(`${sliceName}/getByLocationIds`, async (props) => {
  const response = await api.get(`/location/${props.params?.id}`, props)

  return response.data
})

export default { fetch, getById, getByLocationIds }
