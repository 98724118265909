import apiThunk, { api } from '@ucheba/utils/helpers/api'
import { IFacet } from '@ucheba/utils/types/search'
import { addLimitOffset } from '@ucheba/utils/helpers/router'
import { allTagName, sliceName, specialitiesSearchLimit } from './constants'
import { ESpecialitiesFacets, ISpecialitiesFacets } from './types'

const fetch = apiThunk(`${sliceName}/fetch`, async (props) => {
  const { limit } = props.data

  const response = await api.get(
    'specialities/search',
    addLimitOffset(props, limit || specialitiesSearchLimit)
  )

  const facetsOriginal = response.data.facets

  response.data.facets = Object.keys(facetsOriginal).reduce((acc, facetName) => {
    if (facetName === ESpecialitiesFacets.tags) {
      acc[facetName] = facetsOriginal[facetName].map((facet) => {
        let items: IFacet[] = []

        if (facet.items) {
          items = [
            ...facet.items,
            {
              count: facet.count,
              name: allTagName,
              value: facet.value,
            },
          ]
        }

        const faceCopy = { ...facet }

        faceCopy.items = items

        return faceCopy
      })
    } else {
      acc[facetName] = facetsOriginal[facetName]
    }

    return acc
  }, {} as ISpecialitiesFacets)

  return response.data
})

const fetchByFriendlyURL = apiThunk(`${sliceName}/fetchByFriendlyURL`, async (props) => {
  const { limit } = props?.data || {}

  const response = await api.get(
    '/specialities/search-by-friendly-url',
    addLimitOffset(props, limit || specialitiesSearchLimit)
  )

  const facetsOriginal = response.data.result.facets

  response.data.result.facets = Object.keys(facetsOriginal).reduce((acc, facetName) => {
    if (facetName === ESpecialitiesFacets.tags) {
      acc[facetName] = facetsOriginal[facetName].map((facet) => {
        let items: IFacet[] = []

        if (facet.items) {
          items = [
            ...facet.items,
            {
              count: facet.count,
              name: allTagName,
              value: facet.value,
            },
          ]
        }

        const faceCopy = { ...facet }

        faceCopy.items = items

        return faceCopy
      })
    } else {
      acc[facetName] = facetsOriginal[facetName]
    }

    return acc
  }, {} as ISpecialitiesFacets)

  return response.data
})

export default { fetch, fetchByFriendlyURL }
