/* eslint-disable no-param-reassign */
import { authCookieToken } from '@ucheba/utils/constants/core'
import {
  getCookieDomain,
  getParsedCookies,
  isServer,
  setCookie,
} from '@ucheba/utils/helpers/core'
import { NextPageContext } from 'next/dist/shared/lib/utils'
import { analyticEvent } from '@ucheba/utils/helpers/analytics/events'
import {
  EAnalyticEvents,
  EAnalyticSections,
} from '@ucheba/utils/helpers/analytics/events/types'
import dayjs from '@ucheba/utils/helpers/date/dayjs'
import { IAuthState, ILoginAction, IToken } from './types'
import { sliceName } from './constants'

export const login = (
  state: IAuthState[typeof sliceName],
  { payload }: ILoginAction
): void => {
  if (payload?.token) {
    state.token = payload.token
    state.expiresAt = payload.expiresAt || ''
  }
}

const sendEventIsNewToken = (token: IToken['token']): void => {
  if (isServer) return

  const parsedCookies = getParsedCookies()

  if (token === parsedCookies[authCookieToken]) return

  analyticEvent({
    eventName: EAnalyticEvents.userAuthSuccess,
    section: EAnalyticSections.authForm,
  })
}

export const setAuthCookie = ({
  ctx,
  token,
  sendEvent = true,
}: {
  ctx?: NextPageContext
  token: IToken
  sendEvent?: boolean
}): void => {
  // FIXME: надо сделать, чтобы значение бралось из expiresAt и возвращалось в виде Date
  const expiresCalc = dayjs().add(1, 'months').toDate()

  if (sendEvent) {
    sendEventIsNewToken(token.token)
  }

  setCookie(
    authCookieToken,
    token.token,
    {
      expires: expiresCalc,
      domain: getCookieDomain(),
      httpOnly: false,
    },
    ctx
  )
}

export const removeAuthCookie = (props?: { ctx?: NextPageContext }): void => {
  const { ctx } = props || {}
  setCookie(
    authCookieToken,
    '',
    {
      expires: -1,
      domain: getCookieDomain(),
      httpOnly: false,
    },
    ctx
  ) // у серверной работы с куки нет метода remove
}

export const createLoginAction = (payload: ILoginAction['payload']): ILoginAction => {
  return {
    type: `${sliceName}/login`,
    payload,
  }
}
