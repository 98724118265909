/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import { IAuthPhoneCodeState, ISendCodeTimeout } from './types'
import { extraReducersResponse, responseInitialState } from '../../../utils/response'
import { sliceName } from './constants'
import thunks from './thunks'
import { ELoading } from '../../../utils/response/types'

export default createSlice({
  name: sliceName,

  initialState: {
    timeout: 60 as ISendCodeTimeout['timeout'],
    phone: null,
    test: 0,
    ...responseInitialState,
  },

  reducers: {
    setTest: (state, { payload }): void => {
      state.test = payload
    },
    setPhone: (state, { payload }): void => {
      state.phone = payload
    },
    resetLoading: (state): void => {
      state.loading = ELoading.idle
    },
    setTimeoutValue: (state, { payload }): void => {
      state.timeout = payload
    },
  },

  extraReducers: (builder) => {
    extraReducersResponse<IAuthPhoneCodeState[typeof sliceName], ISendCodeTimeout>({
      builder,
      thunk: thunks.sendCode,
      fulfilled: (state, action) => {
        state.timeout = Number(action.payload)
      },
    })

    extraReducersResponse<IAuthPhoneCodeState[typeof sliceName], ISendCodeTimeout>({
      builder,
      thunk: thunks.sendCodeCall,
      fulfilled: (state, action) => {
        state.timeout = Number(action.payload)
      },
    })
  },
})
