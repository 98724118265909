import apiThunk, { api } from '@ucheba/utils/helpers/api'
import { sliceName } from './constants'

const fetch = apiThunk(`${sliceName}/fetch`, async (params) => {
  const response = await api.get(`/profile/favorites/${params?.data?.type}`, params)

  return response.data
})

const add = apiThunk(`${sliceName}/add`, async (params) => {
  const response = await api.post(
    `/profile/favorites/${params?.data?.type}/${params?.data?.id}`,
    null,
    params
  )

  return response.data
})

const remove = apiThunk(`${sliceName}/remove`, async (params) => {
  const response = await api.delete(
    `/profile/favorites/${params?.data?.type}/${params?.data?.id}`,
    params
  )

  return response.data
})

export default { fetch, add, remove }
