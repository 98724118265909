import axios from 'axios'
// import { throttleAdapterEnhancer } from 'axios-extensions'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { NextPageContext } from 'next'
import { EHttpStatusCode } from '../../constants/api'
import { getParsedCookies, isDev } from '../core'
import { IApi, IApiConfig, IApiThunk } from './types'
import { authCookieToken, tmpToken } from '../../constants/core'

/** Подключение адаптеров axios
 * Подробнее здесь: https://github.com/kuitos/axios-extensions
 */
const { adapter } = axios.defaults
// ? throttleAdapterEnhancer(axios.defaults.adapter)
// : undefined

/** Создание инстанса api с первичными настройками
 * Подробнее: https://github.com/axios/axios#creating-an-instance
 */
export const api: IApi = axios.create({
  headers: {
    'Cache-Control': 'no-cache',
  },
  baseURL: process.env.NEXT_PUBLIC_API_URL || 'https://api.ucheba.ru/v1/',
  adapter,
})

/** Перехватывание запросов в api
 * Подробнее: https://github.com/axios/axios#interceptors
 */
api.interceptors.request.use(
  (apiConfig: IApiConfig): IApiConfig | Promise<IApiConfig> => {
    // Делать что-то прежде чем запрос будет отправлен
    // если дополнительные параметры (props) существуют (куки, к примеру)
    const config = { ...apiConfig }
    const { props } = config

    /** Удаляем не нужны данные для методов */
    switch (config.method) {
      case 'get':
        delete config.data
        break
      case 'post':
      case 'patch':
      case 'delete':
        delete config.params
        break
      default:
    }

    if (props) {
      const propsCookie = props.cookie

      if (propsCookie[authCookieToken]) {
        config.headers['X-Auth-Token'] = propsCookie[authCookieToken]
      } else if (propsCookie[tmpToken]) {
        config.headers['X-Auth-Token'] = propsCookie[tmpToken]
      }
    }

    if (isDev) {
      // console.log('Запрос отправлен: ', config)
    }

    return config
  },
  (error) => {
    // Делать что-то если запрос пришел с ошибкой
    return Promise.reject(error)
  }
)

export const getHttpStatusText = (code: number): string => {
  const STATUS_CODES = {
    [EHttpStatusCode.UNAUTHORIZED]: 'Вам необходимо авторизоваться',
    [EHttpStatusCode.FORBIDDEN]: 'Доступ запрещен',
    [EHttpStatusCode.NOT_FOUND]: 'Не найдено',
  }
  return STATUS_CODES[code] || 'Что-то пошло не так'
}

const apiThunk: IApiThunk = (thunkName, requestFunction) => {
  return createAsyncThunk(thunkName, async ({ ctx, data }, thunkApi) => {
    try {
      const parsedCookie = getParsedCookies(ctx)
      const token = parsedCookie?.[authCookieToken] || ''

      const params = {
        ctx,
        data: data || {},
        params: data || {},
        props: {
          cookie: {
            ...parsedCookie,
            [authCookieToken]: token,
          },
        },
      }

      return await requestFunction(params)
    } catch (err) {
      console.error('catch error', err)

      if (err.response) {
        return thunkApi.rejectWithValue({
          name: err.response.statusText,
          message: err.response.data.message || '',
          debugMessage: err.response.data.debugMessage || '',
          formErrors: err.response.data.formErrors || '',
          code: err.response.status,
        })
      }

      return {}
    }
  })
}

interface ISendRequestProps {
  url: string
  method?: 'get' | 'post' | 'delete' | 'patch'
  data?: Record<string, any>
  ctx?: NextPageContext | null
}

interface ISendRequestResponse<T> {
  data: T
  status: number
  statusText: string
  headers: any
  config: Record<string, any>
  request?: any
}

interface ISendRequestError<T = any> {
  config: AxiosRequestConfig
  code?: string
  request?: any
  response?: ISendRequestResponse<T>
  isAxiosError: boolean
  toJSON: () => object
}

interface ISendRequest<T> {
  (props: ISendRequestProps): ISendRequestResponse<T> | ISendRequestError<T>
}

export const sendRequest: ISendRequest = async (props) => {
  const { url, method = 'get', data, ctx } = props || {}

  let params = {} as Record<string, any>

  try {
    const parsedCookie = getParsedCookies(ctx)
    const token = parsedCookie?.[authCookieToken] || ''

    params = {
      ctx,
      data: data || {},
      params: data || {},
      props: {
        cookie: {
          ...parsedCookie,
          [authCookieToken]: token,
        },
      },
    }
  } catch (err) {
    console.error('error', err)
  }

  let response = null as T | null | undefined

  try {
    switch (method) {
      case 'post':
        response = await api.post(url, params?.data, params)

        break

      case 'patch':
        response = await api.patch(url, params?.data, params)

        break

      case 'delete':
        response = await api.delete(url, params)

        break

      default:
        response = await api.get(url, params)
    }
  } catch (err) {
    console.error('catch error', err)

    return err
  }

  return response
}

export default apiThunk
