import apiThunk, { api } from '@ucheba/utils/helpers/api'
import { sliceName } from './constants'

const fetch = apiThunk(`${sliceName}/fetch`, async (params) => {
  const response = await api.get(`adv/${params.data.id}`, params)

  return {
    data: response.data || null,
    id: params.data.id,
  }
})

export default { fetch }
