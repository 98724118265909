/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import { IUserData } from './types'
import { responseInitialState } from '../utils/response'
import { sliceName } from './constants'

export default createSlice({
  name: sliceName,

  initialState: {
    entity: {
      selectedLocations: [],
      isShowSelectLocation: false,
      isCanSetSelectLocationByIP: false,
      isRedirectOnSelectLocation: true,
    } as IUserData,
    ...responseInitialState,
  },

  reducers: {
    setSelectedLocation: (state, { payload }): void => {
      state.entity.selectedLocations = payload
    },
    setIsShowSelectLocation: (state, { payload }): void => {
      state.entity.isShowSelectLocation = payload
    },
    setIsCanSetSelectLocationByIP: (state, { payload }): void => {
      state.entity.isCanSetSelectLocationByIP = payload
    },
    toggleIsRedirectOnSelectLocation: (state, { payload }): void => {
      state.entity.isRedirectOnSelectLocation = payload
    },
  },
})
