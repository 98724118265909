import styled, { StyledProps } from 'styled-components'
import { EColor, sv } from '@ucheba/utils/helpers/styles/variables'
import { mathVars, sm } from '@ucheba/utils/helpers/styles/mixins'
import {
  EModBlock,
  EModDisabled,
  EModEndIcon,
  EModJustifyContent,
  EModProgress,
  EModStartIcon,
  IModBlock,
  IModDisabled,
  IModEndIcon,
  IModJustifyContent,
  IModProgress,
  IModStartIcon,
} from '@ucheba/utils/helpers/styles/mod.types'
import { IPropWidth } from '@ucheba/utils/helpers/components/prop.types'
import {
  EModButtonColor,
  EModButtonIcon,
  EModButtonShape,
  EModButtonSize,
  EModButtonView,
  IModButtonColor,
  IModButtonIcon,
  IModButtonShape,
  IModButtonSize,
  IModButtonView,
} from '../types'
import { getButtonColor, getButtonColorOutline } from '../mixins'

interface IInnerProp {
  textAlign: 'left' | 'center' | 'right'
}

enum ETextAlign {
  left = 'flex-start',
  center = 'center',
  right = 'flex-end',
}

const Inner = styled.span<IModProgress & IInnerProp>`
  display: flex;
  text-align: ${(props) => props.textAlign};
  justify-content: ${(props) => ETextAlign[props.textAlign]};

  ${sm.mod(EModProgress.true)`
    visibility: hidden;
  `};
`

const ProgressWrapper = styled.div<StyledProps<IModProgress>>`
  width: 32px;
  height: 32px;

  ${sm.mod(EModProgress.true)`
    ${sm.position.absoluteCenter};
  `};
`

const Icon = styled.span<IModStartIcon & IModEndIcon & IModProgress>`
  display: inline-flex;
  justify-content: center;
  align-items: center;

  ${sm.mod(EModStartIcon.true)`
    margin-right: ${sv.spacing.xsmall};
  `};

  ${sm.mod(EModEndIcon.true)`
    margin-left: ${sv.spacing.xsmall};
  `};

  ${sm.mod(EModProgress.true)`
    visibility: hidden;
  `};
`

interface IModResetHeight {
  resetHeight?: boolean
}

const Block = styled.div<
  StyledProps<
    IModButtonColor &
      IModButtonSize &
      IModButtonView &
      IModBlock &
      IModButtonShape &
      IModDisabled &
      IModButtonIcon &
      IModJustifyContent &
      IModResetHeight &
      IModProgress &
      IPropWidth
  >
>`
  cursor: pointer;
  border-radius: ${sv.radius.medium};
  text-decoration: none;
  display: inline-flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  transition: ${sv.duration.fast};
  border: none;
  touch-action: manipulation;
  font-weight: ${sv.fontWeight.semibold};
  position: relative;

  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  user-select: none;


  ${getButtonColor('transparent', 'transparent', 'black')};

  ${sm.mod(EModButtonColor.red)`
    ${getButtonColor('red', 'pink', 'white')};

    ${sm.mod(EModButtonView.outline)`
      ${getButtonColorOutline('red', 'pink')};
    `};
  `};

  ${sm.mod(EModButtonColor.white)`
    ${getButtonColor('white', 'white', 'black')};

    ${sm.mod(EModButtonView.outline)`
      ${getButtonColorOutline('blue', 'cobalt')};
    `};


    @media (hover: hover) {
      &:hover {
        color: ${sv.color.cobalt};
      }
    }

    @media (hover: none) {
      &:active {
        color: ${sv.color.cobalt};
      }
    }
  `};

  ${sm.mod(EModButtonColor.blue)`
    ${getButtonColor('blue', 'cobalt', 'white')};

    ${sm.mod(EModButtonView.outline)`
      ${getButtonColorOutline('blue', 'cobalt')};
    `};
  `};

  ${sm.mod(EModButtonColor.orange)`
    ${getButtonColor('orange', 'mandarin', 'white')};

    ${sm.mod(EModButtonView.outline)`
      ${getButtonColorOutline('orange', 'mandarin')};
    `};
  `};

  ${sm.mod(EModButtonColor.gold)`
    ${getButtonColor('gold', 'tangerine', 'black')};

    ${sm.mod(EModButtonView.outline)`
      ${getButtonColorOutline('gold', 'tangerine')};
    `};
  `};

  ${sm.mod(EModButtonColor.coffee)`
    ${getButtonColor('coffee', 'coffee', 'white')};

    ${sm.mod(EModButtonView.outline)`
      ${getButtonColorOutline('coffee', 'coffee')};
    `};
  `};

  ${sm.mod(EModButtonColor.green)`
    ${getButtonColor('green', 'green', 'white')};

    ${sm.mod(EModButtonView.outline)`
      ${getButtonColorOutline('green', 'green')};
    `};
  `};

  ${sm.mod(EModButtonColor.gray25)`
    ${getButtonColor('gray25', 'gray25', 'white')};

    ${sm.mod(EModButtonView.outline)`
      ${getButtonColorOutline('gray25', 'gray25', 'black')};
    `};
  `};

  ${sm.mod(EModButtonColor.gray5)`
    ${getButtonColor('gray5', 'gray25', 'white')};

    ${sm.mod(EModButtonView.outline)`
      ${getButtonColorOutline('gray5', 'gray25', 'black')};
    `};
  `};

  ${sm.mod(EModButtonColor.emerald)`
    ${getButtonColor('emerald', 'green', 'white')};

    ${sm.mod(EModButtonView.outline)`
      ${getButtonColorOutline('emerald', 'green')};
    `};
  `};

  ${sm.mod(EModButtonColor.lime)`
    ${getButtonColor('lime', 'acid', 'emerald')};

    ${sm.mod(EModButtonView.outline)`
      ${getButtonColorOutline('lime', 'acid')};
    `};
  `};

  ${sm.mod(EModButtonColor.maroon)`
    ${getButtonColor('maroon', 'maroon', 'white')};

    ${sm.mod(EModButtonView.outline)`
      ${getButtonColorOutline('maroon', 'gray25', 'black')};
    `};
  `};

  ${sm.mod(EModButtonColor.pine)`
    ${getButtonColor('pine', 'pine', 'white')};

    ${sm.mod(EModButtonView.outline)`
      ${getButtonColorOutline('pine', 'gray25', 'black')};
    `};
  `};

  ${sm.mod(EModButtonColor.pink)`
    ${getButtonColor('pink', 'raspberry', 'white')};

    ${sm.mod(EModButtonView.outline)`
      ${getButtonColorOutline('pink', 'raspberry')};
    `};
  `};

  ${sm.mod(EModButtonColor.navy)`
    ${getButtonColor('navy', 'navy', 'white')};

    ${sm.mod(EModButtonView.outline)`
      ${getButtonColorOutline('navy', 'navy', 'navy')};
    `};
  `};

  ${sm.mod(EModButtonColor.plum)`
    ${getButtonColor('plum', 'plum', 'white')};

    ${sm.mod(EModButtonView.outline)`
      ${getButtonColorOutline('plum', 'plum')};
    `};
  `};

  ${sm.mod(EModButtonColor.black)`
    ${getButtonColor('black', 'black', 'white')};

    ${sm.mod(EModButtonView.outline)`
      ${getButtonColorOutline('black', 'black', 'black')};
    `};
  `};

  ${sm.mod(EModButtonColor.rose)`
    ${getButtonColor('rose', 'flamingo', 'maroon')};

    ${sm.mod(EModButtonView.outline)`
      ${getButtonColorOutline('rose', 'flamingo', 'rose')};
    `};
  `};

  ${sm.mod(EModDisabled.true)`
    cursor: default;

    ${getButtonColor('gray5', 'gray5', 'gray60')};

     ${sm.mod(EModButtonView.outline)`
        ${getButtonColorOutline('gray5', 'gray5', 'gray60')};
    `};

  `};

  ${sm.mod(EModButtonSize.xxsmall)`
    padding: ${sv.spacing.usmall};
    font-size: ${sv.fontSize.small1};
    line-height: ${sv.lineHeight.small1};
    height: 18px;
  `};

  ${sm.mod(EModButtonSize.xsmall)`
    padding: ${sv.spacing.xxsmall};
    font-size: ${sv.fontSize.medium};
    line-height: ${sv.lineHeight.medium};
    height: 24px;
  `};

  ${sm.mod(EModButtonSize.small)`
    padding: ${sv.spacing.xxsmall} ${sv.spacing.small};
    font-size: ${sv.fontSize.medium};
    line-height: ${sv.lineHeight.medium};
    height: 40px;

    ${Icon} {
      ${sm.mod(EModStartIcon.true)`
        margin-right: ${sv.spacing.xxsmall};
      `};
    }
  `};

  ${sm.mod(EModButtonSize.medium)`
    padding: ${sv.spacing.xsmall} ${sv.spacing.medium};
    font-size: ${sv.fontSize.large};
    line-height: ${sv.lineHeight.large};
    height: 48px;
  `};

  ${sm.mod(EModButtonView.link)`
    padding-left: 0;
    padding-right: 0;
    ${sm.linkColor.blue};
    background-color: transparent;

      @media (hover: hover) {
        &:hover {
            background-color: transparent;
            box-shadow: none;
        }
      }

      @media (hover: none) {
        &:active {
            background-color: transparent;
            box-shadow: none;
        }
      }

    ${sm.mod(EModDisabled.true)`
      cursor: default;

      ${getButtonColor('gray5', 'gray5', 'gray60')};
    `};

    ${sm.mod(EModButtonColor.black)`
      ${sm.linkColor.black};
    `};

    ${sm.mod(EModButtonColor.plum)`
      ${sm.linkColor.plum};
    `};

    ${sm.mod(EModButtonColor.green)`
      ${sm.linkColor.green};
    `};

    ${sm.mod(EModButtonColor.white)`
      ${sm.linkColor.white};
    `};

    ${sm.mod(EModButtonColor.orange)`
      ${sm.linkColor.orange};

       @media (hover: hover) {
        &:hover {
           color: ${sv.color.mandarin}
        }
      }

      @media (hover: none) {
        &:active {
          color: ${sv.color.mandarin}
        }
      }
    `};

    ${sm.mod(EModButtonColor.red)`
      ${sm.linkColor.red};
    `};

    ${sm.mod(EModButtonColor.pink)`
      ${sm.linkColor.pink};
    `};


    ${sm.mod(EModButtonColor.emerald)`
      ${sm.linkColor.emerald};
    `};


    ${sm.mod(EModDisabled.true)`
      @media (hover: hover) {
        &:hover {
          background-color: transparent;
        }
      }

      @media (hover: none) {
        &:active {
          background-color: transparent;
        }
      }
    `};
  `};

  ${sm.mod(EModButtonIcon.true)`
    padding-left: 0;
    padding-right: 0;

    ${sm.mod(EModButtonSize.xsmall)`
      padding-top: 10px;
      padding-bottom: 10px;
      width: ${sv.spacing.large};
    `};

    ${sm.mod(EModButtonSize.small)`
      padding-top: 10px;
      padding-bottom: 10px;
      width: ${mathVars(sv.lineHeight.medium, '+', mathVars(sv.spacing.xxsmall, '*', 2))};
    `};

    ${sm.mod(EModButtonSize.medium)`
      width: ${mathVars(sv.lineHeight.medium, '+', mathVars(sv.spacing.xsmall, '*', 2))};
    `};

     ${sm.mod(EModButtonColor.coffee)`
        ${getButtonColor('transparent', 'gray25', 'coffee')};
    `};

    ${sm.mod(EModButtonColor.white)`
        ${getButtonColor('transparent', 'gray25', 'white')};
    `};

    ${sm.mod(EModButtonColor.pine)`
        ${getButtonColor('transparent', 'gray25', 'pine')};
    `};

    ${sm.mod(EModButtonColor.maroon)`
        ${getButtonColor('transparent', 'gray25', 'maroon')};
    `};
  `};

  ${sm.mod(EModBlock.true)`
    display: flex;
    width: 100%;

    ${sm.mod(EModJustifyContent.center)`
      padding-left: 0;
      padding-right: 0;
    `};
  `};

  ${sm.mod(EModButtonShape.circle)`
    border-radius: 50%;
  `};

  ${Object.keys(EModJustifyContent).map(
    (item) => sm.mod(EModJustifyContent[item])`
      justify-content: ${item};
    `
  )};
  &:disabled,
  &:hover:disabled {
    background-color: ${(props) => {
      return props.disabled && props.progress ? EColor[props.color] : sv.color.gray5
    }};
  },

  ${(props) =>
    props.resetHeight
      ? `
    height: auto;
    padding: 0
  `
      : ``}
`

export default { Block, Icon, ProgressWrapper, Inner }
