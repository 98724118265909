import selectors from './selectors'
import slice from './slice'
import thunks from './thunks'
import actions from './actions'

export {
  slice as clientProductsRequestsSlice,
  selectors as clientProductsRequestsSelectors,
  thunks as clientProductsRequestsThunks,
  actions as clientProductsRequestsActions,
}
