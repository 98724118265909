import apiThunk, { api } from '@ucheba/utils/helpers/api'
import { sliceName } from './constsnts'

const getProgramsStatistics = apiThunk(
  `${sliceName}/getProgramsStatistics`,
  async (params) => {
    const { programTypeValue } = params.data

    const response = await api.get(
      `/entrance-planners/${programTypeValue}/programs/count`,
      params
    )

    return response.data
  }
)

const getSpecialtiesStatistics = apiThunk(
  `${sliceName}/getSpecialtiesStatistics`,
  async (params) => {
    const { programTypeValue } = params.data

    const response = await api.get(
      `/entrance-planners/${programTypeValue}/specialities/count`,
      params
    )

    return response.data
  }
)

export default { getProgramsStatistics, getSpecialtiesStatistics }
