export const LINKS = [
  { subject: 'russian', text: ' по русскому языку' },
  { subject: 'mathematics', text: ' по математике ' },
  { subject: 'social', text: ' по обществознанию' },
  { subject: 'biology', text: ' по биологии' },
  { subject: 'informatics', text: ' по информатике' },
  { subject: 'physics', text: ' по физике' },

  { subject: 'history', text: ' по истории' },
  { subject: 'literature', text: ' по литературе' },
  { subject: 'english', text: ' по английскому языку' },
  { subject: 'chemistry', text: ' по химии' },
]
