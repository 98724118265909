import apiThunk, { api } from '@ucheba/utils/helpers/api'
import { sliceName } from './constants'

const getPlan = apiThunk(`${sliceName}/getPlan`, async (params) => {
  const { programTypeValue } = params.data
  const response = await api.get(`/entrance-planners/${programTypeValue}`, params)

  return response.data
})

const deleteUz = apiThunk(`${sliceName}/deleteUz`, async (params) => {
  const response = await api.delete(`/entrance-planners/institutions`, params)

  return response.data
})

const getProgramIds = apiThunk(`${sliceName}/getProgramIds`, async (params) => {
  const response = await api.get(`/entrance-planners/programs`, params)

  return response.data
})

const getUzIds = apiThunk(`${sliceName}/getUzIds`, async (params) => {
  const response = await api.get(`/entrance-planners/institutions`, params)

  return response.data
})

const postUz = apiThunk(`${sliceName}/postUz`, async (params) => {
  const response = await api.post(`/entrance-planners/institutions`, params?.data, params)

  return { ...response.data, entityIds: params.data.entityIds }
})

export default { getPlan, deleteUz, getProgramIds, getUzIds, postUz }
