import { NextRouter } from 'next/router'
import { NextPageContext } from 'next/dist/shared/lib/utils'
import escapeRegexp from 'lodash.escaperegexp'
import difference from 'lodash.difference'
import { ParsedUrl, ParseOptions, Stringifiable, StringifyOptions } from 'query-string'
import { IDomain } from '@ucheba/store/domain/types'
import { authSelectors } from '@ucheba/store/auth'
import { getParsedCookies, isBrowser, isUchebaLocal, isUchebaLocalFunc } from '../core'
import { coreQuery } from '../../constants/queries'
import {
  IRedirect,
  IRedirectByAuthStatus,
  IStringifyUrl,
  IStringifyUrlQuery,
  TQueryEgeListValues,
} from './types'
import { queryStringBaseOptions, validHostsForRedirect } from './constants'
import { arrayUnique } from '../array'
import { IRequestFunctionProps } from '../api/types'
import { EDataKeys } from '../../types/dataKeys'
import { tmpToken } from '../../constants/core'
import { sendRequest } from '../api'

const queryString = require('query-string')

/** Возвращаешь из роутера значение квери параметра */
// TODO: удалить, так как теперь есть parseUrl(router.asPath).query
export const getQueryValueByRouter = (
  router: NextRouter,
  queryKey: string
): string | string[] | undefined => {
  const fromQuery = router?.query[queryKey]

  if (fromQuery) return fromQuery

  const matchQuery = router?.asPath?.match(new RegExp(`[&?]${queryKey}=(.*)(&|$)`))

  return matchQuery ? matchQuery[1] : undefined
}

/** Возвращает путь с добавленными квери параметрами */
export const getFullRouterPath = (
  source: NextRouter | string,
  query?: string
): string => {
  const url = typeof source === 'string' ? source : source?.asPath

  const path = url.replace(/\?.*/g, '')
  return query ? `${path}?${query}` : path
}

/** Возвращает search params из урла */
export const getSearchParams = (url: string): URLSearchParams => {
  const queries = url.replace(/.*(\?.*)/g, '$1')

  return new URLSearchParams(queries)
}

/** Из query string формирует объект с учетом массивов `foo[]=bar1&foo[]=bar2` */
export const queryStringToObject = (url: string): Record<string, unknown> => {
  const params = getSearchParams(url)
  const obj = {}

  // eslint-disable-next-line no-restricted-syntax
  for (const key of params.keys()) {
    if (params.getAll(key).length > 1) {
      obj[key.replace(/\[]$/g, '')] = params.getAll(key)
    } else {
      const value = params.get(key)
      const isValueArr = /\[]$/g.test(key)

      if (value) {
        obj[key.replace(/\[]$/g, '')] = isValueArr ? [value] : value
      }
    }
  }

  return obj
}

/** Из объекта формирует query string с учетом массивов `foo[]=bar1&foo[]=bar2` */
export const objectToQueryString = (initialObj: Record<string, unknown>): string => {
  const reducer =
    (obj, parentPrefix = '') =>
    (prev, key): string[] => {
      const val = obj[key]
      let prefix = key

      if (parentPrefix) {
        prefix = parentPrefix
          ? `${parentPrefix.replace(/\[]$/g, '')}[]`
          : key.replace(/\[]$/g, '')
      }

      if (val == null || typeof val === 'function') {
        prev.push(`${prefix}=`)

        return prev
      }

      if (['number', 'boolean', 'string'].includes(typeof val)) {
        prev.push(`${prefix}=${encodeURIComponent(val)}`)

        return prev
      }

      prev.push(Object.keys(val).reduce(reducer(val, prefix), []).join('&'))

      return prev
    }

  return Object.keys(initialObj).reduce(reducer(initialObj), []).join('&')
}

/** Мержит старые и новые квери параметры с конвертацией их в query string */
export const getFullQueryString = (
  router: NextRouter,
  newQueryObj?: Record<string, unknown>,
  excludeKeys?: string[]
): string => {
  let oldQueryObj = {}

  if (isBrowser) {
    oldQueryObj = queryStringToObject(window.location.search)
  } else if (router?.asPath) {
    const params = router.asPath.replace(/.*\?(.*)/g, '$1')

    oldQueryObj = queryStringToObject(/=/g.test(params) ? params : '')
  }

  if (excludeKeys) {
    excludeKeys.forEach((key) => {
      delete oldQueryObj[isBrowser && key ? key.replace(/\[]/g, '') : key]
    })
  }

  const allQueries = newQueryObj ? { ...oldQueryObj, ...newQueryObj } : oldQueryObj

  /** Удаляем все пустые квери параметры */
  const cleanQueryObj = Object.keys(allQueries).reduce((acc, queryKey) => {
    const value = allQueries[queryKey]
    const isValueValid = Array.isArray(value) ? value.length : value

    if (value === 'false' || isValueValid) {
      acc[queryKey] = value
    }

    return acc
  }, {})

  return objectToQueryString(cleanQueryObj)
}

export const redirect: IRedirect = ({
  location,
  ctx,
  statusCode = 302,
  withQuery = true,
}) => {
  if (isBrowser) {
    window.location.href = location
  } else if (ctx && ctx.res) {
    ctx.res.writeHead(statusCode, {
      Location: withQuery
        ? getFullRouterPath(location, objectToQueryString(ctx.query))
        : location,
    })
    ctx.res.end()
  }
}

/* Редиректим относительно статуса авторизации
 * Если хотим редиректить при положительном статусе авторизации - передаем true
 * Иначе - false
 * */
export const redirectByAuthStatus: IRedirectByAuthStatus = ({
  ctx,
  store,
  location,
  withQuery,
  condition,
  withTmpUser,
}): Promise<void> => {
  return new Promise((resolve) => {
    const { getState } = store
    const isAuth = authSelectors.isAuth(getState())
    const isNeedRedirect = condition ? isAuth : !isAuth
    const cookies = getParsedCookies(ctx as any)
    const tmpTokenCookie = cookies && cookies[tmpToken]

    if (withTmpUser && tmpTokenCookie) {
      resolve()

      return
    }

    if (isNeedRedirect) {
      redirect({
        location,
        ctx,
        withQuery,
      })
    } else {
      resolve()
    }
  })
}

/* Получение урла куда возвращаем пользователя */
export const getReturnUrl = (source: NextRouter | NextPageContext): string => {
  const originRedirectUrl = (source?.query?.[coreQuery.redirect] as string) || '/'
  const isInnerRoute = /^\//g.test(originRedirectUrl)
  const isExternalRoute = /^http/g.test(originRedirectUrl)
  const isJS = /javascript/g.test(originRedirectUrl)
  let resultUrl = '/'

  if (!isJS) {
    if (isInnerRoute) {
      resultUrl = originRedirectUrl
    } else if (isExternalRoute) {
      try {
        // строка "/" не валидна для конструктора new URL
        // поэтому оборачиваем в try catch
        const { hostname } = new URL(originRedirectUrl)

        const domains = hostname.split('.')
        const clearHost = domains.slice(Math.max(domains.length - 2, 0)).join('.')

        if (validHostsForRedirect.includes(clearHost)) {
          resultUrl = originRedirectUrl
        }
      } catch (error) {
        console.log('error', error)
      }
    }
  }

  return resultUrl
}

/* Редирект на redirect из квери */
export const redirectToReturnUrl = (router: NextRouter): void => {
  const returnUrl = getReturnUrl(router)

  setTimeout(() => {
    window.location.href = returnUrl
  }, 10)
}

/** Возвращается offset по номеру страницы и лимиту */
export const getOffsetByPage = (page = 1, limit = 15): number => {
  return page === 1 ? 0 : page * limit - limit
}

/* Добавляет limit и offset для запроса в апи */
export const addLimitOffset = (
  props: IRequestFunctionProps,
  limit: number,
  isMain?: number
): IRequestFunctionProps => {
  const newProps = { ...props }
  const page = newProps.params[coreQuery.page] || 1

  if (!props?.params?.[coreQuery.page]) {
    delete newProps.params[coreQuery.page]
  }

  if (getOffsetByPage(page, limit)) {
    newProps.params[EDataKeys.offset] = getOffsetByPage(page, limit)
  }

  newProps.params[EDataKeys.limit] = limit

  if (isMain) {
    newProps.params[EDataKeys.isMain] = isMain
  }

  return newProps
}

/** Преобразовывает массив предметов егэ в квери строку */
export const getQueryEgeList = (selectedValues: TQueryEgeListValues): string => {
  return selectedValues.reduce((acc, { set, val }) => {
    let newAcc = acc

    newAcc += `ege_${set}_set=1&ege_${set}_val=${val}&`

    return newAcc
  }, '')
}

/** Удаляет с query string параметр со значением и возвращает новую query string */
export const removeQueryWithValue = (
  source: NextRouter | string,
  queryWithValue: string
): string => {
  const query = typeof source === 'string' ? source : getFullQueryString(source)
  const queryForRegExp = escapeRegexp(queryWithValue)
  const removeRegExp = new RegExp(`&?${queryForRegExp}`, 'g')

  return query.replace(removeRegExp, '')
}

// TODO: заменить собствееные реализации на ту, что поставляется либой query-string
/* Оставляет в урле только определенные параметры. Подробнее: https://github.com/sindresorhus/query-string#pickurl-keys-options */
export const pickFromUrl = (
  url: string,
  target: string[] | ((key: string, value: string | boolean | number) => boolean),
  options: ParseOptions & StringifyOptions = {}
): Stringifiable => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return queryString.pick(url, target, {
    ...queryStringBaseOptions,
    ...options,
  })
}

/* Исключает из урла определенные параметры. Подробнее: https://github.com/sindresorhus/query-string#excludeurl-keys-options */
export const excludeFromUrl = (
  url: string,
  target: string[] | ((key: string, value: string | boolean | number) => boolean),
  options: ParseOptions & StringifyOptions = {}
): string => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return queryString.exclude(url, target, {
    ...queryStringBaseOptions,
    ...options,
  })
}

/* Парсит урл, выделяя из него параметры. Подробнее: https://github.com/sindresorhus/query-string#parsestring-options */
export const parseUrl = (
  url: string,
  options: ParseOptions & StringifyOptions = {}
): ParsedUrl => {
  return queryString.parseUrl(url, {
    ...queryStringBaseOptions,
    ...options,
  })
}

/* Исключает из урла параметры, которые можно передавать как только названием, так и со значениями */
export const excludeExtendedFromUrl = (
  url: string,
  exclude: (string | IStringifyUrlQuery)[],
  options: ParseOptions & StringifyOptions = {}
): string => {
  const optionsObject = {
    ...queryStringBaseOptions,
    ...options,
  }
  let tmpUrl = url

  exclude.forEach((excludeParam) => {
    if (typeof excludeParam === 'string') {
      /* Если нужно исключить весь параметр не зависимо от значения */
      tmpUrl = excludeFromUrl(tmpUrl, [excludeParam], optionsObject)
    } else if (excludeParam) {
      /* Если переданы параметры со значениями, то идем по каждому значению */
      Object.keys(excludeParam).forEach((excludeParamName) => {
        const excludeParamValue = excludeParam[excludeParamName]
        const parsedUrl = parseUrl(tmpUrl as string, optionsObject)
        const queryFromUrl = parsedUrl.query
        const queryFromUrlParamValue = queryFromUrl[excludeParamName] || ''
        const isQueryFromUrlParamValueArray = Array.isArray(queryFromUrlParamValue)

        /* Если значение является массивом, то исключаем значения массива из квери параметров */
        if (Array.isArray(excludeParamValue) || isQueryFromUrlParamValueArray) {
          const excludeParamValueArray = Array.isArray(excludeParamValue)
            ? excludeParamValue
            : [excludeParamValue]

          queryFromUrl[excludeParamName] = Array.isArray(queryFromUrlParamValue)
            ? difference(
                queryFromUrlParamValue.map((value) => String(value)),
                excludeParamValueArray.map((value) => String(value))
              )
            : queryFromUrlParamValue

          tmpUrl = queryString.stringifyUrl(
            {
              url: parsedUrl.url,
              query: queryFromUrl,
            },
            optionsObject
          )
        } else {
          /* Если значение не массив, то исключаем его из квери параметра */
          tmpUrl = excludeFromUrl(
            tmpUrl,
            (name, value) => {
              return name === excludeParamName && value === excludeParamValue
            },
            optionsObject
          )
        }
      })
    }
  })

  return tmpUrl
}

/* Приводит урл к строке с параметрами. Подробнее: https://github.com/sindresorhus/query-string#stringifyurlobject-options */

export const stringifyUrl: IStringifyUrl = (url, params, options = {}, fragment) => {
  const parsedUrl = parseUrl(url)
  // TODO: сделать работу с фрагментами
  const fragmentValue = (fragment || parsedUrl.fragmentIdentifier) as string
  const fragmentIdentifier = fragmentValue ? { fragmentIdentifier: fragmentValue } : {}

  const optionsObject = {
    ...queryStringBaseOptions,
    ...options,
  }

  const allQuery = params.set || { ...parsedUrl.query }

  if (params.include) {
    const includePrepared = params.include

    Object.keys(includePrepared).forEach((includeKey) => {
      const originValue = allQuery[includeKey] || []
      const originValueArray = Array.isArray(originValue) ? originValue : [originValue]
      const includeValue = includePrepared[includeKey]

      allQuery[includeKey] = Array.isArray(includeValue)
        ? arrayUnique([
            ...originValueArray.map((value) => String(value)),
            ...includeValue.map((value) => String(value)),
          ])
        : String(includeValue)
    })
  }

  if (params.update) {
    const { update } = params

    Object.keys(update).forEach((updateKey) => {
      const updateValue = update[updateKey]

      allQuery[updateKey] = Array.isArray(updateValue)
        ? updateValue.map((value) => String(value))
        : updateValue
    })
  }

  let urlWithQueryString = queryString.stringifyUrl(
    {
      url: parsedUrl.url,
      query: {
        ...allQuery,
      },
      ...fragmentIdentifier,
    },
    optionsObject
  )

  if (params.exclude) {
    urlWithQueryString = excludeExtendedFromUrl(
      urlWithQueryString,
      params.exclude,
      optionsObject
    )
  }

  return urlWithQueryString
}

/* Возвращает квери в единичном виде */
export const getSingleQuery = <T = string>(
  query: string | string[] | null | undefined
): T | string | undefined | null => {
  return Array.isArray(query) ? query[0] : query
}

/** Получение регионального поддомена по айди выбранной локации */
export const getRegionDomainById = async ({
  locationId,
}: {
  locationId: number
}): Promise<string> => {
  if (!locationId) return 'www'

  const response = await sendRequest({ url: `/locations/${locationId}/domain` })

  const selectedDomain = response?.data

  return selectedDomain?.slug || 'www'
}

/** Получение href по контексту */
const getHref = (ctx?: NextPageContext): string => {
  return !isBrowser
    ? `//${ctx?.req?.headers?.host}${ctx?.req?.url}`
    : window.location.href
}

/** Получение host регионального поддомена */
export const getHostByRegionDomain = async ({
  locationId,
  ctx,
  basePath,
}: {
  locationId: number
  ctx?: NextPageContext
  basePath?: string
}): Promise<{
  url: string
  domainSlug?: string
}> => {
  const href = getHref(ctx)

  const parsedUrl = parseUrl(href)
  const host = !isBrowser ? ctx?.req?.headers?.host : window?.location?.host
  if (!host || host.includes('localhost')) {
    return { url: parsedUrl.url, domainSlug: 'www' }
  }
  const hostSplit = host.split('.')
  const slug: string = hostSplit[0]

  const regionDomainById = await getRegionDomainById({ locationId })
  const regExp = new RegExp(slug)

  let newUrl = parsedUrl.url
  const domainZone = hostSplit[hostSplit.length - 1]
  /** Добавляем basePath */
  newUrl = newUrl.replace(domainZone, `${domainZone}${basePath ? `${basePath}` : ''}`)

  /** Реплейсим региональный домен */
  newUrl = newUrl.replace(regExp, regionDomainById)

  return {
    url: newUrl,
    domainSlug: regionDomainById,
  }
}

/** Получение полного пути с региональным поддоменом */
export const getUpdatedPathByRegionDomain = async ({
  locationId,
  ctx,
  hrefTo = '',
}: {
  locationId: number
  ctx?: NextPageContext
  hrefTo?: string
}): Promise<string> => {
  const hostWithRegionDomain = await getHostByRegionDomain({
    locationId,
    ctx,
  })

  const href = getHref(ctx)

  const { query } = parseUrl(href)

  const fullPath = `${hostWithRegionDomain.url}${hrefTo}`

  return stringifyUrl(fullPath, {
    update: query,
  })
}

interface IGetQueryElement {
  (
    query: Record<string, string | string[] | null | undefined>,
    queryName: string,
    isArray?: boolean
  ): string | string[] | null
}

export const getQueryElement: IGetQueryElement = (query, queryName, isArray) => {
  const value = query[queryName]

  if (!value) return null

  if (isArray) {
    return Array.isArray(value) ? value : [value]
  }

  return Array.isArray(value) ? value[0] || null : value
}

export const removeQueryParameters = (url: string): string => {
  return url.replace(/\?.*$/, '')
}
