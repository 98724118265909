/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import { IClientProductsRequestsEntity, IClientProductsRequestsState } from './types'
import { extraReducersResponse, responseInitialState } from '../../utils/response'
import { sliceName } from './constants'
import thunks from './thunks'
import { ELoading } from '../../utils/response/types'

export default createSlice({
  name: sliceName,

  initialState: {
    entity: null as IClientProductsRequestsEntity | null,
    ...responseInitialState,
  },

  reducers: {
    resetLoading: (state): void => {
      state.loading = ELoading.idle
    },
    resetError: (state): void => {
      state.error = null
    },
  },

  extraReducers: (builder) => {
    extraReducersResponse<
      IClientProductsRequestsState[typeof sliceName],
      IClientProductsRequestsEntity
    >({
      builder,
      thunk: thunks.send,
      fulfilled: (state, { payload }) => {
        state.entity = payload
      },
    })

    extraReducersResponse<IClientProductsRequestsState[typeof sliceName], null>({
      builder,
      thunk: thunks.patch,
      fulfilled: () => null,
    })
  },
})
