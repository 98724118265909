import { sliceName } from './constants'
import { getStateError, getStateItem } from '../../../utils/core'
import { ISpecialitiesIdSimilarState } from './types'
import { specialitiesIdSimilarAdaptor } from './slice'

const adapterSelectors =
  specialitiesIdSimilarAdaptor.getSelectors<ISpecialitiesIdSimilarState>(
    (state) => state[sliceName]
  )

export default {
  ...adapterSelectors,

  loading: getStateItem<
    ISpecialitiesIdSimilarState,
    ISpecialitiesIdSimilarState[typeof sliceName]['loading']
  >(sliceName, 'loading'),

  total: getStateItem<
    ISpecialitiesIdSimilarState,
    ISpecialitiesIdSimilarState[typeof sliceName]['total']
  >(sliceName, 'total'),

  error: getStateError<ISpecialitiesIdSimilarState>(sliceName),
}
