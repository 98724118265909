/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import { IProfileRegistrationState, IUserData } from './types'
import { extraReducersResponse, responseInitialState } from '../../utils/response'
import { sliceName } from './constants'
import thunks from './thunks'
import { ELoading } from '../../utils/response/types'
import { IUser } from '../../auth/phone/types'

export default createSlice({
  name: sliceName,

  initialState: {
    userData: null as IUserData | null,
    user: null as IUser | null,
    ...responseInitialState,
  },

  reducers: {
    setUserData: (state, { payload }): void => {
      state.userData = payload
    },
    resetLoading: (state): void => {
      state.loading = ELoading.idle
    },
  },

  extraReducers: (builder) => {
    extraReducersResponse<IProfileRegistrationState[typeof sliceName], IUser>({
      builder,
      thunk: thunks.fetch,
      fulfilled: (state, { payload }) => {
        state.user = payload
      },
    })
  },
})
