/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import { sliceName } from './constants'
import { extraReducersResponse, responseInitialState } from '../../utils/response'
import thunks from './thunks'

import {
  IInstitutionItem,
  IInstitutionState,
  IQuestionById,
  IShortChancesOfAdmission,
} from './types'

export default createSlice({
  name: sliceName,

  initialState: {
    currentInstitution: null as IInstitutionItem | null,
    shortChancesOfAdmission: null as IShortChancesOfAdmission | null,
    questionById: null as IQuestionById | null,
    ...responseInitialState,
  },

  reducers: {},

  extraReducers: (builder) => {
    extraReducersResponse<IInstitutionState[typeof sliceName], IShortChancesOfAdmission>({
      builder,
      thunk: thunks.fetchShortChancesOfAdmission,
      fulfilled: (state, { payload }) => {
        state.shortChancesOfAdmission = payload
      },
    })

    extraReducersResponse<IInstitutionState[typeof sliceName], IInstitutionItem>({
      builder,
      thunk: thunks.fetchInstitution,
      fulfilled: (state, { payload }) => {
        state.currentInstitution = payload
      },
    })

    extraReducersResponse<IInstitutionState[typeof sliceName], IQuestionById>({
      builder,
      thunk: thunks.fetchQuestionById,
      fulfilled: (state, { payload }) => {
        state.questionById = payload
      },
    })
  },
})
