import { IFeedbacksState, IInstitutionFeedbacks } from './types'
import { sliceName } from './constants'
import { getStateItem } from '../../utils/core'

export default {
  loading: getStateItem<IFeedbacksState, IFeedbacksState[typeof sliceName]['loading']>(
    sliceName,
    'loading'
  ),

  entity: getStateItem<IInstitutionFeedbacks, IInstitutionFeedbacks>(sliceName, 'entity'),
}
