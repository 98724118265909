import { getStateError, getStateItem } from '../../utils/core'
import { sliceName } from './constants'
import { IPlannerPlanState } from './types'

export default {
  entity: getStateItem<IPlannerPlanState, IPlannerPlanState[typeof sliceName]['entity']>(
    sliceName,
    'entity'
  ),

  entityUzIds: getStateItem<
    IPlannerPlanState,
    IPlannerPlanState[typeof sliceName]['entityUzIds']
  >(sliceName, 'entityUzIds'),

  entityProgramIds: getStateItem<
    IPlannerPlanState,
    IPlannerPlanState[typeof sliceName]['entityProgramIds']
  >(sliceName, 'entityProgramIds'),

  loading: getStateItem<
    IPlannerPlanState,
    IPlannerPlanState[typeof sliceName]['loading']
  >(sliceName, 'loading'),

  error: getStateError<IPlannerPlanState>(sliceName),
}
