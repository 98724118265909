import React, { FC, memo, useCallback, useState } from 'react'
import { useSelector } from 'react-redux'

import { Container } from '@ucheba/ui/components/Container/desktop'
import { Logo } from '@ucheba/ui/components/Logo/desktop'
import { Text } from '@ucheba/ui/components/Text/desktop'

import styled from 'styled-components'

import { sv } from '@ucheba/utils/helpers/styles/variables'
import Link from 'next/link'
import { LINKS } from '../constants'
import { EFooterColorLogo, IFooterProps } from '../types'
import { footerSelectors } from '../store'

import { FooterSocialLinks } from '../base/FooterSocialLinks'
import { FooterNavLinks } from '../base/FooterNavLinks'
import { ETypeRequest } from '../../../pages/for-abiturients/ege/index/types'
import {
  EApplicationFormProductTypes,
  EApplicationFunnels,
} from '../../AppliactionForm/types'

import { ApplicationForm } from '../../AppliactionForm/desktop'

import {
  Block,
  ButtonStyled,
  Column,
  FooterLinks,
  Inner,
  Links,
  LogoLink,
  ShowAllLinks,
  SocialLinks,
  Title,
  TopBlock,
} from '../base/Footer'
import InformerEddu from './InformerEddu'
import { useFooterCore } from '../bll'

const ButtonStyledSC = styled(ButtonStyled)`
  width: 225px;
`

const InformerWrapper = styled.div`
  width: 113px;
  height: 42px;
  background-color: #ffffff;
  padding: 1px;
  border-radius: 10px;
`

const TopWrapper = styled.div<{ color: string }>`
  padding: 32px 0 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid
    ${({ color }) =>
      color === 'black' || color === 'navy' ? sv.color.white25 : 'rgba(3, 15, 35, 0.15)'};
`

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

/** Футер сайта */
const Footer: FC<IFooterProps> = ({ notFoundPage, ...props }) => {
  const color = useSelector(footerSelectors.color)
  const navLinks = useSelector(footerSelectors.navLinks)
  const isShowFooter = useSelector(footerSelectors.isShowFooter)
  const isShowMessengers = useSelector(footerSelectors.isShowMessengers)

  const { isSchool } = useFooterCore()

  const [showAllTrainingEGE, setShowAllTrainingEGE] = useState(false)
  const [showAllTrainingOGE, setShowAllTrainingOGE] = useState(false)
  const [showAllEGE, setShowAllEGE] = useState(false)
  const [showAllOGE, setShowAllOGE] = useState(false)
  const [isOpenFreeLesson, setIsOpenFreeLesson] = useState(false)

  const handleCloseFreeLesson = useCallback((): void => setIsOpenFreeLesson(false), [])
  const handleOpenFreeLesson = useCallback((): void => setIsOpenFreeLesson(true), [])

  if (!isShowFooter) return null

  return (
    <Block color={color} {...props}>
      {!notFoundPage && (
        <Container>
          <TopWrapper color={color}>
            <Title>Готовим к поступлению в лучшие вузы России</Title>

            <ButtonWrapper>
              {isSchool && (
                <InformerWrapper>
                  <InformerEddu />
                </InformerWrapper>
              )}

              <ButtonStyledSC onClick={handleOpenFreeLesson} color='blue' size='small'>
                Бесплатный пробный урок
              </ButtonStyledSC>
            </ButtonWrapper>
          </TopWrapper>

          <TopBlock>
            <Column>
              <Link href='https://school.ucheba.ru/training/ege'>
                <Text fontWeight='bold' fontSize='h4'>
                  Бесплатный тренажер по подготовке к ЕГЭ
                </Text>
              </Link>

              <Links isShow={showAllTrainingEGE}>
                {LINKS.map(({ subject, text }) => (
                  <a
                    key={subject}
                    href={`https://school.ucheba.ru/training/ege/${subject}`}
                  >
                    Тренажер {`ЕГЭ ${text}`}
                  </a>
                ))}
              </Links>

              {!showAllTrainingEGE && (
                <ShowAllLinks
                  color={color}
                  onClick={() => setShowAllTrainingEGE((prevValue) => !prevValue)}
                >
                  Все предметы
                </ShowAllLinks>
              )}
            </Column>

            <Column>
              <Link href='https://school.ucheba.ru/training/oge'>
                <Text fontWeight='bold' fontSize='h4'>
                  Бесплатный тренажер по подготовке к ОГЭ
                </Text>
              </Link>

              <Links isShow={showAllTrainingOGE}>
                {LINKS.map(({ subject, text }) => (
                  <a
                    key={subject}
                    href={`https://school.ucheba.ru/training/oge/${subject}`}
                  >
                    Тренажер {`ОГЭ ${text}`}
                  </a>
                ))}
              </Links>

              {!showAllTrainingOGE && (
                <ShowAllLinks
                  color={color}
                  onClick={() => setShowAllTrainingOGE((prevValue) => !prevValue)}
                >
                  Все предметы
                </ShowAllLinks>
              )}
            </Column>

            <Column>
              <Link href='/for-abiturients/ege'>
                <Text fontWeight='bold' fontSize='h4'>
                  Подготовка к ЕГЭ
                </Text>
              </Link>

              <Links isShow={showAllEGE}>
                {LINKS.map(({ subject, text }) => (
                  <a key={subject} href={`/for-abiturients/ege/${subject}`}>
                    {`ЕГЭ ${text}`}
                  </a>
                ))}
              </Links>

              {!showAllEGE && (
                <ShowAllLinks
                  color={color}
                  onClick={() => setShowAllEGE((prevValue) => !prevValue)}
                >
                  Все предметы
                </ShowAllLinks>
              )}
            </Column>

            <Column>
              <Link href='/for-abiturients/oge'>
                <Text fontWeight='bold' fontSize='h4'>
                  Подготовка к ОГЭ
                </Text>
              </Link>

              <Links isShow={showAllOGE}>
                {LINKS.map(({ subject, text }) => (
                  <a key={subject} href={`/for-abiturients/oge/${subject}`}>
                    {`ОГЭ ${text}`}
                  </a>
                ))}
              </Links>

              {!showAllOGE && (
                <ShowAllLinks
                  color={color}
                  onClick={() => setShowAllOGE((prevValue) => !prevValue)}
                >
                  Все предметы
                </ShowAllLinks>
              )}
            </Column>
          </TopBlock>
        </Container>
      )}

      <Container>
        <Inner color={color}>
          <LogoLink href='/' aria-label='На главную'>
            <Logo color={EFooterColorLogo[color]} />
          </LogoLink>

          <FooterLinks>
            <FooterNavLinks list={navLinks} />
          </FooterLinks>

          {isShowMessengers && (
            <SocialLinks>
              <FooterSocialLinks />
            </SocialLinks>
          )}
        </Inner>
      </Container>

      <ApplicationForm
        funnel={EApplicationFunnels.free_lesson}
        productType={EApplicationFormProductTypes.ege}
        requestTarget={ETypeRequest.ege_lead_free_lesson}
        open={isOpenFreeLesson}
        close={handleCloseFreeLesson}
      />
    </Block>
  )
}

export { Footer }
export default memo(Footer)
