/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import { analyticEvent } from '@ucheba/utils/helpers/analytics/events'
import {
  EAnalyticEvents,
  EAnalyticEventTargets,
  EAnalyticSections,
} from '@ucheba/utils/helpers/analytics/events/types'
import { extraReducersResponse, responseInitialState } from '../../../utils/response'
import { sliceName } from './constants'
import { IRequestId } from './types'
import thunks from './thunks'

export default createSlice({
  name: sliceName,

  initialState: {
    requestId: null as IRequestId | null,
    isParent: null,
    packageId: 6,
    successAddParent: null,
    ...responseInitialState,
  },

  reducers: {
    setPackage: (state, { payload }): void => {
      state.packageId = payload
    },
    setIsParent: (state, { payload }): void => {
      state.isParent = payload
    },
  },

  extraReducers: (builder) => {
    extraReducersResponse({
      builder,
      thunk: thunks.request,
      fulfilled: (state, action) => {
        analyticEvent({
          targets: [EAnalyticEventTargets.fb, EAnalyticEventTargets.ur],
          eventName: EAnalyticEvents.consultSubmitLead,
          section: EAnalyticSections.consultLendingForm,
        })

        state.requestId = action.payload
      },
      rejected: (state, action) => {
        state.requestId = action.payload
      },
    })
    extraReducersResponse({
      builder,
      thunk: thunks.addParent,
      fulfilled: (state, action) => {
        state.successAddParent = action.payload
      },
    })
  },
})
