import apiThunk, { api } from '@ucheba/utils/helpers/api'
import { sliceName } from './constants'

const send = apiThunk(`${sliceName}/send`, async (params) => {
  const response = await api.post(`/event`, params?.data, params)

  return response.data
})

export default { send }
