import { useEffect, useState } from 'react'

/** Создает div с заданным id, если такого еще нет и возвращает его */
export const useDivInBody = (id: string): Node | null => {
  const [divElement, setDivElement] = useState(null as Node | null)

  useEffect(() => {
    let element = document.getElementById(id)

    if (element) {
      setDivElement(element)
    } else {
      element = document.createElement('div')
      element.id = id

      document.body.appendChild(element)
      setDivElement(element)
    }

    return (): void => {
      if (divElement && divElement.parentNode === document.body) {
        document.body.removeChild(divElement)
      }
    }
  }, [id]) // divElement не добавлять в зависимости

  return divElement
}
