import selectors from './selectors'
import slice from './slice'
import thunks from './thunks'
import actions from './actions'

export {
  slice as pageDataProfSlice,
  selectors as pageDataProfSelectors,
  thunks as pageDataProfThunks,
  actions as pageDataProfActions,
}
