import React, { ErrorInfo, ReactNode } from 'react'
import ErrorHandler from './ErrorHandler'

interface Props {
  // eslint-disable-next-line react/require-default-props
  children?: ReactNode
}
interface State {
  hasError: boolean
}

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    // Define a state variable to track whether is an error or not
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(): State {
    // Update state so the next render will show the fallback UI
    return { hasError: true }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    // You can use your own error logging service here
    console.error('Uncaught error:', { error, errorInfo })
    ErrorHandler(error, errorInfo)
  }

  render(): ReactNode {
    const { hasError } = this.state
    const { children } = this.props
    // Check if the error is thrown
    if (hasError) {
      // You can render any custom fallback UI
      return (
        <div>
          <h1>Произошла ошибка, скоро все будет исправлено!</h1>
          <a href='/'> На главную </a>
        </div>
      )
    }
    // Return children components in case of no error
    return children
  }
}

export default ErrorBoundary
