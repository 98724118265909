import React, { FC, memo } from 'react'
import SC from '../base/Text.styles'
import { ITextProps } from '../types'

const { Block } = SC

/** Текстовый блок */
const Text: FC<ITextProps> = (props) => {
  const {
    html = true,
    wordBreak = 'break-word',
    as = 'div',
    textDecoration,
    children,
    textAlign,
    opacity,
    firstLetterUppercase,
    fontSize,
    fontWeight,
    lineHeight,
    textShadow,
    ...otherProps
  } = props

  return (
    <>
      {html ? (
        <Block
          wordBreak={wordBreak}
          textAlign={textAlign}
          textDecoration={textDecoration}
          firstLetterUppercase={firstLetterUppercase}
          fontSize={fontSize}
          fontWeight={fontWeight}
          lineHeight={lineHeight}
          textShadow={textShadow}
          as={as}
          dangerouslySetInnerHTML={{ __html: `${children}` }}
          opacity={opacity}
          {...otherProps}
        />
      ) : (
        <Block
          wordBreak={wordBreak}
          textAlign={textAlign}
          textDecoration={textDecoration}
          firstLetterUppercase={firstLetterUppercase}
          fontSize={fontSize}
          fontWeight={fontWeight}
          lineHeight={lineHeight}
          textShadow={textShadow}
          as={as}
          opacity={opacity}
          {...otherProps}
        >
          {children}
        </Block>
      )}
    </>
  )
}

export { Text }
export default memo(Text)
