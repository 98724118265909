import { sliceName } from './constants'
import { getStateError, getStateItem } from '../utils/core'
import { IInstitutionsState } from './types'
import { institutionsAdaptor } from './slice'

const adapterSelectors = institutionsAdaptor.getSelectors<IInstitutionsState>(
  (state) => state[sliceName]
)

export default {
  ...adapterSelectors,

  loading: getStateItem<
    IInstitutionsState,
    IInstitutionsState[typeof sliceName]['loading']
  >(sliceName, 'loading'),

  total: getStateItem<IInstitutionsState, IInstitutionsState[typeof sliceName]['total']>(
    sliceName,
    'total'
  ),

  error: getStateError<IInstitutionsState>(sliceName),
}
