import apiThunk, { api } from '@ucheba/utils/helpers/api'
import { sliceName } from './constants'

const fetch = apiThunk(`${sliceName}/fetch`, async (params) => {
  const response = await api.get(`/specialities/${params?.data.id}`, params)

  return response.data
})

const fetchMin = apiThunk(`${sliceName}/fetchMin`, async (params) => {
  const response = await api.get(`/specialities/${params?.data.id}/min`, params)

  return response.data
})

const fetchMinItems = apiThunk(`${sliceName}/fetchMinItems`, async (params) => {
  const response = await api.get(`/specialities/min`, params)

  return response.data
})

export default { fetch, fetchMin, fetchMinItems }
