import React, { FC } from 'react'
import { Notice } from '@ucheba/ui/components/Notice/desktop'
import { Text } from '@ucheba/ui/components/Text/desktop'
import { Spacing } from '@ucheba/ui/components/Spacing/desktop'
import { Button } from '@ucheba/ui/components/Button/desktop'
import { ButtonGroup } from '@ucheba/ui/components/ButtonGroup/desktop'
import { defineLocationNotice } from '@ucheba/utils/middlewares/defineLocationMiddleware'
import { IDefineLocationNoticeProps } from '../types'
import { useDefineLocationNotice } from '../bll'

/** Нотис с определением геолокации */
const DefineLocationNotice: FC<IDefineLocationNoticeProps> = () => {
  const { title, onClickAcceptHandler, onClickDeclineHandler, notice } =
    useDefineLocationNotice()

  return (
    <Notice hideProgress showClose={false} open={notice.open}>
      <Text fontWeight='bold'>{title}</Text>

      <Spacing spacing='xxsmall' />

      <ButtonGroup spacing='xxsmall'>
        <Button size='small' onClick={onClickAcceptHandler} color='blue'>
          {defineLocationNotice.buttons.accept.buttonText}
        </Button>

        <Button size='small' onClick={onClickDeclineHandler} color='blue' view='link'>
          {defineLocationNotice.buttons.decline.buttonText}
        </Button>
      </ButtonGroup>
    </Notice>
  )
}

export default DefineLocationNotice
