import selectors from './selectors'
import slice from './slice'
import thunks from './thunks'
import actions from './actions'

export {
  slice as profileSlice,
  selectors as profileSelectors,
  thunks as profileThunks,
  actions as profileActions,
}
