import apiThunk, { api } from '@ucheba/utils/helpers/api'
import { sliceName } from './constants'

/** Получение текущего шага */
const fetch = apiThunk(`${sliceName}/getStep`, async (params) => {
  const response = await api.get(
    `/forms/${params.data.type || 'profile'}/${params?.data.userId}`,
    params
  )

  return response.data
})

export default { fetch }
