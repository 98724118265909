import { createSlice } from '@reduxjs/toolkit'
import { sliceName } from './constants'

export default createSlice({
  name: sliceName,
  initialState: {
    siteName: null as unknown as string,
  },
  reducers: {
    setSiteName: (state, { payload }) => {
      // eslint-disable-next-line no-param-reassign
      state.siteName = payload
    },
  },
})
