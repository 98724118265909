import { useSelector } from 'react-redux'
import { profileSelectors } from '@ucheba/store/profile'
import { useMemo } from 'react'

/** Хук проверяет дал ли пользователь разрешение писать в один из переданных мессенджеров  */
export const useAllowedMessangers = (msn: string[]): boolean => {
  const profile = useSelector(profileSelectors.entity)

  return useMemo(() => {
    if (profile) {
      const { messengers } = profile

      if (!messengers.length || !msn?.length) return false

      return msn.some((name) =>
        messengers.some(
          ({ messengerType, isAllowed }) => isAllowed && messengerType === name
        )
      )
    }

    return false
  }, [profile, msn])
}
