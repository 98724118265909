/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import { sliceName } from './constants'
import { extraReducersResponse, responseInitialState } from '../../utils/response'
import thunks from './thunks'

import { IInstitutionFeedbacks, IFeedbacksState } from './types'

export default createSlice({
  name: sliceName,

  initialState: {
    entity: {} as IInstitutionFeedbacks,
    ...responseInitialState,
  },

  reducers: {},

  extraReducers: (builder) => {
    extraReducersResponse<IFeedbacksState[typeof sliceName], IInstitutionFeedbacks>({
      builder,
      thunk: thunks.fetchFeedbacks,
      fulfilled: (state, { payload }) => {
        state.entity = payload
      },
    })
  },
})
