import { createSlice } from '@reduxjs/toolkit'
import { sliceName } from './constants'
import { extraReducersResponse, responseInitialState } from '../../utils/response'
import { IInstitutionsProgramTypes, IInstitutionsProgramTypesState } from './types'
import thunks from './thunks'

export default createSlice({
  name: sliceName,

  initialState: {
    programTypes: [] as IInstitutionsProgramTypes[],
    ...responseInitialState,
  },

  reducers: {},

  extraReducers: (builder) => {
    extraReducersResponse<
      IInstitutionsProgramTypesState[typeof sliceName],
      IInstitutionsProgramTypes[]
    >({
      builder,
      thunk: thunks.fetch,
      fulfilled: (state, { payload }) => {
        // eslint-disable-next-line no-param-reassign
        state.programTypes = payload
      },
    })
  },
})
