import apiThunk, { api, getHttpStatusText } from '@ucheba/utils/helpers/api'
import { getFullRouterPath, objectToQueryString } from '@ucheba/utils/helpers/router'
import { sliceName } from './constants'

const fetch = apiThunk(`${sliceName}/fetch`, async (params) => {
  const { data = {} } = params

  const { type, id } = data

  delete data.type

  delete data.id

  const response = await api.get(
    getFullRouterPath(`/feedbacks/${type}/${id}`, objectToQueryString(data)),
    { ...params, data }
  )

  return response.data
})

const fetchOrder = apiThunk(`${sliceName}/fetchOrder`, async (params) => {
  const { data = {} } = params

  const { feedbackId, type, id } = data

  delete data.type

  delete data.feedbackId

  delete data.id

  const response = await api.get(
    getFullRouterPath(
      `/feedbacks/${feedbackId}/order/${type}/${id}`,
      objectToQueryString(data)
    ),
    { ...params, data }
  )

  return response.data
})

const send = apiThunk(`${sliceName}/sendFeedback`, async (params) => {
  const { data = {} } = params
  const { text, id, rate, parentId, type } = data

  delete data.type
  delete data.text
  delete data.id
  delete data.rate
  delete data.parentId

  try {
    await api.post(
      `/feedbacks/${type}/${id}`,
      {
        text,
        rate,
        parentId,
      },
      params
    )

    return { status: 200 }
  } catch (err) {
    const { status } = err.response
    return {
      error: getHttpStatusText(status),
    }
  }
})

const rate = apiThunk(`${sliceName}/rateFeedback`, async (params) => {
  const { isUseful, id } = params.data

  try {
    await api.post(`/feedbacks/${id}/vote`, { isUseful }, params)

    return { status: 200 }
  } catch (err) {
    const { status } = err.response
    return {
      error: getHttpStatusText(status),
    }
  }
})

export default { fetch, fetchOrder, send, rate }
