import React, { FC } from 'react'
import { Layout as LayoutTouch } from '@ucheba/ui/components/Layout/touch'
import { Layout as LayoutDesktop } from '@ucheba/ui/components/Layout/desktop'
import { useToggle } from '@ucheba/utils/hooks/useToggle'
import { isBrowser } from '@ucheba/utils/helpers/core'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import { ILayoutProps } from './types'
import { Footer as FooterDesktop } from '../Footer/desktop'
import { Footer as FooterTouch } from '../Footer/touch'
import { Header as HeaderDesktop } from '../Header/desktop'
import { Header as HeaderTouch } from '../Header/touch'
import { SidebarContent } from '../SidebarContent/touch'
import { useInitMenuByUzType } from '../../../utils/hooks/useInitMenuByUzType'

const Sidebar = dynamic(() => import('@ucheba/ui/components/Sidebar/touch'))

const Layout: FC<ILayoutProps> = (props) => {
  const { isLayout, isTouch, children } = props
  const [isSidebarOpen, toggleSidebarOpen] = useToggle(false)

  const { pathname } = useRouter()

  const notFoundPage = pathname === '/404'

  const { href } = useInitMenuByUzType()

  const SidebarTouch: FC = () => (
    <>
      {isBrowser && (
        <Sidebar open={isSidebarOpen} onClickClose={toggleSidebarOpen}>
          <SidebarContent />
        </Sidebar>
      )}
    </>
  )

  if (isTouch) {
    return (
      <LayoutTouch
        Sidebar={isLayout ? <SidebarTouch /> : null}
        Header={
          isLayout && !notFoundPage ? (
            <HeaderTouch toggleSidebar={toggleSidebarOpen} />
          ) : null
        }
        Footer={isLayout ? <FooterTouch notFoundPage={notFoundPage} /> : null}
      >
        {children}
      </LayoutTouch>
    )
  }

  return (
    <LayoutDesktop
      Header={
        isLayout && !notFoundPage ? <HeaderDesktop $activeMenuItemHref={href} /> : null
      }
      Footer={isLayout ? <FooterDesktop notFoundPage={notFoundPage} /> : null}
    >
      {children}
    </LayoutDesktop>
  )
}

export default Layout
