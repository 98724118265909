/* eslint-disable no-param-reassign */
import { createEntityAdapter, createSlice, EntityState } from '@reduxjs/toolkit'
import { IItemOfInstitutionsRankingsParticipants } from './types'
import { IResponseInitialState } from '../../../../utils/response/types'
import { extraReducersResponse, responseInitialState } from '../../../../utils/response'
import { sliceName } from './constants'
import thunks from './thunks'

export const institutionsRankingsParticipantsAdapter =
  createEntityAdapter<IItemOfInstitutionsRankingsParticipants>()

export default createSlice({
  name: sliceName,

  initialState:
    institutionsRankingsParticipantsAdapter.getInitialState<IResponseInitialState>(
      responseInitialState
    ),

  reducers: {},

  extraReducers: (builder) => {
    extraReducersResponse<
      EntityState<IItemOfInstitutionsRankingsParticipants>,
      IItemOfInstitutionsRankingsParticipants
    >({
      builder,
      thunk: thunks.fetch,
      options: {
        withoutCondition: true,
      },
      fulfilled: (state, action) => {
        institutionsRankingsParticipantsAdapter.upsertOne(state, action.payload)
      },
    })
  },
})
