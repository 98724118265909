import { locationAllRussiaId } from '@ucheba/utils/middlewares/defineLocationMiddleware'
import { ILocation } from './types'
import { locationAllRussiaContent } from './constants'

const getNewAllRussiaLocation = (location: ILocation): ILocation => {
  return {
    ...location,
    name: locationAllRussiaContent.name,
    nameGenitive: locationAllRussiaContent.nameGenitive,
    namePrepositional: locationAllRussiaContent.namePrepositional,
  }
}

/** Изменить контент (текст) локации, если эта локация является "Вся Россия" ("Россия" - как она приходит из АПИ) */
export const changeLocationIfAllRussiaLocation = (
  locations: ILocation | ILocation[],
  id?: number
): ILocation | ILocation[] => {
  if (Array.isArray(locations)) {
    return locations.map((location) => {
      if (location.id === locationAllRussiaId) {
        return getNewAllRussiaLocation(location)
      }

      return location
    })
  }

  if (id === locationAllRussiaId) {
    return getNewAllRussiaLocation(locations)
  }

  return locations
}
