import apiThunk, { api } from '@ucheba/utils/helpers/api'
import { sliceName } from './constants'

const fetchPartnerEmployers = apiThunk(`${sliceName}/partners`, async (params) => {
  const response = await api.get(`/institutions/${params.data.id}/partners`, params)

  return response.data
})

export default {
  fetchPartnerEmployers,
}
