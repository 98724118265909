/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import { EColor } from '@ucheba/utils/helpers/styles/variables'
import { EHeaderColor } from '../types'
import { sliceName } from './constants'
import { headerSlice } from './index'

const initialState = {
  color: 'black' as keyof typeof EHeaderColor,
  fade: 'white' as keyof typeof EColor,
  width: 'auto',
  isShowNavigation: true,
  isShowHeader: true,
  changedId: 0,
  isShowPhone: true,
}

export default createSlice({
  name: sliceName,

  initialState,

  reducers: {
    setColor: (state, { payload }): void => {
      state.color = payload
      headerSlice.caseReducers.changeId(state)
    },

    setFade: (state, { payload }): void => {
      state.fade = payload
      headerSlice.caseReducers.changeId(state)
    },

    setWidth: (state, { payload }): void => {
      state.width = payload
      headerSlice.caseReducers.changeId(state)
    },

    changeId: (state): void => {
      state.changedId = Date.now()
    },

    toggleNavigation: (state, { payload }): void => {
      state.isShowNavigation = payload
    },

    toggleHeader: (state, { payload }): void => {
      state.isShowHeader = payload
    },

    showPhone: (state, { payload }) => {
      state.isShowPhone = payload
    },
  },
})
