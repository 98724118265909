export const bannerNoticeId = 'bannerNotice'
export const bannerBottomSheetId = 'bannerNotice'

export const cookieSession = 'bannerSession'
export const cookieSessionCount = 'bannerSessionCount'
export const cookieBannerBlock = 'bannerSessionBlock'

export const queryBannerId = 'bannerId'
export const queryAdvBlockId = 'advBlockId'

export const timer = {
  session: 3600, // время сессии
  break: 0, // перерыв между показами
  counter: 4, // кол-во показов за сессию
}

// Список путей страниц на которых не отображается BannerBottomSticky
export const bottomStickyExclusionPaths = [
  '/for-abiturients/planner/programs',
  '/for-abiturients/planner/interests',
  '/for-abiturients/planner/specialties',
  '/for-abiturients/planner/plan',
  '/for-abiturients/events/[id]',
  '/uz/[id]',
  '/uz/[id]/olympiads',
  '/uz/[id]/teachers',
  '/uz/[id]/promotions',
  '/uz/[id]/programs',
  '/uz/[id]/programs/[slug]',
  '/uz/[id]/partner-employers',
  '/uz/[id]/divisions',
  '/uz/[id]/articles',
  '/uz/[id]/questions',
  '/uz/[id]/feedbacks',
  '/uz/[id]/news',
  '/uz/[id]/news/[newsId]',
  '/events/opendoors',
  '/events/opendoors/calendar',
  '/events/opendoors/vuz',
  '/404',

  // далее список где есть RequestWidget
  '/for-abiturients/ege/[subject]',
  '/for-abiturients/ege/free-lessons',
  '/for-abiturients/ege',
  '/for-abiturients/ege/quest',
  '/for-abiturients/ege/short-course/[subject]',
  '/for-abiturients/ege/test',
  '/for-abiturients/oge/[subject]',
  '/for-abiturients/oge',
  '/for-abiturients/prof',
  '/for-abiturients/school',
  '/for-abiturients/school/subscription',
  '/for-abiturients/school/tutors/ege',
  '/for-abiturients/school/tutors/oge',
  '/for-abiturients/school/tutors/ege/[subject]',
  '/for-abiturients/school/tutors/oge/[subject]',
  '/events/admission-support',
  '/for-abiturients/prof/test',
  '/for-abiturients/prof/test/[id]/result',
  '/for-abiturients/prof/test/[id]/payment',
  '/program/[id]',
  `/for-abiturients/prof/test/[id]/responses`,
]
