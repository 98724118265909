import { getStateError, getStateItem } from '../../utils/core'
import { IEventsSimilarState } from './types'
import { sliceName } from './constants'

export default {
  entity: getStateItem<
    IEventsSimilarState,
    IEventsSimilarState[typeof sliceName]['entity']
  >(sliceName, 'entity'),

  loading: getStateItem<
    IEventsSimilarState,
    IEventsSimilarState[typeof sliceName]['loading']
  >(sliceName, 'loading'),

  error: getStateError<IEventsSimilarState>(sliceName),
}
