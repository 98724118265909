import { IInstitutionsRankingsState, IItemOfInstitutionsRankings } from './types'
import { institutionsRankingsAdapter } from './slice'
import { sliceName } from './constants'
import { getStateById, getStateError } from '../../utils/core'

const adapterSelectors =
  institutionsRankingsAdapter.getSelectors<IInstitutionsRankingsState>(
    (state) => state[sliceName]
  )

export default {
  ...adapterSelectors,

  byId: getStateById<IInstitutionsRankingsState, IItemOfInstitutionsRankings>(
    adapterSelectors
  ),

  error: getStateError<IInstitutionsRankingsState>(sliceName),
}
