import { getStateItem } from '../utils/core'
import { IQuestionsState } from './types'
import { sliceName } from './constants'

export default {
  entity: getStateItem<IQuestionsState, IQuestionsState[typeof sliceName]['entity']>(
    sliceName,
    'entity'
  ),

  order: getStateItem<IQuestionsState, IQuestionsState[typeof sliceName]['order']>(
    sliceName,
    'order'
  ),

  loading: getStateItem<IQuestionsState, IQuestionsState[typeof sliceName]['loading']>(
    sliceName,
    'loading'
  ),
}
