/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import { EFooterColor } from '../types'
import { sliceName } from './constants'
import { footerSlice } from './index'

const initialState = {
  color: 'silver' as keyof typeof EFooterColor,
  changedId: 0,
  isShowFooter: true,
  navLinks: null as
    | {
        name: string
        href: string
      }[]
    | null,
  isShowMessengers: true,
}

export default createSlice({
  name: sliceName,

  initialState,

  reducers: {
    setColor: (state, { payload }): void => {
      state.color = payload
      footerSlice.caseReducers.changeId(state)
    },

    changeId: (state): void => {
      state.changedId = Date.now()
    },

    toogleFooter: (state, { payload }) => {
      state.isShowFooter = payload
    },

    setNavLinks: (state, { payload }) => {
      state.navLinks = payload
    },

    setShowMessengers: (state, { payload }) => {
      state.isShowMessengers = payload
    },
  },
})
