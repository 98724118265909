import styled, { StyledProps } from 'styled-components'
import { sm } from '@ucheba/utils/helpers/styles/mixins'
import { sv } from '@ucheba/utils/helpers/styles/variables'
import { EModActive, IModActive } from '@ucheba/utils/helpers/styles/mod.types'
import { IPropWidth } from '@ucheba/utils/helpers/components/prop.types'
import {
  ENavigationColor,
  EModNavigationColor,
  IModNavigationColor,
  IModIsFadeExist,
  EModIsFadeExist,
} from '../types'
// width: max-content;
const Item = styled.li<StyledProps<IModActive & IModNavigationColor>>`
  margin-right: ${sv.spacing.usmall};
  line-height: 1.38;

  button,
  a {
    padding-top: ${sv.spacing.small};
    padding-bottom: ${sv.spacing.small};
    font-weight: ${sv.fontWeight.medium};
    transition: ${sv.duration.fast};
    ${sm.linkColor.black};
    width: max-content;
  }

  &:last-child {
    margin-right: 0;
  }
`

const List = styled.ul`
  display: flex;
  align-items: center;
  overflow-y: hidden;
  ${sm.resetList};

  &::-webkit-scrollbar {
    display: none;
  }
`

const Block = styled.nav<StyledProps<IModIsFadeExist & IPropWidth>>`
  ${sm.mod(EModIsFadeExist.true)`
    display: flex;
    position: relative;
    align-items: center;
  `}

  width: ${(props) => props.width};
`

const A = styled.a<StyledProps<IModActive & IModNavigationColor>>`
  button,
  a {
    padding-top: ${sv.spacing.small};
    padding-bottom: ${sv.spacing.small};
    font-weight: ${sv.fontWeight.medium};
    transition: ${sv.duration.fast};
    ${sm.linkColor.black};
    width: max-content;
  }

  ${Object.keys(EModNavigationColor).map(
    (item) => sm.mod(EModNavigationColor[item])`
      button,
      a {
        color: ${sv.color[item]};

        ${sm.mod(EModActive.true)`
          background-color: ${sv.color[ENavigationColor[item]]};
        `};

        &:hover {
          color: ${sv.color[item]};
          background-color: ${sv.color[ENavigationColor[item]]};
        }
      }
    `
  )};

  ${sm.mod(EModActive.true)`
    button,
    a {
      font-weight: ${sv.fontWeight.bold};
    }
  `};
`

export default { Block, List, Item, A }
