import React, { FC, memo } from 'react'
import styled from 'styled-components'

const Block = styled.div`
  .gr__wrap {
    margin: 0;
    height: 40px;
    width: 111.1px;
    border: none;
    padding: 7px;
  }

  .gr__big-rating--wrapper {
    padding-right: 4px;
    margin-right: 5.9px;
  }

  .gr__big-rating--wrapper .logo {
    zoom: 0.5;
  }

  .gr__big-rating--wrapper {
    margin-right: 6px;
  }

  .gr__api-big {
    font-size: 12px;
    color: black;
  }

  .gr__line {
    padding-right: 4px;
  }

  .gr__star {
    width: 10px;
    height: 10px;
  }
`

const InformerEddu: FC = () => {
  return (
    <>
      <Block id='getRatingFromEddu' data-id='83166' />
      <script type='text/javascript' src='https://eddu.pro/getRating.js' />
    </>
  )
}

export default memo(InformerEddu)
