import apiThunk, { api } from '@ucheba/utils/helpers/api'
import { sliceName } from './constants'

const fetchOlympiads = apiThunk(`${sliceName}/olympiads`, async (params) => {
  const response = await api.get(`/olympiads`, params)
  return response.data
})

export default {
  fetchOlympiads,
}
