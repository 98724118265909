import apiThunk, { api } from '@ucheba/utils/helpers/api'
import { addLimitOffset } from '@ucheba/utils/helpers/router'
import { sliceName } from './constants'

const fetch = apiThunk(`${sliceName}/fetch`, async (params) => {
  const { limit } = params.data

  const res = await api.get(`/olympiads/search`, addLimitOffset(params, limit || 15))

  return res.data
})

const fetchByFriendlyURL = apiThunk(`${sliceName}/fetchByFriendlyURL`, async (params) => {
  const { limit } = params.data

  const res = await api.get(
    `olympiads/search-by-friendly-url`,
    addLimitOffset(params, limit || 15)
  )

  return res.data
})

export default { fetch, fetchByFriendlyURL }
