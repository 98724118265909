import React, { FC, memo } from 'react'
import { ILoader } from '../types'
import SC from '../base/styles'

const { Loader: LoaderSC, LoaderCircle } = SC

const Loader: FC<ILoader> = () => {
  return (
    <LoaderSC>
      <LoaderCircle />

      <LoaderCircle />

      <LoaderCircle />

      <LoaderCircle />
    </LoaderSC>
  )
}

export default memo(Loader)
