import { IInstitutionsProgramsState } from './types'
import { sliceName } from './constants'
import { getStateItem } from '../../utils/core'

export default {
  entity: getStateItem<
    IInstitutionsProgramsState,
    IInstitutionsProgramsState[typeof sliceName]['entity']
  >(sliceName, 'entity'),

  preparatory: getStateItem<
    IInstitutionsProgramsState,
    IInstitutionsProgramsState[typeof sliceName]['preparatory']
  >(sliceName, 'preparatory'),

  programsHigher: getStateItem<
    IInstitutionsProgramsState,
    IInstitutionsProgramsState[typeof sliceName]['programsHigher']
  >(sliceName, 'programsHigher'),

  programsVocational: getStateItem<
    IInstitutionsProgramsState,
    IInstitutionsProgramsState[typeof sliceName]['programsVocational']
  >(sliceName, 'programsVocational'),

  programsMaster: getStateItem<
    IInstitutionsProgramsState,
    IInstitutionsProgramsState[typeof sliceName]['programsMaster']
  >(sliceName, 'programsMaster'),

  programsPostgraduate: getStateItem<
    IInstitutionsProgramsState,
    IInstitutionsProgramsState[typeof sliceName]['programsPostgraduate']
  >(sliceName, 'programsPostgraduate'),

  programsCourse: getStateItem<
    IInstitutionsProgramsState,
    IInstitutionsProgramsState[typeof sliceName]['programsCourse']
  >(sliceName, 'programsCourse'),

  programsMBA: getStateItem<
    IInstitutionsProgramsState,
    IInstitutionsProgramsState[typeof sliceName]['programsMBA']
  >(sliceName, 'programsMBA'),

  programsSchool: getStateItem<
    IInstitutionsProgramsState,
    IInstitutionsProgramsState[typeof sliceName]['programsSchool']
  >(sliceName, 'programsSchool'),

  queryString: getStateItem<
    IInstitutionsProgramsState,
    IInstitutionsProgramsState[typeof sliceName]['queryString']
  >(sliceName, 'queryString'),

  priceFrom: getStateItem<
    IInstitutionsProgramsState,
    IInstitutionsProgramsState[typeof sliceName]['priceFrom']
  >(sliceName, 'priceFrom'),

  priceTo: getStateItem<
    IInstitutionsProgramsState,
    IInstitutionsProgramsState[typeof sliceName]['priceTo']
  >(sliceName, 'priceTo'),

  loading: getStateItem<
    IInstitutionsProgramsState,
    IInstitutionsProgramsState[typeof sliceName]['loading']
  >(sliceName, 'loading'),
}
